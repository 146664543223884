import { Grid } from '@chakra-ui/react'
import { FeaturedProductColumn } from '@/columns'

export default function FeaturedProductStack({ ...grid }) {
  return (
    <Grid {...grid} className="FeaturedProductStack">
      {() =>
        grid.columns.map((column) => (
          <FeaturedProductColumn
            spacing={30}
            key={column.id}
            {...{ ...column }}
          />
        ))
      }
    </Grid>
  )
}
