import { gql } from 'graphql-request'
/** MODEL: ASSET */
const PhotoFrag = gql`
  fragment PhotoFrag on Asset {
    id
    url
    width
    imageName
    height
    fileName
    mimeType
    size
  }
`

const CardMediaFrag = gql`
  fragment CardMediaFrag on Asset {
    assetType
    fileName
    handle
    height
    id
    imageName
    mimeType
    size
    stage
    url
    width
  }
`

/** MODEL: NavLink */
const NavLinkFrag = gql`
  fragment NavLinkFrag on NavLink {
    id
    navLinkSlug
    isExternal
    navLinkLabel
    navLinkName
    navLinkSize
    navLinkUrl
    navLinkVariant
  }
`
/** MODEL: Button */
const CardButtonFrag = gql`
  fragment CardButtonFrag on Button {
    href
    id
    label
    slug
    variant
    info
    size
  }
`

/** MODEL: Card */
const CardColumnFrag = gql`
  fragment CardColumnFrag on Card {
    id
    cardName
    cardSlug
    headline
    subheadline
    copy
    note
    cardSize
    cardVariant
    colSpan
    mediaStyle
    cardCloudMedia
    cardButton {
      href
      id
      label
      slug
      variant
      info
      size
    }
  }
`
/** MODEL: Company */
const CompanyFrag = gql`
  fragment CompanyFrag on Company {
    id
    companyName
    companyHeadline
    companyLogo {
      id
      width
      height
      url
      imageName
      fileName
      mimeType
      size
    }
  }
`

/** MODEL: Partnership */
const PartnershipFrag = gql`
  fragment PartnershipFrag on Partnership {
    partnershipName
    partnershipLabel
    partnerImage {
      height
      id
      width
      imageName
      url
    }
  }
`
/** MODEL: Person */
const PersonFrag = gql`
  fragment PersonFrag on Person {
    id
    name
    role
    photo {
      ...PhotoFrag
    }
    company {
      ...CompanyFrag
    }
  }
  ${[PhotoFrag, CompanyFrag]}
`
export {
  PersonFrag,
  PartnershipFrag,
  CardColumnFrag,
  CardButtonFrag,
  CardMediaFrag,
  NavLinkFrag
}

// const ButtonFrag = gql`
//   fragment ButtonFrag on Button {
//     href
//     id
//     label
//     slug
//     variant
//     info
//     size
//   }
// `
// const ButtonOnCardBlockFrag = gql`
//   fragment ButtonOnCardBlockFrag on Button {
//     href
//     id
//     label
//     slug
//     variant
//     info
//     size
//   }
// `
// const TooltipFrag = gql`
//   fragment TooltipFrag on Tooltip {
//     id
//     tooltipAction
//     tooltipCopy
//     tooltipName
//     tooltipSlug
//     tooltipLayerStyle
//   }
// `

// const AssetFrag = gql`
//   fragment AssetFrag on Asset {
//     id
//     width
//     height
//     url
//     imageName
//     fileName
//     mimeType
//     size
//   }
// `
// const LogoCloudFrag = gql`
//   fragment LogoCloudFrag on LogoCloud {
//     id
//     title
//     companies {
//       ...CompanyFrag
//     }
//   }
//   ${[CompanyFrag]}
// `

// export {
//   NavLinkFrag,
//   PersonFrag,
//   CardMediaFrag,
//   CardColumnFrag,
//   CardButtonFrag,
//   LogoCloudFrag,
//   PhotoFrag,
//   AssetFrag,
//   ButtonFrag,
//   CompanyFrag,
//   ButtonOnCardBlockFrag,
//   TooltipFrag,
//   PartnershipFrag
// }
