import React, { useState, useCallback } from 'react'
import { useRouter } from 'next/router'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Stack,
  ModalBody,
  Box,
  Button,
  VStack,
  HStack,
  useBreakpointValue
} from '@chakra-ui/react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import ROUTES from '@/constants/routes'
import { ButtonMain, ButtonLink } from '@/components'
import { SignIn } from '@/modules'
import { useFireAuth } from '@/lib/fireAuth'

const NoTeamModal = ({ handleAction }) => {
  return (
    <VStack spacing={10} align="center">
      <Box>
        <Box textStyle="head2">Create An Account</Box>
        <Box textStyle="body2">
          You still need to register your business to create an account. If you
          are having trouble, our customer service team can help.
        </Box>
      </Box>
      <Button
        variant="solid"
        onClick={() =>
          handleAction(ROUTES.ONBOARDING_NEW_MEMBER_CREATE_TEAM_ACCOUNT)
        }
      >
        Create Account
      </Button>

      <Box>
        <ButtonLink
          variant="form-link"
          size="lg"
          rel="noopener noreferrer"
          target="_blank"
          to={ROUTES.CONTACT_US}
        >
          CONTACT US
        </ButtonLink>
      </Box>
      <Box>
        <Button variant="form-link" onClick={() => handleAction(ROUTES.HOME)}>
          BACK TO HOME
        </Button>
      </Box>
    </VStack>
  )
}

const NotLoggedInModal = ({ switchScreen, handleActionPath }) => {
  const talign = useBreakpointValue({ base: 'left', md: 'center' })
  return (
    <VStack spacing="10" align="center">
      <VStack spacing="10px">
        <Box textStyle="head3" textAlign={talign}>
          Keep Discovering with an Elevate Subscription
        </Box>
        <Box textStyle="body1" textAlign={talign}>
          Sign up to claim cash back and access collective-negotiated pricing on
          products, services, and benefits for your small business.
        </Box>
      </VStack>
      <Box>
        <ButtonMain
          label="Sign Up"
          variant="solid"
          href={`${ROUTES.ONBOARDING_NEW_MEMBER_SIGN_UP}`}
        ></ButtonMain>
      </Box>
      <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
        <Box textStyle="body5" w="100%" align="center">
          Already have an Elevate subscription?
        </Box>
        <Button
          variant="form-link"
          // size="sm"
          onClick={() => switchScreen('sign-in')}
        >
          LOG IN
        </Button>
      </Stack>
      <Box>
        <Button
          variant="form-link"
          onClick={() => handleActionPath(ROUTES.HOME)}
        >
          BACK TO HOME
        </Button>
      </Box>
    </VStack>
  )
}

export default NiceModal.create(({ name, variant, hasExit = false }) => {
  const innerVariant = useBreakpointValue({ base: 'mobile', md: variant })

  const [showSignInForm, setShowSignInForm] = useState(false)
  const modal = useModal()
  const router = useRouter()
  const { status, team } = useFireAuth()

  const changeActionPath = useCallback(
    (path) => {
      // console.log('handleAction', path)
      router.push(path)
    },
    [router]
  )
  const changeSwitchScreen = useCallback((type) => {
    //console.log('onSwtchScreen', type)

    if (type === 'sign-in') {
      setShowSignInForm(true)
    }
    //router.push(path)
  }, [])
  const nextAction = () => {
    // e.preventDefault()
    //router.push('/home')
  }

  const nextForgotPassSucess = () => {
    router.push('/home')
  }
  if (status === 'loading') {
    return <Box>Loading...</Box>
  }
  return (
    <Modal
      closeOnOverlayClick={false}
      blockScrollOnMount={true}
      closeOnEsc={false}
      variant={innerVariant}
      isOpen={() => modal.show()}
      onClose={() => modal.remove()}
    >
      <ModalOverlay />
      <ModalContent>
        {status === 'loggedIn' && !team ? (
          <ModalBody className="ScrollBlockerModal">
            <NoTeamModal handleAction={changeActionPath} />
          </ModalBody>
        ) : (
          <ModalBody className="ScrollBlockerModal">
            {showSignInForm ? (
              <VStack spacing="10">
                <SignIn
                  onDone={() => modal.remove()}
                  onForgotPassSuccess={nextForgotPassSucess}
                />
                <Box>
                  <Button
                    variant="form-link"
                    onClick={() => changeActionPath(ROUTES.HOME)}
                  >
                    BACK TO HOME
                  </Button>
                </Box>
              </VStack>
            ) : (
              <NotLoggedInModal
                handleActionPath={changeActionPath}
                switchScreen={() => changeSwitchScreen('sign-in')}
              />
            )}
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  )
})
