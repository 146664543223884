import React from 'react'
import { useFireAuth } from '@/lib/fireAuth'
import { Box } from '@chakra-ui/react'

import { formatCurrency } from '@/utils/_formatCurrency'
const AccountTag = () => {
  const { team } = useFireAuth()

  const cbAmount = _.get(team, 'cashBackEstimate', 0)
  const dollars = formatCurrency(cbAmount)
  return (
    <Box
      bg="brand.primary"
      py="2px"
      px="8px"
      color="white"
      flexDirection="column"
      borderRadius="full"
      textStyle="tag-account"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      maxWidth="80px"
    >
      {dollars}
    </Box>
  )
}

export default AccountTag
