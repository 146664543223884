const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})
function formatCurrency(value) {
  return formatter.format(value)
}

export { formatCurrency }
