import * as React from "react";

function SvgBenefitsCtaArrow2(props) {
  return (
    <svg
      viewBox="0 0 244 244"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="Benefits_CTA-arrow2_svg__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={244}
        height={244}
      >
        <rect width={244} height={244} rx={120} fill="#C4C4C4" />
      </mask>
      <g mask="url(#Benefits_CTA-arrow2_svg__a)">
        <path fill="#F2F2F2" d="M0 0h244v244H0z" />
        <path
          d="M175.068 190.738c35.031.473 58.014-6.859 68.932-11.476v-19.553c-10.918 6.017-33.901 15.612-68.932 15.138-50.87-.691-66.693-30.17-110.127-30.17-30.374 0-53.67 13.34-64.94 21.364v12.702c11.27-8.024 34.566-21.365 64.94-21.365 43.434.008 59.257 32.668 110.127 33.36z"
          fill="#C7E4E9"
        />
        <path
          d="M175.068 174.855c35.031.474 58.014-9.114 68.932-15.138V98.42c-11.854 8.821-37.278 25.065-68.932 29.697-40.184 5.874-67.068-10.032-110.127-6.933-30.164 2.173-53.603 17.65-64.94 26.734v18.131c11.27-8.024 34.566-21.365 64.94-21.365 43.434 0 59.257 29.479 110.127 30.171z"
          fill="#76CBE2"
        />
        <path
          d="M175.068 190.738c-50.87-.692-66.693-33.352-110.127-33.352-30.374 0-53.67 13.341-64.94 21.365v14.476c11.27-7.761 34.566-20.568 64.94-19.959 39.689.752 51.671 29.239 110.127 42.865 23.109 5.385 54.749-1.406 68.932-5.076v-31.803c-10.896 4.625-33.901 11.958-68.932 11.484z"
          fill="#FF8777"
        />
        <path
          d="M175.068 216.178c-58.486-13.634-70.438-42.113-110.127-42.865-30.374-.609-53.67 12.198-64.94 19.959v23.658c11.322-7.866 34.739-20.417 64.94-16.649 50.922 6.347 56.973 41.767 101.096 52.4 36.094 8.694 64.993 2.572 77.963-1.361v-40.255c-14.183 3.669-45.823 10.46-68.932 5.113z"
          fill="#EC554B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M122.147 60.487l23.693 15.058 30.637 18.934v65.357l-24.833-15.673v49.602H92.518l-.015-49.606-24.84 15.677V94.549l30.12-19.014 24.364-15.048zm-12.445 34.136l-19.535 12.332v12.067l24.827-15.669.021 67.908h14.126v-67.912l24.833 15.673v-11.996l-20.085-12.412-11.913-7.572-12.274 7.581z"
          fill="#3352DF"
        />
        <path
          d="M106.222 180.058v-60.759L81.39 134.972v-32.893l23.684-14.95 16.987-10.497 16.484 10.492 24.225 14.973v32.875l-24.827-15.673v60.759h-31.721z"
          fill="#fff"
        />
        <path
          d="M122.037 79.536l15.188 9.663h.031l23.04 14.267v27.055l-17.3-10.915-7.533-4.758v62.754h-26.809v-62.754l-7.533 4.758-17.294 10.915v-27.086l22.519-14.212 15.673-9.687h.018zm.025-5.796l-18.32 11.315-24.826 15.673v38.676l24.826-15.673v58.757h36.633v-58.757l24.832 15.673v-38.676l-25.36-15.673-17.804-11.315h.019z"
          fill="#757EFF"
        />
      </g>
    </svg>
  );
}

export default SvgBenefitsCtaArrow2;
