import {
  Box,
  List,
  ListItem,
  Flex,
  Link,
  Spacer,
  Stack,
  Text,
  Button,
  Heading,
  Divider,
  VStack,
  Center,
  Grid,
  HStack,
  GridItem,
  AspectRatio,
  Tag
} from '@chakra-ui/react'

import { ButtonMain } from '@/components'
import { formatCurrency } from '@/utils/_formatCurrency'
import Image from 'next/image'
import { SpecialProductCard } from '@/components'
import { MDXRemote } from 'next-mdx-remote'
import _ from 'lodash'
import { repeat } from 'lodash'
import APPVARS from '@/constants/appvars'

const Body2 = (props) => {
  return <Box textStyle="body2" {...props} />
}
const copyComponents = {
  p: (props) => <Box w="100%" textStyle="body1" {...props} />,
  ol: (props) => <Box as="ol" paddingStart="6" {...props} />
}
const headlineComponents = {
  p: (props) => <Box w="100%" textStyle="head3" {...props} />
}
const subheadlineComponents = {
  p: (props) => <Box w="100%" textStyle="head6" {...props} />
}

const FlipInfoColumn = ({
  headline,
  subheadline,
  copy,
  note,
  colSpan,
  cardMedia,
  cardVariant,
  cardTheme,
  cardSize,
  cardButton,
  gridGap,
  flip
}) => {
  return (
    <Grid
      py={{ base: '40px', md: '60px' }}
      w="100%"
      className="INFO_BLOCK_GRIDD_COL"
      templateColumns={{ base: '1fr', md: '1fr 10px 1fr' }}
      templateAreas={{
        base: `'media' 'content'`,
        md: `'media . content'`
      }}
      templateRows={{ base: 'auto', md: '1fr' }}
      alignItems="center"
      justifyContent={{ base: 'center', md: 'space-between' }}
      maxWidth={{
        base: 'max(395px, calc(100% - 20px))',
        md: 'max(395px, calc(100% - 20px))',
        lg: '100%'
      }}
      rowGap={{ base: '20px', md: '0' }}
    >
      <AspectRatio
        w="100%"
        maxWidth={{
          base: 'max(395px, calc(100% - 20px))',
          md: 'min(395px, calc(100% - 20px))'
        }}
        gridArea={{ base: 'media', md: flip ? 'content' : 'media' }}
      >
        <Image
          src={cardMedia.url}
          alt={cardMedia.title}
          title={cardMedia.title}
          layout="fill"
          objectFit="contain"
        />
      </AspectRatio>

      {/*flip info card copy*/}
      <GridItem
        w="100%"
        minWidth="container.xs"
        maxWidth={{
          base: 'max(395px, calc(100% - 20px))',
          md: 'min(395px, calc(100% - 20px))'
        }}
        alignItems="center"
        gridArea={{ base: 'content', md: flip ? 'media' : 'content' }}
        // order={{ base: '3', lg: `${flip ? '3' : '1'}` }}
      >
        <VStack px="20px" align="stretch" spacing="5" textAlign="left">
          {headline && (
            <MDXRemote {...headline.mdx} components={headlineComponents} />
          )}
          {subheadline && (
            <MDXRemote
              {...subheadline.mdx}
              components={subheadlineComponents}
            />
          )}
          {copy && <MDXRemote {...copy.mdx} components={copyComponents} />}
          {cardButton && (
            <Box key={cardButton.id}>
              <ButtonMain
                {...cardButton}
                href={cardButton.href}
                onAction={handleButtonAction}
              />
            </Box>
          )}
        </VStack>
      </GridItem>
    </Grid>
  )
}
export default FlipInfoColumn
// templateRows={[
//   'repeat(2, 1fr)',
//   '[gl-top] 1.5rem  [gl-title-top] 1fr [gl-title-bot] 0.1.5rem [gl-bot]'
// ]}
