import { gql } from 'graphql-request'
import { CardColumnFrag, CardButtonFrag } from './models'
import {
  FaqFrag,
  ResourceFrag,
  ProductFrag,
  CompanyColumnFrag,
  BenefitCardColumnFrag
} from './columns'

/** MODEL: InfoBlock */
const InfoBlockFrag = gql`
  fragment InfoBlockFrag on InfoBlock {
    id
    infoBlockName
    infoBlockSlug
    infoBlockTextAlign
    infoBlockLayerStyle
    infoBlockSpaceX
    infoBlockSpaceY
    infoBlockSize
    infoBlockWidth
    infoBlockRichText {
      html
      raw
      json
      markdown
    }
    infoBlockMdText
    blockComponent
    infoBlockButton {
      href
      id
      label
      slug
      variant
      info
      size
    }
  }
`
/** MODEL: RichTextBlock  */
const RichTextBlockFrag = gql`
  fragment RichTextBlockFrag on RichTextBlock {
    id
    richTextBlockLayerStyle
    richTextBlockName
    richTextBlockRichText {
      raw
      html
      json
      markdown
    }
    richTextBlockSlug
    richTextBlockTextAlign
  }
`
/** MODEL: HeroBlock */
const HeroBlockFrag = gql`
  fragment HeroBlockFrag on HeroBlock {
    id
    heroSlug
    heroName
    heroHeadline
    heroSubheadline
    heroTextAlign
    heroCopy
    heroNote
    blockComponent
    buttons {
      href
      id
      label
      slug
      variant
      info
      size
    }
    heroImage {
      id
      width
      height
      url
      imageName
    }
  }
`
/** MODEL: GridBlock */
const GridBlockFrag = gql`
  fragment GridBlockFrag on GridBlock {
    id
    gridName
    gridSlug
    gridTextAlign
    gridRichText {
      html
      raw
      json
      markdown
    }
    gridMdText
    gridComponent: blockComponent
    columnComponent
    tag
    layout
    cols
    gridSpacing
    gridGap
    blockWidth
    columns {
      __typename
      ... on Card {
        ...CardColumnFrag
        __typename
        cardMedia {
          assetType
          fileName
          handle
          height
          id
          imageName
          mimeType
          size
          stage
          url
          width
        }
      }
      __typename
      ... on Faq {
        ...FaqFrag
      }
      __typename
      ... on Company {
        ...CompanyColumnFrag
      }
      __typename
      ... on Product {
        ...ProductFrag
      }
      __typename
      ... on Resource {
        ...ResourceFrag
      }
      __typename
      ... on Benefit {
        ...BenefitCardColumnFrag
      }
    }
  }
  ${[
    CardColumnFrag,
    FaqFrag,
    CompanyColumnFrag,
    ProductFrag,
    ResourceFrag,
    BenefitCardColumnFrag
  ]}
`
/** MODEL: Modal */
const ModalBlockFrag = gql`
  fragment ModalBlockFrag on Modal {
    id
    blockComponent
    modalButtons {
      href
      id
      label
      slug
      variant
      info
      size
    }
    modalCopy
    modalHeadline
    modalName
    modalRichContent {
      html
    }
    modalSize
    modalSlug
    modalSubheadline
    modalVariant
    modalNote
  }
`
/** MODEL: CustomBlock */
const CustomBlockFrag = gql`
  fragment CustomBlockFrag on CustomBlock {
    id
    customBlockName
    customBlockSlug
    customBlockHeadline
    customBlockSubheadline
    customBlockCopy
    customBlockLayerStyle
    customBlockContent {
      html
      json
    }
    customBlockJson
    customBlockClass
    customBlockSize
    blockComponent
    customBlockButtons {
      href
      id
      label
      slug
      variant
      info
      size
    }
  }
`
/** MODEL: Card Block */
const CardBlockFrag = gql`
  fragment CardBlockFrag on CardBlock {
    id
    cardBlockName
    cardBlockSlug
    cardBlockContent {
      html
      json
      raw
    }
    cardBlockVariant
    cardBlockMediaOrder
    cardBlockMediaStyle
    cardBlockSize
    cardBlockWidth
    textAlign
    blockComponent
    cardBlockMedia {
      assetType
      fileName
      handle
      height
      id
      imageName
      mimeType
      size
      stage
      url
      width
    }
    cardBlockButton {
      ...CardButtonFrag
    }
  }
  ${[CardButtonFrag]}
`

export {
  RichTextBlockFrag,
  CardBlockFrag,
  InfoBlockFrag,
  ModalBlockFrag,
  HeroBlockFrag,
  GridBlockFrag,
  CustomBlockFrag
}
