import React from 'react'
import { useForm } from 'react-hook-form'

export function Form({ defaultValues, children, onSubmit }) {
  const { handleSubmit, register, formState } = useForm({ defaultValues })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {Array.isArray(children)
        ? children.flat().map((child) => {
            return child?.props && child.props.name
              ? React.createElement(child.type, {
                  ...{
                    ...child.props,
                    register,
                    key: child.props.name
                  }
                })
              : child
          })
        : children}
      {/* <pre>{JSON.stringify(formState, null, 2)}</pre> */}
    </form>
  )
}
export default Form
//create profilequestionspopup
//
