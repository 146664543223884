import React from 'react'
import { Box, VStack } from '@chakra-ui/react'
const FeaturedProductContent = ({
  children,
  colSpan,
  textAlign,
  align,
  spacing
}) => {
  return (
    <Box colSpan={colSpan} textAlign={textAlign} align={align}>
      <VStack spacing={spacing}>{children}</VStack>
    </Box>
  )
}

export default FeaturedProductContent
