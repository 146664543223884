import React from 'react'
import { Center } from '@chakra-ui/react'
import Image from 'next/image'
const FooterLogo = ({ logo }) => {
  return (
    <Center
      display="flex"
      w="100%"
      direction="center"
      alignItems={{ base: 'center', md: 'left' }}
      justifyContent={{ base: 'center', md: 'left' }}
    >
      <Image
        src={logo.url}
        alt={logo.title}
        title={logo.title}
        width={153}
        height={50}

        // width="152px"
        // height="50px"
      />
    </Center>
  )
}

export default FooterLogo
