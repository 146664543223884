import _ from 'lodash'
import { initializeApp } from 'firebase/app'

import {
  getAuth,
  updateProfile,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  sendEmailVerification,
  applyActionCode
} from 'firebase/auth'
import {
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  initializeFirestore,
  collection,
  getDoc,
  writeBatch
} from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
}

const firebaseApp = initializeApp(firebaseConfig)
// Auth exports
export const fbauth = getAuth()
//console.log('fbauth2', fbauth)

export const fsdb = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true
})

export const storage = getStorage()

export async function createNewMember(uid, data) {
  const memRef = doc(collection(fsdb, 'members', uid))

  await setDoc(memRef, {
    uid,
    ...data,
    dateAdded: serverTimestamp(),
    dateUpdated: serverTimestamp()
  })
  return data
}

export const signInSimple = (email, password) => {
  return signInWithEmailAndPassword(fbauth, email, password)
}

//TODO: #748 Needs testing and have add a background function that checks if member.manualSetupConfirmed changes from null or false to true then triggers zendSell workflow and Change Passwrod email
export const signInAutoRegister = async (email, password, terms) => {
  await signInWithEmailAndPassword(fbauth, email, password).then(
    async (userCredential) => {
      // Signed in
      const user = userCredential.user
      const memRef = doc(fsdb, 'members', user.uid)
      const memberDoc = await getDoc(memRef)
      if (memberDoc.exists()) {
        const member = memberDoc.data()
        // console.log('memberDoc exists', member)
        const teamRef = doc(fsdb, 'teamAccounts', member.teamAccountId)
        if (!member?.manualSetupConfirmed) {
          await sendPasswordResetEmail(fbauth, member.email)
          const batch = writeBatch(fsdb)
          batch.update(teamRef, {
            dateAdded: serverTimestamp(),
            dateUpdated: serverTimestamp()
          })
          batch.update(memRef, {
            manualSetupConfirmed: true,
            dateAdded: serverTimestamp(),
            dateUpdated: serverTimestamp()
          })
          return batch.commit()
        }
      }
      // else
      // return console.log("mem no")
    }
  )
}
export const signOutSimple = async () => {
  rg4js('endSession')
  rg4js('setUser', {
    isAnonymous: true
  })
  return signOut(fbauth)
}

export const signUpSimple = async ({ userData }) => {
  const { firstName, lastName, phoneNumber, email, location, password } =
    userData
  const displayName = `${firstName}${lastName}`
  const createUser = await createUserWithEmailAndPassword(
    fbauth,
    email,
    password
  )
  const { user } = createUser
  //console.log('userID', user)
  const { uid, emailVerified } = user
  //console.log('uid', uid)

  await updateProfile(user, {
    displayName: displayName
  })

  setDoc(doc(fsdb, 'members', uid), {
    uid,
    firstName,
    lastName,
    phoneNumber,
    email,
    displayName,
    emailVerified,
    location,
    dateAdded: serverTimestamp(),
    dateUpdated: serverTimestamp()
  })
}
export const getMemberDataById = async (uid) => {
  const memberRef = doc(fsdb, 'members', uid)
  const memberSnap = await getDoc(memberRef)
  if (memberSnap.exists()) {
    return memberSnap.data()
  } else {
    return null
  }
}

export const updateMember = async ({ memberId, memData }) => {
  //console.log('memData', memData)

  const memRef = doc(fsdb, 'members', memberId)
  return updateDoc(memRef, {
    firstName: memData.firstName,
    lastName: memData.lastName,
    phoneNumber: memData.phoneNumber,
    location: memData.location,
    dateUpdated: serverTimestamp()
  })
}

export const sendPasswordResetEmail2 = async (email) => {
  await sendPasswordResetEmail(fbauth, email).then(() => {
    return true
  })
  return false
}

export const sendEmailVerification2 = async (user) => {
  user = fbauth.currentUser
  console.log('user', user)
  await sendEmailVerification(user).then(() => {
    console.log('verify email sent')
  })
}

export const confirmEmailIsVerified = async (code) => {
  await applyActionCode(fbauth, code).then(() => {
    console.log('confirmEmailIsVerified ran. code: ', code)
  })
}

export const updateMemberEmailVerified = async (memberId, emailVerified) => {
  const memRef = doc(fsdb, 'members', memberId)
  return updateDoc(memRef, {
    emailVerified: emailVerified,
    dateUpdated: serverTimestamp()
  })
}

export const verifyPasswordResetCode2 = async (code, password) => {
  await verifyPasswordResetCode(fbauth, code).then((email) => {
    //console.log('verifyPasswordReset')
    const accountEmail = email
    const accountPassword = password
    confirmPasswordReset(fbauth, code, password).then(() => {
      signInWithEmailAndPassword(fbauth, accountEmail, accountPassword)
    })
    // console.log("Verify Email", accountEmail)
    // return signInWithEmailAndPassword(fbauth, accountEmail, password)
    return true
  })
  return false
}

/**`
 * Converts a firestore document to JSON
 * @param  {DocumentSnapshot} doc
 */
export function postToJSON(docData) {
  const data = docData.data()
  //const {dateAdded, dateUpdated} = data;

  const dateAdded = _.has(data, 'dateAdded.seconds')
    ? data.dateAdded.toDate()
    : data.dateAdded
  const dateUpdated = _.has(data, 'dateUpdated.seconds')
    ? data.dateAdded.toDate()
    : data.dateUpdated

  return {
    ...data,
    dateAdded,
    dateUpdated
    // Gotcha! firestore timestamp NOT serializable to JSON. Must convert to milliseconds
    // dateAdded: data?.dateAdded.toMillis() || 0,
    // dateUpdated: data?.dateUpdated.toMillis() || 0
  }
}
