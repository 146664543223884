import React from 'react'
import { VStack, Stack, Box, HStack, Grid } from '@chakra-ui/react'
import dayjs from 'dayjs'
import NiceModal from '@ebay/nice-modal-react'
import APPVARS from '@/constants/appvars'
import { TotalSavingsPanel, TotalCashBackPanel } from 'components/index'

import { useCleanAccountActivity } from '@/hooks'
import { TipPopper, ActivitySelector } from '@/elements'
import {
  CashBackReport,
  AccountActivityTable,
  CashBackReportModule
} from '@/modules'
import { accountScreensCopy } from '@/constants/account-screens-copy'

const AccountActivityContainer = ({ team, data }) => {
  const isMobile = false

  const {
    recordsReady,
    records,
    cashBackRecords,
    cashBackTotal,
    specialSavingsTotal,
    everydaySavingsTotal
  } = useCleanAccountActivity({ data, team })
  //console.log('team.dateAdded', joinedDate)
  if (records && !_.isArray(records)) {
    return <h1>wait!</h1>
  }
  return (
    <Box
      maxW={{
        base: APPVARS.BLOCK_MAX_WIDTH_BASE,
        lg: APPVARS.BLOCK_MAX_WIDTH_FULL
      }}
      mx={{ base: 'auto' }}
      w="100%"
    >
      {recordsReady && (
        <VStack
          className="ACCOUNT_ACTIVITY_CONTAINER"
          mt="10px"
          justify="stretch"
        >
          <CashBackReport
            cashBackBalance={cashBackTotal}
            cashBackUpdateDate={dayjs().format('MMM DD, YYYY')}
            payOutBalance="$0.00"
            payOutUpdateDate="No Record Found"
          />

          <Box maxWidth="100%">
            <Grid
              justify="stretch"
              mx="auto"
              mb="10px"
              templateRows={{ base: `'36px 44px'`, md: '44px' }}
              templateColumns={{ base: '1fr', md: '1fr 3fr' }}
              templateAreas={{
                base: `'report_title' 'report_selector'`,
                md: `'report_title  report_selector'`
              }}
              columnGap={{ base: '10px', md: '60px' }}
              alignItems="stretch"
            >
              <HStack gridArea="report_title" spacing="10px" w="max-content">
                <Box textStyle="head4">
                  {accountScreensCopy.ACTIVITY_REPORT.HEADLINE}
                </Box>
                <TipPopper
                  steps={['step1', 'step2', 'step3']}
                  currentStep="step2"
                  copy={`Select dates to view your Total Cash Back and Total Savings for a specific time range.`}
                />
              </HStack>

              <ActivitySelector
                // maxWidth="600px"
                w="100%"
                justifySelf="end"
                alignItems="center"
                justifyContent="flex-end"
                gridArea="report_selector"
                flexGrow={1}
                team={team}
              />
            </Grid>
          </Box>
          <Grid
            alignItems="stretch"
            px={{ base: '0px', lg: '0px' }}
            spacing="24px"
            templateRows={{ base: '1fr', md: '1fr' }}
            templateColumns={{ base: '1fr', md: '1fr 1fr' }}
            templateAreas={{
              base: `'cashback_panel' 'savings_panel'`,
              md: `'cashback_panel savings_panel'`
            }}
            columnGap={{ base: '10px', md: '10px' }}
            width="100%"
          >
            <TotalCashBackPanel
              gridArea="cashback_panel"
              cashBackRecords={cashBackRecords}
              totalCurrent={cashBackTotal}
              totalLifetime={cashBackTotal}
            />
            <TotalSavingsPanel
              gridArea="savings_panel"
              specialTotals={specialSavingsTotal}
              everydayTotals={everydaySavingsTotal}
              savingsTotals={specialSavingsTotal + everydaySavingsTotal}
            />
          </Grid>

          <Box w="100%">
            <Box layerStyle="activity-graph-panel" p={7}>
              <HStack spacing="10px" mb="28px">
                <Box textStyle="head5">Purchase History</Box>
              </HStack>
              <AccountActivityTable tableRows={records} />
            </Box>

            <Box mt="10px" textStyle="body3" fontStyle="italic">
              {accountScreensCopy.ACTIVITY_REPORT.COPY}
            </Box>
          </Box>
        </VStack>
      )}
    </Box>
  )
}
export default AccountActivityContainer
