import {
  Link as ChakraLink,
  useColorModeValue,
  Box,
  Button
} from '@chakra-ui/react'
import _ from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

function NavLink({ to, activeProps, children, _hover, handleClick, ...props }) {
  const router = useRouter()
  const path = router.asPath

  let isActive = false
  ////console.log('path', path)
  ////console.log('to', to)
  if (path === '/') {
    ////console.log('hometotrim', to)
    isActive = path === to
  } else {
    const totrim = to.substring(1)
    ////console.log('totrim', totrim)
    isActive = _.startsWith(path, totrim, 1)
  }

  ////console.log('router', router)

  if (isActive) {
    return (
      <ChakraLink color="brand.primary" fontWeight="semibold" {...props}>
        {children}
      </ChakraLink>
    )
  }

  if (to === 'log-in') {
    return (
      <ChakraLink {...props} _hover={{ color: 'brand.primary' }}>
        {children}
      </ChakraLink>
    )
  }
  if (to === 'log-out') {
    return (
      <ChakraLink {...props} _hover={{ color: 'brand.primary' }}>
        {children}
      </ChakraLink>
    )
  }
  if (to.includes('*')) {
    return (
      <ChakraLink {...props} onClick={onAction}>
        {children}
      </ChakraLink>
    )
  }
  if (to.includes('#')) {
    return <ChakraLink {...props}>{label}</ChakraLink>
  }
  return (
    <Link href={to} passHref>
      <ChakraLink {...props} _hover={{ color: 'brand.primary' }}>
        {children}
      </ChakraLink>
    </Link>
  )
}

export default NavLink
