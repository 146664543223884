import React from 'react'
import { Box, Divider, Flex, VStack, Grid, GridItem } from '@chakra-ui/react'

const PanelForm = ({ title, actionBtn = null, children }) => {
  return (
    <Box className="PanelForm" layerStyle="settings-form" w="full">
      <Box mb="3">
        {actionBtn ? (
          <Flex justifyContent="space-between">
            <Box textStyle="head5">{title}</Box>
            {actionBtn}
          </Flex>
        ) : (
          <Flex>
            <Box textStyle="head5">{title}</Box>
          </Flex>
        )}
      </Box>
      <Box>{children}</Box>
    </Box>
  )
}

export default PanelForm
