import { useCallback, useState, useEffect } from 'react'

import dayjs from 'dayjs'

import { graphcmsClient } from '@/graphql/_client'
import { useFireAuth } from '@/lib/fireAuth'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)
const useCheckSpecialBonus = ({ specialid }) => {
  const [isReady, setIsReady] = useState(false)
  const { status, team } = useFireAuth()
  const [specialBonus, setSpecialBonus] = useState()
  const [isActive, setIsActive] = useState(false)
  const getQuery = useCallback(async () => {
    try {
      const client = graphcmsClient()
      const { specialProduct } = await client.request(getSpecialProductById, {
        id: specialid
      })
      const active = dayjs().isBetween(
        specialProduct.dateActivated,
        specialProduct.dateDeactivated,
        null,
        '[]'
      )
      if (active) {
        setSpecialBonus(specialProduct)
        setIsActive(true)
        setIsReady(true)
      } else {
        setSpecialBonus(false)
        setIsActive(false)
        setIsReady(true)
      }
    } catch (error) {
      //console.log(error)
    }
  }, [specialid])

  useEffect(() => {
    if (status === 'loggedIn' && team) {
      getQuery({ id: specialid })
    }
  }, [getQuery, specialid, status, team])
  return { isReady, isActive, specialBonus }
}
export default useCheckSpecialBonus
