import React from 'react'
import { Box } from '@chakra-ui/react'
import APPVARS from '@/constants/appvars'
const ArticleInfoContainer = ({ children }) => {
  return (
    <Box
      className="ARTICLE_INFO"
      maxW={{
        base: APPVARS.BLOCK_MAX_WIDTH_BASE,
        lg: APPVARS.BLOCK_MAX_WIDTH_FULL
      }}
      mx={{ base: 'auto' }}
      w="100%"
    >
      {children}
    </Box>
  )
}

export default ArticleInfoContainer
