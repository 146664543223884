import React, { useState, useEffect } from 'react'
import {
  Box,
  VStack,
  Input,
  Text,
  InputRightElement,
  IconButton
} from '@chakra-ui/react'

import { useRouter } from 'next/router'
import { FieldInputPassword, SubmitBtn } from '@/forms'
import { newPasswordSchema } from '@/constants/form-schemas'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { verifyPasswordResetCode2 } from '@/lib/firebase'

import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri'

const ResetPasswordForm = ({ qparams }) => {
  const [apiErrors, setApiErrors] = useState()
  const [show, setShow] = React.useState(false)
  const [isActionCode, setActionCode] = useState()
  const [hasApiErrors, setHasApiErrors] = useState(false)
  // const isMobile = useBreakpointValue({ base: true, md: false })
  const router = useRouter()
  const handleShowPassword = () => setShow(!show)
  useEffect(() => {
    const oobCode = qparams
    setActionCode(oobCode)
  }, [qparams])

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(newPasswordSchema)
  })

  const onSubmitNewPassword = async (data) => {
    setApiErrors()
    const { password } = data
    verifyPasswordResetCode2(isActionCode, password)
      .then(() => {
        router.push('/home')
      })
      .catch((error) => {
        setApiErrors(`${JSON.stringify(error)}`)
        setHasApiErrors(true)
      })
  }

  return (
    <Box minWidth={{ base: '380px', md: '500px' }} w="100%" px="4">
      <Box textStyle="head3" mt={10} mb={10}>
        Reset Password
      </Box>
      <VStack
        as="form"
        spacing={4}
        align="start"
        onSubmit={handleSubmit(onSubmitNewPassword)}
      >
        <Text>Enter your new password</Text>

        <FieldInputPassword
          name="password"
          label="Password"
          error={errors.password}
          labelInline={true}
        >
          <Input
            size="sm"
            id="password"
            placeholder="Password (minimum 8 characters)"
            type={show ? 'text' : 'password'}
            {...register('password')}
          />
          <InputRightElement>
            <IconButton
              aria-label="hide password"
              onClick={handleShowPassword}
              variant="icon-simple"
              icon={show ? <RiEyeCloseLine /> : <RiEyeLine />}
            />
          </InputRightElement>
        </FieldInputPassword>
        {hasApiErrors && apiErrors ? (
          <Text bgColor="red.100">{apiErrors}</Text>
        ) : (
          ''
        )}
      </VStack>
    </Box>
  )
}

export default ResetPasswordForm
