import React from 'react'
import { formatDateNormal } from '@/utils/_formatDates'
import { Select, Box, Flex } from '@chakra-ui/react'
const ActivitySelector = ({ team, ...props }) => {
  const joinedDate = team ? formatDateNormal({ value: team.dateAdded }) : null
  return (
    <Flex {...props}>
      <Box flexShrink="0" flexGrow="0" textStyle="subtitle2" pe="10px">
        Time Range
      </Box>
      <Select
        flexShrink="1"
        flexGrow="1"
        width="100%"
        height="44px"
        maxWidth="401px"
        placeholder={`${joinedDate} - Today`}
      ></Select>
    </Flex>
  )
}

export default ActivitySelector
