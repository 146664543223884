import React, { useRef } from 'react'
import { Flex, Box } from '@chakra-ui/react'
import { HomeBar, Seo } from '@/components'
import APPVARS from '@/constants/appvars'
import { ElevateLogoLarge } from '@/assets/logos'
import useScrollSpy from 'react-use-scrollspy'
import { FooterSectionsContainer, FooterContainer } from '@/containers'
export default function HomeLayout({ children, page }) {
  const homeSections = [useRef(null), useRef(null), useRef(null)]

  const activeSection = useScrollSpy({
    sectionElementRefs: homeSections,
    offsetPx: 0
  })

  //console.log('page', page)
  return (
    <>
      {page?.seo && <Seo {...page.seo} />}

      <Box id="LAYOUT_HOME" w="100%" overflowX="hidden">
        <HomeBar navType="home" activeSection={activeSection} />

        <Flex
          w="full"
          className="LAYOUT_MAIN_COL"
          direction="column"
          minH="100vh"
        >
          <Box
            ref={homeSections[0]}
            flexGrow="0"
            width="100vw"
            height={APPVARS.NAV_BAR_HEIGHT}
            alignSelf="flex-start"
            className="NAV_Space"
          />
          <Flex
            className="LAYOUT_HOME_BANNER"
            width={{ base: '255px', sm: '400px' }}
            direction="column"
            justifyContent="center"
            alignItems="center"
            ref={homeSections[1]}
            my="6"
            mx="auto"
          >
            <ElevateLogoLarge width="255px" height="85px" />
            <Box
              mt="3"
              textAlign="center"
              textStyle="home-header"
              color="grey.400"
            >
              Discover curated products & exclusive deals chosen by our
              collective.
            </Box>
          </Flex>
          <Flex
            ref={homeSections[2]}
            className="LAYOUT_HOME_CONTENT"
            flexGrow="1"
            dir="column"
            alignItems="stretch"
            justifyContent="center"
          >
            {children}
          </Flex>
          {page?.footerSections && (
            <FooterSectionsContainer sections={page.footerSections} />
          )}

          {page?.footer && (
            <Box className="LAYOUT_HOME_FOOTER" flexGrow="0">
              <FooterContainer {...page.footer} />
            </Box>
          )}
        </Flex>
      </Box>
    </>
  )
}

export const getLayout = (page) => (
  <HomeLayout {...page.props}>{page}</HomeLayout>
)
