import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import MODALS from '@/constants/modals'

import {
  CustomModal,
  SignInModal,
  ScrollBlockerModal,
  BuyBlockerModal,
  OnboardNewModal,
  CreateTeamToContinue,
  ModalInviteYourTeam,
  VendorAccountModal,
  ModalDeleteBusinessAcount,
  ModalDeleteYourTeamMember,
  DisconnectVendorModal,
  SetUpCashBackModal,
  CashBackArticleModal,
  ModalEarlyCashBack50
} from '@/modals'

const ModalLayer = ({ children }) => {
  NiceModal.register(MODALS.CUSTOM_MODAL, CustomModal)
  NiceModal.register(MODALS.SCROLL_BLOCKER_MODAL, ScrollBlockerModal)
  NiceModal.register(MODALS.BUY_BLOCKER_MODAL, BuyBlockerModal)
  NiceModal.register(MODALS.SIGN_IN, SignInModal)
  NiceModal.register(MODALS.ONBOARD_NEW_MODAL, OnboardNewModal)
  NiceModal.register(MODALS.VENDOR_ACCOUNT_MODAL, VendorAccountModal)
  NiceModal.register(MODALS.CREATE_TEAM_TO_CONTINUE, CreateTeamToContinue)
  NiceModal.register(MODALS.INVITE_YOUR_TEAM, ModalInviteYourTeam)
  NiceModal.register(MODALS.DELETE_YOUR_TEAM_MEMBER, ModalDeleteYourTeamMember)
  NiceModal.register(MODALS.DELETE_BUSINESS_ACCOUNT, ModalDeleteBusinessAcount)
  NiceModal.register(MODALS.DISCONNECT_VENDOR, DisconnectVendorModal)
  NiceModal.register(MODALS.SET_UP_CASHBACK, SetUpCashBackModal)
  NiceModal.register(MODALS.CASHBACK_ARTICLE_MODAL, CashBackArticleModal)
  NiceModal.register(MODALS.EARLY_CASH_BACK_50, ModalEarlyCashBack50)
  return <>{children}</>
}
export default ModalLayer
