import { useCallback, useState, useEffect } from 'react'
import _ from 'lodash'
import { useFireAuth } from '@/lib/fireAuth'
import dayjs from 'dayjs'

const useCheckBeforeBuy = (product) => {
  const [productObject, setProductObject] = useState()
  const [hasConnectedAccount, setHasConnectedAccount] = useState(false)
  const [vendorCode, setVendotCode] = useState()
  const [odAccountStatus, setOdAccountStatus] = useState(false)
  const [specialClaimed, setSpecialClaimed] = useState()
  const [isReady, setIsReady] = useState(false)
  const { status, member, team } = useFireAuth()

  const fullProductCallback = useCallback(
    (productData) => {
      const isFeatured = _.get(productData, 'isFeatured', false)
      const productSlug = _.get(productData, 'productSlug', 'xxxx')
      const productId = _.get(productData, 'productId', productSlug)
      const productName = _.get(productData, 'productName', 'na')
      const productVendor = _.get(productData, 'productVendor', 'OTHER')
      const productCtaLink = _.get(
        productData,
        'productCtaLink',
        'https://officedepot.com'
      )
      const productCtaLabel = _.get(productData, 'productCtaLabel', 'Buy Now')
      const teamAccountId = _.get(team, 'teamAccountId', 'na')
      const vendorAccounts = _.get(team, 'vendorAccounts')

      const vendorAccount = _.get(vendorAccounts, 'OD')
      const vendorAccountId = _.get(vendorAccount, 'vendorAccountId')
      const vendorName = productVendor
      const productUnit = _.get(productData, 'unit')
      const productPrice = _.get(productData, 'price', 0)
      //const productListPrice = _.get(productData, 'listPrice', 888)
      const specialProduct = _.get(productData, 'specialProduct', null)
      const specialType = _.get(specialProduct, 'specialType', 'na')
      const specialMod = _.get(specialProduct, 'specialMod', 1)
      const specialId = _.get(specialProduct, 'id')
      const specialLimit = _.get(specialProduct, 'specialLimit')
      const specialsClaimedBy = _.get(specialProduct, 'claimedBy', [])
      const totalIssued = _.get(specialProduct, 'totalIssued', 0)
      const availableSpec = _.get(specialProduct, 'totalAvailable', 0)
      const someLeft = availableSpec > 0

      //console.log('specialsClaimedBy', specialsClaimedBy)
      const isClaimed = _.includes(specialsClaimedBy, teamAccountId)
      //console.log('isClaimed', isClaimed)
      const dateDeactivated = _.get(productData, 'dateDeactivated', false)
      const specialSlug = _.get(specialProduct, 'specialProductSlug', null)
      const productProgramPrice =
        specialType === 'ELEVATE_SPECIAL' ? specialMod : productPrice

      const isActive = dateDeactivated
        ? dayjs().isSameOrBefore(dayjs(dateDeactivated))
        : false

      const randNum = Math.floor(Math.random() * 7)
      const qtySold = randNum > 0 ? randNum : 1
      const netSales = productProgramPrice * qtySold
      const firstName = _.get(member, 'firstName')
      const lastName = _.get(member, 'lastName')
      const fullName = `${lastName}, ${firstName}`
      const memberEmail = _.get(member, 'email')
      return {
        isFeatured,
        productSlug,
        productId,
        productCtaLabel,
        productCtaLink,
        productName,
        productVendor,
        teamAccountId,
        vendorAccountId,
        vendorName,
        isActive,
        productPrice,
        productUnit,
        specialSlug,
        specialLimit,
        specialType,
        specialMod,
        specialId,
        productProgramPrice,
        someLeft,
        isClaimed,
        qtySold,
        netSales,
        email: memberEmail,
        name: fullName
      }
    },
    [member, team]
  )

  useEffect(() => {
    if (status === 'loggedIn' && team) {
      const productVendor = _.get(product, 'productVendor', 'OTHER')

      const hc = _.hasIn(team, 'vendorAccounts')

      const spls = _.get(team, 'specials', [])
      const po = fullProductCallback(product)
      setVendotCode(productVendor)
      setOdAccountStatus(_.get(team, 'odAccountStatus', 'READY'))
      setSpecialClaimed(spls)
      setHasConnectedAccount(hc)
      setProductObject(po)
      setIsReady(true)
    }
    if (status === 'loggedIn' && !team) {
      setProductObject(null)
      setHasConnectedAccount(false)
      setIsReady(true)
    }
    if (status === 'loggedOut') {
      setProductObject(null)
      setHasConnectedAccount(false)
      setIsReady(true)
    }
  }, [fullProductCallback, product, status, team])
  return {
    isReady,
    team,
    vendorCode,
    hasConnectedAccount,
    productObject,
    specialClaimed,
    odAccountStatus
  }
}
export default useCheckBeforeBuy
