import React from 'react'
import { VStack, Box, useToken } from '@chakra-ui/react'
import APPVARS from '@/constants/appvars'
import { BlockWrapper } from '@/wrappers'
import _ from 'lodash'
const _getBg = (sectionBackground, bg) => {
  // console.log('bg', bg)
  if (!sectionBackground) {
    return
  }
  const { url } = sectionBackground
  return {
    backgroundImage: `url(${url})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }
}

import { SectionImage } from '@/elements'
function PageSection({ section }) {
  const [bg] = useToken('layerStyle', [_.toLower(section.layerStyle)])
  const backclass = _getBg(section.sectionBackground, bg)

  //console.log('section', section)
  return (
    <Box
      className="PAGE_SECTION"
      layerStyle={section.layerStyle}
      id={section.sectionSlug}
      width="100%"
      justifyContent={
        section.sectionImageAlign ? section.sectionImageAlign : 'flex-start'
      }
      alignItems="center"
      {...backclass}
    >
      {section?.sectionImage && (
        <Box
          justifyContent="center"
          maxW={{
            base: APPVARS.BLOCK_MAX_WIDTH_BASE,
            lg: APPVARS.BLOCK_MAX_WIDTH_FULL
          }}
          mx={{ base: 'auto' }}
          w="100%"
          pt={APPVARS.HRZ['3XL']}
        >
          {section?.sectionImage && <SectionImage {...section.sectionImage} />}
        </Box>
      )}

      <VStack className="PAGE_SECTION_BLOCKS">
        {section.sectionBlocks && (
          <BlockWrapper blocks={section.sectionBlocks} />
        )}
      </VStack>
    </Box>
  )
}

export default function PageSectionsContainer({ sections }) {
  if (!sections) {
    return null
  }
  return (
    <Box className="PAGE_SECTIONS_CONTAINER" flexGrow="1" w="100%">
      {sections.map((section) => (
        <PageSection key={section.id} section={section} />
      ))}
    </Box>
  )
}
