const textStyles = {
  sectionHead1: {
    // you can also use responsive styles
    fontFamily: 'heading',
    fontSize: ['lg2', 'lg4'],
    fontWeight: 'semibold',
    lineHeight: 'base1'
  },
  head1: {
    // you can also use responsive styles
    fontFamily: 'heading',
    fontSize: ['lg3', 'lg3', 'lg4', 'lg4'],
    fontWeight: 'bold',
    lineHeight: 'base1'
  },
  head2: {
    fontFamily: 'heading',
    fontSize: ['lg1', 'lg1', 'lg2', 'lg2'],
    fontWeight: 'semibold',
    lineHeight: 'short2'
  },
  head2s: {
    fontFamily: 'heading',
    fontSize: ['md4', 'md4', 'md5', 'md5'],
    fontWeight: 'semibold',
    lineHeight: 'short2'
  },
  //benefits page - "interested" text
  head2b: {
    fontFamily: 'heading',
    fontSize: ['md4', 'md4'],
    fontWeight: 'semibold',
    lineHeight: 'short2'
  },
  // "login to your elevate account" header
  head3l: {
    fontFamily: 'heading',
    fontSize: ['lg1'],
    fontWeight: 'bold',
    lineHeight: 'base1'
  },
  head3: {
    fontFamily: 'heading',
    fontSize: ['md4', 'md4', 'md5', 'md5'],
    fontWeight: 'bold',
    lineHeight: 'short1'
  },
  head4: {
    fontFamily: 'heading',
    fontSize: ['md3', 'md3', 'md4', 'md4'],
    fontWeight: 'bold',
    lineHeight: 'short2',
    textTransform: 'none'
  },
  head5: {
    fontFamily: 'heading',
    fontSize: ['md2', 'md2', 'md3', 'md3'],
    fontWeight: 'semibold',
    lineHeight: 'base1',
    textTransform: 'none'
  },
  head6: {
    fontFamily: 'heading',
    fontSize: ['sm2', 'sm3'],
    fontWeight: 'semibold',
    lineHeight: 'base1'
  },
  subtitle1: {
    fontFamily: 'body',
    fontSize: 'md2',
    fontWeight: 'normal',
    lineHeight: 'base1'
  },
  subtitle2: {
    fontFamily: 'body',
    fontSize: 'md1',
    fontWeight: 'medium',
    lineHeight: 'base2'
  },
  claimit: {
    fontFamily: 'body',
    fontSize: ['md1'],
    fontWeight: 'bold',
    lineHeight: 'base2'
  },
  //TODO: Verify that all body1 instances ar 18px in size and 26px lineheight
  body1: {
    fontFamily: 'body',
    fontSize: 'md2', //18px
    fontWeight: 'normal',
    lineHeight: 'base1' //144% or 26px
  },
  body2: {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'md1',
    lineHeight: 'base2'
  },
  body3: {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'sm3',
    color: 'gray.400',
    lineHeight: 'base2'
  },
  body4: {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'sm3',
    color: 'gray.300',
    lineHeight: '5'
  },
  body5: {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'sm3',
    color: 'black',
    textTransform: 'uppercase',
    lineHeight: 'short1'
  },
  'body-footer': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'sm2',
    color: 'grey.200',
    lineHeight: '4'
  },
  'btn-label1': {
    fontFamily: 'body',
    fontSize: 'md1',
    fontWeight: 'bold',
    lineHeight: 'equal'
  },
  'tag-account': {
    fontFamily: 'body',
    fontSize: 'sm25',
    fontWeight: 'normal',
    lineHeight: 'equal'
  },
  'btn-label2': {
    fontFamily: 'heading',
    fontSize: 'md1',
    fontWeight: 'extrabold',
    lineHeight: 'equal'
  },
  'benefit-val1': {
    fontFamily: 'heading',
    fontSize: 'sm2',
    fontWeight: 'normal',
    lineHeight: 'base2',
    textTransform: 'uppercase'
  },
  'step-text1': {
    fontFamily: 'heading',
    fontSize: 'sm2',
    fontWeight: 'normal',
    lineHeight: 'base2',
    textTransform: 'uppercase'
  },
  price1: {
    fontFamily: 'heading',
    fontWeight: 'semibold',
    fontSize: ['md3'],
    lineHeight: 'base2'
  },
  price2: {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: ['sm3', 'md1'],
    lineHeight: 'base2'
  },
  'price1-cross': {
    fontFamily: 'heading',
    fontWeight: 'semibold',
    fontSize: ['md1', 'md2'],
    lineHeight: 'base2',
    textDecoration: 'line-through'
  },
  'price2-cross': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: ['sm3', 'md1'],
    lineHeight: 'base2',
    textDecoration: 'line-through'
  },
  'btn-text': {
    fontFamily: 'heading',
    fontSize: 'sm4',
    fontWeight: 'bold',
    lineHeight: 'none'
  },
  'btn-text1': {
    fontFamily: 'body',
    fontSize: 'md1',
    fontWeight: 'medium',
    lineHeight: 'none'
  },
  'article-top': {
    fontFamily: 'heading',
    fontSize: 'sm2',
    fontWeight: 'normal',
    lineHeight: 'base2',
    textTransform: 'uppercase'
  },
  'nav-text1': {
    fontFamily: 'heading',
    fontSize: 'sm3',
    fontWeight: 'normal',
    lineHeight: 'base1'
  },
  'article-home-subtitle': {
    fontFamily: 'heading',
    fontSize: 'sm2',
    fontWeight: 'normal',
    lineHeight: 'base1',
    textTransform: 'uppercase'
  },
  'link-text1': {
    fontFamily: 'body',
    fontSize: 'sm3',
    fontWeight: 'normal',
    lineHeight: 'base1'
  },
  'activity-totals': {
    fontFamily: 'heading',
    fontSize: 'md5',
    fontWeight: 300,
    lineHeight: 'short1',
    letterSpacing: '0'
  },
  'home-header': {
    fontFamily: 'body',
    fontSize: 'sm25',
    fontWeight: 400,
    lineHeight: 'short2',
    letterSpacing: '0'
  },
  'fine-print': {
    fontFamily: 'body',
    fontSize: 'md1',
    fontWeight: 400,
    lineHeight: 'base2',
    letterSpacing: '0'
  },
  'activity-tbd': {
    fontFamily: 'heading',
    fontWeight: 'semibold',
    fontSize: 'md5',
    lineHeight: 'base1'
  },
  'nav-drawer-note': {
    fontFamily: 'heading',
    fontWeight: '300',
    fontSize: 'sm2',
    lineHeight: 'base1',
    fontStyle: 'italic'
  }
}
export default textStyles
