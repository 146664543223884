import React, { memo } from 'react'

import { Box, Button, HStack, VStack } from '@chakra-ui/react'
import APPVARS from '@/constants/appvars'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { useFireAuth } from '@/lib/fireAuth'
import { formatDateNormal } from '@/utils/_formatDates'
import ROUTES from '@/constants/routes'
import MODALS from '@/constants/modals'

import NiceModal from '@ebay/nice-modal-react'

function AccNavLink({
  to,
  activeProps,
  children,
  _hover,
  handleClick,
  ...props
}) {
  const { team } = useFireAuth()
  const router = useRouter()
  const isActivity = to === ROUTES.ACCOUNT_ACTIVITY
  const isActive = router.asPath === to
  const handleAccClick = (e) => {
    e.preventDefault()

    NiceModal.show(`${MODALS.CREATE_TEAM_TO_CONTINUE}`, {
      name: 'CreateTeamToContinue'
    })

    //router.push(href)
  }

  if (isActivity && !team) {
    return (
      <Button variant="tabnav" onClick={handleAccClick}>
        <Box textStyle="body5">{children}</Box>
      </Button>
    )
  }
  if (isActive) {
    return (
      <Button variant="tabnav-active">
        <Box textStyle="body5">{children}</Box>
      </Button>
    )
  }
  return (
    <Link href={to} passHref>
      <Button variant="tabnav">
        <Box textStyle="body5">{children}</Box>
      </Button>
    </Link>
  )
}
const AccountMemberProfile = ({ member }) => {
  //console.log('member', member)
  const getDate = _.get(member, 'dateAdded', Date.now())
  const joinedDate = formatDateNormal({ value: getDate })

  return (
    <VStack mt="40px" spacing="4px" alignSelf="start" alignItems="flex-start">
      <Box textStyle="head3">Hello, {member?.firstName}</Box>
      <Box textStyle="body2" color="grey">
        Joined {joinedDate}
      </Box>
    </VStack>
  )
}
const AccountNav = (props) => {
  const { status, member } = useFireAuth()

  if (status !== 'loggedIn') {
    return null
  }
  if (!member) {
    return null
  }
  return (
    <VStack
      spacing="30px"
      borderBottomColor="inherit"
      borderBottomStyle="solid"
      borderBottomWidth="1px"
      maxW={{
        base: APPVARS.BLOCK_MAX_WIDTH_BASE,
        lg: APPVARS.BLOCK_MAX_WIDTH_FULL
      }}
      mx={{ base: 'auto' }}
      w="100%"
    >
      <AccountMemberProfile member={member} />
      <HStack w="100%">
        <AccNavLink to={ROUTES.ACCOUNT_ACTIVITY}>
          <Box textStyle="body5" color="gray.300">
            ACTIVITY
          </Box>
        </AccNavLink>

        <AccNavLink to={ROUTES.ACCOUNT_SETTINGS}>
          <Box textStyle="body5" color="gray.300">
            ACCOUNT SETTINGS
          </Box>
        </AccNavLink>
      </HStack>
    </VStack>
  )
}

export default memo(AccountNav)
