import React, { useState } from 'react'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { signInAutoRegister } from '@/lib/firebase'
import { AlertBox } from '@/elements'
import { activateSignInSchema } from '@/constants/form-schemas'

import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri'
import { ButtonLink } from '@/components'
import ROUTES from '@/constants/routes'
import APPVARS from '@/constants/appvars'

import {
  FieldInputEmail,
  FieldInputPassword,
  SubmitBtn,
  FieldInput
} from '@/forms'

import { getAlertHtmlContent } from '@/helpers'
import {
  IconButton,
  Link,
  Flex,
  HStack,
  Checkbox,
  Box,
  Text,
  Input,
  Button,
  InputRightElement,
  VStack,
  InputGroup
} from '@chakra-ui/react'

const ActivateSignInForm = ({ onFormComplete, switchScreen }) => {
  const [errorBox, setErrorBox] = useState()
  const [termsChecked, setTermsChecked] = useState(false)

  const [showAlertBox, setShowAlertBox] = useState(false)
  const [show, setShow] = React.useState(false)
  const handleShowPassword = () => setShow(!show)

  const {
    register,
    formState: { errors, isSubmitting, isValid, isDirty },
    handleSubmit,
    control
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      terms: false
    },
    resolver: yupResolver(activateSignInSchema)
  })

  const onSignInSubmit = async (data) => {
    const { email, password, terms } = data

    signInAutoRegister(email, password, terms)
      .then(() => {
        // Signed in
        onFormComplete()
      })
      .catch((error) => {
        const errorCode = error.code
        console.log('errorCode', errorCode)
        const errorHtml = getAlertHtmlContent({
          type: 'AUTH',
          code: errorCode,
          msg: error.message
        })

        setErrorBox({ type: 'error', msg: errorHtml })
        setShowAlertBox(true)
      })
  }

  return (
    <Flex
      as="form"
      direction="column"
      // spacing="10"
      onSubmit={handleSubmit(onSignInSubmit)}
    >
      <Box textStyle="head3l" pt={APPVARS.HRZ.XL}>
        Login to your Elevate account
      </Box>
      <Box textStyle="body1" pt={APPVARS.HRZ.LG} pb={APPVARS.HRZ.XL}>
        Please enter your email and the temporary password provided.
      </Box>
      <VStack w="100%" spacing={3}>
        <FieldInputEmail name="email" label="Email" error={errors.email}>
          <Input
            variant="outline"
            name="email"
            placeholder="email@domain.com"
            {...register('email')}
          />
        </FieldInputEmail>

        <FieldInputPassword label="Password" error={errors.password}>
          <InputGroup>
            <Input
              name="password"
              type={show ? 'text' : 'password'}
              placeholder="************"
              {...register('password')}
            />

            <InputRightElement>
              <IconButton
                aria-label="hide password"
                onClick={handleShowPassword}
                variant="icon-simple"
                icon={show ? <RiEyeCloseLine /> : <RiEyeLine />}
              />
            </InputRightElement>
          </InputGroup>
        </FieldInputPassword>
        <FieldInput>
          <HStack
            w="100%"
            spacing="12px"
            mb={APPVARS.HRZ.LG}
            justify="flex-start"
            alignItems="flex-start"
            mt={APPVARS.HRZ.SM}
          >
            <Controller
              control={control}
              name="terms"
              defaultValue={false}
              render={({ field, name }) => (
                <Checkbox
                  size="md2"
                  borderColor="gray.400"
                  name={name}
                  isChecked={termsChecked}
                  onChange={(e) =>
                    setTermsChecked((tc) => setTermsChecked(!tc))
                  }
                />
              )}
            />
            <Box textStyle="body2" mt={APPVARS.HRZ.SM}>
              I agree to the
              <Link variant="no-caps" href={ROUTES.TERMS_AND_CONDITIONS} ml={1}>
                Terms and Conditions{' '}
              </Link>
              and
              <Link variant="no-caps" href={ROUTES.PRIVACY_POLICY} ml={1}>
                Privacy Policy
              </Link>
            </Box>
          </HStack>
        </FieldInput>

        {showAlertBox && (
          <AlertBox status={errorBox.type}>
            <Flex
              p={APPVARS.HRZ.MD}
              flexDir="column"
              maxW="450px"
              lineHeight="base2"
              //  dangerouslySetInnerHTML={errorBox.msg}
            >
              We’re sorry, but we can’t verify your email or password. Please
              contact our customer service team for help.
              <Link
                pt={2}
                pb={1}
                variant="contact-us"
                href={ROUTES.CONTACT_US_FORM}
              >
                Contact Us
              </Link>
            </Flex>
          </AlertBox>
        )}
      </VStack>
      <Box align="center" mt="32px">
        <SubmitBtn
          type="submit"
          label="Log In"
          id="signin-submit"
          isDisabled={!isDirty || !isValid || !termsChecked}
          isWorking={isSubmitting}
          variant="solid"
        />
      </Box>
    </Flex>
  )
}

export default ActivateSignInForm
