import React from 'react'

import { IoHelpCircleSharp } from 'react-icons/io5'
import {
  Box,
  IconButton,
  Icon,
  Portal,
  HStack,
  Circle,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton
} from '@chakra-ui/react'

function TipPopper({ steps, currentStep, copy }) {
  const initialFocusRef = React.useRef()

  return (
    <Popover
      placement="top-start"
      closeOnBlur={false}
      initialFocusRef={initialFocusRef}
      returnFocusOnClose={false}
    >
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <Circle
              size="sm"
              height="24px"
              width="24px"
              borderColor="gray.300"
              borderRadius="full"
            >
              <IoHelpCircleSharp color="#C4C8D3" size="24px" />
            </Circle>
          </PopoverTrigger>

          <PopoverContent
            color="white"
            bg="purple"
            borderColor="purple"
            borderRadius="0"
          >
            <PopoverHeader pt={4} fontWeight="bold" border="0"></PopoverHeader>

            <PopoverCloseButton />
            <PopoverBody>{copy}</PopoverBody>
            <PopoverFooter
              border="0"
              d="flex"
              alignItems="center"
              justifyContent="flex-end"
            ></PopoverFooter>
            <PopoverArrow bg="purple" size="xl" />
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}

export default TipPopper
