import { Box, VStack, GridItem, Divider } from '@chakra-ui/react'
import { MDXRemote } from 'next-mdx-remote'
import Image from 'next/image'
import APPVARS from '@/constants/appvars'
const copycomp = {
  p: (props) => (
    <Box
      textStyle="body4"
      align="left"
      w="100%"
      lineHeight="base1"
      sx={{
        display: '-webkit-box',
        WebkitLineClamp: 5,
        WebkitBoxOrient: 'vertical'
      }}
      color="black"
      {...props}
    />
  )
}
const headcomp = {
  p: (props) => (
    <Box
      textStyle="subtitle2"
      w="100%"
      color="black"
      sx={{
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical'
      }}
      {...props}
    />
  )
}
const subcomp = {
  p: (props) => <Box textStyle="head6" w="100%" {...props} />
}

export default function BenefitCardColumn({
  benefitName,
  benefitSlug,
  benefitPartnerName,
  benefitPartnerLogo,
  benefitCardHeadline,
  benefitCardSubheadline,
  benefitCardCopy,
  benefitCardValue,
  colSpan = 1
}) {
  return (
    <GridItem
      align="stretch"
      // w="268px"
      width={{ base: 'calc(100vw - 60px)', sm: '268px' }}
      height="300px"
      overflow="hidden"
      justifyItems="stretch"
      alignSelf="stretch"
      justifySelf="center"
      colSpan={colSpan}
    >
      {benefitPartnerLogo && (
        <Box
          justifyItems="center"
          pos="relative"
          height="80px"
          // width={{ base: 'calc(100vw - 40px)', md: '268px' }}
          overflow="hidden"
        >
          <Image
            src={benefitPartnerLogo.url}
            alt={benefitPartnerLogo.imageName}
            layout="fill"
            objectFit="contain"
          />
        </Box>
      )}

      <Box mt={APPVARS.VRT.LG} align="left">
        {benefitCardHeadline && (
          <MDXRemote {...benefitCardHeadline.mdx} components={headcomp} />
        )}
        {benefitCardSubheadline && (
          <MDXRemote {...benefitCardSubheadline.mdx} components={subcomp} />
        )}
        <VStack spacing={APPVARS.VRT.MD} textAlign="left" align="stretch">
          {benefitCardValue && (
            <Box
              textStyle="benefit-val1"
              color="elevate_special.500"
              mt={APPVARS.VRT.XS}
              fontSize="sm2"
              lineHeight="short2"
            >
              {benefitCardValue}
            </Box>
          )}
          <Divider variant="benefit" />
          {benefitCardCopy && (
            <MDXRemote
              w="100%"
              {...benefitCardCopy.mdx}
              components={copycomp}
            />
          )}
        </VStack>
      </Box>
    </GridItem>
  )
}
