import { Box, Grid, VStack, Flex, useBreakpointValue } from '@chakra-ui/react'
import _ from 'lodash'
import APPVARS from '@/constants/appvars'
import { SectionImage } from '@/elements'
import { HomeCard } from '@/components'
const FeaturedWrapper = ({ section, articles }) => {
  const mainFeaturedArticles = _.take(articles, 2)
  const restFeaturedArticles = _.drop(articles, 2)
  const ar = useBreakpointValue({ base: 16 / 9, sm: 16 / 9, md: 1, lg: 1 })
  const ar2 = useBreakpointValue({ base: 16 / 9, sm: 16 / 9, md: 1, lg: 1 })
  //console.log('layerstyle', section?.layerStyle)
  return (
    <Box
      className="HomeSectionFeatured"
      layerStyle={section?.layerStyle ? section.layerStyle : 'default'}
      pb="80px"
    >
      <VStack
        p="0"
        maxW={{
          base: APPVARS.BLOCK_MAX_WIDTH_BASE,
          lg: APPVARS.BLOCK_MAX_WIDTH_FULL
        }}
        mx={{ base: 'auto', xl: 'auto' }}
        spacing="10"
      >
        <Flex
          width="100%"
          className="HomeSectionFeatured_content"
          justifyContent={
            section.sectionImageAlign ? section.sectionImageAlign : 'flex-start'
          }
          alignItems="center"
        >
          {section.sectionImage && (
            <Box
              pt="60px"
              className="HomeSectionFeatured_sectionImage"
              alignSelf="flex-start"
            >
              <SectionImage
                align={
                  section.imageSectionAlign ? section.imageSectionAlign : 'left'
                }
                {...section.sectionImage}
                width="260px"
                height="54px"
              />
            </Box>
          )}
        </Flex>

        <Grid
          width="100%"
          templateColumns={{
            base: '1fr',
            sm: '1.5fr 24px 1fr',
            md: '1.5fr 24px 1fr',
            lg: '616px auto 288px'
          }}
          templateRows={{ base: '1fr', md: '1fr' }}
          templateAreas={{
            base: `'featured' 'remain'`,
            sm: `'featured . remain'`,
            md: 'featured . remain',
            lg: 'featured . remain'
          }}
        >
          <VStack
            w="100%"
            alignItems="stretch"
            spacing="60px"
            gridArea="featured"
          >
            <HomeCard
              type="primary"
              ar={ar}
              article={mainFeaturedArticles[0]}
              headerStyle="head3"
            />
            <HomeCard
              type="secondary"
              ar={16 / 9}
              article={mainFeaturedArticles[1]}
              headerStyle="head3"
            />
          </VStack>
          <VStack
            width="100%"
            spacing="60px"
            pt={{ base: '60px', sm: 0 }}
            gridArea="remain"
          >
            {restFeaturedArticles.map((article) => (
              <HomeCard
                ar={ar2}
                key={article.id}
                type="remain"
                article={article}
                headerStyle="head4"
              />
            ))}
          </VStack>
        </Grid>
      </VStack>
    </Box>
  )
}

export default FeaturedWrapper
