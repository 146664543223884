import React from 'react'
import { Box, Stack, StackDivider } from '@chakra-ui/react'
import { NavLink, AccountTag } from '@/components'
const NavMenuDivider = () => {
  return (
    <Box mx="10px">
      <Box textStyle="nav-text1" color="grey.200">
        |
      </Box>
    </Box>
  )
}

const NavMenu = ({ isVertical = false, children, menuItems }) => {
  return (
    <Stack
      direction={isVertical ? 'column' : 'row'}
      spacing="10px"
      align={isVertical ? 'center' : 'center'}
      divider={
        isVertical ? (
          <StackDivider borderColor="gray.200" />
        ) : (
          <NavMenuDivider color="grey.200" />
        )
      }
    >
      {menuItems &&
        menuItems.length > 0 &&
        menuItems.map((item) => {
          return (
            <Box key={item.id}>
              <NavLink
                to={`/${item.slug}`}
                variant="navbtn"
                aria-label={item.slug}
              >
                {item.label ||
                  item.slug.charAt(0).toUpperCase() + item.slug.slice(1)}
              </NavLink>
            </Box>
          )
        })}
      {children}
    </Stack>
  )
}

export default NavMenu
