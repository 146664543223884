import {
  writeBatch,
  collection,
  getDocs,
  setDoc,
  query,
  arrayUnion,
  addDoc,
  updateDoc,
  where,
  doc,
  serverTimestamp
} from 'firebase/firestore'
import { v1 as uuidv1 } from 'uuid'
import { fsdb } from './firebase'
import _ from 'lodash'

const API_URL_DEV = 'https://gdl-service-layer-staged-uak3zqgy4q-uc.a.run.app'
const API_URL_PROD = 'https://gdl-service-layer-uak3zqgy4q-uc.a.run.app'

const API_URL_BASE =
  process.env.VERCEL_ENV === 'production' ? API_URL_PROD : API_URL_DEV

export const checkIfTeamAccountIsAvaliable = async (domain) => {
  const q = query(
    collection(fsdb, 'teamAccounts'),
    where('domain', '==', domain)
  )
  return getDocs(q)
}

export const createTeamAccount = async ({ memberData, teamData }) => {
  const ownerMember = {
    email: memberData.email,
    uid: memberData.uid,
    firstName: memberData.firstName,
    lastName: memberData.lastName,
    status: 'COMPLETED',
    role: 'owner'
  }

  const newTeamDocRef = doc(collection(fsdb, 'teamAccounts'))

  const teamAccountId = newTeamDocRef.id
  const vendorAccounts = {
    OD: {
      status: 'READY',
      type: '',
      connectedAccountId: '',
      teamAccountId: teamAccountId,
      vendorAccountId: '',
      vendorName: 'OD',
      vendorDescription: 'OfficeDepot'
    }
  }

  await setDoc(newTeamDocRef, {
    teamAccountId: newTeamDocRef.id,
    teamAccountStatus: 'pending',
    company: teamData.company,
    domain: teamData.domain,
    industry: teamData.industry,
    stage: teamData.stage,
    address1: teamData.address1,
    address2: teamData.address2,
    city: teamData.city,
    state: teamData.state,
    zipcode: teamData.zipcode,
    odAccountStatus: 'READY',

    teamMembers: arrayUnion(ownerMember),
    vendorAccounts: vendorAccounts,
    dateAdded: serverTimestamp(),
    dateUpdated: serverTimestamp()
  })

  const memberDoc = doc(fsdb, 'members', memberData.uid)

  await updateDoc(memberDoc, {
    teamAccountId: teamAccountId,
    teamAccountRole: 'owner',
    dateUpdated: serverTimestamp()
  })
}
export const updateTeamAccount = async ({ teamAccountId, teamData }) => {
  //console.log('teamData', teamData)
  const { dateAdded, dateUpdated, ...newTeamData } = teamData
  const teamRef = doc(fsdb, 'teamAccounts', teamAccountId)
  return updateDoc(teamRef, {
    ...newTeamData,
    dateUpdated: serverTimestamp()
  })
}
export const inviteTeamMembers = async ({
  teamAccountId,
  teamOriginalArray,
  teamNewArray
}) => {
  const noobs = teamNewArray.map((member) => {
    return { ...member, status: 'pending', role: 'member' }
  })
  const comboTeam = [...teamOriginalArray, ...noobs]

  const teamref = doc(fsdb, 'teamAccounts', teamAccountId)

  return updateDoc(teamref, {
    teamMembers: comboTeam
  })
}
// TeamMembers
export const updateTeamMembers = async ({ teamAccountId, teamMemberArray }) => {
  const teamMembers = teamMemberArray.map((member) => {
    return { ...member, uid: null, status: 'pending', role: 'member' }
  })
  const teamref = doc(fsdb, 'teamAccounts', teamAccountId)

  await updateDoc(teamref, {
    teamMembers: arrayUnion(...teamMembers)
  }).then(function () {
    console.log('teamMember added successfully')
  })
}

export const addBenefitsRequests = async ({ selectedList, teamAccountId }) => {
  const newBenefitsArray = selectedList.map((selectedBenefits) => {
    return selectedBenefits
  })

  const teamref = doc(fsdb, 'teamAccounts', teamAccountId)

  return updateDoc(teamref, {
    benefitRequests: newBenefitsArray,
    dateUpdated: serverTimestamp()
  })
}

// create funding source
export const requestFundingSource = async ({
  teamAccountId,
  dwollaCustomerId,
  isConnected
}) => {
  try {
    const docRef = doc(fsdb, 'teamAccounts', teamAccountId)

    const fundingSources = {
      dwolla: {
        dwollaCustomerId: dwollaCustomerId || null,
        isConnected: isConnected || null
      },
      stripe: {
        checkoutSessionId: null,
        stripeCustomerId: null,
        isConnected: null
      }
    }
    await updateDoc(docRef, {
      fundingSources: fundingSources,
      dateUpdated: serverTimestamp()
    })
    //console.log('Error creating documents: ', error)
    return { success: true, error: null }
  } catch (error) {
    console.log('Error creating documents: ', error)
    return { success: false, error: error }
  }
}

export const addDwollaCustomerId = async ({
  dwollaCustomerId,
  teamAccountId
}) => {
  console.log('dwollaCustomerId', dwollaCustomerId)
  const teamref = doc(fsdb, 'teamAccounts', teamAccountId)

  return updateDoc(teamref, {
    dwollaCustomerId: dwollaCustomerId,
    dateUpdated: serverTimestamp()
  })
}

export const requestNewVendorAccount = async ({
  teamAccountId,
  companyData
}) => {
  try {
    const docRef = doc(fsdb, 'teamAccounts', teamAccountId)
    const {
      address1,
      address2,
      city,
      state,
      zipcode,
      numberOfEmployees,
      annualRevenue,
      annualEstimatedSupplySpend
    } = companyData

    const shippingAddress = {
      line1: address2 === null ? address1 : `${address1}, ${address2}`,
      city: city,
      state: state,
      postal_code: zipcode,
      country: 'US'
    }

    const vendorAccounts = {
      OD: {
        status: 'PENDING',
        type: 'PORTAL',
        connectedAccountId: '',
        teamAccountId: teamAccountId,
        vendorAccountId: '',
        vendorName: 'OD',
        vendorDescription: 'OfficeDepot'
      }
    }
    await updateDoc(docRef, {
      shippingAddress: shippingAddress,
      numberOfEmployees: numberOfEmployees,
      annualEstimatedSupplySpend: annualEstimatedSupplySpend,
      annualRevenue: annualRevenue,
      odAccountStatus: 'PENDING',
      vendorAccounts: vendorAccounts,
      dateAdded: serverTimestamp(),
      dateUpdated: serverTimestamp()
    })

    return { success: true, error: null }
  } catch (error) {
    console.log('Error creating documents: ', error)
    return { success: false, error: error }
  }
}

export const updateCashBackEstimate = async ({ teamAccountId, value }) => {
  try {
    const teamRef = doc(fsdb, 'teamAccounts', teamAccountId)
    return await updateDoc(teamRef, { cashbackEstimate: value })
  } catch (error) {
    console.log('Error updating documents: ', error)
  }
}

export const addAccountActivity = async (specialActivity) => {
  try {
    const { teamAccountId, specialMod, productPrice } = specialActivity

    await addDoc(collection(fsdb, 'accountActivity'), {
      ...specialActivity,
      orderCreateDate: serverTimestamp()
    })

    const teamref = doc(fsdb, 'teamAccounts', teamAccountId)

    return await updateDoc(teamref, {
      cashBackEstimate: specialMod * productPrice
    })
  } catch (error) {
    console.log('Error add addAccountActivity: ', error)
    return false
  }
}
export const addSpecialProduct = async ({
  teamAccountId,
  newSpecial,
  claimedSpecials
}) => {
  try {
    const allSpecials = [...claimedSpecials, newSpecial]
    const batch = writeBatch(fsdb)
    console.log(`teamAccountId: ${teamAccountId}`)
    const taRef = doc(fsdb, 'teamAccounts', teamAccountId)
    batch.update(taRef, { specials: [...allSpecials] })
    const specialsClaimId = uuidv1()
    console.log(`specialsClaimId: ${specialsClaimId}`)
    const specialsDocColRef = doc(fsdb, 'specials', specialsClaimId)
    batch.set(specialsDocColRef, {
      specialsClaimId: specialsClaimId,
      ...newSpecial
    })

    await batch.commit()
    //doc(fsdb, 'specialProducts', newSpecial.specialProductId)
    // await addDoc(collection(fsdb, 'specials'), {
    //   ...newSpecial,
    // })
    return true
  } catch (error) {
    console.log('Error Update addSpecialProduct: ', error)
    return false
  }
}

export const addNewConnectedAccount = async ({
  teamAccountId,
  accountData
}) => {
  const vendorName = _.get(accountData, 'vendorName', 'OD')
  const vendorAccountId = _.get(accountData, 'vendorAccountId', '')
  const status = _.get(accountData, 'status', 'READY')
  const connectedAccountId = _.get(accountData, 'connectedAccountId', '')
  const type = _.get(accountData, 'type', '')
  const taRef = doc(fsdb, 'teamAccounts', teamAccountId)
  const connectedAccount = _.assign(
    {
      connectedAccountId,
      vendorName: 'OD',
      vendorDescription: 'OfficeDepot',
      vendorAccountId,
      status,
      type,
      teamAccountId
    },
    {}
  )
  const vendorAccount = {}
  vendorAccount[vendorName] = connectedAccount

  return updateDoc(taRef, {
    vendorAccounts: vendorAccount
  }).catch(() => {
    console.log('error', error)
  })
}

export const deleteOfficeDepotVendorId = async ({ connectedAccountId }) => {
  //console.log('connectedAccountId teamAccounts.js', connectedAccountId)
  const requestOptions = {
    method: 'DELETE'
  }
  const fetchUrl = `${API_URL_BASE}/api/connected-account/remove/${connectedAccountId}`
  const response = await fetch(fetchUrl, requestOptions)
  return response.json()
}
//console.log('requestOptions', requestOptions)
// const response = await fetch(
//   `https://gdl-service-layer-uak3zqgy4q-uc.a.run.app/api/connected-account/remove/${connectedAccountId}`,
//   requestOptions
// )

//console.log('responseJson', responseJson)
// const result = _.get(responseJson, 'success')

// }

export const removeConnectedAccount = async ({
  teamAccountId,
  accountData
}) => {
  const disconnectStatus = 'NOT CONNECTED'
  try {
    const taRef = doc(fsdb, 'teamAccounts', teamAccountId)
    const upAcc = {
      OD: {
        status: disconnectStatus,
        connectedAccountId: accountData.connectedAccountId,
        teamAccountId: teamAccountId,
        type: accountData.type,
        vendorAccountId: accountData.vendorAccountId,
        vendorName: 'OD',
        vendorDescription: 'Office Depot'
      }
    }
    await updateDoc(taRef, {
      vendorAccounts: upAcc,
      odAccountStatus: disconnectStatus
    })

    //console.log('_addNewConnectedAccount', result)
    return true
  } catch (error) {
    //console.log('Error removing vendor: ', error)
    return false
  }
}

export const verifyVendorAccountId = async ({
  teamAccountId,
  vendorName,
  vendorAccountId
}) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      teamAccountId: teamAccountId,
      vendorName: vendorName,
      vendorAccountId: vendorAccountId
    })
  }
  const verifyIdUrl = `${API_URL_BASE}/api/connected-account/verify-vendor`
  const response = await fetch(verifyIdUrl, requestOptions)
  const responseJson = await response.json()

  const resSuccess = _.get(responseJson, 'success')
  const resError = _.get(responseJson, 'error', false)
  const resData = _.get(responseJson, 'data')
  const resStatus = _.get(responseJson, 'data.status')

  return {
    resSuccess,
    resError,
    resData,
    resStatus
  }
  // if (resSuccess) {
  //   return { resSuccess: true, resStatus: resStatus, resData: resData }
  // }

  // return response.json()
}

export const updateVendorAccount = async ({
  teamAccountId,
  accountData,
  odAccountStatus
}) => {
  const vendorAccountId = _.get(accountData, 'vendorAccountId')
  const status = _.get(accountData, 'status')
  const connectedAccountId = _.get(accountData, 'connectedAccountId')
  const type = _.get(accountData, 'type', 'BUSINESS')
  const taRef = doc(fsdb, 'teamAccounts', teamAccountId)
  const connectedAccount = _.assign(
    {
      connectedAccountId,
      vendorName: 'OD',
      vendorDescription: 'Office Depot',
      vendorAccountId,
      status,
      type: _.toUpper(type),
      teamAccountId
    },
    {}
  )
  return updateDoc(taRef, {
    'vendorAccounts.OD': connectedAccount,
    odAccountStatus: _.toUpper(odAccountStatus)
  })
}
// export const getSpecialProducts = async ({ teamAccountId }) => {
//   const teamAccountRef = doc(fsdb,'teamAccounts',teamAccountId)
//   const teamSpecialAccountRef = teamAccountRef.collection('specialProducts')
//   try {
//     const results = await teamSpecialAccountRef.get()
//     if (results.empty) {
//       return { complete: true, status: 'empty', data: [] }
//     }
//     return { complete: true, status: 'empty', data: results.docs }
//   } catch (error) {
//     return { status: true, status: 'error', data: error }
//   }
// }

// export const addSpecialProduct = async ({ teamAccountId, specialProduct }) => {
//   const teamAccountRef = fsdb.collection('teamAccounts').doc(teamAccountId)
//   const specialProductsRef = teamAccountRef
//     .collection('specialProductsClaimed')
//     .doc()
//   const specialProductClaimId = specialProductsRef.id

//   try {
//     await specialProductsRef.set({
//       specialProductClaimId: specialProductClaimId,
//       teamAccountId: teamAccountId,
//       ...specialProduct,
//       dateClaimed: serverTimestamp()
//     })
//     return { complete: true, status: 'added', data: specialProductClaimId }
//   } catch (error) {
//     return { complete: true, status: 'error', data: error }
//   }
// }

export const addProductClick = async ({ clickData }) => {
  //console.log(`clickData`, clickData)

  try {
    await addDoc(collection(fsdb, 'productClicks'), {
      ...clickData,
      orderCreateDate: serverTimestamp()
    })
    //console.log(`cdata`, cdata)
    return
    // return cdata
    //console.log('Product Add', docRef.id)
  } catch (error) {
    //console.error('Error adding document: ', error)
  }
}

export const getProductClicks = async ({ teamAccountId }) => {
  //console.log(`clickData`, clickData)
  try {
    const q = query(
      collection(fsdb, 'productClicks'),
      where('teamAccountId', '==', teamAccountId)
    )

    // const query = fsdb
    //   .collection('productClicks')
    //   .where('teamAccountId', '==', teamAccountId)
    const qsnap = await getDocs(q)
    const clickbuys = qsnap.docs.map((doc) => doc.data())
    //console.log('clickbuys', clickbuys)
    return clickbuys
    //console.log('Product Add', docRef.id)
  } catch (error) {
    //console.error('Error adding document: ', error)
  }
}
