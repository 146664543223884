const divider = {
  defaultProps: {
    variant: 'solid'
  },
  baseStyle: {
    opacity: '0.6',
    borderColor: 'inherit',
    borderStyle: 'solid',
    borderWidth: '1px'
  },
  variants: {
    benefit: (props) => ({
      flexDirection: 'column',
      alignItems: 'center',
      opacity: '1',
      border: '1px solid',
      stroke: 'gray.400',
      borderColor: 'gray.400'
    })
  }
}

export default divider
