const input = {
  // style object for base or default style
  baseStyle: {
    background: 'white',
    border: '1px solid  grey-200',
    boxSizing: 'border-box',
    borderRadius: '8px'
  },

  sizes: {
    md: {
      fontSize: 'md1',
      lineHeight: 'md4'
    }
  },
  variants: {
    outline: (props) => ({
      bg: '#ff0000',
      color: '#00ff00'
    })
  },
  // default values for `size` and `variant`
  defaultProps: {
    size: 'md',
    variant: 'outline'
  }
}
export default input
