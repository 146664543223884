import { gql } from 'graphql-request'
/** MODEL: Faq */
const FaqFrag = gql`
  fragment FaqFrag on Faq {
    id
    faqName
    faqHeadline
    faqSubheadline
    faqCopy
  }
`
/** MODEL: Company */
const CompanyColumnFrag = gql`
  fragment CompanyColumnFrag on Company {
    id
    companyName
    companyHeadline
    companyLogo {
      id
      width
      height
      url
      imageName
      fileName
      mimeType
      size
    }
  }
`
/** MODEL: Product */
const ProductFrag = gql`
  fragment ProductFrag on Product {
    id
    productName
    productSlug
    productHeadline
    productSubheadline
    productVendor
    price
    unit
    productCtaLabel
    productCtaLink
    productId
    colSpan
    cloudImage
    isFeatured
    featuredProductHeadline
    featuredProductCopy
    specialProduct {
      slug
      dateActivated
      dateDeactivated
      id
      specialLimit
      specialMod
      specialType
      specialProductRestrictions
      totalAvailable
      totalClaimed
      totalIssued
      tooltip {
        id
        tooltipAction
        tooltipCopy
        tooltipName
        tooltipSlug
        tooltipLayerStyle
      }
    }
    featuredProductImage {
      fileName
      height
      id
      mimeType
      size
      url
      width
      imageName
    }
    productImage {
      fileName
      height
      id
      mimeType
      size
      url
      width
      imageName
    }
  }
`
/** MODEL: Resource */
const ResourceFrag = gql`
  fragment ResourceFrag on Resource {
    id
    resourceCopy
    resourceCtaLabel
    resourceCtaLink
    resourceHeadline
    resourceName
    resourceSlug
    resourceSubheadline
    resourceImage {
      fileName
      height
      id
      mimeType
      size
      url
      width
      imageName
    }
  }
`

/** MODEL: Company */
const BenefitCardColumnFrag = gql`
  fragment BenefitCardColumnFrag on Benefit {
    benefitPartnerName
    benefitSlug
    id
    benefitPartnerLogo {
      fileName
      height
      id
      mimeType
      size
      url
      width
      imageName
    }
    benefitCardHeadline
    benefitCardSubheadline
    benefitCardCopy
    benefitCardValue
  }
`

export {
  FaqFrag,
  ResourceFrag,
  ProductFrag,
  CompanyColumnFrag,
  BenefitCardColumnFrag
}

// export {
//   StatFrag,
//   PricingPlanFrag,
//   FaqFrag,
//   ResourceFrag,
//   ProductFrag,
//   CompanyColumnFrag,
//   ColumnsFrag
// }
