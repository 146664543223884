import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Box, VStack, Input, Flex, Button, SimpleGrid } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { updateMemberEmailVerified, fbauth } from '@/lib/firebase'

const CreateCustomer = ({
  member,
  team,
  firstName,
  lastName,
  email,
  setDwollaCustomerId,
  setGetStarted,
  requestFundingSource,
  dwollaCustomerId
}) => {
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    mode: 'onChange'
  })

  const submitDwollaCustomerId = async () => {
    const teamAccountId = member?.teamAccountId
    try {
      await requestFundingSource({ dwollaCustomerId, teamAccountId })
    } catch (error) {
      console.log('error', error)
    }
  }

  const setEmailVerifiedOnMember = async () => {
    let memberId = member?.uid
    let emailVerified = fbauth?.currentUser?.emailVerified
    try {
      await updateMemberEmailVerified(memberId, emailVerified)
    } catch (error) {
      console.log('error: ', error)
    }
  }

  function handleSubmitCustomer(event) {
    const dwollaCustomerId = team?.fundingSources?.dwolla.dwollaCustomerId
    if (dwollaCustomerId) {
      console.log('nahhhhhh', dwollaCustomerId)
      setDwollaCustomerId(dwollaCustomerId)
      setEmailVerifiedOnMember()
      setGetStarted(true)
    } else {
      axios
        .post('/api/customer', {
          firstName: `${firstName}`,
          lastName: `${lastName}`,
          email: `${email}`,
          type: 'receive-only'
        })
        .then(
          (response) => {
            const location = response.data.token
            if (location.substring(12, 19) === 'sandbox') {
              const id = location.substring(41)
              console.log('id1', id)
              setDwollaCustomerId(id)
              submitDwollaCustomerId()
              setEmailVerifiedOnMember()

              setGetStarted(true)
            } else {
              const id = location.substring(33)
              console.log('id2', id)
              setDwollaCustomerId(id)
              submitDwollaCustomerId()
              setEmailVerifiedOnMember()

              setGetStarted(true)
            }
          },
          (error) => {
            // eslint-disable-next-line no-console
            if (error.response) {
              console.log('error.response', error.response)
            }
            console.log('Error: Failed to create a Customer.', error)
          }
        )
    }
  }

  return (
    <VStack w="100%" spacing="8">
      <VStack w="100%" spacing="5"></VStack>
      <VStack
        pt="52px"
        as="form"
        align="stretch"
        onSubmit={handleSubmit(handleSubmitCustomer)}
        spacing="20px"
      >
        <Flex width="100%" alignItems="center" justify="center" mt="30px">
          <Button
            id="create-new-dwolla-customer-submit"
            variant="solid"
            isLoading={isSubmitting}
            type="submit"
            disabled={!email || !firstName || !lastName}
            alignSelf="flex-end"
          >
            Get Started
          </Button>
        </Flex>
      </VStack>
    </VStack>
  )
}

export default CreateCustomer
