import React, { useState, useEffect } from 'react'
import {
  Box,
  VStack,
  Input,
  Flex,
  Button,
  Checkbox,
  SimpleGrid,
  Select
} from '@chakra-ui/react'
import * as yup from 'yup'
import { FieldInput } from '@/forms'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFireAuth } from '@/lib/fireAuth'
import { requestNewVendorAccount } from '@/lib/teamAccounts'
import {
  REVENUE,
  ESTIMATED_SPEND,
  EMPLOYEE_NUM,
  TERMS_NEW_ODACCOUNT
} from '@/constants/form-options'
// import { createDLConnectedAccount } from '@/lib/datalakes'
// import { createOfficeDepotConnectedAccount } from '@/lib/firebase'
import { AlertBox } from '@/elements'
import { onboardScreensCopy } from '@/constants/onboard-screens-copy'

import { LogoOfficeDepotMax } from '@/logos'
const AccountSchema = yup.object().shape({
  numberOfEmployees: yup.string().required('Enter the number of employees'),
  annualRevenue: yup.string().required('Enter business annual revenue'),
  annualEstimatedSupplySpend: yup
    .string()
    .required('Enter business annual supply spend'),
  address1: yup.string().required('Enter shipping address'),
  address2: yup.string(),
  city: yup.string().required('Enter shipping city'),
  state: yup.string().required('Enter shipping state'),
  zipcode: yup.string().required('Enter shipping ZIP code'),
  terms: yup.bool().oneOf([true], 'Please accept T&Cs')
})

const accountFields = {
  numberOfEmployees: '',
  annualRevenue: '',
  annualEstimatedSupplySpend: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipcode: '',
  terms: false
}

const SuccessAlertBox = () => {
  return <Box></Box>
}
const CreateNewVendorAccount = ({
  handleNextAction,
  handleBackAction,
  fromModal = false
}) => {
  const [formReady, setFormReady] = useState()
  const [formDefaults, setFormDefaults] = useState()
  const [formSentSuccess, setFormSentSuccess] = useState(false)
  const [apiError, setApiError] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const { member, team } = useFireAuth()
  useEffect(() => {
    const defaultVal = {
      ...accountFields
    }
    setFormDefaults(defaultVal)
    setFormReady(true)
  }, [])
  const {
    register,
    handleSubmit,

    formState: { errors, isDirty, isValid, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues: formDefaults,
    resolver: yupResolver(AccountSchema)
  })

  const submitNewVendorAccount = async (data) => {
    const { email, firstName, lastName, teamAccountId } = member
    const companyData = { ...data, email, firstName, lastName, teamAccountId }
    try {
      const { success, error } = await requestNewVendorAccount({
        teamAccountId,
        companyData
      })
      setFormSentSuccess(success)
    } catch (error) {
      setErrorMessage(error.message)
      setApiError(true)
    }
  }

  if (!formReady) {
    return <Box>form</Box>
  }
  return (
    <VStack w="100%" spacing="8">
      <VStack w="100%" spacing="5">
        <Box w="100%" textStyle="head4">
          {onboardScreensCopy.VENDOR_NEW_MAIN_SCREEN.HEADLINE}
        </Box>
        <Box w="100%" textStyle="body2">
          {onboardScreensCopy.VENDOR_NEW_MAIN_SCREEN.COPY}
        </Box>
      </VStack>
      <Box
        layerStyle="form1"
        p="30px"
        border="solid 1px"
        maxW="container.lg"
        align="stretch"
      >
        <Box w="180px" h="52px">
          <LogoOfficeDepotMax />
        </Box>
        <VStack
          pt="52px"
          as="form"
          align="stretch"
          onSubmit={handleSubmit(submitNewVendorAccount)}
          spacing="20px"
        >
          <Box layerStyle="form-head" textStyle="head5" width="100%">
            {onboardScreensCopy.VENDOR_NEW_FORM_SCREEN.HEADLINE}
          </Box>

          {!formSentSuccess && (
            <>
              <Box textStyle="body2">
                {onboardScreensCopy.VENDOR_NEW_FORM_SCREEN.COPY}
              </Box>

              <SimpleGrid minChildWidth="240px" columns={3} spacing="8px">
                <FieldInput
                  label="Number Of Employees"
                  error={errors.numberOfEmployees}
                >
                  <Select
                    placeholder="Select a range"
                    {...register('numberOfEmployees')}
                    errorBorderColor="red.500"
                    focusBorderColor="blue.400"
                    variant="outline"
                  >
                    {EMPLOYEE_NUM.map((opt) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </Select>
                </FieldInput>
                <FieldInput
                  name="revenue"
                  label="Annual Revenue"
                  error={errors.annualRevenue}
                >
                  <Select
                    placeholder="Select a range"
                    {...register('annualRevenue')}
                    errorBorderColor="red.500"
                    focusBorderColor="blue.400"
                    variant="outline"
                  >
                    {REVENUE.map((opt) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </Select>
                </FieldInput>
                <FieldInput
                  label="Annual Office Supply Spend"
                  error={errors.annualEstimatedSupplySpend}
                >
                  <Select
                    placeholder="Select a range"
                    {...register('annualEstimatedSupplySpend')}
                    errorBorderColor="red.500"
                    focusBorderColor="blue.400"
                    variant="outline"
                  >
                    {ESTIMATED_SPEND.map((opt) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </Select>
                </FieldInput>

                <Box textStyle="body3">
                  {onboardScreensCopy.VENDOR_NEW_FORM_SCREEN.NOTE}
                </Box>
              </SimpleGrid>

              <VStack spacing="8px" width="100%">
                <FieldInput
                  label="Primary Shipping Address"
                  error={
                    errors.address1 ||
                    errors.address2 ||
                    errors.city ||
                    errors.state ||
                    errors.zipcode
                  }
                />
                <SimpleGrid columns={2} spacing="8px" w="100%">
                  <Input
                    id="address1"
                    placeholder="Address 1"
                    {...register('address1')}
                  />
                  <Input
                    id="address2"
                    placeholder="Address 2 (Optional)"
                    {...register('address2')}
                  />
                </SimpleGrid>

                <SimpleGrid
                  minChildWidth="240px"
                  columns={3}
                  spacing="8px"
                  w="100%"
                >
                  <Input id="city" placeholder="City" {...register('city')} />
                  <Input
                    id="state"
                    placeholder="State"
                    {...register('state')}
                  />
                  <Input
                    id="zipcode"
                    placeholder="ZIP Code"
                    {...register('zipcode')}
                  />
                </SimpleGrid>
                <Box textStyle="body3" color="grey.300" w="100%">
                  {onboardScreensCopy.VENDOR_NEW_FORM_SCREEN.NOTE2}
                </Box>
              </VStack>

              <FieldInput>
                <Checkbox
                  name="terms"
                  alignItems="flex-start"
                  {...register('terms')}
                >
                  {TERMS_NEW_ODACCOUNT}
                </Checkbox>
              </FieldInput>

              <Flex width="100%" alignItems="center" justify="center" mt="30px">
                <Button
                  id="create-new-vendor-account-submit"
                  variant="solid"
                  isLoading={isSubmitting}
                  type="submit"
                  disabled={!isDirty || !isValid}
                  alignSelf="flex-end"
                >
                  Submit
                </Button>
              </Flex>
            </>
          )}
        </VStack>
        {formSentSuccess && (
          <AlertBox status="success">
            <Box>
              {onboardScreensCopy.VENDOR_NEW_FORM_SCREEN.SUCCESS_MESSAGE}
            </Box>
          </AlertBox>
        )}
      </Box>
      <Box w="100%" align="center" py="60px">
        {formSentSuccess && (
          <Button
            id="create-new-vendor-account-next"
            my="4"
            variant="solid"
            onClick={handleNextAction}
          >
            Next
          </Button>
        )}
        {!fromModal && !formSentSuccess && (
          <VStack>
            <Button
              id="create-new-vendor-account-back"
              variant="solid"
              onClick={handleBackAction}
            >
              Back
            </Button>

            <Button
              id="create-new-vendor-account-skip"
              variant="form-link"
              onClick={handleNextAction}
            >
              SKIP FOR NOW
            </Button>
          </VStack>
        )}
      </Box>
    </VStack>
  )
}

export default CreateNewVendorAccount
