import React from 'react'
import Image from 'next/image'
import { MDXRemote } from 'next-mdx-remote'
import {
  Box,
  VStack,
  VisuallyHidden,
  Grid,
  GridItem,
  Wrap,
  WrapItem,
  useBreakpointValue
} from '@chakra-ui/react'
import { NavButton } from '@/components'
import { getPaddingX, getPaddingY } from '@/theme/helpers'

const foottagcomp = {
  p: (props) => (
    <Box
      textStyle="body-footer"
      textAlign={{ base: 'center', md: 'left' }}
      {...props}
    />
  )
}
const footerCRmobile = {
  p: (props) => (
    <Box
      textStyle="body-footer"
      textAlign={{ base: 'center', md: 'left' }}
      {...props}
    />
  )
}

const FooterNavLinks = ({ footerLinks }) => {
  //console.log('footerLinks', footerLinks)
  return (
    <VStack spacing="4" align="left">
      {footerLinks.map((link) => (
        <NavButton
          px="0"
          key={link.id}
          href={link.navLinkUrl}
          variant={link.navLinkVariant}
          isExternal={link.isExternal}
          size={link.navLinkSize}
          label={link.navLinkLabel}
        />
      ))}
    </VStack>
  )
}

const FooterNavLinks2 = ({ footerLinks }) => {
  //console.log('footerLinks', footerLinks)
  return (
    <Wrap spacing="8px" justify="center">
      {footerLinks.map((link) => (
        <WrapItem key={link.id}>
          <NavButton
            mx={0}
            py="10px"
            key={link.id}
            href={link.navLinkUrl}
            variant={link.navLinkVariant}
            isExternal={link.isExternal}
            size={link.navLinkSize}
            label={link.navLinkLabel}
          />
        </WrapItem>
      ))}
    </Wrap>
  )
}

function Footer({ footerLogo, copyrightNotice, footerTagLine, navLinks }) {
  const paddingY = getPaddingY('lg')
  const paddingX = getPaddingX('lg')
  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <Box
      maxW={{ base: `calc(100vw - 60px)`, lg: '964px' }}
      mx={{ base: 'auto', xl: 'auto' }}
      width="100%"
      as="footer"
      color="white"
      bg="brand.800"
      aria-labelledby="footerHeading"
      py={paddingY}
      // px={paddingX}
    >
      <VisuallyHidden as="h2" id="footerHeading">
        Footer
      </VisuallyHidden>
      <Grid
        templateColumns={{ base: '1fr', md: '250px auto 190px' }}
        templateRows="100 100"
        gap={10}
        templateAreas={{
          base: `'brand' 'navs' 'copyright'`,
          md: `'brand . navs' 'copyright . navs'`
        }}
      >
        <GridItem
          gridArea="brand"
          alignContent={{ base: 'center', md: 'flex-start' }}
        >
          {footerLogo && (
            <Box
              display="flex"
              w="100%"
              direction="center"
              alignItems={{ base: 'center', md: 'left' }}
              justifyContent={{ base: 'center', md: 'left' }}
            >
              <Image
                src={footerLogo.url}
                alt={footerLogo.title}
                title={footerLogo.title}
                width="152px"
                height="50px"
              />
            </Box>
          )}
          {footerTagLine && (
            <Box pt="4">
              {}
              <MDXRemote {...footerTagLine.mdx} components={foottagcomp} />
            </Box>
          )}
        </GridItem>
        <GridItem gridArea="navs">
          {isMobile
            ? navLinks && <FooterNavLinks2 footerLinks={navLinks} />
            : navLinks && <FooterNavLinks footerLinks={navLinks} />}
        </GridItem>
        <GridItem gridArea="copyright" justifyItems="end">
          {copyrightNotice && (
            <MDXRemote
              {...copyrightNotice.mdx}
              components={footerCRmobile}
              textAlign="center"
            />
          )}
        </GridItem>
      </Grid>
    </Box>
  )
}
export default Footer
