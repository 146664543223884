import { Flex, Box } from '@chakra-ui/react'
import { Seo, SiteBar } from '@/components'
import APPVARS from '@/constants/appvars'
import { FooterSectionsContainer, FooterContainer } from '@/containers'

export default function SiteLayout({ children, page, preview = false }) {
  return (
    <>
      {page?.seo && <Seo {...page.seo} />}

      <Box
        as="main"
        className="LAYOUT_SITE"
        flexGrow="1"
        w="100%"
        m="0"
        maxWidth="full"
      >
        <SiteBar navType="site" />
        <Flex
          className="LAYOUT_SITE_COL"
          direction="column"
          alignItems="stretch"
          flexGrow={1}
          justify="stretch"
        >
          <Box
            flexGrow="0"
            height={APPVARS.NAV_BAR_HEIGHT}
            alignSelf="flex-start"
            className="NAV_Space"
          />
          <Flex className="LAYOUT_SITE_CONTENT" flexGrow="1" dir="column">
            {children}
          </Flex>
          {page?.footerSections && (
            <FooterSectionsContainer sections={page.footerSections} />
          )}

          {page?.footer && (
            <Box className="LAYOUT_SITE_FOOTER" flexGrow="0">
              <FooterContainer {...page.footer} />
            </Box>
          )}
        </Flex>
      </Box>
    </>
  )
}

export const getLayout = (page) => (
  <SiteLayout {...page.props}>{page}</SiteLayout>
)
