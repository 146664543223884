import * as React from 'react'

function SvgLogoLogiTechBW(props) {
  return (
    <svg
      width="67"
      height="20"
      viewBox="0 0 67 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3137_9657)">
        <path
          d="M0.554688 14.1336V0.325195H2.9803V14.1336H0.554688Z"
          fill="#3B3F46"
        />
        <path
          d="M9.18982 4.11621C6.07987 4.11621 3.92773 6.50737 3.92773 9.24428C3.92773 12.2988 6.26901 14.4031 9.21399 14.4031C12.0089 14.4031 14.4343 12.3376 14.4343 9.23768C14.4343 6.63098 12.4246 4.11621 9.18982 4.11621ZM9.16126 6.45614C10.7888 6.45614 11.9867 7.72303 11.9867 9.25307C11.9867 10.8753 10.666 12.0588 9.2052 12.0588C7.47915 12.0588 6.3929 10.6907 6.3929 9.25746C6.3929 7.68642 7.61929 6.45614 9.16126 6.45614Z"
          fill="#3B3F46"
        />
        <path
          d="M20.5277 4.11621C17.4178 4.11621 15.2656 6.50737 15.2656 9.24428C15.2656 12.2988 17.6069 14.4031 20.5519 14.4031C23.3468 14.4031 25.7722 12.3376 25.7722 9.23768C25.7722 7.41224 24.9817 6.60229 24.9817 6.60229L25.654 6.37174V4.40313H22.2089C22.2089 4.40313 21.4621 4.11621 20.5277 4.11621ZM20.4992 6.45614C22.1267 6.45614 23.3246 7.72303 23.3246 9.25307C23.3246 10.8753 22.0039 12.0588 20.5431 12.0588C18.817 12.0588 17.7308 10.6907 17.7308 9.25746C17.7308 7.68642 18.9572 6.45614 20.4992 6.45614Z"
          fill="#3B3F46"
        />
        <path
          d="M23.309 14.8086C23.309 16.4309 21.9882 17.6231 20.5275 17.6231C18.8014 17.6231 17.7108 16.2419 17.7108 14.8086H15.25C15.25 17.8632 17.5913 19.9674 20.5363 19.9674C23.3312 19.9674 25.7566 17.9085 25.7566 14.8086H23.309Z"
          fill="#3B3F46"
        />
        <path
          d="M26.7441 14.1339V4.40332H29.1697V14.1339H26.7441Z"
          fill="#3B3F46"
        />
        <path
          d="M31.5253 14.1337V6.58265H30.2246V4.40311H31.5253V1.41504H33.9509V4.40311H35.2868V6.58265H33.9509V14.1337H31.5253Z"
          fill="#3B3F46"
        />
        <path
          d="M29.5582 1.63944C29.5582 2.52654 28.839 3.24567 27.9519 3.24567C27.0648 3.24567 26.3457 2.52654 26.3457 1.63944C26.3457 0.752339 27.0648 0.0332031 27.9519 0.0332031C28.839 0.0332031 29.5582 0.752339 29.5582 1.63944Z"
          fill="#3B3F46"
        />
        <path
          d="M40.7406 4.10938C37.6159 4.10938 35.6016 6.50178 35.6016 9.33192C35.6016 12.2566 37.6226 14.4094 40.7911 14.4094C43.7468 14.4094 45.2008 12.5639 45.2008 12.5639L43.5793 10.9402C43.5793 10.9402 42.355 12.2607 40.8219 12.2607C39.5439 12.2607 38.5221 11.5474 38.1656 10.2393H45.7237V9.5714C45.7237 6.9063 44.1811 4.10938 40.7406 4.10938ZM40.6637 6.24497C41.7811 6.24497 42.9238 6.84276 43.1794 8.27071H38.0997C38.3009 7.26695 39.179 6.24497 40.6637 6.24497Z"
          fill="#3B3F46"
        />
        <path
          d="M51.7901 14.4116C48.6566 14.4116 46.5215 12.025 46.5215 9.211C46.5215 6.30699 48.9346 4.1084 51.7801 4.1084C54.5309 4.1084 55.9389 6.05029 55.9389 6.05029L54.2468 7.7347C54.2468 7.7347 53.2667 6.46829 51.7686 6.46829C50.1082 6.46829 48.9729 7.73844 48.9729 9.24824C48.9729 10.8424 50.1806 12.0655 51.7499 12.0655C53.2721 12.0655 54.2445 10.8011 54.2445 10.8011L55.9535 12.4846C55.9535 12.4846 54.5591 14.4116 51.7901 14.4116Z"
          fill="#3B3F46"
        />
        <path
          d="M56.8359 14.1406V0.290039H59.2616V5.37881C59.2616 5.37881 60.1903 4.10769 62.2107 4.10769C64.7505 4.10769 66.222 5.99576 66.222 8.41057V14.1406H63.7964V8.77718C63.7964 7.15487 62.7864 6.48181 61.6674 6.48181C60.3926 6.48181 59.2616 7.20732 59.2616 8.90272V14.1406H56.8359Z"
          fill="#3B3F46"
        />
      </g>
      <defs>
        <clipPath id="clip0_3137_9657">
          <rect
            width="65.7331"
            height="20"
            fill="white"
            transform="translate(0.521484)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgLogoLogiTechBW
