import {
  Box,
  VStack,
  HStack,
  Tag,
  AspectRatio,
  Flex,
  LinkOverlay,
  LinkBox
} from '@chakra-ui/react'
import _ from 'lodash'

import NextLink from 'next/link'
import Image from 'next/image'

import { Headline } from '@/elements'
import { stickerFormat } from '@/helpers'

// const altWidth = { base: '100%', sm: '100%', md: '400px', lg: '616px' }
// const altMaxWidth = { base: '440px', sm: '631px', md: '400px', lg: '616px' }
// const mainWidth = { base: '100%', sm: '100%', md: '200px', lg: '268px' }
// const mainMaxWidth = { base: '440px', sm: '631px', md: '200px', lg: '268px' }
const altWidth = { base: '100%' }
const altMaxWidth = { base: '100%' }
const mainWidth = { base: '100%' }
const mainMaxWidth = { base: '100%' }
export default function HomeCard({
  article,
  headerStyle,
  type = 'main',
  ar = 1
}) {
  //type 'primary' for first featured article
  // type 'secondary' for second featured article
  // type 'main' for all other articles

  //console.log('bv', bv)

  return (
    <Box
      p="0"
      className={`HomeCard_${type}`}
      w={type === 'main' ? { mainWidth } : { altWidth }}
      maxWidth={type === 'main' ? { mainMaxWidth } : { altMaxWidth }}
    >
      <LinkBox as="article">
        <VStack h="100%" spacing={2} align="stretch">
          {article.articleImages && article.articleImages.length > 0 && (
            <AspectRatio ratio={ar}>
              <Image
                src={article.articleImages[0].url}
                alt={article.articleImages[0].title}
                title={article.articleImages[0].title}
                layout="fill"
                objectFit="cover"
              />
            </AspectRatio>
          )}
          <Box textStyle="article-home-subtitle" color="grey.400">
            {article.articleHomeSubtitle}
          </Box>

          <Flex dir="column" justifyContent="flex-end">
            <NextLink
              id={`home-${article.slug}`}
              href={`/articles/${article.slug}`}
              passHref
            >
              <LinkOverlay w="100%">
                {article.articleHeadline && (
                  <Box
                    w={type === 'main' ? { mainWidth } : { altWidth }}
                    maxWidth={
                      type === 'main' ? { mainMaxWidth } : { altMaxWidth }
                    }
                    overflow="hidden"
                  >
                    <Headline
                      overflow="hidden"
                      sx={{
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical'
                      }}
                      source={article.articleHeadline}
                      textStyle={headerStyle}
                    />
                  </Box>
                )}
              </LinkOverlay>
            </NextLink>
          </Flex>
          {article?.articleSticker.length > 0 && (
            <HStack spacing={4}>
              {article.articleSticker.map((sticker) => {
                const vart = _.toLower(sticker)

                return (
                  <Tag size="md" key={sticker} variant={vart}>
                    {stickerFormat(sticker)}
                  </Tag>
                )
              })}
            </HStack>
          )}
        </VStack>
      </LinkBox>
    </Box>
  )
}
