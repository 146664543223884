import { Box, VStack, Grid, GridItem, AspectRatio } from '@chakra-ui/react'

import Image from 'next/image'

import { RichText } from '@graphcms/rich-text-react-renderer'

import { ButtonMain } from '@/components'
import APPVARS from '@/constants/appvars'
const Body2 = (props) => {
  return <Box textStyle="body2" {...props} />
}

const components = {
  p: Body2
}
const CardBlock = ({
  cardBlockName,
  cardBlockSlug,
  cardBlockContent,
  cardBlockMedia,
  cardBlockMediaOrder = 1,
  cardBlockMediaStyle,
  cardBlockVariant,
  cardBlockSize = 'full',
  cardBlockButton,
  cardBlockWidth = 'lg',
  textAlign = 'left',
  blockComponent
}) => {
  //const maxBlockWidth = APPVARS.MAX_BLOCK_WIDTH
  const flip = cardBlockMediaOrder > 0 ? true : false
  return (
    <Grid
      w="100%"
      py={cardBlockVariant === 'PAGE_CARD' ? '80px' : '20'}
      // maxWidth={blockSize}

      className={`CARD_BLOCK" ${cardBlockVariant}`}
      gap="24px"
      templateColumns={{ base: '1fr', md: '1fr  1fr' }}
      // templateColumns="repeat(auto-fit, minmax(320px, 394px))"
      templateAreas={{
        base: `'media' 'content'`,
        md: `'media  content'`
      }}
      templateRows={{ base: 'auto', md: '1fr' }}
      alignItems="center"
      justifyContent="space-between"
      px={{ base: '20px', md: '0' }}
    >
      {cardBlockMedia && (
        <AspectRatio
          gridArea={flip ? 'content' : 'media'}
          height="100%"
          ratio="1"
        >
          <Image
            src={cardBlockMedia.url}
            alt={cardBlockMedia.title}
            title={cardBlockMedia.title}
            layout="fill"
            objectFit="contain"
          />
        </AspectRatio>
      )}
      <GridItem
        w="100%"
        gridArea={flip ? 'media' : 'content'}
        minWidth={{ base: '212px', sm: '320px' }}
        maxWidth={cardBlockMedia ? '395px' : '100%'}
        alignItems="left"
        align="stretch"
      >
        <VStack spacing="5" w="100%">
          {/*card copy*/}
          {/* TODO: #239 We could target individual elements within the RichText
              component so add a marginBottom mb="5"(20px) or I think  the Box above that wraps  the RichText should probably be a  VStack with spacing="5", this will add 20px between each element,  then you'll have to  remove the mb="3" on each element below */}
          {cardBlockContent && (
            <VStack
              align="stretch"
              spacing="5"
              textAlign={textAlign}
              w="100%"
              fontSize="md2"
            >
              {/* TODO: #237 We could target individual elements within the RichText
              component so add a marginBottom mb="5"(20px) or I think  the Box above that wraps  the RichText should probably be a  VStack with spacing="5", this will add 20px between each element,  then you'll have to  remove the mb="3" on each element below */}
              <RichText
                content={cardBlockContent.json}
                renderers={{
                  h1: ({ children }) => <Box textStyle="head1">{children}</Box>,
                  h3: ({ children }) => <Box textStyle="head3">{children}</Box>,
                  h5: ({ children }) => <Box textStyle="head5">{children}</Box>,
                  p: ({ children }) => <Box textStyle="body1">{children}</Box>,
                  ol: ({ children }) => (
                    <Box as="ol" paddingStart="6">
                      {children}
                    </Box>
                  ),
                  ul: ({ children }) => (
                    <Box as="ul" paddingStart="6">
                      {children}
                    </Box>
                  ),
                  bold: ({ children }) => <strong>{children}</strong>
                }}
              />
            </VStack>
          )}
          {cardBlockButton && (
            <Box w="100%">
              <ButtonMain {...cardBlockButton} />
            </Box>
          )}
        </VStack>
      </GridItem>
    </Grid>
  )
}
export default CardBlock
