import React from 'react'
import { Box } from '@chakra-ui/react'
import { AlertBox } from '@/elements'
const NavMenuNote = ({ status, children }) => {
  return (
    <Box my="2">
      <AlertBox status={status}>
        <Box w="full" textStyle="nav-drawer-note" textAlign="center" p="2">
          {children}
        </Box>
      </AlertBox>
    </Box>
  )
}

export default NavMenuNote
