import { gql } from 'graphql-request'
// TODO: #459 remove and refactor unused queries and fragments @tech-ea

import { PageFrag, PageSectionFrag, FooterSectionsFrag } from './page'

import {
  ArticlesIndexFrag,
  ArticleFeaturedFrag,
  ArticleFrag,
  ArticleSectionFrag,
  ArticleHomePopFrag
} from './article'

const get404PageQuery = gql`
  query get404Query() {
    page(where: { slug: "page-not-found" }) {
      ...PageFrag
      pageSections: sections {
        ... on Section {
          ...PageSectionFrag
        }
      }
    }
  }
  ${[PageFrag, PageSectionFrag]}
`
const getHomePageIndexQuery = gql`
  query getHomePageIndexQuery() {
    page(where: { slug: "home" }) {
     ...PageFrag
      pageSections: sections {
        __typename
        ... on Section {
         ...PageSectionFrag
        }
      }
    }
    featuredArticles: articles(
      where: { featured: true }, stage: PUBLISHED
      orderBy: sortRank_ASC
    ) {
      ...ArticleFeaturedFrag
    }
    latestArticles: articles(
      where: { featured_not: true }, stage: PUBLISHED
      first: 9
      orderBy: publishedOn_DESC
    ) {
      ...ArticleFrag
    }
    mostPopular: articles(
      where: {popularity_gt: 0  }, stage: PUBLISHED
      first: 3
      orderBy: popularity_DESC
    ) {
      ...ArticleHomePopFrag
    }
  }
  ${[
    PageFrag,
    PageSectionFrag,
    ArticleFeaturedFrag,
    ArticleFrag,
    ArticleHomePopFrag
  ]}
`

const getHomePageArticlesNext = gql`
  query getHomePageIndexQuery() {
    nextArticles: articles(
      where: { featured_not: true }, stage: PUBLISHED
      skip: 9
      orderBy: publishedOn_DESC
    ) {
      ...ArticleFrag
    }
  }
  ${[ArticleFrag]}
`
const getPageSlugs = gql`
  query getPagePathsSlugQuery() {
    pages(where: {pageType_in: [INFO]}, stage: PUBLISHED) {
      slug
      id
    }
  }
`
const getPageBySlugQuery = gql`
  query getPageBySlugQuery($slug: String!) {
    page(where: { slug: $slug }) {
      ...PageFrag
      pageSections: sections {
        ... on Section {
          ...PageSectionFrag
        }
      }
    }
  }
  ${[PageFrag, PageSectionFrag]}
`
const getLandingPageIndexQuery = gql`
  query getLandingIndexQuery() {
    pages(where: {AND: {pageType: LANDING, phase: LIVE}}) {
      pageType,
      pageCategory,
      pageName,
      phase,
      publishedAt,
      slug
      id
    }
  }
`
const getLandingPageSlugs = gql`
query getLandingPageSlugs() {
  pages(where: {pageType_in: [LANDING]}, stage: PUBLISHED) {
    slug
  }
}`

const getLandingPageBySlugQuery = gql`
  query getLandingPageBySlugQuery($slug: String!) {
    page(where: { slug: $slug }) {
      ...PageFrag
      pageSections: sections {
        ... on Section {
          ...PageSectionFrag
        }
      }
    }
  }
  ${[PageFrag, PageSectionFrag]}
`
const getInternalPageBySlugQuery = gql`
  query getInternalPageBySlugQuery($slug: String!) {
    page: page(where: { slug: $slug }) {
      id
      footerSections {
        ... on Section {
          ...FooterSectionsFrag
        }
      }
      footer {
        id
        footerName
        footerSlug
        copyrightNotice
        footerLogo {
          assetType
          fileName
          handle
          height
          id
          imageName
          mimeType
          size
          url
          width
        }
        id
        footerName
        footerSlug
        copyrightNotice
        footerTagLine
        navLinks {
          id
          isExternal
          navLinkLabel
          navLinkName
          navLinkSize
          navLinkSlug
          navLinkUrl
          navLinkVariant
        }
      }
    }
  }
  ${[FooterSectionsFrag]}
`

const getArticlesIndexQuery = gql`
  query getArticlesIndexQuery() {
    page(where: { slug: "articles" }) {
     ...PageFrag
    }
    articles(
      stage: PUBLISHED
      orderBy:  sortRank_ASC
    ) {
      ...ArticlesIndexFrag
    }
  }
  ${[PageFrag, ArticlesIndexFrag]}
`

const getArticleSlugs = gql`
query getArticleSlugs() {
  articles(stage: PUBLISHED ) {
    slug
  }
}
`
const getArticleBySlugQuery = gql`
  query getArticleBySlugQuery($slug: String!) {
    page(where: { slug: "articles" }) {
      isPublic
      ...PageFrag
    }
    article: article(where: { slug: $slug }) {
      ...ArticleFrag
      articleSections: sections {
        ... on Section {
          ...ArticleSectionFrag
        }
      }
    }
  }
  ${[PageFrag, ArticleFrag, ArticleSectionFrag]}
`

const getPreviewArticleBySlug = gql`
  query getPreviewArticleBySlug($slug: String!) {
    article: article(where: { slug: $slug }) {
      ...ArticleFrag
      articleSections: sections {
        ... on Section {
          ...ArticleSectionFrag
        }
      }
    }
  }
  ${[ArticleFrag, ArticleSectionFrag]}
`

const getActiveSpecials = gql`
query getActiveSpecials($today: DateTime!) {
  specialProducts(where: {dateDeactivated_gte: $today}, STAGE: PUBLISHED){
    id
    dateActivated
    dateDeactivated
    slug
    specialLimit
    stage
    totalClaimed
    specialType
  }
`

const getSpecialProductById = gql`
  query getSpecialProductById($id: ID!) {
    specialProduct(where: { id: $id }) {
      dateActivated
      dateDeactivated
      id
      specialLimit
      specialMod
      specialProductCopy
      specialProductName
      slug
      specialProductRestrictions
      specialType
      tooltip {
        id
        tooltipAction
        tooltipCopy
        tooltipName
        tooltipSlug
        tooltipLayerStyle
      }
      totalAvailable
      totalClaimed
      totalIssued
      claimedBy
      product {
        productName
        price
        productId
        productSlug
      }
    }
  }
`
export {
  getActiveSpecials,
  get404PageQuery,
  getHomePageIndexQuery,
  getPageSlugs,
  getPageBySlugQuery,
  getLandingPageIndexQuery,
  getLandingPageSlugs,
  getLandingPageBySlugQuery,
  getArticlesIndexQuery,
  getArticleSlugs,
  getArticleBySlugQuery,
  getInternalPageBySlugQuery,
  getPreviewArticleBySlug,
  getSpecialProductById
}
