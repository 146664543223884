import {
  Box,
  List,
  ListItem,
  Flex,
  Link,
  Spacer,
  Stack,
  Text,
  Button,
  Heading,
  Divider,
  VStack,
  Center,
  Grid,
  HStack,
  GridItem,
  AspectRatio,
  Tag
} from '@chakra-ui/react'
import { useBreakpointValue, useMediaQuery } from '@chakra-ui/react'
import { formatCurrency } from '@/utils/_formatCurrency'
import Image from 'next/image'
import { SpecialProductCard } from '@/components'
import { MDXRemote } from 'next-mdx-remote'
import _ from 'lodash'
import { repeat } from 'lodash'
import { useGetSpecialProducts } from '@/hooks'
const Body2 = (props) => {
  return <Box textStyle="body2" {...props} />
}

const components = {
  p: Body2
}
const cardcolhead = {
  ol: (props) => <Box m="0" p="0" {...props} />,
  li: (props) => <ListItem {...props} />
}
const InfoCardStack = ({
  headline,
  subheadline,
  copy,
  note,
  colSpan,
  cardMedia,
  cardVariant,
  cardTheme,
  cardSize,
  cardButton,
  gridGap,
  flip
}) => {
  return (
    <Stack
      w="container.lg"
      bg="white"
      direction={{ base: 'column', md: `${flip ? 'row' : 'row-reverse'}` }}
      spacing={gridGap}
      justifyContent="space-between"
    >
      <Box position="relative" width="394px" height="380px">
        {cardMedia && (
          <Image
            src={cardMedia.url}
            alt={cardMedia.title}
            title={cardMedia.title}
            layout="fill"
            objectFit="cover"
          />
        )}
      </Box>
      <VStack spacing="2" textAlign="left">
        {headline && (
          <Box textStyle="head5" w="100%" paddingStart="5">
            <MDXRemote {...headline.mdx} />
          </Box>
        )}
        {subheadline && (
          <Box textStyle="head4">
            <MDXRemote {...subheadline.mdx} />
          </Box>
        )}
        {copy && <MDXRemote {...copy.mdx} components={components} />}
      </VStack>
    </Stack>
  )
}
export default InfoCardStack
