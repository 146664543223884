import axios from 'axios'
import { useEffect } from 'react'
import { Flex, Box, Input, VStack, HStack, Select } from '@chakra-ui/react'
import { SubmitBtn, FieldInput } from '@/forms'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useFireAuth } from '@/lib/fireAuth'
import { requestFundingSource } from '../../lib/teamAccounts'
import APPVARS from '@/constants/appvars'

const dwollaAddBankSchema = yup.object().shape({
  routingNumber: yup
    .number()
    .test(
      'routingNumber',
      'Please enter a valid 9-digit routing number ',
      (routingNumber) => String(routingNumber).length === 9
    )
    .typeError('Please enter a valid 9-digit routing number ')
    .required(),
  accountNumber: yup
    .number()
    .typeError('Please enter a valid account number')
    .required(),
  type: yup.string().required('Please select an account type').nullable(),
  bankName: yup.string().required('Please enter the name of your bank')
})

export default function AddBank({ dwollaCustomerId, setAccountConnected }) {
  const { member } = useFireAuth()
  // Loading the dwolla.js script
  useEffect(() => {
    // console.log('dwollaCustomerId', dwollaCustomerId)
    const script = document.createElement('script')
    script.src = 'https://cdn.dwolla.com/1/dwolla.js'
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const {
    register,
    formState: { errors, isSubmitting, isValid, isDirty },
    handleSubmit,
    control
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(dwollaAddBankSchema)
  })

  // Async function that uses axios to call the funding-sources-token endpoint
  async function asyncFunc() {
    const response = await axios.get(
      `/api/funding-sources-token/${dwollaCustomerId}`
    )
    return response.data.token
  }

  function callback(err, res) {
    // eslint-disable-next-line no-unused-vars
    const logValue = {
      error: err,
      response: res
    }
  }

  // Function that handles calling the dwolla.js function for adding a bank
  async function addBank() {
    const token = await asyncFunc()
    dwolla.configure('sandbox')
    const bankInfo = {
      accountNumber: accountNumber.value,
      type: type.value,
      name: bankName.value,
      routingNumber: routingNumber.value
    }
    console.log('bank info', bankInfo)

    dwolla.fundingSources.create(token, bankInfo, callback)
    setAccountConnected(true)
    return false
  }

  function bankConnect() {
    addBank()
    setAccountConnected(true)
  }

  return (
    <Box
      as="form"
      direction="column"
      w="80%"
      spacing={APPVARS.VRT.LG}
      onSubmit={handleSubmit(addBank)}
    >
      <HStack w="100%" spacing={APPVARS.HRZ.LG}>
        <FieldInput
          name="accountNumber"
          label="Account Number"
          error={errors.accountNumber}
        >
          <Input
            name="accountNumber"
            id="accountNumber"
            variant="outline"
            placeholder="Account Number"
            {...register('accountNumber')}
          />
        </FieldInput>
        <FieldInput
          name="routingNumber"
          label="Routing Number"
          error={errors.routingNumber}
        >
          <Input
            variant="outline"
            name="routingNumber"
            id="routingNumber"
            placeholder="222222226"
            defaultValue="222222226"
            {...register('routingNumber')}
          />
        </FieldInput>
      </HStack>
      <HStack w="100%" spacing={APPVARS.HRZ.LG}>
        <FieldInput name="type" label="Account Type" error={errors.type}>
          <Select
            placeholder="Account Type"
            id="type"
            variant="outline"
            {...register('type')}
          >
            <option value="checking">Checking</option>
            <option value="savings">Savings</option>
          </Select>
        </FieldInput>
        <FieldInput name="bankName" label="Bank Name" error={errors.bankName}>
          <Input
            name="bankName"
            id="bankName"
            variant="outline"
            placeholder="Bank Name"
            {...register('bankName')}
          />
        </FieldInput>
      </HStack>
      <Box align="center" mt="32px">
        <SubmitBtn
          type="submit"
          label="Complete"
          id="signin-submit"
          isDisabled={!isDirty || !isValid}
          isWorking={isSubmitting}
          variant="solid"
        />
      </Box>
    </Box>
  )
}
