import { Box, Grid, VStack, useBreakpointValue } from '@chakra-ui/react'

import { HomeCard } from '@/components'

const LatestWrapper = ({ section, articles }) => {
  const ar = useBreakpointValue({ base: 16 / 9, sm: 16 / 9, md: 1, lg: 1 })
  ////console.log('section', section)
  return (
    <Box
      pt="60px"
      pb="80px"
      layerStyle={section?.layerStyle ? section.layerStyle : 'default'}
      className="HomeSectionLatest"
    >
      <VStack
        px={{ base: '20px', lg: 0 }}
        maxW="964px"
        mx={{ base: 0, lg: 'auto' }}
        spacing="60px"
      >
        <Box textStyle="sectionHead1">MORE LATEST ARTICLES</Box>

        <Grid
          maxW="964px"
          width="100%"
          templateColumns={{
            base: '1fr',
            md: '200px 200px 200px',
            lg: '268px 268px 268px'
          }}
          templateRows={{ base: 'auto', md: '1fr' }}
          templateAreas={{
            base: `'main'`,
            md: `'main main main'`
          }}
          justifyContent="space-between"
          rowGap="40px"
        >
          {articles.map((article) => (
            <HomeCard
              gridArea="main"
              key={article.id}
              type="main"
              ar={ar}
              article={article}
              headerStyle="head5"
            />
          ))}
        </Grid>
      </VStack>
    </Box>
  )
}

export default LatestWrapper
