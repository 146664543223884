import { rgba } from 'polished'

export const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#3B3F46',
  black2: rgba(59, 63, 70, 1),
  white: '#FFFFFF',
  bluebrand: {
    900: '#211241',
    800: '#282965',
    700: '#1D4797',
    600: '#2d4ad4',
    500: '#3353df',
    400: '#525ffe',
    300: '#757EFF',
    200: '#a1abf0',
    100: '#c7ccf6',
    50: '#e9ebfc'
  },
  purple: rgba(123, 97, 255, 1),
  cashback: {
    500: rgba(117, 126, 255, 1)
  },
  success: {
    500: rgba(199, 228, 233, 1), //#C7E4E9
    200: rgba(199, 228, 233, 0.3)
  },
  // color="elevate_special.500"
  elevate_special: {
    500: rgba(35, 181, 137, 1), //#23B589
    400: rgba(35, 181, 137, 0.5)
  },
  everyday_deal: {
    500: rgba(35, 181, 137, 1), //#23B589
    400: rgba(35, 181, 137, 0.5)
  },
  office_depot: {
    500: rgba(35, 181, 137, 1),
    400: rgba(35, 181, 137, 0.5)
  },
  elevate: {
    800: rgba(40, 41, 101, 1), //#282965
    500: rgba(29, 71, 151, 1), //#1D4797 #23B589
    400: rgba(117, 126, 255, 1), //#757EFF
    300: rgba(171, 173, 255, 1) //#ABADFF
  },
  brand: {
    800: rgba(40, 41, 101, 1), //#282965
    primary: rgba(29, 71, 151, 1), //#1D4797
    secondary: rgba(117, 126, 255, 1), //#757EFF
    300: rgba(171, 173, 255, 1) //#ABADFF
  },
  gray: {
    400: rgba(110, 118, 133, 1), //#6E7685
    300: rgba(148, 156, 171, 1), //#949CAB
    250: rgba(196, 200, 211, 1), //#C4C8D3
    200: rgba(238, 241, 244, 1), //#EEF1F4
    100: rgba(250, 250, 250, 1) //#FAFAFA
  },
  grey: {
    400: rgba(110, 118, 133, 1), //same as above
    300: rgba(148, 156, 171, 1),
    250: rgba(196, 200, 211, 1),
    200: rgba(238, 241, 244, 1),
    100: rgba(250, 250, 250, 1)
  },
  blue: {
    600: rgba(0, 42, 172, 1), //#002AAC
    500: rgba(51, 82, 223, 1), //#3352DF
    400: rgba(117, 126, 255, 1), //#757EFF
    300: rgba(171, 173, 255, 1) //#ABADFF
  },
  yellow: {
    500: rgba(250, 217, 74, 1) //#FAD94A;
  },
  red: {
    300: rgba(255, 135, 119, 1) //#FF8777
  },
  page: {
    back: rgba(255, 135, 119, 1), //#FF8777
    500: rgba(51, 82, 223, 1), //#3352DF
    400: rgba(117, 126, 255, 1), //#757EFF
    300: rgba(171, 173, 255, 1) //#ABADFF
  },
  tabs: {
    600: rgba(117, 126, 255, 1), //#757EFF
    500: rgba(51, 82, 223, 1), //#3352DF
    400: rgba(117, 126, 255, 1), //#757EFF
    300: rgba(171, 173, 255, 1), //#ABADFF
    200: rgba(199, 228, 233, 1) //#C7E4E9;
  }
}
export default colors
