import React from 'react'
import { AspectRatio, Flex, Box } from '@chakra-ui/react'
import Image from 'next/image'
const FeaturedProductImage = ({
  url,
  title,
  fileName,
  colSpan,
  width,
  height
}) => {
  return (
    <AspectRatio ratio={1} alignSelf="flex-start">
      {url && (
        <Image
          src={url}
          alt={fileName ? fileName : title}
          title={title}
          layout="fill"
          objectFit="contain"
        />
      )}
    </AspectRatio>
  )
}

export default FeaturedProductImage
