import { Link as ChakraLink } from '@chakra-ui/react'
import NextLink from 'next/link'
import React from 'react'

const ButtonLink = ({ to, children, ...props }) => {
  return (
    <NextLink href={to} passHref>
      <ChakraLink {...props}>{children}</ChakraLink>
    </NextLink>
  )
}

export default ButtonLink
