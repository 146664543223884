import React from 'react'
import _ from 'lodash'
import { Grid } from '@chakra-ui/react'
import { Stepper } from '@/components'

const OnboardNav = () => {
  //const [toggle, setToggle] = React.useState(true)
  //const [steps] = useState([...onboardingNewMemberSteps])
  return (
    <Grid w="100%" templateColumns="1fr">
      <Stepper mt={12} />
    </Grid>
  )
}

export default OnboardNav
