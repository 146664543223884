import * as yup from 'yup'

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const locationRegExp = /^[0-9]+$/

export const signupSchema = yup.object().shape({
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().required('Please enter your last name'),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, 'Please enter a valid phone number')
    .min(12, 'Please enter a valid phone number')
    .max(12, 'Please enter a valid phone number')
    .required('Please enter a valid phone number'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address'),
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,}$/,
      'Must include 12 characters with at least one uppercase letter, one lowercase letter, one number, and one symbol'
    ),
  location: yup
    .string()
    .matches(locationRegExp, 'Please enter a valid ZIP Code')
    .min(5, 'Please enter a valid ZIP Code')
    .max(5, 'Please enter a valid ZIP Code')
    .required('Please enter a valid ZIP Code'),
  terms: yup
    .bool()
    .oneOf([true], 'Please agree to Terms and Conditions and Privacy Policy')
})
export const newPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,}$/,
      'Must Contain 12 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    )
})

export const activateSignInSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup
    .string()
    .min(8, 'Enter valid password')
    .required('Enter valid password'),
  terms: yup.boolean().required()
})

export const signInSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup
    .string()
    .min(8, 'Enter valid password')
    .required('Enter valid password')
})
