import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  GridItem,
  Divider,
  Button,
  VStack,
  SimpleGrid,
  Switch,
  Text,
  useBreakpointValue
} from '@chakra-ui/react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import MODALS from '@/constants/modals'
import VENDORS from '@/constants/vendors'
import { useFireAuth } from '@/lib/fireAuth'
import { PanelForm } from '@/forms'
import _ from 'lodash'
import { VendorSwitch } from '@/components'
import { LogoODMax } from '@/logos'

const TeamAccountVendors = () => {
  const { team } = useFireAuth()
  const isMobile = useBreakpointValue({ base: true, md: false })
  const teamAccountId = _.get(team, 'teamAccountId')
  const vendorAccounts = _.get(team, 'vendorAccounts')

  const vendorsArray = _.keys(vendorAccounts).map((key) => {
    const vendor = vendorAccounts[key]
    const obj = { vendorCode: key, ...vendor }
    return obj
  })

  const openVendorModal = ({ teamAccountId, account }) => {
    //console.log('account', account)
    NiceModal.show(`${MODALS.VENDOR_ACCOUNT_MODAL}`, {
      modalSize: 'container.sm',
      teamAccountId,
      account
    })
    // if (account.status === 'ready') {
    //   connectModal.show({
    //     modalSize: 'container.lg',
    //     account
    //   })
    // } else {
    //   disconnectModal.show({
    //     modalSize: 'container.lg',
    //     account
    //   })
    // }
  }
  //console.log('vendorAccounts', vendorAccounts)
  return (
    <PanelForm title="Cash Back Connections">
      <Grid
        h="24px"
        justifyContent="center"
        templateColumns={{ base: '2fr  1fr', md: '1fr 150px 1fr' }}
        templateRows={{ base: '1fr', md: '1fr' }}
        templateAreas={{
          base: `'partner status'`,
          md: `'partner  account status'`
        }}
      >
        <GridItem area="partner">
          <Box textStyle="subtitle2">
            {isMobile ? 'Partners/Account Number' : 'Partners'}
          </Box>
        </GridItem>
        {!isMobile && (
          <GridItem area="account">
            <Box textStyle="subtitle2">Account Number</Box>
          </GridItem>
        )}
        <GridItem area="status">
          <Box px="3" textAlign="right" textStyle="subtitle2">
            Status
          </Box>
        </GridItem>
      </Grid>
      <Divider w="100%" marginBottom="2" marginTop="2" />

      <VStack maxH="100%" spacing="20px">
        {vendorsArray &&
          vendorsArray.map((account) => (
            <Grid
              key={account.vendorCode}
              h="34px"
              w="100%"
              justifyItems="start"
              alignItems="center"
              justifyContent="center"
              templateColumns={{
                base: '2fr  1fr',
                md: '1fr 150px 1fr'
              }}
              templateRows={{ base: '1fr', md: '1fr' }}
              templateAreas={{
                base: `'partner status'`,
                md: `'partner account status'`
              }}
              rowGap="20px"
            >
              <GridItem
                area="partner"
                align="center"
                textStyle="body2"
                alignSelf="top"
                justifySelf="start"
              >
                <LogoODMax />
                {isMobile && (
                  <Box pt="3" textAlign="left">
                    {account.vendorAccountId}
                  </Box>
                )}
              </GridItem>
              {!isMobile && (
                <GridItem area="account" textStyle="body2">
                  <Box pt="3" textAlign="left">
                    {account.vendorAccountId}
                  </Box>
                </GridItem>
              )}

              <GridItem placeSelf="center end" area="status" textStyle="body2">
                <VendorSwitch
                  status={account.status}
                  onChangeAction={() =>
                    openVendorModal({ teamAccountId, account })
                  }
                />
              </GridItem>
            </Grid>
          ))}
      </VStack>
    </PanelForm>
  )
}

export default TeamAccountVendors
