import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Text,
  HStack,
  Button
} from '@chakra-ui/react'
import NiceModal, {
  useModal,
  show,
  create,
  remove,
  register
} from '@ebay/nice-modal-react'

export default NiceModal.create(
  ({ name, variant, hasExit, onAction, onCancel }) => {
    const modal = useModal()

    return (
      <Modal
        variant={variant}
        isOpen={() => modal.show()}
        onClose={() => modal.remove()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton onClick={() => modal.remove()} />
          <ModalBody>
            <Box>
              <Text textStyle="head3">Disconnect Account?</Text>
              <Text>
                This will end future cash back and savings with this vendor.
              </Text>
              <HStack>
                <Button variant="outline" onClick={() => modal.remove()}>
                  {' '}
                  Cancel{' '}
                </Button>
                <Button variant="solid"> Yes, Disconnect</Button>
              </HStack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
)
