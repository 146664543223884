import _ from 'lodash'
import {
  Box,
  Button as ChakraButton,
  Link as ChakraLink
} from '@chakra-ui/react'
import NextLink from 'next/link'

function NavButton({
  href,
  label,
  id,
  slug,
  variant,
  size,
  isExternal,
  onAction
}) {
  //if (!href || !label) return null

  const vart = variant ? _.toLower(variant) : 'default'
  //console.log('NavBtn', variant)
  if (isExternal) {
    return (
      <Box w="100%" textAlign="left" textStyle="btn-text">
        <ChakraLink size={size} isExternal href={href} variant={vart}>
          {label}
        </ChakraLink>
      </Box>
    )
  }
  //console.log('vart', vart)
  if (href.includes('#')) {
    return (
      <ChakraButton size={size} variant={vart}>
        {label}
      </ChakraButton>
    )
  }

  if (href.includes('*')) {
    return (
      <ChakraButton size={size} variant={vart} onClick={onAction}>
        {label}
      </ChakraButton>
    )
  }
  return (
    <Box w="100%" textAlign="left" textStyle="btn-text">
      <NextLink href={href} passHref>
        <ChakraLink
          size={size}
          className={vart}
          as="a"
          textStyle="btn-text"
          variant={vart}
        >
          {label}
        </ChakraLink>
      </NextLink>
    </Box>
  )
}

export default NavButton
