import { Box, ListItem, VStack } from '@chakra-ui/react'

import { MDXRemote } from 'next-mdx-remote'

const Body2 = (props) => {
  return <Box textStyle="body2" {...props} />
}

const components = {
  p: Body2
}
const copycomp = {
  p: (props) => <Box textStyle="body2" w="100%" {...props} />,
  ol: (props) => <Box m="0" p="0" {...props} />,
  li: (props) => <ListItem marginInlineStart={40} {...props} />
}
const headcomp = {
  p: (props) => <Box textStyle="head5" w="100%" {...props} />
}
const subcomp = {
  p: (props) => <Box textStyle="head6" w="100%" {...props} />
}
const cardcolhead = {
  ol: (props) => <Box m="0" p="0" {...props} />,
  li: (props) => <ListItem {...props} />
}
const InfoCardColumn = ({
  headline,
  subheadline,
  copy,
  note,
  colSpan,
  cardMedia,
  cardCloudMedia,
  cardVariant,
  cardTheme,
  cardSize,
  cardButton,
  cols
}) => {
  //console.log(`cardTheme`, cardTheme)
  return (
    <VStack
      spacing={{ base: '1', md: '4' }}
      flexGrow="1"
      textAlign={{ base: 'left', md: 'center' }}
    >
      {headline && <MDXRemote {...headline.mdx} components={headcomp} />}
      {subheadline && <MDXRemote {...subheadline.mdx} components={subcomp} />}
      {copy && <MDXRemote w="100%" {...copy.mdx} components={copycomp} />}
    </VStack>
  )
}
export default InfoCardColumn
