import { Box, SimpleGrid } from '@chakra-ui/react'

import * as Columns from '@/columns'
import { RichText } from '@graphcms/rich-text-react-renderer'
import { MDXRemote } from 'next-mdx-remote'
import APPVARS from '@/constants/appvars'

const mdText = {
  h3: (props) => (
    <Box textStyle="head3" w="100%" textAlign="center" {...props} />
  ),
  p: (props) => (
    <Box mt="5" textStyle="body2" w="100%" textAlign="center" {...props} />
  )
}

function createMarkup(someHtml) {
  return { __html: someHtml }
}

export default function LogosBlockResponsive({
  columnComponent,
  columns,
  gridTag,
  gridName,
  gridGap,
  blockWidth = 'lg',
  cols = 1,
  spacing = '28',
  gridTextAlign,
  gridRichText,
  gridMdText,
  gridSpacing = 'md'
}) {
  //const resCols = '1fr' //useBreakpointValue({ base: 2, md: 4 })
  // const maxBlockWidth = APPVARS.MAX_BLOCK_WIDTH
  return (
    <Box
      py="20"
      className="LogosBlockResponsive"
      overflow="hidden"
      width="100%"
    >
      {gridRichText && (
        <Box pb="20" textAlign={gridTextAlign}>
          <RichText
            content={gridRichText.json}
            renderers={{
              h3: ({ children }) => <Box textStyle="head3">{children}</Box>,
              bold: ({ children }) => <strong>{children}</strong>
            }}
          />
        </Box>
      )}
      {gridMdText && (
        <Box>
          <MDXRemote {...gridMdText.mdx} components={mdText} />
        </Box>
      )}
      <SimpleGrid
        className="LOGOS_BLOCk"
        gap="4"
        columns={cols}
        // templateColumns={{
        //   base: `repeat(${resCols}, minmax('100px','auto'))`,
        //   sm: `repeat(${resCols}, auto)`,
        //   md: `repeat(${resCols}, minmax('100px','260px'))`
        // }}
        templateRows="54px"
      >
        {columns.map((column) => {
          const Component =
            Columns[columnComponent] || Columns[column.__typename]

          if (!Component) return null

          return <Component key={column.id} cols={cols} {...column} />
        })}
      </SimpleGrid>
    </Box>
  )
}
