import { mode, transparentize } from '@chakra-ui/theme-tools'
import { NoUndefinedVariablesRule } from 'graphql'
import { normalizeConfig } from 'next/dist/server/config-shared'

const disabledStyle = {
  bg: 'gray.250',
  color: 'gray.300',
  border: `2px solid`,
  borderColor: 'gray.250',
  _hover: {
    bg: 'gray.250',
    color: 'gray.300',
    border: `2px solid`,
    borderColor: 'gray.250'
  }
}
const button = {
  // style object for base or default style
  baseStyle: {
    margin: '0px',
    fontWeight: 500,
    borderRadius: '4px',
    _focus: {
      boxShadow: 'outline'
    },
    _disabled: {
      color: 'gray.250',
      border: `2px solid`,
      borderColor: `gray.250`,
      cursor: 'not-allowed',
      boxShadow: 'none'
    },
    _hover: {
      _disabled: {
        bg: 'initial'
      }
    }
  },

  sizes: {
    sm: {
      minH: '100%',
      fontSize: 'sm3',
      lineHeight: 'equal'
    },
    md: {
      fontSize: 'md1',
      px: '24px',
      py: '14px'
    },
    lg: {
      fontSize: 'md1',
      px: '6',
      py: '4'
    }
  },

  variants: {
    solid: (props) => ({
      fontWeight: 'normal',
      color: 'white',
      bg: 'elevate.500',
      border: `2px solid`,
      borderColor: `elevate.500`,
      _disabled: {
        bg: 'gray.250',
        color: 'gray.300',
        border: `2px solid`,
        borderColor: 'gray.250',
        _hover: {
          bg: 'gray.250',
          color: 'gray.300',
          border: `2px solid`,
          borderColor: 'gray.250'
        }
      },
      _hover: {
        bg: 'elevate.500',
        border: `2px solid`,
        borderColor: `elevate.500`,
        textDecoration: 'none'
      },
      _active: {
        bg: 'brand.400',
        border: `2px solid`,
        borderColor: 'brand.400'
      }
    }),
    default: (props) => ({
      bg: 'white',
      color: 'brand.primary',
      border: `2px solid`,
      borderColor: 'brand.primary',
      fontWeight: 500,
      textDecoration: 'none',
      _hover: {
        color: 'brand.800',
        borderColor: 'brand.800',
        textDecoration: 'none'
      },
      _active: {
        color: 'brand.secondary',
        borderColor: `brand.secondary`
      },
      _disabled: {
        color: 'gray.250',
        border: `2px solid`,
        borderColor: 'gray.250'
      }
    }),
    outline: (props) => ({
      bg: 'white',
      color: 'elevate.500',
      border: `2px solid`,
      borderColor: `elevate.500`,
      fontWeight: 500,
      textDecoration: 'none',
      _hover: {
        bg: 'yellow.500',
        color: 'elevate.500',
        borderColor: 'elevate.500',
        textDecoration: 'none'
      },
      _active: {
        color: 'elevate.500',
        borderColor: `elevate.500`
      },
      _disabled: {
        color: 'gray.250',
        border: `2px solid`,
        borderColor: 'gray.250'
      }
    }),
    'buy-fprod': (props) => ({
      bg: 'white',
      color: 'brand.primary',
      border: `3px solid`,
      borderColor: `brand.primary`,
      fontFamily: 'heading',

      fontWeight: 800,
      textDecoration: 'none',
      _hover: {
        bg: 'yellow.500',
        color: 'brand.primary',
        borderColor: 'brand.primary',
        textDecoration: 'none'
      },
      _active: {
        color: 'brand.primary',
        borderColor: `brand.primary`
      },
      _disabled: {
        color: 'gray.250',
        border: `2px solid`,
        borderColor: 'gray.250'
      }
    }),
    'buy-prod': (props) => ({
      bg: 'white',
      color: 'brand.primary',
      border: `2px solid`,
      borderColor: `brand.primary`,
      fontFamily: 'body',
      fontWeight: 500,

      textDecoration: 'none',
      _hover: {
        bg: 'yellow.500',
        color: 'brand.800',
        borderColor: 'brand.800',
        textDecoration: 'none'
      },
      _active: {
        color: 'brand.400',
        borderColor: `brand.400`
      },
      _disabled: {
        color: 'gray.250',
        border: `2px solid`,
        borderColor: 'gray.250'
      }
    }),
    claimit: (props) => ({
      fontFamily: 'body',
      fontWeight: 500,
      textDecoration: 'none',
      bg: 'blue.400',
      color: 'white',
      border: `2px solid`,
      borderColor: 'blue.400',
      _hover: {
        bg: 'white',
        color: 'blue.400',
        border: `2px solid`,
        borderColor: 'blue.400',
        textDecoration: 'none'
      },
      _active: {
        bg: 'blue.400',
        color: 'white',
        borderColor: 'blue.400'
      },
      _disabled: {
        bg: 'white',
        color: 'blue.400',
        border: `2px solid`,
        borderColor: 'blue.400',
        textDecoration: 'none'
      }
    }),
    'not-available': (props) => ({
      fontWeight: 500,
      textDecoration: 'none',
      bg: 'grey.250',
      color: 'grey.400',
      border: 'none',
      _disabled: {
        bg: 'grey.250',
        color: 'grey.400',
        border: 'none',
        cursor: 'not-allowed',
        boxShadow: 'none'
      },
      _hover: {
        _disabled: {
          bg: 'grey.250'
        }
      }
    }),
    primary: (props) => ({
      fontWeight: 500,
      color: 'white',
      bg: 'brand.primary',
      borderRadius: '4px',
      padding: '16px 24px',
      textDecoration: 'none',
      _hover: {
        bg: 'brand.800'
      },
      _active: {
        bg: 'brand.400'
      },
      _disabled: {
        bg: 'gray.250',
        color: 'gray.300'
      }
    }),
    secondary: (props) => ({
      fontWeight: 500,
      color: 'white',
      bg: 'brand.secondary',
      border: `2px solid`,
      borderRadius: 4,

      borderColor: `brand.secondary`,
      _hover: {
        color: 'brand.secondary',
        bg: 'white',
        border: `2px solid`,
        borderRadius: 4,
        borderColor: `brand.secondary`
      },
      _active: {
        bg: 'brand.secondary',
        color: 'white',
        border: `2px solid`,
        borderRadius: 4,
        borderColor: `brand.secondary`
      },
      _selected: {
        bg: 'white',
        color: 'brand.secondary',
        border: `2px solid`,
        borderColor: `brand.secondary`,
        textDecoration: 'none'
      },
      _disabled: {
        bg: 'gray.250',
        color: 'gray.400',
        border: `2px solid`,
        borderColor: 'gray.250'
      }
    }),
    card_block_button: (props) => ({
      bg: 'white',
      color: 'brand.primary',
      border: `3px solid`,
      borderColor: `brand.primary`,
      fontFamily: 'heading',
      fontWeight: 700,

      textDecoration: 'none',
      _hover: {
        bg: 'yellow.500',
        color: 'brand.800',
        borderColor: 'brand.800',
        textDecoration: 'none'
      },
      _active: {
        color: 'brand.400',
        borderColor: `brand.400`
      },
      _disabled: {
        color: 'gray.250',
        border: `2px solid`,
        borderColor: 'gray.250'
      }
    }),
    pillbtn: (props) => ({
      bg: 'white',
      color: 'brand.800',
      border: '1px solid',
      fontSize: 'sm2',
      lineHeight: '16px',
      borderRadius: '20px',
      borderColor: 'brand.800',
      fontFamily: 'heading',
      fontWeight: 400,
      textDecoration: 'none',
      _active: {
        bg: 'brand.primary',
        color: 'white'
      },
      _disabled: {
        bg: 'transparent',
        color: 'gray.250',
        border: '1px solid',
        borderColor: 'gray.250'
      }
      // _hover: {
      //   bg: 'transparent',
      //   color: 'gray.400',
      //   borderColor: 'gray.400'
      // },
      // _selected: {
      //   bg: 'brand.primary',
      //   color: 'white',
      //   border: `1px solid`,
      //   borderColor: 'elevate.800',
      //   textDecoration: 'none'
      // },
      // _active: {
      //   bg: 'brand.primary',
      //   color: 'white',
      //   border: `1px solid`,
      //   borderColor: 'elevate.800',
      //   textDecoration: 'none'
      // },
    }),
    ghost: (props) => ({
      color: 'white',
      fontWeight: 'medium',
      textDecoration: 'none',
      border: '2px solid',
      borderColor: 'white',
      backgroundColor: 'transparent',
      _hover: {
        color: `brand.primary`,
        textDecoration: 'none',
        backgroundColor: 'white',
        borderColor: 'white'
      },
      _active: {
        color: `brand.primary`,
        textDecoration: 'none',
        backgroundColor: 'white',
        borderColor: 'white'
      },
      _disabled: {
        color: 'gray.250',
        border: '0px'
      }
    }),
    'link-lite': (props) => ({
      fontFamily: 'body',
      lineHeight: 'short1',
      color: 'white',
      textDecoration: 'underline',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      _hover: {
        textDecoration: 'underline'
      },
      _active: {
        fontWeight: 'normal'
      },
      _disabled: {
        color: 'gray.250',
        border: '0px'
      }
    }),
    link: (props) => ({
      fontFamily: 'body',
      color: `brand.primary`,
      textDecoration: 'none',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      _hover: {
        textDecoration: 'underline'
      },
      _active: {
        fontWeight: 'normal'
      },
      _disabled: {
        color: 'gray.250',
        border: '0px'
      }
    }),
    'form-link': (props) => ({
      color: 'brand.primary',
      fontWeight: 'bold',
      textDecoration: 'underline',
      _hover: {
        borderColor: 'brand.primary'
      },
      _active: {
        fontWeight: 'bold'
      },
      _disabled: {
        color: 'gray.250',
        border: '0px'
      }
    }),
    link_footer: (props) => ({
      color: 'white',
      fontWeight: 'normal',
      textDecoration: 'none',
      textTransform: 'uppercase',
      _hover: {
        textDecoration: 'underline'
      },
      _active: {
        fontWeight: 'semibold'
      },
      _disabled: {
        color: 'gray.250',
        border: '0px'
      }
    }),
    'tabnav-active': (props) => ({
      outline: '2px solid transparent',
      textTransform: 'uppercase',
      textDecoration: 'none',
      fontWeight: 'normal',
      borderRadius: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 2,
      paddingBottom: 2,
      paddingInlineStart: 4,
      paddingInlineEnd: 4,
      marginBottom: '-2px',
      color: 'black',
      textDecoration: 'none',
      borderBottomColor: 'tabs.200',
      borderBottomWidth: '2px',
      borderBottomStyle: 'solid'
    }),
    tabnav: (props) => ({
      outline: '2px solid transparent',
      textTransform: 'uppercase',
      textDecoration: 'none',
      fontWeight: 'normal',
      borderRadius: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 2,
      paddingBottom: 2,
      paddingInlineStart: 4,
      paddingInlineEnd: 4,
      borderBottom: 'none',
      marginBottom: '-2px',
      borderBottomColor: 'inherit',
      _hover: {
        bg: 'tabs.200',
        color: 'black',
        textDecoration: 'none'
      },

      _disabled: {
        color: 'gray.250',
        borderBottomColor: 'transparent',
        borderBottomWidth: '2px',
        borderBottomStyle: 'solid'
      }
    }),
    'simple-icon': (props) => ({
      color: `grey.400`,
      fontWeight: 'medium',
      textDecoration: 'none',
      border: 'none',
      borderColor: 'none',
      backgroundColor: 'transparent',
      _hover: {
        color: `brand.primary`,
        textDecoration: 'none'
      },
      _active: {
        color: `brand.primary`,
        textDecoration: 'none'
      },
      _disabled: {
        color: 'gray.250',
        border: '0px'
      }
    })

    // 4. We can override existing variants
    // outline: (props) => ({
    //   bg: props.colorMode === 'dark' ? 'gray.300' : 'blue.400',
    //   color: props.colorMode === 'dark' ? 'blue.600' : 'white',
    // }),
  },
  // default values for `size` and `variant`
  defaultProps: {
    size: 'md',
    variant: 'outline'
  }
}
export default button
