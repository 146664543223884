import { Flex, Box, Grid } from '@chakra-ui/layout'
import { AppBar, Seo } from '@/components'

import APPVARS from '@/constants/appvars'
export default function PublicLayout({ children, page }) {
  //console.log('appvas', APPVARS)
  return (
    <>
      {page && page?.seo && <Seo {...page.seo} />}
      <Grid
        templateColumns="1fr"
        className="LAYOUT_PUBLIC"
        w="100%"
        minWidth="100vw"
        minH="100vh"
      >
        <AppBar navType="onboarding" />
        <Flex
          w="100%"
          className="LAYOUT_PUBLIC_COL"
          direction="column"
          border="solid 1px blue"
        >
          <Box
            className="NavHold"
            flexGrow="0"
            height={APPVARS.NAV_BAR_HEIGHT}
            alignSelf="flex-start"
          />
          <Flex flexGrow="1" justifyContent="center">
            {children}
          </Flex>
        </Flex>
      </Grid>
    </>
  )
}

export const getLayout = (page) => (
  <PublicLayout {...page.props}>{page}</PublicLayout>
)
