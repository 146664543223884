import React, { useState } from 'react'

import {
  Box,
  Flex,
  Text,
  Input,
  Button,
  InputRightElement,
  VStack
} from '@chakra-ui/react'
import ROUTES from '@/constants/routes'
import { SignInForm, ForgotPasswordForm } from '@/modules'

const SignIn = ({ onDone, onForgotPassSuccess }) => {
  const [forgotPass, setForgotPass] = useState(false)

  const onSwitchScreen = () => {
    setForgotPass(!forgotPass)
  }

  const handleOnDone = () => {
    //console.log('handleOnDone')
    onDone()
    //onForgotPassSuccess()
  }

  const handleForgotPassword = () => {
    //console.log("forgotPasswordonDone")
    onDone()
    onForgotPassSuccess()
  }

  return (
    <Box id="SignIn" w="100%" maxWidth="520px" mx="auto">
      {forgotPass ? (
        <ForgotPasswordForm onFormComplete={handleForgotPassword} />
      ) : (
        <SignInForm
          onFormComplete={handleOnDone}
          switchScreen={onSwitchScreen}
        />
      )}
      {/* <Flex width="100%" justifyContent="center" alignItems="center" my="10">
        <Button variant="form-link" onClick={() => router.push(ROUTES.HOME)}>
          BACK TO HOME
        </Button>
      </Flex> */}
    </Box>
  )
}

export default SignIn
