import _ from 'lodash'
import {
  Box,
  Button as ChakraButton,
  Link as ChakraLink
} from '@chakra-ui/react'
import NextLink from 'next/link'

function ChakraNextLink({ href, children, variant, ...props }) {
  return (
    <NextLink href={href} passHref>
      <ChakraLink variant={variant} size="lg" {...props}>
        {children}
      </ChakraLink>
    </NextLink>
  )
}
function Button({
  href,
  label,
  id,
  slug,
  variant,
  size = 'lg',
  isExternal,
  onAction,
  isDisabled = false
}) {
  //if (!href || !label) return null
  //console.log('HeroButton', variant)
  const vart = variant ? _.toLower(variant) : 'default'
  //console.log('vart', vart)
  if (onAction) {
    return (
      <ChakraButton id={slug} variant={vart} onClick={onAction}>
        <Box textStyle="btn-text1">{label}</Box>
      </ChakraButton>
    )
  }
  if (!href) {
    return (
      <ChakraButton id={slug} variant={vart} href={href} disabled={isDisabled}>
        <Box textStyle="btn-text1">{label}</Box>
      </ChakraButton>
    )
  }
  if (href.includes('#')) {
    return (
      <ChakraButton id={slug} variant={vart} href={href}>
        <Box textStyle="btn-text1">{label}</Box>
      </ChakraButton>
    )
  }

  if (href.includes('http')) {
    return (
      <ChakraNextLink
        id={slug}
        variant={vart}
        href={href}
        size={size}
        isExternal
      >
        {label}
      </ChakraNextLink>
    )
  }
  if (href.includes('*')) {
    return (
      <ChakraButton
        id={slug}
        textStyle="btn-text"
        variant="outline"
        onClick={onAction}
      >
        {label}
      </ChakraButton>
    )
  }
  return (
    <NextLink href={href} passHref>
      <ChakraButton
        className={vart}
        id={slug}
        as="a"
        variant={vart}
        size={size}
        textStyle="btn-text"
      >
        {label}
      </ChakraButton>
    </NextLink>
  )
}

export default Button
