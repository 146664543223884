import * as React from "react"

function YellowTick(props) {
  return (
    <svg
      viewBox="0 3 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.908 7.51a1.029 1.029 0 01-.018 1.455l-7.74 7.543c-.4.39-1.036.39-1.436 0l-4.603-4.486a1.029 1.029 0 011.436-1.474l3.885 3.787 7.022-6.843a1.029 1.029 0 011.454.019z"
        fill="#FAD94A"
      />
    </svg>
  )
}

export default YellowTick

