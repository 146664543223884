import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { formatCurrency } from '@/utils/_formatCurrency'
import { IoRocket } from 'react-icons/io5'
import { TiArrowUpOutline } from 'react-icons/ti'

import { useTable } from 'react-table'

import {
  Box,
  Icon,
  Circle,
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react'
const SpecialIcon = () => (
  <Circle w="18px" h="18px" bg="elevate_special.500" color="white">
    <Icon as={TiArrowUpOutline} w="11px" h="11px" />
  </Circle>
)
const CashbackIcon = ({ w, h }) => (
  <Circle w="18px" h="18px" bg="cashback.500" color="white">
    <Icon as={IoRocket} w="11px" h="11px" />
  </Circle>
)

function AccountActivityTable({ tableRows }) {
  const columns = useMemo(
    () => [
      {
        Header: ({}) => {
          return (
            <Box pr="2" justify="center" textAlign="left">
              Date
            </Box>
          )
        },
        accessor: 'date',
        id: 'date',
        width: 100,
        Cell: ({ cell: { value } }) => {
          return (
            <Box pr="2" justify="center" textAlign="left">
              {value}
            </Box>
          )
        }
      },
      {
        Header: '',
        id: 'specialType',
        accessor: 'specialType',
        width: 24,
        Cell: ({ cell: { value } }) => {
          return (
            <Box justify="center" align="center">
              {value === 'CASH_BACK' ? (
                <CashbackIcon />
              ) : value === 'ELEVATE_SPECIAL' ? (
                <SpecialIcon />
              ) : null}
            </Box>
          )
        }
      },
      {
        accessor: 'description',
        id: 'description',
        width: 380,
        Header: ({}) => {
          return (
            <Box pr="2" justify="center" textAlign="left">
              Description
            </Box>
          )
        },
        Cell: ({ cell: { value } }) => {
          return (
            <Box px="1" className="cell" justify="flex-start" textAlign="left">
              {value}
            </Box>
          )
        }
      },
      {
        Header: 'Total',
        accessor: 'payTotal',
        id: 'payTotal',
        width: 80,
        Cell: ({ cell: { value } }) => {
          return (
            <Box px="2" textAlign="right">
              {formatCurrency(value)}
            </Box>
          )
        }
      },
      {
        Header: 'Savings',
        accessor: 'savings',
        id: 'savings',
        width: 80,
        Cell: ({ cell: { value } }) => {
          return (
            <Box px="2" textAlign="right">
              {formatCurrency(value)}
            </Box>
          )
        }
      },
      {
        Header: 'Special',
        accessor: 'specialSavings',
        id: 'specialSavings',
        width: 80,
        Cell: ({ cell: { value } }) => {
          return (
            <Box px="2" textAlign="right">
              {formatCurrency(value)}
            </Box>
          )
        }
      },
      {
        Header: 'Cash back',
        accessor: 'cashBack',
        id: 'cashBack',
        width: 80,
        Cell: ({ cell: { value } }) => {
          return (
            <Box px="2" textAlign="right">
              {formatCurrency(value)}
            </Box>
          )
        }
      }
    ],
    []
  )
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableRows })

  // Render the UI for your table
  return (
    <Box width="100%">
      <ChakraTable size="sm" {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  px="1"
                  textAlign="right"
                  key={column.id}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <Tr key={row.id} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Td
                      key={cell.id}
                      px="0"
                      textAlign="right"
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </ChakraTable>
    </Box>
  )
}
export default AccountActivityTable
AccountActivityTable.propTypes = {
  headline: PropTypes.string
}
AccountActivityTable.defaultProps = {
  headline: 'Component',
  tableRows: [
    {
      vendorCode: 'OD',
      specialType: null,
      date: '03/03/2022',
      description: '- Bar,Oatml,Raisin,Walnt,2.4z',
      listTotal: 31.99,
      payTotal: 34.89,
      savings: -2.900000000000002,
      specialSavings: 0,
      cashBack: 0
    },
    {
      vendorCode: 'OD',
      specialType: null,
      date: '03/09/2022',
      description: '- BAR,CLIF,CHOC CHIP,ORGANIC',
      listTotal: 30.99,
      payTotal: 27.29,
      savings: 3.6999999999999993,
      specialSavings: 0,
      cashBack: 0
    },
    {
      vendorCode: 'OD',
      specialType: null,
      date: '03/09/2022',
      description: '- BAR,PEANUT BUTTER,CRUNCHY',
      listTotal: 30.99,
      payTotal: 30.19,
      savings: 0.7999999999999972,
      specialSavings: 0,
      cashBack: 0
    },
    {
      vendorCode: 'OD',
      specialType: null,
      date: '03/16/2022',
      description: '- SKINNY,POP,100 CALORIE,20CT',
      listTotal: 61.31,
      payTotal: 32.35,
      savings: 28.96,
      specialSavings: 0,
      cashBack: 0
    },
    {
      vendorCode: 'OD',
      specialType: null,
      date: '03/16/2022',
      description: '- WATER,LACROIX,LEMON,2/12PKS',
      listTotal: 23.7,
      payTotal: 16.99,
      savings: 6.710000000000001,
      specialSavings: 0,
      cashBack: 0
    },
    {
      vendorCode: 'OD',
      specialType: null,
      date: '03/16/2022',
      description: '- CHOCOLATE,MMS,FUN SZ,85.23OZ',
      listTotal: 81.32,
      payTotal: 41.44,
      savings: 39.879999999999995,
      specialSavings: 0,
      cashBack: 0
    },
    {
      vendorCode: 'OD',
      specialType: null,
      date: '03/16/2022',
      description: '- WATER,LACROIX,LIME,2/12PKS',
      listTotal: 23.7,
      payTotal: 16.99,
      savings: 6.710000000000001,
      specialSavings: 0,
      cashBack: 0
    },
    {
      vendorCode: 'OD',
      specialType: null,
      date: '03/16/2022',
      description: '- HK ANDERSON PRETZEL 44OZ',
      listTotal: 12.99,
      payTotal: 13.19,
      savings: -0.1999999999999993,
      specialSavings: 0,
      cashBack: 0
    },
    {
      vendorCode: 'OD',
      specialType: null,
      date: '03/16/2022',
      description: '- Bulk Item Convenience Fee',
      listTotal: 14.97,
      payTotal: 14.97,
      savings: 0,
      specialSavings: 0,
      cashBack: 0
    }
  ]
}
