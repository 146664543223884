import { Box, ListItem, VStack, GridItem, AspectRatio } from '@chakra-ui/react'

import Image from 'next/image'

import { MDXRemote } from 'next-mdx-remote'

const Body2 = (props) => {
  return <Box textStyle="body2" {...props} />
}

const components = {
  p: Body2
}
const copycomp = {
  p: (props) => <Box textStyle="body2" w="100%" {...props} />,
  ol: (props) => <Box m="0" p="0" {...props} />,
  li: (props) => <ListItem marginInlineStart={40} {...props} />
}
const headcomp = {
  p: (props) => <Box textStyle="head5" w="100%" {...props} />
}
const subcomp = {
  p: (props) => <Box textStyle="head6" w="100%" {...props} />
}
const cardcolhead = {
  ol: (props) => <Box m="0" p="0" {...props} />,
  li: (props) => <ListItem {...props} />
}
const InfoCardColumn = ({
  headline,
  subheadline,
  copy,
  note,
  colSpan,
  cardMedia,
  cardCloudMedia,
  cardVariant,
  cardTheme,
  cardSize,
  cardButton,
  cols
}) => {
  //console.log(`cardTheme`, cardTheme)
  return (
    <GridItem
      colSpan={1}
      display="flex"
      flexDirection="column"
      justifyContent={{ base: 'center' }}
      maxWidth={{ base: '280px', md: '300px' }}
    >
      {cardCloudMedia && (
        <AspectRatio
          className={cardTheme}
          position="relative"
          maxHeight="100px"
        >
          <Image
            src={cardCloudMedia.url}
            alt="cloud card media"
            layout="fill"
            objectFit="contain"
          />
        </AspectRatio>
      )}
      {cardMedia && (
        <AspectRatio flex="0 0 100px" position="relative" maxHeight="100px">
          <Image
            src={cardMedia.url}
            alt={cardMedia.title}
            title={cardMedia.title}
            layout="fill"
            objectFit="contain"
          />
        </AspectRatio>
      )}
      <VStack
        mt={{ base: '1', md: '4' }}
        spacing={{ base: '1', md: '4' }}
        flexGrow="1"
        textAlign="center"
        px="5"
      >
        {headline && <MDXRemote {...headline.mdx} components={headcomp} />}
        {subheadline && <MDXRemote {...subheadline.mdx} components={subcomp} />}
        {copy && <MDXRemote w="100%" {...copy.mdx} components={copycomp} />}
      </VStack>
    </GridItem>
  )
}
export default InfoCardColumn

/**
 *  <Box position="relative" width="auto" height="500px">
        {cardMedia && (
          <Image
            src={cardMedia.url}
            alt={cardMedia.title}
            title={cardMedia.title}
            layout="fill"
            objectFit="contain"
          />
        )}
        {cardCloudMedia && (
          <Image
            src={cardCloudMedia.url}
            alt="cloud card media"
            layout="fill"
            objectFit="contain"
          />
        )}
      </Box>
 */
