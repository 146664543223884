import { GridBlock } from '@/blocks'
import { FlipResourceColumn } from '@/columns'

// import APPVARS from '@/constants/appvars'
export default function FlipResourceBlock({ page, ...grid }) {
  //const { blockWidth } = { ...grid }
  //console.log(`FlipResourceBlock`, blockWidth)

  /// const maxBlockWidth = APPVARS.MAX_BLOCK_WIDTH
  return (
    <GridBlock
      {...grid}
      className="GRID_BLOCK_FlipResourceBlock"
      w="100%"
      //maxWidth={maxBlockWidth}
    >
      {() =>
        grid.columns.map((column, index) => (
          <FlipResourceColumn
            flip={index % 2 === 0}
            key={column.id}
            {...{ ...column }}
          />
        ))
      }
    </GridBlock>
  )
}
