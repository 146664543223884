import React from 'react'
import Image from 'next/image'
// id
// width
// height
// url
// imageName
// fileName
// mimeType
// size
import { Box, Flex } from '@chakra-ui/react'
const SectionImage = ({
  align,
  url,
  mimeType,
  fileName,
  imageName = 'Section Image',
  width,
  height
}) => {
  // Works on Home but not on benefits page
  const iwidth = width === 0 ? '100%' : width
  const iheight = height === 0 ? '100%' : height
  // console.log('url', url)
  const alignTerm = align === 'center' ? 'center' : 'flex-start'
  return (
    <Flex justifyContent={alignTerm} alignItems="center" flexDirection="column">
      <Box
        width={iwidth}
        height={iheight}
        position="relative"
        minWidth="212px"
        minHeight="42px"
        maxWidth="100%"
        maxHeight="100%"
      >
        <Image
          src={url}
          alt={fileName ? fileName : 'sectionimg'}
          layout="fill"
          objectFit="contain"
        />
      </Box>
    </Flex>
  )
}

export default SectionImage

/*

  <Image
src={props.url}
alt={props.imageName ? props.imageName : 'image name'}
/>
  <Image
boxSize="100%"
objectFit="cover"
src={section.sectionImage.url}
alt="Section Image"
/> */
