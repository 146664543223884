import {
  Box,
  Divider,
  VStack,
  Grid,
  HStack,
  Tag,
  Button
} from '@chakra-ui/react'
import { formatCurrency } from '@/utils/_formatCurrency'
import { SpecialProductCard, BuyFeaturedProduct } from '@/components'
import { MDXRemote } from 'next-mdx-remote'
import _ from 'lodash'
import { getPaddingY, getGridSpacing } from '@/theme/helpers'
import { FeaturedProductImage, FeaturedProductContent } from '@/elements'

import { stickerFormat } from '@/helpers'
const Body2 = (props) => {
  return <Box textStyle="body2" {...props} />
}

const components = {
  p: Body2
}

const copycomp = {
  p: (props) => <Box textStyle="body2" w="100%" {...props} />
}
const compH3 = {
  p: (props) => <Box as="h3" textStyle="head3" w="100%" {...props} />
}
const compH4 = {
  p: (props) => <Box as="h4" textStyle="head4" w="100%" {...props} />
}
const subcomp = {
  p: (props) => <Box textStyle="head6" w="100%" textAlign="center" {...props} />
}
const SimpleProductPrice = ({ unit, price, specialProduct }) => {
  //const originalPrice = price
  const productUnit = unit ? unit : ''
  const specialType = _.get(specialProduct, 'specialType', null)
  //console.log('specialType', specialType)
  const specialMod = _.get(specialProduct, 'specialMod', null)

  const currentPrice = specialType === 'ELEVATE_SPECIAL' ? specialMod : price

  return (
    <HStack w="100%" gap={2} align="baseline">
      {specialType === 'ELEVATE_SPECIAL' && (
        <Box as="span" textStyle="price2-cross">
          {formatCurrency(price)}
          {productUnit && productUnit}
        </Box>
      )}

      <Box as="span" textStyle="price1">
        {formatCurrency(currentPrice)}
        {productUnit && productUnit}
      </Box>
      <Box>
        {specialType === 'ELEVATE_SPECIAL' && (
          <Tag size="md" variant="solid" colorScheme="elevate_special">
            {stickerFormat(specialType)}
          </Tag>
        )}
        {specialType === 'CASH_BACK' && (
          <Tag size="md" variant="solid" colorScheme="cashback">
            {stickerFormat(specialType)}
          </Tag>
        )}
      </Box>
    </HStack>
  )
}

const FeaturedProductColumn = ({
  id,
  productName,
  productSlug,
  productHeadline,
  productImage,
  productVendor,
  isFeatured,
  featuredProductHeadline,
  featuredProductCopy,
  featuredProductImage,
  productId,
  price,
  unit,
  productCtaLink,
  productCtaLabel,
  specialProduct
}) => {
  const displayImg = featuredProductImage ? featuredProductImage : productImage
  //console.log('specialProduct', specialProduct)
  const yPad40 = getPaddingY('md')
  const gspacing = getGridSpacing('lg')
  const specialType = _.get(specialProduct, 'specialType')

  const specialId = _.get(specialProduct, 'id')

  return (
    <Box py={yPad40} className="FeaturedProductColumn">
      <Grid
        className="gridFeaturedProduct"
        templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
        gap={6}
      >
        {displayImg && (
          <FeaturedProductImage
            {...displayImg}
            colSpan={1}
            width={{ base: '100vw', md: '394px' }}
            height={{ base: '380px', md: '380px' }}
          />
        )}
        <FeaturedProductContent
          colSpan={2}
          textAlign="left"
          align="stretch"
          spacing="5"
        >
          {featuredProductHeadline && (
            <MDXRemote {...featuredProductHeadline.mdx} components={compH4} />
          )}

          <VStack w="100%" spacing="5">
            {productName && (
              <Box w="100%" textStyle="subtitle1">
                {productName}
              </Box>
            )}
            {price && (
              <SimpleProductPrice
                unit={unit}
                price={price}
                specialProduct={specialProduct}
              />
            )}

            <Divider />
            {featuredProductCopy && (
              <MDXRemote {...featuredProductCopy.mdx} components={copycomp} />
            )}

            {specialType === 'CASH_BACK' && (
              <Box layerStyle="claim-panel" w="100%">
                <SpecialProductCard id={specialId} />
              </Box>
            )}
          </VStack>
        </FeaturedProductContent>
      </Grid>
      <Box pt={yPad40}>
        <BuyFeaturedProduct
          className="BuyFeaturedProduct"
          product={{
            id,
            isFeatured,
            featuredProductHeadline,
            featuredProductCopy,
            featuredProductImage,
            productName,
            productImage,
            productSlug,
            productVendor,
            productId,
            price,
            unit,
            productCtaLink,
            productCtaLabel,
            specialProduct
          }}
        />
      </Box>
    </Box>
  )
}
export default FeaturedProductColumn
