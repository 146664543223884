import * as React from 'react'

function SvgLogoAdobeBW(props) {
  return (
    <svg
      width="76"
      height="20"
      viewBox="0 0 76 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3137_9676)">
        <path d="M8.39605 0H0.0332031V20L8.39605 0Z" fill="#3B3F46" />
        <path d="M14.2793 0H22.6312V20L14.2793 0Z" fill="#3B3F46" />
        <path
          d="M11.3373 7.37109L16.6601 19.9998H13.1679L11.577 15.9791H7.68164L11.3373 7.37109Z"
          fill="#3B3F46"
        />
        <path
          d="M36.2752 12.7185L37.2614 15.5461C37.294 15.6169 37.3431 15.6496 37.4302 15.6496H39.3262C39.4297 15.6496 39.4461 15.6006 39.4297 15.4971L35.5125 4.61719C35.4962 4.53002 35.4798 4.51367 35.3927 4.51367H33.0391C32.9737 4.51367 32.9356 4.5627 32.9356 4.63353C32.9029 5.20558 32.8538 5.37992 32.783 5.54881L29.2908 15.4807C29.2744 15.6006 29.3126 15.6496 29.4106 15.6496H31.105C31.2085 15.6496 31.2575 15.6169 31.2957 15.5134L32.2273 12.7185H36.2752ZM32.7667 10.8716C33.2788 9.32435 33.9544 7.34124 34.2268 6.20803H34.2431C34.5809 7.39572 35.3763 9.7493 35.7359 10.8716H32.7667Z"
          fill="#3B3F46"
        />
        <path
          d="M44.4656 15.8185C45.4844 15.8185 46.5686 15.6332 47.6691 15.1593C47.7563 15.1266 47.7726 15.0884 47.7726 15.0067C47.7399 14.7016 47.7018 14.2603 47.7018 13.9225V3.6801C47.7018 3.61472 47.7018 3.58203 47.6201 3.58203H45.7732C45.7023 3.58203 45.6696 3.61472 45.6696 3.70189V7.17233C45.3809 7.13965 45.1793 7.1233 44.9396 7.1233C41.9595 7.1233 40.1289 9.09007 40.1289 11.5472C40.1289 14.3965 42.0085 15.8185 44.4656 15.8185V15.8185ZM45.6696 13.9225C45.3645 14.0206 45.0268 14.0587 44.6835 14.0587C43.3324 14.0587 42.2264 13.296 42.2264 11.4437C42.2264 9.80377 43.3651 8.83401 44.8742 8.83401C45.1793 8.83401 45.4463 8.8667 45.6696 8.95387V13.9225Z"
          fill="#3B3F46"
        />
        <path
          d="M53.6637 7.12305C51.1194 7.12305 49.5449 9.07347 49.5449 11.4815C49.5449 13.6335 50.798 15.8182 53.6256 15.8182C56.0173 15.8182 57.6953 14.0585 57.6953 11.4271C57.6953 9.10616 56.2733 7.12305 53.6637 7.12305ZM53.5602 8.83375C54.9985 8.83375 55.6087 10.0705 55.6087 11.4815C55.6087 13.2249 54.7097 14.0912 53.6637 14.0912C52.3725 14.0912 51.6098 13.007 51.6098 11.4434C51.6098 9.8362 52.4215 8.83375 53.5602 8.83375V8.83375Z"
          fill="#3B3F46"
        />
        <path
          d="M59.6042 3.58203C59.5334 3.58203 59.4844 3.61472 59.4844 3.70189V15.2955C59.4844 15.3445 59.5334 15.4317 59.6042 15.448C60.416 15.6986 61.2659 15.8185 62.143 15.8185C64.6546 15.8185 67.0954 14.2603 67.0954 11.1222C67.0954 8.85035 65.5372 7.1233 63.0965 7.1233C62.5353 7.1233 62.0123 7.21047 61.5547 7.36302L61.5329 3.71823C61.5329 3.59838 61.5002 3.58203 61.3803 3.58203H59.6042ZM65.0087 11.3292C65.0087 13.2088 63.723 14.0914 62.3337 14.0914C62.045 14.0914 61.7889 14.0751 61.5547 14.0043V9.05738C61.8216 8.95387 62.143 8.8667 62.7369 8.8667C64.0771 8.8667 65.0087 9.7166 65.0087 11.3292Z"
          fill="#3B3F46"
        />
        <path
          d="M73.9885 11.9392C74.8166 11.9392 75.4976 11.9228 75.7319 11.8683C75.8191 11.852 75.8518 11.8193 75.8681 11.7485C75.9171 11.5633 75.9389 11.1764 75.9389 10.7025C75.9389 9.08981 74.9692 7.12305 72.463 7.12305C69.9024 7.12305 68.4805 9.20967 68.4805 11.5633C68.4805 13.6499 69.581 15.8182 72.6646 15.8182C73.8196 15.8182 74.566 15.633 75.2089 15.3279C75.2743 15.2952 75.3069 15.2407 75.3069 15.1427V13.7316C75.3069 13.6499 75.2579 13.6335 75.2089 13.6662C74.566 13.9386 73.8686 14.0748 73.1059 14.0748C71.3789 14.0748 70.5943 13.1214 70.5453 11.9392H73.9885ZM70.5453 10.4791C70.6815 9.65097 71.2045 8.75203 72.3759 8.75203C73.6671 8.75203 74.0048 9.8362 74.0048 10.3265C74.0048 10.3429 74.0048 10.4137 74.0048 10.4627C73.934 10.4791 73.7161 10.4791 73.0732 10.4791H70.5453Z"
          fill="#3B3F46"
        />
      </g>
      <defs>
        <clipPath id="clip0_3137_9676">
          <rect
            width="75.9066"
            height="20"
            fill="white"
            transform="translate(0.0332031)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgLogoAdobeBW
