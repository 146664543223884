import {
  Box,
  Flex,
  Link,
  Spacer,
  Stack,
  Text,
  Button,
  VStack
} from '@chakra-ui/react'
import { useBreakpointValue } from '@chakra-ui/react'

import Image from 'next/image'

import { Headline, Subheadline, Copy } from '@/elements'
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})
export default function ProductCard({
  productSlug,
  productName,
  productHeadline,
  productSubheadline,
  price,
  unit,
  productImage,
  productLink,
  productCtaLabel
}) {
  const swidth = useBreakpointValue({
    base: '20rem',
    sm: '24rem',
    md: '48rem',
    lg: '60rem'
  })

  // border="1px solid #EEF1F4"
  return (
    <VStack
      bg="white"
      align="stretch"
      border="1px solid #EEF1F4"
      borderRadius="8px"
      width={productImage.width >= swidth ? swidth : productImage.width}
      height="100%"
      overflow="hidden"
    >
      {productImage && (
        <Image
          flex="1 1 1"
          className="product-card-image"
          src={productImage.url}
          alt={productImage.title}
          title={productImage.title}
          width={productImage.width}
          height={productImage.height}
          objectFit="cover"
        />
      )}

      <Box flex="1 1 0" textAlign="left" px="1rem">
        {productHeadline && (
          <Headline textStyle="subtitle2" source={productHeadline} />
        )}
        {productSubheadline && (
          <Subheadline textStyle="st3" source={productSubheadline} />
        )}

        <Box textStyle="price2" color="black">
          {formatter.format(price)}
        </Box>
      </Box>
      <Spacer />
      <Box flex="1 1 0" textAlign="left" p="1rem">
        <Link
          href={productLink ? productLink : 'http://officedepot.com'}
          target="_blank"
        >
          <Button variant="outline">
            {productCtaLabel ? productCtaLabel : 'Buy Now'}
          </Button>
        </Link>
      </Box>
    </VStack>
  )
}
