import React from 'react'
import { Box } from '@chakra-ui/react'
import { AlertBox } from '@/elements'
import { getErrorContent } from '@/utils/_errors'

const ErrorBox = ({ error, type = 'txt' }) => {
  if (!error) return null
  //console.log('error', error)
  const msg =
    type === 'html'
      ? error.customData
      : `<p>${getErrorContent({ error: error })}</p>`
  return (
    <AlertBox status="error">
      <Box
        textStyle="body1"
        sx={{
          a: {
            textDecoration: 'underline',
            fontWeight: 'bold'
          }
        }}
        dangerouslySetInnerHTML={{ __html: msg }}
      />
    </AlertBox>
  )
}

export default ErrorBox
