import React from 'react'
import { Box } from '@chakra-ui/react'
import { MDXRemote } from 'next-mdx-remote'
const Copy = (props) => {
  const { source, ...more } = props
  return (
    <Box {...more}>
      <MDXRemote {...source.mdx} />
    </Box>
  )
}

export default Copy
