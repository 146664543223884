import { Button } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';

const ButtonOption = ({
  register,
  name,
  answer,
  step,
  error,
  passClearStateFunc,
  key,
  ...rest
}) => {
  const [isSelected, setSelect] = useState(false);

  useEffect(() => {
    //Resets the state of the component when answer options changes
    setSelect(false);
  }, [answer]);

  function toggleState() {
    setSelect(!isSelected);
  }

  return (
    <Button
      _hover={{
        backgroundColor: 'rgba(171, 173, 255, 0.2)',
        color: 'blue.500',
      }}
      // {...register(name)}
      {...rest}
      name={name}
      layerStyle={
        isSelected ? 'selectOption' : error ? 'error' : 'defaultOption'
      }
      variant={isSelected ? 'selectOption' : error ? 'error' : 'defaultOption'}
      value={isSelected ? answer : null}
      width="100%"
      onClick={toggleState}
      key={key}
    >
      {answer}
    </Button>
  );
};
export default ButtonOption;
