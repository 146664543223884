import { GraphQLClient } from 'graphql-request'
// TODO: #460 need to add doppler, and solidify the branchs for draft preview of master environment,  stage, dev, etc.. @tech-ea
const graphcmsMutantClient = () =>
  new GraphQLClient(process.env.NEXT_PUBLIC_GRAPHCMS_URL, {
    headers: {
      authorization: `Bearer ${process.env.NEXT_PUBLIC_MUTANT_TOKEN_MASTER}`
    }
  })

const graphcmsClient = (preview = false) =>
  new GraphQLClient(process.env.NEXT_PUBLIC_GRAPHCMS_URL, {
    headers: {
      ...(process.env.NEXT_PUBLIC_PUBLISHED_TOKEN_MASTER && {
        Authorization: `Bearer ${
          preview
            ? process.env.NEXT_PUBLIC_MUTANT_TOKEN_MASTER
            : process.env.NEXT_PUBLIC_PUBLISHED_TOKEN_MASTER
        }`
      })
    }
  })

export { graphcmsClient, graphcmsMutantClient }
