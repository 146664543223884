import React from 'react'
import { Box } from '@chakra-ui/react'

const TermlyFrame = ({ src }) => {
  return (
      <Box
        as="iframe"
        src={src}
        height="100%"
        width={[200, 300, 400, 800]}
      />
  )
}

export default TermlyFrame
