import React, { useState } from 'react'
import _ from 'lodash'
import { Box, Button, Tooltip, Icon, Grid, GridItem } from '@chakra-ui/react'
import { IoHelpCircleSharp } from 'react-icons/io5'

import { BsCheck } from 'react-icons/bs'
import { useGetSpecialBonus } from '@/hooks'

import { getCashbackTitle } from '@/utils/_articleTools'

import { formatDateNormal } from '@/utils/_formatDates'

import { addSpecialProduct, addAccountActivity } from '@/lib/teamAccounts'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
// dayjs.extend(isSameOrBefore)

const SpecialTooltip = ({ tooltip }) => {
  if (!tooltip) {
    return null
  }
  const { tooltipCopy } = tooltip
  if (!tooltipCopy) {
    return null
  }

  return (
    <Tooltip hasArrow label={tooltipCopy} fontSize="sm3" bg="purple">
      <Box>
        <Icon as={IoHelpCircleSharp} w="24px" h="24px" color="grey.250" />
      </Box>
    </Tooltip>
  )
}

const SpecialBonusCard = () => {
  const [setIsJustClaimed] = useState(false)
  const { isReady, team, specialBonus } = useGetSpecialBonus()

  //console.log('specialBonus', specialBonus)
  async function handleClick() {
    const vendorAccountId = _.get(team, 'vendorAccounts.OD.vendorAccountId')
    const teamAccountId = team.teamAccountId
    const idsClaimedAlready = specialBonus.claimedBy
    const alreadyClaimed = _.indexOf(idsClaimedAlready, teamAccountId)

    if (alreadyClaimed === -1) {
      const specialProduct = specialBonus
      const productInfo = specialProduct.product
      dayjs.extend(utc)
      const currentSpecialRow = {
        vendorAccountId: vendorAccountId,
        teamAccountId: teamAccountId,
        dateClaimed: dayjs.utc().format(),
        id: specialProduct.id,
        slug: specialProduct.slug,
        specialProductName: specialProduct.specialProductName,
        specialType: specialProduct.specialType,
        specialMod: specialProduct.specialMod,
        productName: productInfo.productName,
        productPrice: productInfo.price,
        productId: productInfo.productId,
        productSlug: productInfo.productSlug
      }

      await addSpecialProduct({
        teamAccountId,
        newSpecial: currentSpecialRow
      })
        .then(() => {
          setIsJustClaimed(true)
        })
        .catch((err) => console.log(err))

      // await addAccountActivity({
      //   teamAccountId: teamAccountId,
      //   vendorName: productInfo.productVendor,

      //   productPrice: productInfo.price,
      //   productId: productInfo.productId,
      //   productSlug: productInfo.productSlug,
      //   qtySold: 1,
      //   productProgramPrice: productInfo.price,
      //   listPrice: productInfo.price,
      //   specialProductName: specialProduct.specialProductName,
      //   specialType: specialProduct.specialType,
      //   specialMod: specialProduct.specialMod,
      //   specialLimit: 1
      // })
      //   .then(() => {
      //     setIsJustClaimed(true)
      //   })
      //   .catch((err) => console.log(err))
    }
  }
  if (!isReady) {
    return <Box>Loading...</Box>
  }
  if (!specialBonus) {
    return <Box>No special bonus</Box>
  }
  const isClaimed = _.find(team.specials)

  const claimsLeft = specialBonus.totalIssued - specialBonus.totalClaimed
  //console.log(`claimsLeft`, claimsLeft)
  dayjs.extend(isSameOrBefore)

  const dateDeactivated = _.get(specialBonus, 'dateDeactivated', dayjs())
  const isActive = dayjs().isSameOrBefore(dayjs(dateDeactivated), 'day')
  //console.log(`isActive`, isActive)
  const claimsAvailable = claimsLeft > 0 ? claimsLeft : 0

  return (
    <Grid
      templateColumns={{ base: '1fr', md: '1fr 1fr 1fr 140px' }}
      templateRows={{ base: '1fr', md: '1fr auto' }}
      templateAreas={{
        base: "'headline' 'cta' 'details'",
        md: "'headline headline headline cta' 'details details details details'"
      }}
      bg="white"
      w="100%"
      height="100%"
      align="stretch"
      rowGap={4}
      borderWidth="1px"
      borderStyle="solid"
      borderColor="grey.100"
      p="18px"
    >
      <GridItem gridArea="headline">
        <Box textStyle="subtitle2">
          {getCashbackTitle(specialBonus.specialMod)}
        </Box>
        <Box textStyle="body4" color="grey.300">
          {claimsAvailable} claims left
        </Box>
      </GridItem>
      <GridItem
        gridArea="cta"
        alignSelf={{ base: 'strech', md: 'start' }}
        justifySelf={{ base: 'stretch', md: 'end' }}
      >
        {!isClaimed ? (
          <Button
            width={{ base: 'full', md: 'auto' }}
            size="sm"
            disabled={claimsAvailable < 1}
            variant="claimit"
            onClick={handleClick}
          >
            Claim It
          </Button>
        ) : (
          <Button
            size="sm"
            disabled={true}
            variant="claimit"
            rightIcon={<BsCheck ml="10px" size="24px" />}
          >
            Claimed
          </Button>
        )}
      </GridItem>
      <GridItem gridArea="details">
        {isClaimed && (
          <Box textStyle="claimit" color="blue.400" pb="4">
            You got it! Cash Back will be added to your balance on your Activity
            Report in your business account.
          </Box>
        )}

        {isActive ? (
          <Box textStyle="body4" color="grey.300">
            Expires on{' '}
            {formatDateNormal({ value: specialBonus.dateDeactivated })}
          </Box>
        ) : (
          <Box textStyle="body4" color="grey.300">
            Expired
          </Box>
        )}
      </GridItem>
      {specialBonus && <SpecialTooltip {...specialBonus.tooltip} />}
    </Grid>
  )
}
export default SpecialBonusCard
