import React from 'react'
import {
  Box,
  Flex,
  Spacer,
  GridItem,
  Grid,
  Button,
  VStack,
  useBreakpointValue
} from '@chakra-ui/react'
import Link from 'next/link'
import Image from 'next/image'
import { addProductClick } from '@/lib/teamAccounts'
import { useRouter } from 'next/router'
import { useModal } from '@ebay/nice-modal-react'
import MODALS from '@/constants/modals'
import ROUTES from '@/constants/routes'
import { useFireAuth } from '@/lib/fireAuth'
import { formatCurrency } from '@/utils/_formatCurrency'
import { BuyProduct } from '@/components'
const ProductCardColumn = ({
  productId,
  productSlug,
  productName,
  productVendor,
  productHeadline,
  productSubheadline,
  cloudImage,
  price,
  unit,
  isFeatured,
  productImage,
  productCtaLink,
  productCtaLabel,
  colSpan = 1
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const cardWidth = colSpan === 2 ? '446px' : '223px'
  return (
    <GridItem rowSpan={1} colSpan={isMobile ? 1 : colSpan} display="flex">
      <Box
        bg="white"
        width="100%"
        align="stretch"
        border="1px solid #EEF1F4"
        borderRadius="8px"
        height="350px"
        overflow="hidden"
      >
        {productImage && (
          <Box
            position="relative"
            height="160px"
            width="auto"
            overflow="hidden"
          >
            <Image
              src={productImage.url}
              alt={productImage.imageName}
              layout="fill"
              objectFit="cover"
            />
          </Box>
        )}
        {cloudImage && (
          <Box
            position="relative"
            height="160px"
            width="auto"
            maxWidth="400px"
            overflow="hidden"
          >
            <Image
              src={cloudImage.url}
              alt="product"
              width={190}
              height={119}
            />
          </Box>
        )}
        <Flex
          height="190px"
          direction="column"
          justify="space-between"
          textAlign="left"
          px="10px"
          py="4"
          overflow="hidden"
        >
          <Box
            textStyle="subtitle2"
            overflow="hidden"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical'
            }}
          >
            {productName}
          </Box>
          <Box textStyle="price2">
            {formatCurrency(price)}
            {unit && unit}
          </Box>

          <Spacer />
          {productId && (
            <BuyProduct
              product={{
                productId,
                productSlug,
                productName,
                productVendor,
                productHeadline,
                productSubheadline,
                price,
                unit,
                productCtaLink,
                productCtaLabel
              }}
            />
          )}
        </Flex>
      </Box>
    </GridItem>
  )
}

export default ProductCardColumn
