import React from 'react'
import { Box, Link } from '@chakra-ui/react'

// import { useRouter } from 'next/router'
const LinkExternal = ({ children, href, variant }) => {
  return (
    <Link isExternal variant={variant} href={href}>
      {children}
    </Link>
  )
}

export default LinkExternal
