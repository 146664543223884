const ROUTES = {
  HOME: '/home',
  DISCOVER: '/discover',
  ACCOUNT: '/account/settings',
  ACCOUNT_SETTINGS: '/account/settings',
  ACCOUNT_ACTIVITY: '/account/activity',
  ONBOARDING_NEW_MEMBER_SIGN_UP: '/onboarding/new-member/sign-up',
  ONBOARDING_NEW_MEMBER_CREATE_TEAM_ACCOUNT:
    '/onboarding/new-member/create-team-account',
  ONBOARDING_SIGN_IN: '/onboarding/sign-in',
  ONBOARDING_NEW_MEMBER_INVITE_YOUR_TEAM:
    '/onboarding/new-member/invite-your-team',
  ONBOARDING_NEW_MEMBER_VENDOR_SETUP: '/onboarding/new-member/vendor-setup',
  ONBOARDING_NEW_MEMBER_ONBOARDING_COMPLETE:
    '/onboarding/new-member/onboarding-complete',
  LANDING: '/landing',
  CONTACT_US: 'https://excelerateamerica.zendesk.com/hc/en-us',
  FORGOT_PASSWORD: '/onboarding/forgot-password',
  TERMS_AND_CONDITIONS_SIGN_UP:
    'https://app.termly.io/document/terms-of-use-for-ecommerce/fe03ffd5-09de-4d67-ad08-d4d9700f6f68',
  PRIVACY_POLICY_SIGN_UP:
    'https://app.termly.io/document/privacy-policy/80f95814-362d-44d0-a4d5-a1d6e50b8d37',
  CONTACT_US_FORM: 'https://excelerateamerica.zendesk.com/hc/en-us/requests/new',
  DISCLAIMER_POLICY: 'https://app.termly.io/document/disclaimer/ec242c00-bc93-4528-a65a-44252849667d',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  DISCLAIMER: '/disclaimer'
}
export default ROUTES
