import React from 'react'
import { Button } from '@chakra-ui/react'
const SubmitBtn = ({ label, isWorking, variant, isDisabled = false }) => {
  return (
    <Button
      mt={4}
      isLoading={isWorking}
      type="submit"
      variant={variant}
      alignSelf="center"
      disabled={isDisabled}
    >
      {label}
    </Button>
  )
}
export default SubmitBtn
