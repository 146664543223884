import React from 'react'

import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'

//import { defaultUrl } from '@/config/next-seo.config'

export function Seo({
  id,
  image,
  keywords,
  noIndex: noindex = false,
  ...props
}) {
  const router = useRouter()

  const SEO = {
    ...(keywords && { keywords: keywords.toString() }),
    noindex,
    openGraph: {
      ...(image && {
        images: [
          {
            alt: props.title,
            ...image
          }
        ]
      }),
      url: router.asPath,
      ...props
    },
    ...props
  }

  return <NextSeo {...SEO} />
}

export default Seo
