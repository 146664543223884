import React, { useState, useEffect } from 'react'
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Lorem,
  Button,
  VStack,
  useBreakpointValue
} from '@chakra-ui/react'
import _ from 'lodash'
import { useRouter } from 'next/router'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { SignIn } from '@/modules'
const SignInModal = NiceModal.create(
  ({ variant = 'normal', size = 'md', slug }) => {
    const modal = useModal()
    const handleResolve = () => {
      // console.log('resolveslug')
      modal.hide()
    }
    const router = useRouter()
    const handleForgotonDone = () => {
      router.push('/')
    }
    const innerVariant = useBreakpointValue({ base: 'mobile', md: variant })
    return (
      <Modal
        variant={innerVariant}
        size={size}
        isOpen={modal.visible}
        onClose={modal.hide}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton onClick={() => modal.hide()} />
          <ModalBody>
            <SignIn
              onDone={handleResolve}
              onForgotPassSuccess={handleForgotonDone}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
)

export default SignInModal
