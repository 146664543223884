import React, { useState } from 'react'
import { Box, Button, Stack, Input, SimpleGrid, VStack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { FieldInput } from '@/forms'
import InputMask from 'react-input-mask'

const FormFieldGroup = ({ label, children, ...props }) => {
  return (
    <VStack spacing="2" w="100%">
      <Box textStyle="subtitle2" alignItems="flex-start" w="100%">
        {label}
      </Box>
      <SimpleGrid w="100%" {...props}>
        {children}
      </SimpleGrid>
    </VStack>
  )
}
const MemberForm = ({ member, isEditing, handleUpdate }) => {
  //console.log('member', member)

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    mode: 'onChange',
    defaultValues: { ...member }
  })
  return (
    <Box w="100%" as="form" onSubmit={handleSubmit(handleUpdate)}>
      {isEditing ? (
        <VStack spacing="8">
          <FieldInput name="name" label="Name">
            <Stack direction={{ base: 'column', md: 'row' }}>
              <FormFieldGroup minChildWidth="320px" spacing="2">
                <FieldInput>
                  <Input
                    id="firstName"
                    variant="filled"
                    name="firstName"
                    {...register('firstName')}
                    defaultValue={member?.firstName}
                    errors={errors.firstName}
                  />
                </FieldInput>

                <FieldInput>
                  <Input
                    variant="filled"
                    name="lastName"
                    id="lastName"
                    {...register('lastName')}
                    errors={errors.lastName}
                  />
                </FieldInput>
              </FormFieldGroup>
            </Stack>
          </FieldInput>
          <FormFieldGroup label="Email" minChildWidth="320px" spacing="2">
            <Input
              variant="filled"
              isReadOnly={true}
              id="email"
              placeholder="Enter your email address"
              defaultValue={member?.email}
              focusBorderColor="gray.100"
            />
          </FormFieldGroup>
          <FormFieldGroup label="Mobile" minChildWidth="320px" spacing="2">
            <FieldInput errors={errors.phoneNumber}>
              <Input
                variant="filled"
                id="phoneNumber"
                {...register('phoneNumber')}
                defaultValue={member?.phoneNumber}
                as={InputMask}
                mask="***-***-****"
                maskChar={null}
              />
            </FieldInput>
          </FormFieldGroup>
          <FormFieldGroup label="ZIP Code" minChildWidth="320px" spacing="2">
            <Input
              placeholder={member?.location}
              {...register('location')}
              variant="outline"
              id="location"
              isRequired={true}
            />
          </FormFieldGroup>
          <VStack>
            <Button
              type="submit"
              width="83px"
              height="46px"
              variant="solid"
              onClick={() => console.log(errors.location)}
            >
              Save
            </Button>
          </VStack>
        </VStack>
      ) : (
        <VStack spacing="8">
          <FormFieldGroup label="Name" minChildWidth="320px" spacing="2">
            <Input
              name="firstName"
              variant="filled"
              isReadOnly={true}
              id="firstName"
              defaultValue={member?.firstName}
              focusBorderColor="gray.100"
            />
            <Input
              name="lastName"
              variant="filled"
              isReadOnly={true}
              id="lastName"
              defaultValue={member?.lastName}
              focusBorderColor="gray.100"
            />
          </FormFieldGroup>
          <FormFieldGroup label="Email" minChildWidth="320px" spacing="2">
            <Input
              variant="filled"
              isReadOnly={true}
              id="email"
              defaultValue={member?.email}
              focusBorderColor="gray.100"
            />
          </FormFieldGroup>
          <FormFieldGroup label="Mobile" minChildWidth="320px" spacing="2">
            <Input
              variant="filled"
              id="phoneNumber"
              isReadOnly={true}
              focusBorderColor="gray.100"
              defaultValue={member?.phoneNumber}
            />
          </FormFieldGroup>
          <FormFieldGroup label="ZIP Code" minChildWidth="320px" spacing="2">
            <Input
              variant="filled"
              isReadOnly={true}
              name="location"
              defaultValue={member?.location}
              focusBorderColor="gray.100"
            />
          </FormFieldGroup>
        </VStack>
      )}
    </Box>
  )
}

export default MemberForm
