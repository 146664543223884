import React from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box
} from '@chakra-ui/react'

const AlertBox = ({ status, children }) => {
  return <Alert status={status}>{children}</Alert>
}

export default AlertBox
