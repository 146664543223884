const { VStack, HStack, Box, Spacer } = require('@chakra-ui/layout')
import { SavingsGraph } from 'components/index'
// specialTotals={specialSavingsTotal}
// savingsTotals={specialSavingsTotal + everydaySavingsTotal}
// everydayTotals={everydaySavingsTotal}
const TotalSavingsPanel = ({
  savingsTotals,
  specialTotals,
  everydayTotals
}) => {
  return (
    <Box layerStyle="activity-graph-panel" width="100%" p="30px">
      <VStack width="100%">
        <HStack width="100%">
          <Box textStyle="head5">Total Savings</Box>
          <Spacer />
          <Box textStyle="activity-totals" textAlign="right">
            {'$' + savingsTotals.toFixed(2)}{' '}
          </Box>
        </HStack>
        <SavingsGraph
          elevateSpecials={specialTotals.toFixed(2)}
          everydayDeals={everydayTotals.toFixed(2)}
        />

        <Box color="gry.300">
          Lifetime {'Savings: $' + savingsTotals.toFixed(2)}
        </Box>
      </VStack>
    </Box>
  )
}
export default TotalSavingsPanel
