import { useCallback, useState, useEffect } from 'react'
import _ from 'lodash'
import { gql } from 'graphql-request'
import { graphcmsClient } from '@/graphql/_client'
import { useFireAuth } from '@/lib/fireAuth'

const getSpecialProduct = gql`
  query getSpecialProduct() {
    specialProduct( where: { slug: "elevate-early-access-cash-back"}) {
        id
        claimedBy
        dateActivated
        dateDeactivated
        product {
          productName
          price
          productId
          productSlug
          productVendor
        }
        slug
        specialLimit
        specialMod
        specialProductRestrictions
        specialType
        tooltip {
          id
          tooltipSlug
          tooltipAction
          tooltipCopy
          tooltipLayerStyle
          tooltipName
        }
        totalAvailable
        totalClaimed
        totalIssued
      }
    }

`
const useGetSpecialBonus = () => {
  const [isReady, setIsReady] = useState(false)
  const { status, member, team } = useFireAuth()
  const [specialBonus, setSpecialBonus] = useState()
  const getQuery = useCallback(async () => {
    try {
      const client = graphcmsClient()
      const { specialProduct } = await client.request(getSpecialProduct)

      //console.log(specialProduct)
      setSpecialBonus(specialProduct)
      setIsReady(true)
    } catch (error) {
      //console.log(error)
    }
  }, [])

  useEffect(() => {
    if (status === 'loggedIn' && team) {
      getQuery({ slug: 'elevate-early-access-cash-back' })
    }
  }, [getQuery, status, team])
  return { isReady, member, team, specialBonus }
}
export default useGetSpecialBonus
