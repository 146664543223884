import React from 'react'
import { Box } from '@chakra-ui/react'
import { MDXRemote } from 'next-mdx-remote'

const mdxstyle = {
  p: (props) => (
    <Box
      textStyle="body-footer"
      textAlign={{ base: 'center', md: 'left' }}
      {...props}
    />
  )
}
const FooterTagline = ({ content }) => {
  return (
    <Box pt="4">
      <MDXRemote {...content.mdx} components={mdxstyle} />
    </Box>
  )
}

export default FooterTagline
