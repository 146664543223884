import React, { useState } from 'react'
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  VStack,
  Flex
} from '@chakra-ui/react'

import { SpecialProductCard } from '@/components'
import NiceModal, {
  useModal,
  show,
  create,
  remove,
  register
} from '@ebay/nice-modal-react'

export default NiceModal.create(
  ({ id, variant = 'normal', hasExit = false }) => {
    const [claimed, setClaimed] = useState(false)
    const modal = useModal()

    const nowClickBuy = () => {
      setClaimed(true)
    }
    //console.log('productObject', productObject)
    // const specialId = productObject.specialId
    return (
      <Modal
        variant={variant}
        isOpen={() => modal.show()}
        onClose={() => modal.remove()}
      >
        <ModalOverlay />
        <ModalContent>
          {hasExit ? <ModalCloseButton onClick={() => modal.hide()} /> : ''}
          <ModalBody>
            <VStack w="100%" spacing="10">
              <VStack w="100%" spacing="10px" align="left">
                <Box textStyle="head3">Wait! You can still earn Cash Back.</Box>
                <Box textStyle="body1">
                  Before you shop, earn easy Cash Back by clicking on the Claim
                  It button below.
                </Box>
              </VStack>
              <SpecialProductCard id={id} onFormComplete={nowClickBuy} />
              <Button
                variant="solid"
                disabled={claimed}
                onClick={() => modal.resolve({ resolved: true })}
              >
                Buy Now
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
)
