import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'
import components from './components'
import {
  colors,
  letterSpacings,
  lineHeights,
  fontWeights,
  fonts,
  fontSizes,
  spacing,
  shadows,
  borders
} from './foundations'
// import { colors } from './colors'
// Global style overrides
import { breaks, container, largeSizes } from './dimensions'
import { globalStyles, layerStyles, textStyles } from './styles'

// const breakpoints = createBreakpoints({
//   ...breaks
// })

// 2. Update the breakpoints as key-value pairs

// const container = {
//   xs: '268px',
//   sm: '394px',
//   md: '616px',
//   lg: '812px',
//   xl: '964px'
// }
export const hrz = {
  xs: '4px',
  sm: '8px',
  md: '12px',
  lg: '24px',
  xl: '48px',
  xxl: '72px',
  '3XL': '96px'
}
export const vrt = {
  xs: '4px',
  sm: '8px',
  md: '12px',
  lg: '24px',
  xl: '30px',
  xxl: '80px'
}
const sizes = {
  ...spacing,
  ...largeSizes,
  hrz,
  vrt,
  container
}

const theme = extendTheme(
  {
    colors,
    letterSpacings,
    lineHeights,
    fontWeights,
    fonts,
    fontSizes,
    shadows,
    borders,
    sizes,
    components,
    styles: globalStyles,
    layerStyles,
    textStyles,
    breakpoints: { ...breaks },
    semanticTokens: {
      sizes: {
        hxl: '300px'
      }
    }
  },
  withDefaultColorScheme({
    colorScheme: 'tabs',
    components: ['Tabs']
  })
)
export default theme
