import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  VStack,
  Flex,
  Box
} from '@chakra-ui/react'
import NiceModal, {
  useModal,
  show,
  create,
  remove,
  register
} from '@ebay/nice-modal-react'
import { ButtonMain } from '@/components'
import { useGetModalData } from '@/hooks'

export default NiceModal.create(
  ({ name, variant, hasExit, onAction, onCancel }) => {
    const modal = useModal()
    const modalData = useGetModalData('setUpCashBack')
    return (
      <Modal
        variant={variant}
        isOpen={() => modal.show()}
        onClose={() => modal.remove()}
      >
        <ModalOverlay />
        <ModalContent>
          {hasExit && <ModalCloseButton onClick={() => modal.hide()} />}
          <ModalBody>
            <Box w="100%">
              {modalData?.title && (
                <Box textStyle="head2">{modalData.title} </Box>
              )}
              {modalData?.subTitle && (
                <Box mt="3" textStyle="bt2">
                  {modalData.subTitle}
                </Box>
              )}
              {modalData?.copy && (
                <Box mt="3" textStyle="body1">
                  {modalData.copy}
                </Box>
              )}
              {modalData?.buttons && (
                <Flex mt="10" w="100%" justify="center">
                  {modalData.buttons.map((button) => (
                    <ButtonMain
                      variant="solid"
                      key={button.id}
                      href={button.buttonHref}
                      label={button.buttonLabel}
                    />
                  ))}
                </Flex>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
)
