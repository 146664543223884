import { Box, Link, ListItem, OrderedList, VStack } from '@chakra-ui/react'
import { RichText } from '@graphcms/rich-text-react-renderer'
import APPVARS from '@/constants/appvars'

export default function RichTextBlock({
  richTextBlockName,
  richTextBlockSlug,
  richTextBlockTextAlign = 'left',
  richTextBlockLayerStyle,
  richTextBlockRichText
}) {
  return (
    <Box
      className="RICH_TEXT_BLOCK"
      mx="auto"
      w="100%"
      layerStyle={richTextBlockLayerStyle ? richTextBlockLayerStyle : 'default'}
      maxWidth={{ base: 'calc(100vw - 40px)', md: '694px' }}
    >
      <VStack spacing="10px" w="100%" mt="24px">
        {richTextBlockRichText && (
          <Box w="100%" textAlign={richTextBlockTextAlign}>
            <RichText
              content={richTextBlockRichText.json}
              renderers={{
                a: ({ children, openInNewTab, href, rel, ...rest }) => (
                  <Link
                    variant="rich"
                    href={href}
                    target={openInNewTab ? '_blank' : '_self'}
                    rel={rel || 'noopener noreferrer'}
                    {...rest}
                  >
                    {children}
                  </Link>
                ),
                h1: ({ children }) => (
                  <Box
                    mb="3"
                    textStyle="head1"
                    textAlign={richTextBlockTextAlign}
                  >
                    {children}
                  </Box>
                ),
                // this is the 18px text on /benefits in the first section
                h2: ({ children }) => (
                  <Box
                    mx="auto"
                    pb={APPVARS.HRZ.XL}
                    textStyle="body1"
                    textAlign={richTextBlockTextAlign}
                    maxWidth="616px"
                    lineHeight="26px"
                  >
                    {children}
                  </Box>
                ),
                h3: ({ children }) => (
                  <Box
                    mb="5"
                    textStyle="head3"
                    textAlign={richTextBlockTextAlign}
                  >
                    {children}
                  </Box>
                ),
                h5: ({ children }) => (
                  <Box
                    mb="3"
                    textStyle="head5"
                    textAlign={richTextBlockTextAlign}
                  >
                    {children}
                  </Box>
                ),

                class: ({ className, children, ...rest }) => {
                  if (className === 'details') {
                    return (
                      <Box
                        textStyle="head5"
                        className={className}
                        my="4"
                        {...rest}
                      >
                        {children}
                      </Box>
                    )
                  } else {
                    return (
                      <Box className={className} my="4" {...rest}>
                        {children}
                      </Box>
                    )
                  }
                },
                bold: ({ children }) => <strong>{children}</strong>,
                p: ({ children }) => <Box textStyle="body2">{children}</Box>,
                ol: ({ children }) => (
                  <OrderedList className="OL" textStyle="body2" w="100%">
                    {children}
                  </OrderedList>
                ),
                li: ({ children }) => (
                  <ListItem className="LI" textStyle="body2" w="100%">
                    {children}
                  </ListItem>
                )
              }}
            />
          </Box>
        )}
      </VStack>
    </Box>
  )
}
