import { GridBlock } from '@/blocks'
import { FlipInfoColumn } from '@/columns'
export default function FlipInfoBlock({ page, ...grid }) {
  //console.log(`FlipinfoBlock`, blockWidth)

  //const maxBlockWidth = getBlockWidth(blockWidth)
  return (
    <GridBlock
      {...grid}
      className="GRID_BLOCK_FlipInfo_BLOCK"
      mx={{ base: 'auto', md: 'auto' }}
      width={{
        base: 'calc(100vw - 40px)',
        lg: 'min(calc(100vw - 40px), 812px)'
      }}
      rowGap={{ base: '20px', md: '0' }}
    >
      {() =>
        grid.columns.map((column, index) => (
          <FlipInfoColumn
            flip={index % 2 === 0}
            key={column.id}
            {...{ ...column }}
          />
        ))
      }
    </GridBlock>
  )
}
