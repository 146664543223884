import { Box, VStack, Flex } from '@chakra-ui/react'
import { RichText } from '@graphcms/rich-text-react-renderer'
import { MDXRemote } from 'next-mdx-remote'
import { useModal } from '@ebay/nice-modal-react'
import { ButtonMain } from '@/components'
import MODALS from '@/constants/modals'
import box from 'theme/components/box'
import APPVARS from '@/constants/appvars'
const Body1 = (props) => {
  return <Box textStyle="body1" size="md2" {...props} />
}
const Body2 = (props) => {
  return <Box textStyle="body2" {...props} />
}
const components = {
  p: (props) => (
    <Box
      w="100%"
      mb="10px"
      textAlign="left"
      textStyle="body1"
      size="md2"
      {...props}
    />
  ),

  h3: (props) => (
    <Box w="100%" mb="0" textStyle="head5" textAlign="left" {...props} />
  )
}

function createMarkup(someHtml) {
  return { __html: someHtml }
}

const CustomBlock = ({
  id,
  customBlockName,
  customBlockSlug,
  customBlockHeadline,
  customBlockSubheadline,
  customBlockCopy,
  customBlockLayerStyle,
  customBlockContent,
  customBlockJson,
  customBlockClass,
  customBlockSize,
  customBlockButtons
}) => {
  //console.log('customBlockSize', customBlockSize)
  //const maxBlockWidth = APPVARS.MAX_BLOCK_WIDTH //getBlockWidth(customBlockSize)

  const textAlign = 'center'
  return (
    <VStack
      layerStyle={customBlockLayerStyle ? customBlockLayerStyle : 'default'}
      spacing="30px"
      className={customBlockSlug}
      width="100%"
      justifyContent="center"
      alignItems="center"
      py="20"
    >
      {customBlockHeadline && (
        <Box as="h3" textStyle="head3">
          <MDXRemote {...customBlockHeadline.mdx} />
        </Box>
      )}
      {customBlockSubheadline && (
        <Box as="h4" textStyle="head4">
          <MDXRemote {...customBlockSubheadline.mdx} />
        </Box>
      )}
      {customBlockCopy && (
        <MDXRemote {...customBlockCopy.mdx} components={components} />
      )}
      {customBlockContent && (
        <VStack spacing="10px" textAlign={textAlign} w="100%">
          <RichText
            content={customBlockContent.json}
            renderers={{
              h1: ({ children }) => <Box textStyle="head1">{children}</Box>,
              h3: ({ children }) => <Box textStyle="head3">{children}</Box>,
              h5: ({ children }) => <Box textStyle="head5">{children}</Box>,
              p: ({ children }) => <p>{children}</p>,
              bold: ({ children }) => <strong>{children}</strong>
            }}
          />
        </VStack>
      )}
      {customBlockButtons &&
        customBlockButtons.map((btn, index) => (
          <Flex key={index} justifyContent="center" alignItems="center">
            <ButtonMain label="How It Works" {...btn} />
          </Flex>
        ))}
    </VStack>
  )
}
export default CustomBlock
// templateRows =
//   '[gl-top] 1.5rem [gl-content-top] 5rem [gl-content-bot] 0.75rem [gl-title-top] auto [gl-title-bot] 0.75rem [gl-cta-top] 2.125rem [gl-cta-bot] 1.5rem [gl-bot]'
{
  /* <Box display="flex" justifyContent="center" alignItems="center"> */
}
