import * as React from 'react'

function SvgLogoHPBW(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99984 19.9607C9.90083 19.9607 9.80224 19.9589 9.70383 19.956L11.7395 14.3615H14.5409C15.0327 14.3615 15.5724 13.9835 15.7406 13.5216L17.9491 7.45542C18.3105 6.46224 17.7416 5.6497 16.6848 5.6497H12.7983L9.53726 14.6105H9.53607L7.68737 19.6902C3.3014 18.6475 0.0390625 14.7048 0.0390625 9.99989C0.0390625 5.43056 3.11588 1.58026 7.31057 0.406781L5.40303 5.6497H5.40248L2.23131 14.3615L4.34235 14.3616L7.03918 6.95881H8.62571L5.92888 14.3616L8.03914 14.362L10.5523 7.45542C10.9138 6.46224 10.3449 5.6497 9.28907 5.6497H7.51453L9.55216 0.0495305C9.70069 0.0429361 9.84978 0.0390625 9.99984 0.0390625C15.5011 0.0390625 19.9606 4.49863 19.9606 9.99989C19.9606 15.501 15.5011 19.9607 9.99984 19.9607ZM16.0267 6.9535H14.4406L12.2193 13.0479H13.8055L16.0267 6.9535Z"
        fill="#3B3F46"
      />
    </svg>
  )
}

export default SvgLogoHPBW
