import { useState } from 'react'
const modalContent = {
  simple: {
    title: 'Simple',
    subTitle: 'subtitle',
    buttonText: 'Create Account',
    hyperText: 'Simple'
  },
  newMemberSignUp: {
    variant: 'alt',
    title: 'Earn Cash Back for Your Business',
    subTitle:
      `Welcome to Elevate! We're a collective of entrepreneurs leveraging the power of many to negotiate pricing on products, services and benefits.`,
    checkList: {
      1: 'Earn Cash Back on ODP Business Solutions purchases',
      2: 'Access exclusive Elevate Specials',
      3: 'Discover what entrepreneurs love ',
      4: 'Track your purchasing activity',
      5: 'Subscription ($149 value) sponsored by ODP Business Solutions'
    },
    buttonText: 'Sign Up Now'
  },
  invitedMemberSignUp: {
    variant: 'alt',
    title: "You're Invited!",
    subTitle:
      "Welcome to Elevate, our small business subscription service! You've been invited to join (**Mandalorian LLC**) business account. Joining will get you collective-negotiated pricing and exclusive cash back rewards.",
    checkList: {
      1: 'Free 1-year trial',
      2: 'Earn cash back on purchases',
      3: 'Access member-driven deals',
      4: 'Subscription covers entire team',
      5: 'No payment for 365 days'
    },
    buttonText: 'Start Free Trial'
  },
  articleBlock: {
    variant: 'default',
    title: 'Keep Discovering with an Elevate Subscription',
    subTitle:
      'Sign up to claim cash back and access collective-negotiated pricing on products, services, and benefits for your small business. ',
    buttonText: 'SignUp',
    postButtonText: 'ALREADY HAVE AN ELEVATE SUBSCRIPTION?',
    hyperText: 'LOG IN'
  },
  disconnectAccount: {
    title: 'Disconnect Account',
    subTitle: 'This will end future cash back and savings with Office Depot',
    buttonText: 'Yes, Disconnect',
    buttonText2: 'Cancel'
  },
  alreadyRegisteredBiz: {
    title: 'Hmm, This Business Is Already Registered',
    subTitle:
      'This business already exists in Elevate. Contact our customer service team to help sort this out.',
    copy: 'lllll',
    hyperText: 'CONTACT US'
  },
  setUpCashBack: {
    title: 'Set Up Cash Back',
    subTitle:
      'In order to earn Cash Back on this Office Depot purchase, you need to verify your Office Depot account number. Go to account settings to get set up.',
    buttons: [
      { buttonLabel: 'Account Settings', buttonHref: '/account/settings' }
    ]
  },

  createOD: {
    title: 'Create OD',
    subTitle:
      "You still need to register your business to create an account. If you're having trouble, our customer service team can help.",
    buttonText: 'Create Account',
    hyperText: 'CONTACT OUR TEAM'
  },
  blockAccessNoTeam: {
    title: 'Create An Account',
    subTitle:
      "You still need to register your business to create an account. If you're having trouble, our customer service team can help.",
    buttonText: 'Create Account',
    hyperText: 'CONTACT OUR TEAM'
  },

  otherComponent: {
    layerStyle: 'modal-default',
    title: null,
    subTitle: null,
    buttonText: null,
    hyperText: null
  }
}

const useGetModalData = (modalName) => {
  const [modalType] = useState(modalContent[modalName])

  return modalType
}
export default useGetModalData
