import React, { useState, useEffect, useCallback, ChangeEvent } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import APPVARS from '@/constants/appvars'
import {
  Flex,
  Grid,
  Button,
  Input,
  VStack,
  Box,
  HStack,
  Stack,
  Checkbox,
  InputRightElement,
  InputLeftAddon,
  InputGroup,
  IconButton,
  Link
} from '@chakra-ui/react'
import InputMask from 'react-input-mask'
import { FieldInputEmail, FieldInput, FieldInputPassword } from '@/forms'
import { signUpSimple } from '@/lib/firebase'
import NiceModal from '@ebay/nice-modal-react'
import MODALS from '@/constants/modals'
// import { AlertBox } from '@/elements'

import ROUTES from '@/constants/routes'

import { ErrorBox } from '@/components'

import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri'

import { ButtonLink } from '@/components'

import { signupSchema } from '@/constants/form-schemas'

const SignUpForm = ({ queryData, defaultSignUpFields, onFormComplete }) => {
  const [errorBox, setErrorBox] = useState()

  // const [showAlertBox, setShowAlertBox] = useState(false)

  const [showErrorBox, setShowErrorBox] = useState(false)
  const [appError, setAppError] = useState()

  const [show, setShow] = React.useState(false)

  const handleShowPassword = () => setShow(!show)
  const [firstLoad, setFirstLoad] = useState(true)

  const openModalOnce = useCallback(() => {
    // do something!
    if (firstLoad) {
      NiceModal.show(`${MODALS.ONBOARD_NEW_MODAL}`, { name: 'newMemberSignUp' })
      setFirstLoad(false)
    }
  }, [firstLoad])

  useEffect(() => {
    //console.log('modals', modals)
    if (firstLoad) {
      openModalOnce()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalOnce])

  const {
    register,
    formState: { errors, isSubmitting, isValid, isDirty, isSubmitSuccessful },

    reset,
    handleSubmit,
    control
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      password: '',
      location: '',
      terms: false
    },
    resolver: yupResolver(signupSchema)
  })

  const onResetForm = () => {
    reset({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      password: '',
      location: '',
      terms: false
    })
    setShowErrorBox(false)
  }

  const onSignUpSubmit = async (data) => {
    //console.log('Sign Up data', data)
    const { email } = data
    await signUpSimple({ userData: data })
      .then(() => {
        onFormComplete()
      })
      .catch((error) => {
        console.log('error', error)
        const code = '000'
        const type = 'html'
        const name = 'Email taken'
        const message = `${email} already exists on Elevate. Contact our customer service team to help sort this out. <a href=${ROUTES.CONTACT_US} target="_blank" rel="noopener noreferrer">CONTACT US</a>`
        //const errorBox = { code, type, name, message }

        setAppError({ type, code, name, message })
        //setErrorBox({ type: 'error', msg: { __html: message } })
        setShowErrorBox(true)
      })
  }

  return (
    <Grid
      gap={20}
      templateColumns="1fr"
      alignItems="center"
      maxW={{
        base: APPVARS.BLOCK_MAX_WIDTH_FULL,
        lg: APPVARS.BLOCK_MAX_WIDTH_FULL
      }}
      mx={{ base: 'auto', lg: 'auto' }}
    >
      <VStack
        as="form"
        spacing="30px"
        onSubmit={handleSubmit(onSignUpSubmit)}
        align="start"
        padding="0"
      >
        <VStack align="start">
          <Box textStyle="head4">Sign Up For Elevate</Box>
          <Box textStyle="body2">
            Enter information below to create a username and password for your
            Elevate account.
          </Box>
        </VStack>

        <FieldInput name="name" label="Name">
          <Stack direction={{ base: 'column', md: 'row' }}>
            <Input
              id="firstName"
              placeholder="First Name"
              {...register('firstName')}
              error={errors.firstName}
            />
            <Input
              mt={2}
              id="lastName"
              placeholder="Last Name"
              {...register('lastName')}
              error={errors.lastName}
            />
          </Stack>
        </FieldInput>
        <FieldInputEmail name="email" label="Email" error={errors.email}>
          <Input
            id="email"
            placeholder="Enter Your Email"
            {...register('email')}
          />
        </FieldInputEmail>

        <FieldInput
          name="phoneNumber"
          label="Mobile"
          error={errors.phoneNumber}
        >
          <InputGroup>
            <InputLeftAddon>+1</InputLeftAddon>

            <Input
              id="phoneNumber"
              placeholder="000-000-0000"
              as={InputMask}
              mask="***-***-****"
              maskChar={null}
              {...register('phoneNumber')}
            />
          </InputGroup>
        </FieldInput>

        <FieldInputPassword
          label="Password"
          name="password"
          error={errors.password}
        >
          <InputGroup>
            <Input
              id="password"
              type={show ? 'text' : 'password'}
              placeholder="******"
              {...register('password')}
            />
            {/* insert checkbox to show password*/}
            <InputRightElement>
              <IconButton
                m="0"
                aria-label="hide password"
                onClick={handleShowPassword}
                variant="icon-simple"
                icon={show ? <RiEyeLine /> : <RiEyeCloseLine />}
              />
            </InputRightElement>
          </InputGroup>
        </FieldInputPassword>

        <FieldInput
          autofill={false}
          name="location"
          label="ZIP Code"
          error={errors.location}
        >
          <Input
            autoComplete="nope"
            id="location"
            {...register('location')}
            placeholder="Enter your ZIP Code"
          />
        </FieldInput>

        <FieldInput>
          <HStack
            w="100%"
            spacing="10px"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Controller
              control={control}
              name="terms"
              defaultValue={false}
              render={({ field, name }) => (
                <Checkbox
                  name={name}
                  isChecked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  // {...register('terms')}
                />
              )}
            />
            <Box textStyle="fine-print">
              By clicking Agree and Continue, I consent to Excelerate America
              <ButtonLink
                color="#1D4797"
                to={ROUTES.TERMS_AND_CONDITIONS}
                isExternal
                ml="3px"
              >
                Terms and Conditions{' '}
              </ButtonLink>
              and
              <ButtonLink
                color="#1D4797"
                to={ROUTES.PRIVACY_POLICY}
                isExternal
                ml="3px"
              >
                Privacy Policy
              </ButtonLink>
            </Box>
          </HStack>
        </FieldInput>

        {appError && <ErrorBox error={appError} />}

        <Flex width="100%" alignItems="center" justify="center">
          {!isSubmitSuccessful || !showErrorBox ? (
            <Button
              variant="solid"
              isLoading={isSubmitting}
              type="submit"
              disabled={!isDirty || !isValid}
            >
              Agree And Continue
            </Button>
          ) : (
            <Button
              variant="solid"
              isLoading={isSubmitting}
              //type="submit"
              onClick={onResetForm}
              loadingText="Submitting"
              disabled={!isDirty || !isValid}
            >
              Try Again
            </Button>
          )}
        </Flex>
      </VStack>
    </Grid>
  )
}
export default SignUpForm
