const INDUSTRYSTAGE = {
    STAGES: [
      {
        value: 'idea-development',
        label: 'Idea Development',
      },
      {
        value: 'start-up',
        label: 'Start-up',
      },
      {
        value: 'growth',
        label: 'Growth',
      },
      {
        value: 'expansion',
        label: 'Expansion',
      },
      {
        value: 'maturity',
        label: 'Maturity',
      }
    ]
    ,
    INDUSTRIES: [
      {
        value: 'accounting-finance',
        label: 'Accounting/Finance',
      },
      {
        value: 'advertising-pr',
        label: 'Advertising/PR',
      },
      {
        value: 'agriculture',
        label: 'Agriculture',
      },
      {
        value: 'apparel-fashion',
        label: 'Apparel/Fashion',
      },
      {
        value: 'architecture-planning',
        label: 'Architecture/Planning',
      },
      {
        value: 'arts-crafts',
        label: 'Arts/Crafts',
      },
      {
        value: 'automotive',
        label: 'Automotive',
      },
      {
        value: 'banking-mortgage',
        label: 'Banking/Mortgage',
      },
      {
        value: 'building-construction',
        label: 'Building/Construction',
      },
      {
        value: 'business-services',
        label: 'Business Services',
      },
      {
        value: 'business-supplies-equipment',
        label: 'Business Supplies/Equipment',
      },
      {
        value: 'cannabis-products-services',
        label: 'Cannabis Products/Services',
      },
      {
        value: 'capital-markets-hedge-fund-private-equity',
        label: 'Capital Markets/Hedge Fund/Private Equity',
      },
      {
        value: 'civic-social-organization',
        label: 'Civic/Social Organization',
      },
      {
        value: 'computer-software-engineering',
        label: 'Computer Software/Engineering',
      },
      {
        value: 'consumer-electronics',
        label: 'Consumer Electronics',
      },
      {
        value: 'consumer-services',
        label: 'Consumer Services',
      },
      {
        value: 'defense-space',
        label: 'Defense/Space',
      },
      {
        value: 'design-graphic-design-web-design',
        label: 'Design/Graphic Design/Web Design',
      },
      {
        value: 'education',
        label: 'Education',
      },
      {
        value: 'entertainment',
        label: 'Entertainment',
      },
      {
        value: 'facilities-services',
        label: 'Facilities Services',
      },
      {
        value: 'fine-art',
        label: 'Fine Art',
      },
      {
        value: 'farming',
        label: 'Farming',
      },
      {
        value: 'food-beverage',
        label: 'Food/Beverage',
      },
      {
        value: 'health-fitness',
        label: 'Health/Fitness',
      },
      {
        value: 'hospitality',
        label: 'Hospitality',
      },
      {
        value: 'information-technology-it',
        label: 'Information Technology/IT',
      },
      {
        value: 'internet',
        label: 'Internet',
      },
      {
        value: 'legal-law',
        label: 'Legal/Law',
      },
      {
        value: 'leisure-travel',
        label: 'Leisure/Travel',
      },
      {
        value: 'manufacturing',
        label: 'Manufacturing',
      },
      {
        value: 'marketing-sales',
        label: 'Marketing/Sales',
      },
      {
        value: 'media',
        label: 'Media',
      },
      {
        value: 'medical',
        label: 'Medical',
      },
      {
        value: 'newspapers-journalism',
        label: 'Newspapers/Journalism',
      },
      {
        value: 'photography',
        label: 'Photography',
      },
      {
        value: 'packaging-containers',
        label: 'Packaging/Containers',
      },
      {
        value: 'printing',
        label: 'Printing',
      },
      {
        value: 'real-estate',
        label: 'Real Estate',
      },
      {
        value: 'restaurants',
        label: 'Restaurants',
      },
      {
        value: 'recreational-facilities-services',
        label: 'Recreational Facilities/Services',
      },
      {
        value: 'retail',
        label: 'Retail',
      },
      {
        value: 'staffing-recruiting',
        label: 'Staffing/Recruiting',
      },
      {
        value: 'tourism',
        label: 'Tourism',
      },
      {
        value: 'transportation',
        label: 'Transportation',
      },
      {
        value: 'utilities',
        label: 'Utilities',
      }
    ]
  }
  export default INDUSTRYSTAGE
  