const { VStack, HStack, Box, Spacer } = require('@chakra-ui/layout')
import { CashbackGraph } from 'components/index'
import _ from 'lodash'

const TotalCashBackPanel = ({
  cashBackRecords,
  totalCurrent,
  totalLifetime
}) => {
  const cashBackByVendor = _.keys(cashBackRecords).map((key) => {
    const summy = _.sumBy(cashBackRecords[key], 'cashBack')
    const obj = {}
    obj[key] = summy
    return { vendor: key, cashBack: summy }
  })

  //console.log('cashBackByVendor', cashBackByVendor)
  return (
    <Box layerStyle="activity-graph-panel" width="100%" p="30px">
      <VStack width="100%">
        <HStack width="100%">
          <Box textStyle="head5">Total Cash Back</Box>
          <Spacer />
          <Box textStyle="activity-totals" textAlign="right">
            {'$' + totalCurrent.toFixed(2)}
          </Box>
        </HStack>

        <CashbackGraph vendors={cashBackByVendor} />
        <Box>Lifetime{' Cash Back: $' + totalLifetime.toFixed(2)}</Box>
      </VStack>
    </Box>
  )
}
export default TotalCashBackPanel
