import React from 'react'
import { VStack, Box, useToken } from '@chakra-ui/react'
import { BlockWrapper } from '@/wrappers'

import _ from 'lodash'

const _getBg = (sectionBackground, bg) => {
  if (!sectionBackground) {
    return {
      bg: { bg }
    }
  }
  const { url } = sectionBackground
  return {
    backgroundImage: `url(${url})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }
}

const ArticleSection = ({ section }) => {
  const [bg, border, borderColor, borderRadius, color] = useToken(
    'layerStyles',
    [_.toLower(section.layerStyles)]
  )
  const backclass = _getBg(section.sectionBackground, bg)
  return (
    <Box
      id={section.sectionSlug}
      layerStyle={section.layerStyle ? section.layerStyle : 'default'}
      w="100%"
      justifyContent="center"
      {...backclass}
    >
      <VStack className="ARTICLE_SECTION_BLOCKS">
        {section.sectionBlocks && (
          <BlockWrapper blocks={section.sectionBlocks} />
        )}
      </VStack>
    </Box>
  )
}
const ArticleSectionsContainer = ({ sections }) => {
  if (!sections) {
    return null
  }
  return (
    <Box className="ARTICLE_SECTIONS_CONTAINER" flexGrow="1" w="100%">
      {sections.map((section) => (
        <ArticleSection key={section.id} section={section} />
      ))}
    </Box>
  )
}

export default ArticleSectionsContainer
