const description = `Excelerate America: The new collective for entrepreneurs to collaborate and thrive.`
const title = `Deals`
// const url = `https://elevate.deals`
const seo = {
  title,
  titleTemplate: 'Elevate | %s',
  description,
  openGraph: {
    description,
    title,
    type: 'website',
    url: 'https://elevate.deals'
  },
  twitter: {
    handle: '@ExcelerateBiz',
    site: 'https://elevate.deals'
  }
}

export { seo as defaultSEO }
