// none: 1,
// short1: 1.29,
// short2: 1.33,
// base1: 1.44,

const layerStyles = {
  base: {
    bg: 'transparent',
    border: 'none',
    borderRadius: '0'
  },
  footer: {
    bg: 'brand.800',
    border: 'none',
    boxShadow: 'none',
    borderRadius: '0',
    color: 'white'
  },
  'claim-panel': {
    bg: 'white',
    border: '1px solid white',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    color: 'black'
  },
  'tabnav-selected': {
    color: 'black',
    borderBottomColor: 'tabs.200',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid'
  },
  selected: {
    bg: 'white',
    border: '2px solid',
    borderColor: 'blue.500',
    borderRadius: '4px',
    color: 'blue.500'
  },
  hover: {
    bg: 'rgba(171, 173, 255, 0.2)',
    border: '1px solid',
    borderColor: 'blue.500',
    borderRadius: '4px',
    color: 'blue.500'
  },
  form1: {
    bg: 'white',
    border: '1px solid grey.200',
    boxSizing: 'border-sizing',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: 'lg',
    p: '7'
  },
  error: {
    bg: 'white',
    border: '1px solid',
    borderColor: 'red.400',
    borderRadius: '4px',
    color: 'black'
  },
  primary: {
    bg: 'white',
    border: '2px solid',
    borderColor: 'blue.500',
    borderRadius: '4px',
    color: 'blue.500'
  },
  secondary: {
    bg: 'blue.500',
    border: '1px solid',
    borderColor: 'grey.250',
    borderRadius: '4px',
    color: 'black'
  },
  brand: {
    bg: 'pink',
    border: 'none',
    borderColor: 'none',
    borderRadius: '0',
    color: 'black'
  },
  alt: {
    bg: 'purple',
    border: '1px solid',
    borderColor: 'grey.250',
    borderRadius: '4px',
    color: 'black'
  },
  'modal-default': {
    bg: 'white',
    border: '1px solid',
    borderColor: 'white',
    borderRadius: '4px',
    color: 'black'
  },
  'modal-alt': {
    bg: 'purple',
    border: '1px solid',
    borderColor: 'purple',
    borderRadius: '4px',
    color: 'white'
  },
  ghost: {
    bg: 'transparent',
    border: '1px solid',
    borderColor: 'grey.250',
    borderRadius: '4px',
    color: 'black'
  },
  default: {
    bg: 'transparent',
    border: 0,
    borderColor: 'none',
    borderRadius: 0,
    color: 'black'
  },
  'form-head': {
    bg: 'white',
    borderBottom: '1px solid',
    borderColor: 'grey.200',
    borderRadius: 0,
    color: 'black',
    pb: '10px'
  },
  grey100: {
    bg: 'grey.100',
    border: 0,
    borderColor: 'none',
    borderRadius: 0,
    color: 'black'
  },
  grey200: {
    bg: 'grey.200',
    border: 0,
    borderColor: 'none',
    borderRadius: 0,
    color: 'black'
  },
  hero: {
    bg: 'transparent',
    color: 'white'
  },
  'page-section': {
    bg: 'transparent',
    border: 0,
    borderColor: 'none',
    borderRadius: 0,
    color: 'black'
  },
  'settings-form': {
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    p: '7',
    borderRadius: 2,
    border: '1px solid grey.200'
  },
  'form-panel': {
    bg: 'white',
    border: '1px solid #EEF1F4',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px'
  },
  'activity-graph-panel': {
    border: '1px solid #EEF1F4',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    bordeRadius: '8px'
  }
}
export default layerStyles
