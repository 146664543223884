import { Box, VStack, Stack, Button } from '@chakra-ui/react'
import { useModal } from '@ebay/nice-modal-react'
import { MDXRemote } from 'next-mdx-remote'
import MODALS from '@/constants/modals'

import { ButtonMain } from '@/components'
const Body1 = (props) => {
  return <Box textStyle="body1" size="md2" {...props} />
}
const Body2 = (props) => {
  return <Box textStyle="body2" {...props} />
}
const components = {
  p: Body1
}

function createMarkup(someHtml) {
  return { __html: someHtml }
}

const CustomModalBlock = ({
  id,
  modalButtons,
  modalCopy,
  modalHeadline,
  modalName,
  modalSlug,
  modalSubheadline,
  modalRichContent,
  modalSize
}) => {
  const modal = useModal(`${MODALS.CUSTOM_MODAL}`)

  const handleModalOpen = async (customModal) => {
    modal
      .show({ variant: 'medium', hasExit: true, modalData: customModal })
      .then((path) => {
        router.push(path)
      })
  }

  return (
    <Stack display="flex" direction="row" mt={8} spacing={3}>
      {modalButtons &&
        modalButtons.map((button) => (
          <Box
            key={button.id}
            display="inline-flex"
            borderRadius="md"
            boxShadow="md"
          >
            <ButtonMain
              variant={button.variant}
              label="HIHI"
              onClick={() =>
                handleModalOpen({
                  copy: modalCopy,
                  headline: modalHeadline,
                  subheadline: modalSubheadline,
                  content: modalRichContent,
                  size: modalSize
                })
              }
            />
          </Box>
        ))}
    </Stack>
  )
}
export default CustomModalBlock
