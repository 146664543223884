const card = {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    background: 'purple',
    alignItems: 'center',
    gap: 6
  },
  sizes: {
    xs: {
      minW: '260px'
    },
    sm: {
      minW: '360px'
    },
    md: {
      minW: '460px'
    },
    md: {
      minW: '560px'
    },
    lg: {
      minW: '760px'
    },
    xl: {
      minW: '960px'
    }
  },
  variants: {
    rounded: (props) => ({
      padding: 20,
      borderRadius: 'xl',
      boxShadow: 'md',
      boxSizing: 'border-sizing',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: 2,
      bg: 'white'
    }),
    smooth: (props) => ({
      padding: 6,
      borderRadius: 'base',
      boxShadow: 'md',
      boxSizing: 'border-sizing',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: 2,
      bg: 'white'
    }),
    hero: (props) => ({
      padding: 8,
      borderRadius: 'xl',
      boxShadow: 'xl',
      boxSizing: 'border-sizing',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: 2
    }),
    page_card: (props) => ({
      padding: 8,
      borderRadius: 'none',
      boxShadow: 'none',
      boxSizing: 'border-sizing',
      boxShadow: 'none',
      borderRadius: 0
    })
  },
  defaultProps: {
    size: 'xs',
    variant: 'smooth'
  }
}

export default card
