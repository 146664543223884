const tag = {
  baseStyle: (props) => ({
    container: {
      borderRadius: '4px',
      opacity: 1,
      padding: '3px 6px',
      fontWeight: '400',
      _disabled: {
        opacity: 0.4
      }
    },
    label: {
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: '400',
      overflow: 'visible'
    }
  }),
  variants: {
    gray: (props) => ({
      container: {
        bg: 'gray.400',
        color: 'white',
        textDecoration: 'none'
      }
    }),
    elevate_special: (props) => ({
      container: {
        bg: '#23B589',
        color: 'white',
        textDecoration: 'none'
      }
    }),
    savings: (props) => ({
      container: {
        bg: '#23B589',
        color: 'white',
        textDecoration: 'none'
      }
    }),
    cash_back: (props) => ({
      container: {
        bg: '#757EFF',
        color: 'white',
        textDecoration: 'none'
      }
    }),
    popular: (props) => ({
      container: {
        bg: '#EC554B',
        color: 'white',
        textDecoration: 'none'
      },
      label: {
        fontSize: '18px',

        fontWeight: '400',
        overflow: 'visible'
      }
    }),
    ranked: (props) => ({
      container: {
        bg: '#76CBE2',
        color: 'white',
        textDecoration: 'none'
      },
      label: {
        fontSize: '18px',

        fontWeight: '400',
        overflow: 'visible'
      }
    }),
    outline: (props) => ({
      container: {
        color: '#1D4797',
        bg: 'white',
        border: '1px solid #1D4797',
        textDecoration: 'none'
      }
    })
  },
  sizes: {
    sm: {
      container: {
        padding: '2px 10px',
        fontSize: 'sm2'
      }
    },
    md: {
      container: {
        padding: '3px 6px',
        fontSize: 'sm25'
      }
    },
    lg: {
      container: {
        fontSize: 'sm3',
        padding: '3px 6px'
      }
    }
  },
  defaultProps: {
    size: 'md',
    variant: 'gray',
    colorScheme: 'gray'
  }
}

export default tag
