export const onboardScreensCopy = {
  VENDOR_SETUP_MAIN_SCREEN: {
    HEADLINE: 'Connect Office Depot Account',
    COPY: 'As a collective buying program, Elevate negotiates with product and service partners to get members exclusive pricing, access, and deals typically reserved for large companies. We sometimes receive commission through our partnerships, so we pay it forward to you with Cash Back incentives.',
    COPY2:
      'One of our premier partners, Office Depot, provides a platform for our collective to shop 100s of brand name products relevant to running a business. Connecting your Office Depot business account to Elevate lets you access the best pricing, extra-special discounts, and Cash Back. Our team can set you up if you don’t already have an account.'
  },
  VENDOR_SETUP_FORM_SCREEN: {
    HEADLINE: 'Connect Office Depot Account',
    COPY: 'Elevate Cash Back works with select Office Depot business pricing programs. Enter your Office Depot account number below to verify your eligibility.'
  },
  VENDOR_NEW_MAIN_SCREEN: {
    HEADLINE: 'Create an Office Depot Account',
    COPY: "We'll set you up with an Office Depot account so you can start earning Cash Back on your purchases."
  },
  VENDOR_NEW_FORM_SCREEN: {
    HEADLINE: 'Essential Info',
    COPY: 'The following information will be used to check your eligibility and set up your Office Depot account.',
    NOTE: 'Your information will be kept secure and not be shared to 3rd parties.',
    NOTE2: 'We need this information to create your Office Depot account.',
    SUCCESS_MESSAGE:
      'Thank you! Our customer service team will contact you within 24 hours with your account number and login information.'
  }
}
