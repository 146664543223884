import React, { useState, useEffect } from 'react'
import {
  Box,
  VStack,
  Input,
  Text,
  Button,
  Link as ChakraLink,
  Flex
} from '@chakra-ui/react'
import { FieldInputEmail, SubmitBtn } from '@/forms'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { sendPasswordResetEmail2 } from '@/lib/firebase'
import ROUTES from '@/constants/routes'
import { useRouter } from 'next/router'
import { getAlertHtmlContent } from '@/helpers'
import { AlertBox, LinkExternal } from '@/elements'

const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email().required()
})

const ForgotPassword = ({ onFormComplete }) => {
  const [emailSent, setEmailSent] = useState(false)
  // const [apiErrors, setApiErrors] = useState()
  const [errorBox, setErrorBox] = useState()
  const [showAlertBox, setShowAlertBox] = useState(false)
  const router = useRouter()
  const {
    register,
    formState: { errors, isSubmitting, isValid, isDirty },
    handleSubmit,
    reset
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(forgotPasswordSchema)
  })
  const onResetForm = () => {
    reset()
    setShowAlertBox(false)
  }
  const forgotPassSubmit = async (data) => {
    const { email } = data
    sendPasswordResetEmail2(email)
      .then(setEmailSent(true))
      .catch((error) => {
        setEmailSent(false)
        const errorCode = error.code
        //console.log('errorCode', errorCode)
        const errorHtml = getAlertHtmlContent({
          type: 'AUTH',
          code: errorCode,
          msg: error.message
        })
        const errorMessage = error.message
        setErrorBox({ type: 'error', msg: errorHtml })
        setShowAlertBox(true)
      })
  }

  return (
    <Box>
      {emailSent ? (
        <VStack spacing="10">
          <VStack spacing="10px">
            <Box textStyle="head3">Reset Password Link Sent</Box>
            <Box textStyle="body1">
              We just sent you an email with a link to reset your password to
              the following email address. Please check your inbox.{' '}
            </Box>
          </VStack>
          <Button onClick={onFormComplete}>Continue</Button>
        </VStack>
      ) : (
        <VStack
          as="form"
          spacing="10"
          align="start"
          onSubmit={handleSubmit(forgotPassSubmit)}
        >
          <VStack spacing="10px" align="stretch">
            <Box textStyle="head3">Forgot Password?</Box>
            <Box textStyle="body1">
              Enter your email address and we will send you a link to reset your
              password. Contact us if resetting your password does not work.
            </Box>
          </VStack>
          <FieldInputEmail
            labelInline={true}
            name="email"
            label="Email"
            error={errors.email}
          >
            <Input
              id="email"
              placeholder="Email Address"
              {...register('email')}
            />{' '}
          </FieldInputEmail>
          {showAlertBox && (
            <AlertBox status={errorBox.type}>
              <Box textStyle="body1" dangerouslySetInnerHTML={errorBox.msg} />
            </AlertBox>
          )}
          {!showAlertBox ? (
            <SubmitBtn
              type="submit"
              label="Send Password Reset Link"
              isDisabled={!isDirty || !isValid}
              isWorking={isSubmitting}
              variant="solid"
            />
          ) : (
            <Flex width="100%" alignItems="center" justify="center">
              <Button
                variant="solid"
                isLoading={isSubmitting}
                //type="submit"
                onClick={onResetForm}
                loadingText="Submitting"
                disabled={!isDirty || !isValid}
              >
                Try Again
              </Button>
            </Flex>
          )}
          <Box textStyle="body5" w="100%" align="center">
            Forgot username?{' '}
            <LinkExternal variant="normal" href={ROUTES.CONTACT_US}>
              CONTACT US
            </LinkExternal>
          </Box>
        </VStack>
      )}
    </Box>
  )
}

export default ForgotPassword
