import { Flex, Box } from '@chakra-ui/layout'
import { AppBar, Footer, Seo } from '@/components'

import APPVARS from '@/constants/appvars'
export default function LandingLayout({ children, page }) {
  //console.log('page', page)
  return (
    <>
      {page?.seo && <Seo {...page.seo} />}
      <Box className="LAYOUT_LANDING" minH="100vh">
        <AppBar navType="landing" />
        <Flex className="LAYOUT_LANDING_COL" direction="column" minH="100vh">
          <Box
            className="NavHold"
            flexGrow="0"
            height={APPVARS.NAV_BAR_HEIGHT}
            w="100vw"
            alignSelf="flex-start"
          />
          <Flex
            className="LANDING_CONTENT"
            flexGrow="1"
            justifyContent="center"
          >
            {children}
          </Flex>
        </Flex>
        {page?.footer && (
          <Box flexGrow="0">
            <Footer {...page.footer} />
          </Box>
        )}
      </Box>
    </>
  )
}

export const getLayout = (page) => (
  <LandingLayout {...page.props}>{page}</LandingLayout>
)
