import React from 'react'
import {
  Wrap,
  WrapItem,
  VStack,
  Box,
  useBreakpointValue
} from '@chakra-ui/react'
import { NavButton } from '@/components'
const NormalLinks = ({ links }) => {
  //console.log('footerLinks', footerLinks)
  return (
    <VStack spacing="4" align="left">
      {links.map((link) => (
        <NavButton
          px="0"
          key={link.id}
          href={link.navLinkUrl}
          variant={link.navLinkVariant}
          isExternal={link.isExternal}
          size={link.navLinkSize}
          label={link.navLinkLabel}
        />
      ))}
    </VStack>
  )
}

const MobileLinks = ({ links }) => {
  //console.log('footerLinks', footerLinks)
  return (
    <Wrap spacing="8px" justify="center">
      {links.map((link) => (
        <WrapItem as="div" key={link.id}>
          <NavButton
            key={link.id}
            href={link.navLinkUrl}
            variant={link.navLinkVariant}
            isExternal={link.isExternal}
            size={link.navLinkSize}
            label={link.navLinkLabel}
          />
        </WrapItem>
      ))}
    </Wrap>
  )
}

const FooterNavLinks = ({ navlinks }) => {
  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <Box>
      {isMobile ? (
        <MobileLinks links={navlinks} />
      ) : (
        <NormalLinks links={navlinks} />
      )}
    </Box>
  )
}

export default FooterNavLinks
