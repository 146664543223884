import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody
} from '@chakra-ui/react'
import NiceModal, {
  useModal,
  show,
  create,
  remove,
  register
} from '@ebay/nice-modal-react'
import { AddTeamMember } from '@/modules'
import { useRouter } from 'next/router'

export default NiceModal.create(
  ({ name, variant, hasExit, onAction, onCancel }) => {
    const modal = useModal()

    const router = useRouter()
    const onNextAction = () => {
      //console.log(`path`)
      modal.remove()
      // router.push(ROUTES.ACCOUNT_SETTINGS)
      //setCurStep(2)
    }
    return (
      <Modal
        // variant={variant}
        isOpen={() => modal.show()}
        onClose={() => modal.remove()}
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent>
          {hasExit ? <ModalCloseButton onClick={() => modal.hide()} /> : ''}
          <ModalBody>
            <AddTeamMember
              handleNextAction={onNextAction}
              hasSkipBtn={false}
              onboarding={false}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
)
