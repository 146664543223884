import React from 'react'
import { Box, VStack } from '@chakra-ui/react'
import APPVARS from '@/constants/appvars'

const AccountSettingsContainer = ({ children }) => {
  return (
    <VStack
      spacing="10px"
      className="ACCOUNT_SETTINGS_CONTAINER"
      flexGrow="1"
      maxW={{
        base: APPVARS.BLOCK_MAX_WIDTH_BASE,
        lg: APPVARS.BLOCK_MAX_WIDTH_FULL
      }}
      mx={{ base: 'auto' }}
      w="100%"
    >
      {children}
    </VStack>
  )
}

export default AccountSettingsContainer
