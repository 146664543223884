import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  GridItem,
  Flex,
  VStack,
  Stack,
  Spacer,
  StackDivider,
  useBreakpointValue
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { StepNumber, StepperCongratsIcon } from '@/icons'
import StepIcon from './StepIcon'
import _ from 'lodash'
import ROUTES from '@/constants/routes'

const steps = [
  {
    stepArea: 'step1',
    stepNum: 1,
    show: true,
    title: 'Welcome!',
    stepLabel: 'SIGN UP FOR ELEVATE',
    route: ROUTES.ONBOARDING_NEW_MEMBER_SIGN_UP
  },
  {
    stepArea: 'step2',
    stepNum: 2,
    show: true,
    title: 'Welcome!',
    stepLabel: 'CREATE ACCOUNT',
    route: ROUTES.ONBOARDING_NEW_MEMBER_CREATE_TEAM_ACCOUNT
  },
  // {
  //   stepNum: 3,
  //   show: true,
  //   title: 'Welcome!',
  //   stepLabel: 'INVITE YOUR TEAM',
  //   route: ROUTES.ONBOARDING_NEW_MEMBER_INVITE_YOUR_TEAM
  // },
  {
    stepArea: 'step3',
    stepNum: 3,
    show: true,
    title: 'Welcome!',
    stepLabel: 'SET UP CASH BACK',
    route: ROUTES.ONBOARDING_NEW_MEMBER_VENDOR_SETUP
  },
  {
    stepArea: 'step4',
    stepNum: 4,
    show: false,
    title: `Hooray, you're all set up!`,
    stepLabel: 'ON BOARD COMPLETE',
    route: ROUTES.ONBOARDING_NEW_MEMBER_ONBOARDING_COMPLETE
  }
]

const StepDivider = () => {
  return <Flex grow="1" border="1px dashed #949CAB" my="12px" mx="0" />
}

const Step = ({ stepNum, stepLabel, currentStep, lastStep }) => {
  //console.log(` currentStep= ${currentStep}`)
  // const activeColor = currentStep >= stepNum ? '#3352DF' : '#949CAb'
  const activeColor =
    _.toNumber(currentStep) >= _.toNumber(stepNum) ? '#3352DF' : '#949CAb'
  //console.log('activeCOlor', activeColor)
  return (
    <Stack
      my={{ base: '2', md: '' }}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      w="100%"
    >
      <StepIcon stepNum={stepNum} activeColor={activeColor} />
      <Box textStyle="step-text1" color={activeColor}>
        {stepLabel}
      </Box>
    </Stack>
  )
}

export const Stepper = () => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const [title, setTitle] = useState('Welcome')
  const activeSteps = steps.filter((step) => step.show !== false)
  const lastStep = activeSteps.length
  const [stepIndex, setStepIndex] = useState(1)
  const router = useRouter()

  useEffect(() => {
    const { pathname } = router
    const sindex = _.findIndex(steps, function (o) {
      return o.route == pathname
    })
    const stepIndex = sindex + 1
    const title = _.get(steps[sindex], 'title', 'Welcome!')
    setTitle(title)
    setStepIndex(stepIndex)
  }, [router])

  return (
    <VStack
      px={{ base: '20px', md: '20px', lg: '0' }}
      gap={{ base: '5', md: '10' }}
      py={{ base: '5', md: '10' }}
      w="100%"
      align="center"
    >
      <Box textAlign={isMobile ? 'center' : 'left'} h="100%" textStyle="head1">
        {title}
      </Box>

      <Flex
        direction={{ base: 'column', md: 'row' }}
        w="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        {activeSteps.map((step) => {
          return (
            <Step
              key={step.stepNum}
              stepNum={step.stepNum}
              stepLabel={step.stepLabel}
              currentStep={stepIndex}
              isActive={stepIndex >= step.stepNum}
              lastStep={lastStep}
            />
          )
        })}
      </Flex>
    </VStack>
  )
}

export default Stepper

// <Stack
// justify="center"
// align="left"
// direction={{ base: 'column', md: 'row' }}
// spacing={{ base: '10px', md: '30px' }}
// >
