import React from 'react'
import { Box } from '@chakra-ui/react'
import { MDXRemote } from 'next-mdx-remote'

const footerCRmobile = {
  p: (props) => (
    <Box
      textStyle="body-footer"
      textAlign={{ base: 'center', md: 'left' }}
      {...props}
    />
  )
}

const FooterCopyright = ({ content, textAlign }) => {
  return (
    <MDXRemote
      {...content.mdx}
      components={footerCRmobile}
      textAlign={textAlign}
    />
  )
}

export default FooterCopyright
