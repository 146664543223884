const modal = {
  baseStyle: (props) => ({
    dialog: {
      boxSizing: 'border-sizing',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: 0,
      color: 'black',
      bg: 'white'

      // px: 10,
      // py: 14
    },
    body: { px: '60px', py: '60px' }
  }),
  variants: {
    default: {
      dialog: {
        boxSizing: 'border-sizing',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: 2,
        color: 'black',
        bg: 'white'
      },
      dialogContainer: {
        margin: '0',
        alignItems: 'stretch',
        minHeight: '100vh',
        minHeight: '-webkit-fill-available',
        minHeight: 'fill-available'
      },
      body: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        padding: '60px'
      }
    },
    mobile: {
      dialog: {
        minWidth: '100vw',

        width: '100%',
        margin: '0',
        mx: '0',
        my: '0',
        boxSizing: 'border-sizing',
        boxShadow: 'none',
        borderRadius: 0,
        color: 'black',
        bg: 'white',
        pb: '30px',
        top: '0',
        bottom: '0'
      },
      dialogContainer: {
        margin: '0',
        alignItems: 'stretch',
        overflowY: 'hidden'
      },
      body: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        px: '20px',
        pt: '60px',
        pb: '20px',
        overflowY: 'auto'
      }
    },
    alt: {
      dialogContainer: {},
      dialog: {
        boxSizing: 'border-sizing',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: 0,
        color: 'white',
        bg: 'blue.400'
      },
      body: {
        p: '60px'
      }
    },
    'mobile-alt': {
      dialog: {
        minWidth: '100vw',
        width: '100%',
        marginTop: '0',
        marginBottom: '0',
        boxSizing: 'border-sizing',
        boxShadow: 'none',
        borderRadius: 0,
        color: 'white',
        bg: 'blue.400'
      },
      dialogContainer: {
        margin: '0',
        alignItems: 'stretch'
      },
      body: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: '60px 20px'
      }
    },
    blocker: {
      dialogContainer: {
        alignItems: 'flex-end'
      },
      dialog: {
        minWidth: '100vw',
        width: '100%',
        boxSizing: 'border-sizing',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: 0,
        color: 'black',
        bg: 'white',
        margin: '0'
      },
      body: {
        p: '60px'
      }
    },
    normal: {
      dialog: {
        boxSizing: 'border-sizing',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: 2,
        color: 'black',
        bg: 'white'
      },
      dialogContainer: {},
      body: {
        p: '60px'
      }
    },

    wide: {
      dialog: {
        boxSizing: 'border-sizing',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: 0,
        color: 'black',
        bg: 'white'
      }
    }
  },
  sizes: {
    sm: {},

    md: {},

    lg: {},

    xl: {},
    full: {}
  },
  defaultProps: {
    variant: 'default',
    size: 'xl'
  }
}

export default modal
