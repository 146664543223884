import React, { useState, useEffect, useCallback } from 'react'
import { useBreakpointValue } from '@chakra-ui/react'
import {
  Grid,
  GridItem,
  Flex,
  Box,
  Stack,
  Select,
  HStack,
  VStack,
  Input,
  Button,
  InputLeftAddon,
  InputGroup,
  useDisclosure,
  Spacer
} from '@chakra-ui/react'
import _ from 'lodash'
import { useModal } from '@ebay/nice-modal-react'
import { useRouter } from 'next/router'
import FieldInput from 'forms/FieldInput'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  checkIfTeamAccountIsAvaliable,
  createTeamAccount,
  isTeamAccountFound
} from '@/lib/teamAccounts'
import MODALS from '@/constants/modals'
import ROUTES from '@/constants/routes'
import { useFireAuth } from '@/lib/fireAuth'
import { FormFieldGroup } from '@/forms'

// import { SimpleModal } from '@/modals'
import { AlertBox } from '@/elements'
import { ErrorBox } from '@/components'
import AutoCompletePlaces from 'components/AutoCompletePlaces'
import INDUSTRYSTAGE from '@/constants/industry-stage'

const urlRegExp =
  /^(https?:\/\/)?([\da-z-]+\.)+([a-z]{2,6})?(\/([a-z0-9+\$_-]\.?)+)*\/?$/

const teamAccountSchema = yup.object().shape({
  company: yup.string().required('Please enter your business name'),
  domain: yup
    .string()
    .matches(urlRegExp, 'Please enter a valid website address')
    .required('Please enter a valid website address'),
  stage: yup.string().required('Please select a business stage'),
  industry: yup.string().required('Please select a business industry'),
  address1: yup.string().required('Please enter Address 1'),
  address2: yup.string(),
  city: yup.string().required('Please enter City'),
  state: yup.string().required('Please enter State'),
  zipcode: yup
    .string()
    .min(5, 'Please enter a valid ZIP Code')
    .max(5, 'Please enter a valid ZIP Code')
    .required('Please enter a valid ZIP Code')
})

const bizField = {
  businessName: '',
  domain: '',
  stage: '',
  industry: '',
  address1: '',
  address2: null,
  city: '',
  state: '',
  zipcode: ''
}
const formatArray = (array) =>
  array.reduce((obj, item) => {
    const mainType = item.types[0]
    obj[mainType] = item
    return obj
  }, {})
const SetUpTeam = ({ handleBackAction, handleNextAction }) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const [formDefault, setFormDefault] = useState()
  const [showErrorBox, setShowErrorBox] = useState(false)
  const [appError, setAppError] = useState()
  const [formReady, setFormReady] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [errorBox, setErrorBox] = useState()

  const { member, team } = useFireAuth()
  const modal = useModal(`${MODALS.SIMPLE_MODAL}`)
  //const modal = useModal(SimpleModal)

  const [locationReady, setLocationReady] = useState(false)

  var listStages = INDUSTRYSTAGE.STAGES
  var listIndustries = INDUSTRYSTAGE.INDUSTRIES

  useEffect(() => {
    const defaultVals = {
      ...bizField
    }
    setFormDefault(defaultVals)
    setFormReady(true)
  }, [])

  const {
    register,
    formState: { errors, isValid, isSubmitting, isDirty },
    setValue,
    handleSubmit,

    reset
  } = useForm({
    mode: 'onChange',
    // defaultValues: formDefault,
    resolver: yupResolver(teamAccountSchema)
  })
  const onResetForm = () => {
    reset()
    setShowErrorBox(false)
    setLocationReady(false)
  }

  const splitTheAddress = useCallback(
    (details) => {
      //console.log('details: ', details)

      const addressObj = formatArray(details.address_components)

      _.forEach(addressObj, function (value, key) {
        //console.log(`key ${key} value ${value.short_name}`)
      })

      const streetNumber = addressObj.street_number.short_name
      const streetRoute = addressObj.route.short_name
      const locality = _.get(addressObj, 'locality.short_name', null)
      const city = locality
        ? locality
        : _.get(addressObj, 'sublocality_level_1.short_name', '')

      const state = addressObj.administrative_area_level_1.short_name
      const zipcode = addressObj.postal_code.short_name
      const address1 = streetNumber + ' ' + streetRoute
      setValue('address1', address1)
      setValue('address2', '')
      setValue('city', city)
      setValue('state', state)
      setValue('zipcode', zipcode)
    },
    [setValue]
  )

  if (!formReady) {
    return <Box>form not ready</Box>
  }

  const submitTeamAccount = async (teamAccountData) => {
    //console.log(` teamAccountData=>${JSON.stringify(teamAccountData)}`)
    setIsWorking(true)
    // const { uid } = member
    const { company, domain } = teamAccountData
    //console.log(`domain == ${domain}`)

    const result = await checkIfTeamAccountIsAvaliable(domain)
    //console.log('resuyltttt', result)
    if (result.empty) {
      createTeamAccount({
        memberData: member,
        teamData: teamAccountData
      }).then(() => {
        handleNextAction()
      })
    } else {
      const code = '000'
      const type = 'html'
      const name = 'Website taken'
      const message = `<strong>${company}</strong> with the web address <strong>${domain} </strong> already exists in Elevate. Contact our customer service team to help sort this out. <a href=${ROUTES.CONTACT_US} target="_blank" rel="noopener noreferrer">CONTACT US</a>`
      //const errorBox = { code, type, name, message }

      setAppError({ type, code, name, message })
      setErrorBox({ type: 'error', msg: { __html: message } })
      setShowErrorBox(true)
      ////console.log('domainName:', domainMatch)
      // modal.show({ modalName: 'alreadyRegisteredBiz' })
    }
  }

  //console.log(`isValid ${isValid} isDirty  ${isDirty}`)
  return (
    <Grid
      gap="0"
      templateColumns={{
        base: '[out-l] 20px [inn-l] 1fr [inn-r] 20px [out-r]',
        md: `[inn-l] 1fr [inn-r]`
      }}
      templateRows="1fr"
      templateAreas={{ base: 'side formy side', md: 'form form form' }}
    >
      <GridItem
        colStart="inn-l"
        colEnd={'inn-r'}
        spacing="30px"
        onSubmit={handleSubmit(submitTeamAccount)}
        align="start"
        padding="0"
        width="100%"
      >
        <VStack align="start">
          <Box textStyle="head4">Create Account</Box>
          <Box textStyle="body2">
            Enter information below to register your business.
          </Box>
        </VStack>
        <VStack
          as="form"
          spacing="5"
          align="start"
          py={{ base: '5', md: '10' }}
        >
          <FieldInput
            name="company"
            label="Business Name"
            error={errors.company}
            w="100%"
          >
            <Input
              name="company"
              placeholder="Enter your business name"
              {...register('company')}
            />
          </FieldInput>

          <FieldInput
            name="domain"
            label="Business Domain"
            error={errors.domain}
          >
            <InputGroup>
              {!isMobile && <InputLeftAddon>www.</InputLeftAddon>}

              <Input
                id="domain"
                placeholder="yourdomain.com"
                {...register('domain')}
              />
            </InputGroup>
          </FieldInput>

          <FieldInput
            name="businessInfo"
            label={
              isMobile ? 'Business Info' : 'Business Stage/Business Industry'
            }
          >
            <Stack spacing="4" direction={{ base: 'column', md: 'row' }}>
              <Select
                name="stage"
                mt={0}
                {...register('stage')}
                errorBorderColor="red.500"
                placeholder="Select a stage"
                focusBorderColor="blue.400"
                variant="outline"
                sx={{ option: { bg: 'white.500' } }}
              >
                {listStages.map((item) => {
                  return (
                    <option key={item.value} value={`${item.value}`}>
                      {item.label}
                    </option>
                  )
                })}
              </Select>

              <Select
                name="industry"
                mt={2}
                {...register('industry')}
                errorBorderColor="red.500"
                placeholder="Select an industry"
                focusBorderColor="blue.400"
                variant="outline"
                // color="grey"
                _placeholder={{ color: 'red.500' }}
              >
                {listIndustries.map((item) => {
                  return (
                    <option key={item.value} value={`${item.value}`}>
                      {item.label}
                    </option>
                  )
                })}
              </Select>
            </Stack>
          </FieldInput>

          <FormFieldGroup
            label="Business Address"
            minChildWidth="360px"
            spacing="2"
          >
            {locationReady === false && (
              <AutoCompletePlaces
                currentLocation="Enter location"
                updateLocation={splitTheAddress}
                setLocationReady={setLocationReady}
              />
            )}
            {locationReady === true && (
              <FieldInput>
                <Stack direction={{ base: 'column', md: 'row' }}>
                  <FieldInput error={errors.address1}>
                    <Input
                      name="address1"
                      placeholder="Address 1"
                      {...register('address1')}
                    />
                  </FieldInput>
                  <FieldInput error={errors.address2}>
                    <Input
                      name="address2"
                      placeholder="Address 2 (Optional)"
                      {...register('address2')}
                    />
                  </FieldInput>
                </Stack>
                <HStack mt="2">
                  <FieldInput error={errors.city}>
                    <Input
                      name="city"
                      placeholder="City"
                      {...register('city')}
                    />
                  </FieldInput>
                  <FieldInput error={errors.state}>
                    <Input
                      placeholder="State"
                      name="state"
                      {...register('state')}
                    />
                  </FieldInput>
                  <FieldInput error={errors.zipcode}>
                    <Input
                      name="zipcode"
                      placeholder="ZIP Code"
                      {...register('zipcode')}
                    />
                  </FieldInput>
                </HStack>
              </FieldInput>
            )}
          </FormFieldGroup>

          {showErrorBox && appError && <ErrorBox error={appError} />}

          <Flex
            width="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            py="16"
          >
            <HStack spacing="4">
              {!showErrorBox ? (
                <Button
                  id="set-up-team-register"
                  variant="solid"
                  isLoading={isSubmitting}
                  loadingText="Submitting"
                  type="submit"
                  disabled={!isDirty || !isValid}
                >
                  Register
                </Button>
              ) : (
                <Button
                  id="set-up-team-try-again"
                  variant="solid"
                  isLoading={isSubmitting}
                  loadingText="Submitting"
                  onClick={onResetForm}
                  //type="submit"
                  disabled={!isDirty || !isValid}
                >
                  Try Again
                </Button>
              )}

              <Spacer />
            </HStack>
          </Flex>
        </VStack>
      </GridItem>
    </Grid>
  )
}

export default SetUpTeam
