import {
  Box,
  Flex,
  Grid,
  VStack,
  useBreakpointValue,
  calc
} from '@chakra-ui/react'
// import { SectionImage } from '@/elements'
import {
  HomeMostPopularWord,
  HomeMostPopularLineRight,
  HomeMostPopularLineLeft
} from '@/assets/svg'
import APPVARS from '@/constants/appvars'

import { HomeCard } from '@/components'
const PopularWrapper = ({ section, articles }) => {
  const ar = useBreakpointValue({ base: 16 / 9, sm: 16 / 9, md: 1, lg: 1 })
  ////console.log('section', section)
  return (
    <Box
      maxW={{
        base: APPVARS.BLOCK_MAX_WIDTH_BASE,
        lg: APPVARS.BLOCK_MAX_WIDTH_FULL
      }}
      mx={{ base: 'auto' }}
      w="100%"
      pt="60px"
      pb="40px"
      layerStyle={section?.layerStyle ? section.layerStyle : 'default'}
      className="HomeSectionPopular"
    >
      <VStack spacing="40px">
        <Grid
          width="100%"
          templateColumns={{
            base: '0 1fr 0 ',
            sm: 'auto 272px auto'
          }}
          templateRows={{ base: '40px' }}
          templateAreas={{
            base: `'popular-left popular popular-right'`
          }}
          columnGap={{ base: '0', sm: '20px', md: '50px' }}
          alignItems="center"
        >
          <Box gridArea="popular-left">
            <Box
              w="100%"
              height="5px"
              borderTop={'1px solid #949CAB'}
              borderBottom={'1px solid #949CAB'}
            />
          </Box>
          <Box
            w={{ base: '200', sm: '340' }}
            gridArea="popular"
            objectFit="contain"
          >
            <HomeMostPopularWord />
          </Box>
          <Box gridArea="popular-right">
            <Box
              w="100%"
              height="5px"
              borderTop={'1px solid #949CAB'}
              borderBottom={'1px solid #949CAB'}
            />
          </Box>
        </Grid>

        <Grid
          width="100%"
          templateColumns={{
            base: '1fr',
            md: '200px 200px 200px',
            lg: '268px 268px 268px'
          }}
          templateRows={{ base: 'auto', md: '1fr' }}
          templateAreas={{
            base: `'main'`,
            md: `'main main main'`
          }}
          justifyContent="space-between"
          rowGap="40px"
        >
          {articles.map((article) => (
            <HomeCard
              gridArea="main"
              key={article.id}
              type="main"
              ar={ar}
              article={article}
              headerStyle="head5"
            />
          ))}
        </Grid>
      </VStack>
    </Box>
  )
}

export default PopularWrapper
