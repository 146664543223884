export const breaks = {
  sm: '480px', // 30rem',
  md: '672px', //'42rem', //
  lg: '1024px', //'64rem' //
  xl: '1280px', //'80rem' //
  '2xl': '1536px' //96rem
}
export const defaultBreaksPoints = {
  sm: '480px', // 30rem',
  md: '768px', //'48rem', //
  lg: '992px', //'62rem' //
  xl: '1280px', //'80rem' //
  '2xl': '1536px' //96rem
}
export const container = {
  xs: '268px',
  sm: '440px',
  md: '632px',
  lg: '964px',
  xl: '1240px'
}
export const imageSizeArray = [16, 32, 48, 64, 96, 128, 212, 256]
export const deviceArray = [320, 480, 768, 1024, 1280, 1536]
export const largeSizes = {
  max: 'max-content',
  min: 'min-content',
  full: '100%',
  '3xs': '14rem',
  '2xs': '16rem',
  xs: '20rem',
  sm: '24rem',
  md: '28rem',
  lg: '32rem',
  xl: '40rem',
  xl_2: '42rem',
  xl_3: '51rem',
  xl_4: '56rem',
  xl_6: '64rem'
}
