// First combine the reducer
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { Provider, useSelector, useDispatch } from 'react-redux'
import NiceModal from '@ebay/nice-modal-react'

import logger from 'redux-logger'
import ModalLayer from 'modals/ModalLayer'

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose
const enhancer = composeEnhancers(applyMiddleware(logger))

const store = createStore(
  combineReducers({
    modals: NiceModal.reducer
    // other reducers...
  }),
  enhancer
)

// Passing Redux state to the nice modal provider
const ModalsProvider = ({ children }) => {
  const modals = useSelector((s) => s.modals)
  const dispatch = useDispatch()
  return (
    <NiceModal.Provider modals={modals} dispatch={dispatch}>
      <ModalLayer>{children}</ModalLayer>
    </NiceModal.Provider>
  )
}

export default function ModalsReduxProvider({ children }) {
  return (
    <Provider store={store}>
      <ModalsProvider>{children}</ModalsProvider>
    </Provider>
  )
}
