import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  HStack,
  VStack,
  Grid,
  GridItem,
  Text,
  Flex
} from '@chakra-ui/react'

import { useFireAuth } from '@/lib/fireAuth'
import { updateCashBackEstimate } from '@/lib/teamAccounts'
import { formatCurrency } from '@/utils/_formatCurrency'
import { sendEmailVerification2, fbauth } from '@/lib/firebase'
import Image from 'next/image'
import blueCheck from '@/assets/icons/bluecheckmark.svg'
import { TipPopper } from '@/elements'

const fundingSourceActive = false
const ConnectBankAccountButton = ({
  team,
  member,
  verifyEmailSent,
  setVerifyEmailSent,
  isConnected
}) => {
  const confirmConnectAccountEmail = () => {
    const emailVerified = member?.emailVerified
    if (emailVerified === true) {
      setVerifyEmailSent(true)
      window.open('/account/connect-bank-account', '_blank')
    } else {
      let user = member?.email
      setVerifyEmailSent(true)
      sendEmailVerification2(user)
    }
  }
  return (
    // email not sent for email verification and bank not connected
    !verifyEmailSent && !isConnected ? (
      <Button variant="solid" onClick={confirmConnectAccountEmail}>
        Connect My Bank Account
      </Button>
    ) : (
      // verify email sent but no bank account connected
      <HStack>
        <Button variant="solid" onClick={confirmConnectAccountEmail} disabled>
          Connect My Bank Account
        </Button>
        <Button variant="form-link" onClick={confirmConnectAccountEmail}>
          Resend Email
        </Button>
      </HStack>
    )
  )
}

const AccountConnectedUpdateBankButton = ({
  verifyEmailSent,
  setVerifyEmailSent,
  member
}) => {
  const updateBankAccount = () => {
    const emailVerified = fbauth.currentUser?.emailVerified

    if (emailVerified === true) {
      setVerifyEmailSent(true)
      window.open('/account/connect-bank-account', '_blank')
    } else {
      let user = member?.email
      setVerifyEmailSent(true)
      sendEmailVerification2(user)
    }
  }
  return (
    <HStack>
      <Flex w="100%">
        <Image alt="checkmark" src={blueCheck} h="24px" w="24px" />
        <Text textStyle="body2" w="100%" color="brand.primary" ml="8px" p="8px">
          Account Connected
        </Text>

        <Button onClick={updateBankAccount} ml="8px" variant="form-link">
          Update Bank Account
        </Button>
      </Flex>
    </HStack>
  )
}

const CashBackReport = ({
  cashBackBalance = 0,
  cashBackUpdateDate = 'No Record Found',
  payOutBalance = 0,
  payOutUpdateDate = 'No Record Found'
}) => {
  const { team, member } = useFireAuth()
  const [payoutMoney, setPayoutMoney] = useState(0)
  const [verifyEmailSent, setVerifyEmailSent] = useState(false)

  const isConnected = team?.fundingSources?.dwolla.isConnected

  useEffect(() => {
    console.log('TEAM', team)
    console.log('MEMBER', member)

    const teamId = _.get(team, 'teamAccountId', 0)
    const cashbackEstimate = _.get(team, 'cashBackEstimate', 0)
    const cbb = _.toNumber(cashBackBalance)
    const cbe = _.toNumber(cashbackEstimate)

    if (cbb > cbe) {
      updateCashBackEstimate({ teamAccountId: teamId, value: cbb })
    }
    setPayoutMoney(formatCurrency(cbb))
  }, [cashBackBalance, team, member])

  return (
    <Grid
      p="30px"
      flexGrow={1}
      templateColumns={{
        base: '1fr 20px 1fr',
        md: '1fr 1fr 1fr 1fr'
      }}
      columnGap="20px"
      templateRows={{
        base: '38px minmax(36px, auto) 120px 100px  50px',
        md: '30px 30px minmax(36px, 80px) 100px '
      }}
      templateAreas={{
        base: `'cb . cb_date' 'cb_result cb_result cb_result' 'cb_balance  cb_balance cb_balance' 'cb_payout cb_payout cb_payout'  'cb_cta cb_cta cb_cta'`,
        md: `'cb cb cb_balance cb_balance' 'cb_date cb_date cb_balance cb_balance' 'cb_result cb_result . .' 'cb_cta . cb_payout cb_payout'  'cb_cta . cb_payout cb_payout'`
      }}
      layerStyle="form-panel"
    >
      <GridItem gridArea="cb">
        <HStack spacing="10px" align="flex-start">
          <Box textStyle="head5" color="black2">
            Cash Back
          </Box>
          <Box>
            <TipPopper
              steps={['step1', 'step2', 'step3']}
              currentStep="step1"
              copy={`Check your updated Cash Back Balance earned through Elevate purchases.
            Redeem your Available Cash quarterly. Cash Back redemptions must be at least $10.00.`}
            />
          </Box>
        </HStack>
      </GridItem>
      <GridItem gridArea="cb_date" justifySelf={{ base: 'end', md: 'start' }}>
        <Box textStyle="body4" color="gray.300">
          Last updated on {cashBackUpdateDate}
        </Box>
      </GridItem>
      <GridItem gridArea="cb_result" justifySelf={{ base: 'start', md: 'end' }}>
        <Box color="blue.500" textStyle="body4" mt="12px" textAlign="left">
          We are currently working on this feature. Soon you will be able to
          connect your business bank account to receive Cash Back payouts.
        </Box>
      </GridItem>
      {!fundingSourceActive ? (
        <GridItem gridArea="cb_cta" alignSelf="end" justifySelf="stretch">
          <Button variant="solid" disabled>
            Connect My Bank Account
          </Button>
        </GridItem>
      ) : (
        <GridItem gridArea="cb_cta" alignSelf="end" justifySelf="stretch">
          {!isConnected ? (
            <ConnectBankAccountButton
              team={team}
              member={member}
              verifyEmailSent={verifyEmailSent}
              setVerifyEmailSent={setVerifyEmailSent}
              isConnected={isConnected}
            />
          ) : (
            <AccountConnectedUpdateBankButton
              team={team}
              member={member}
              verifyEmailSent={verifyEmailSent}
              setVerifyEmailSent={setVerifyEmailSent}
            />
          )}
        </GridItem>
      )}
      <VStack
        gridArea="cb_balance"
        alignSelf="center"
        align={{ base: 'center', md: 'end' }}
        justifySelf={{ base: 'center', md: 'end' }}
        spacing="2px"
      >
        <Box color="blue.500" textStyle="body5">
          CURRENT BALANCE:
        </Box>
        <Box textStyle="head2" color="blue.500">
          {payoutMoney}
        </Box>
      </VStack>
      <VStack
        spacing="2px"
        w="100%"
        align={{ base: 'center', md: 'end' }}
        gridArea="cb_payout"
        alignSelf="end"
        justifySelf={{ base: 'center', md: 'end' }}
      >
        <Box textStyle="body5" textAlign="right">
          EXPECTED PAYOUT:
        </Box>
        <Box textStyle="activity-tbd" color="black2">
          TBD
        </Box>

        <Box textStyle="body4" color="gray.300">
          {/* Updated on {payOutUpdateDate} */}
          Payout comes quarterly.
        </Box>
      </VStack>
    </Grid>
  )
}

export default CashBackReport
