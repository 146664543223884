import React from 'react'
import { Box, Flex, useMediaQuery } from '@chakra-ui/react'
import { TopBarLogo } from '@/elements'
import { NavBar } from '@/components'
import { motion } from 'framer-motion'
import APPVARS from '@/constants/appvars'

const TopBarContainer = ({ children, navType, ...props }) => {
  return (
    <Box
      className={`TOP_BAR_CONTAINER-${navType}`}
      as="header"
      pos="fixed"
      top="0"
      left="0"
      right="0"
      w="100vw"
      h={APPVARS.NAV_BAR_HEIGHT}
      zIndex="999"
      align="center"
      bg="white"
      boxShadow="base"
      {...props}
    >
      <Flex
        className="TOP_BAR_CONTAINER_FLEX"
        justify="space-between"
        w="100%"
        maxWidth="964px"
        mx="auto"
        bg="pink"
      >
        {children}
      </Flex>
    </Box>
  )
}

const MotionFlex = motion(Flex)
const MotionBox = motion(Box)
const variantLogo = {
  start: { opacity: 0 },
  end: { opacity: 1 }
}
const HomeBarContainer = ({ animToggle, children }) => {
  const [isBig] = useMediaQuery('(min-width: 672px)')

  return (
    <Box
      className="SITE_HOME_BAR_CONTAINER"
      as="header"
      pos="fixed"
      top="0"
      w="100%"
      h={APPVARS.NAV_BAR_HEIGHT}
      zIndex="999"
      align="center"
      bg="white"
      boxShadow="base"
    >
      <MotionBox
        position="absolute"
        left="24px"
        top="0"
        initial="start"
        variants={variantLogo}
        animate={animToggle ? 'end' : 'start'}
      >
        <TopBarLogo logoLink="/home" />
      </MotionBox>
      {isBig ? (
        <MotionFlex
          h={APPVARS.NAV_BAR_HEIGHT}
          py="6"
          px="6"
          justifyContent={!animToggle ? 'center' : 'flex-end'}
          layout
        >
          {children}
        </MotionFlex>
      ) : (
        <MotionFlex
          h={APPVARS.NAV_BAR_HEIGHT}
          py="6"
          px="6"
          justifyContent={'flex-end'}
          layout
        >
          {children}
        </MotionFlex>
      )}
    </Box>
  )
}

const SiteBar = ({ navType, activeSection }) => {
  return (
    <Box
      className="SITE_BAR_CONTAINER"
      as="header"
      pos="fixed"
      top="0"
      w="100%"
      h={APPVARS.NAV_BAR_HEIGHT}
      zIndex="999"
      align="center"
      bg="white"
      boxShadow="base"
    >
      <Flex
        className="SITE_BAR_CONTAINER_FLEX"
        maxW={{
          base: APPVARS.BLOCK_MAX_WIDTH_BASE,
          lg: APPVARS.BLOCK_MAX_WIDTH_FULL
        }}
        mx={{ base: 'auto' }}
        w="100%"
        h="100%"
        justify="space-between"
      >
        <TopBarLogo pos="relative" logoLink="/home" />
        <Box h={APPVARS.NAV_BAR_HEIGHT} py="6">
          <NavBar id="NavBar" forModal={false} />
        </Box>
      </Flex>
    </Box>
  )
}

const SiteBar1 = ({ navType, activeSection }) => {
  const animToggle = activeSection < 2 ? false : true
  if (navType === 'landing' || navType === 'public') {
    return (
      <TopBarContainer navType={navType}>
        <TopBarLogo logoLink="" />
      </TopBarContainer>
    )
  }
  if (navType === 'onboarding') {
    return (
      <TopBarContainer navType={navType}>
        <TopBarLogo logoLink="/home" />
      </TopBarContainer>
    )
  }
  if (navType === 'home') {
    return (
      <HomeBarContainer animToggle={animToggle}>
        <NavBar id="NavBar" forModal={false} />
      </HomeBarContainer>
    )
  }

  return (
    <TopBarContainer
      navType={navType}
      justifyContent="flex-end"
      alignItems="center"
    >
      <TopBarLogo logoLink="/home" />
      <Box h={APPVARS.NAV_BAR_HEIGHT} py="6" px="6" justifyContent="flex-end">
        <NavBar id="NavBar" forModal={false} />
      </Box>
    </TopBarContainer>
  )
}

export default SiteBar
