import React from 'react'
import { Box, GridItem, Grid, Button, VStack } from '@chakra-ui/react'
import Link from 'next/link'
import Image from 'next/image'
import { addProductClick } from '@/lib/teamAccounts'
import { useRouter } from 'next/router'
import { useModal } from '@ebay/nice-modal-react'
import MODALS from '@/constants/modals'
import ROUTES from '@/constants/routes'
import { useFireAuth } from '@/lib/fireAuth'
import { formatCurrency } from '@/utils/_formatCurrency'
const ProductCardNew = ({
  productSlug,
  productName,
  productHeadline,
  productSubheadline,

  cloudImage,
  price,
  unit,
  productImage,
  productCtaLink,
  productCtaLabel,
  colSpan = 1
}) => {
  const { team } = useFireAuth()
  // border="1px solid #EEF1F4"
  // const { team } = useFireAuth()
  const router = useRouter()

  const modal = useModal(`${MODALS.BLOCK_PURCHASE_MODAL}`)
  // const isMobile = useBreakpointValue({ base: true, sm: false })
  const handleBuyButtonClick = async ({
    productSlug,
    productName,
    price,
    unit,
    productCtaLink
  }) => {
    const connectedAccounts = _.get(team, 'connectedAccounts', [])
    if (connectedAccounts.length > 0) {
      await addProductClick({ teamData: team, productData: product }).then(
        () => {
          window.open(productCtaLink, '_blank')
        }
      )
    } else {
      modal.show({ variant: 'medium', hasExit: true }).then((path) => {
        router.push(path)
      })
    }
  }
  return (
    <GridItem rowSpan={1} colSpan={colSpan} display="flex">
      <VStack
        bg="white"
        width="100%"
        align="stretch"
        border="1px solid #EEF1F4"
        borderRadius="8px"
        height="100%"
        overflow="hidden"
        spacing={4}
      >
        {productImage && (
          <Box
            position="relative"
            height="160px"
            width="auto"
            maxWidth="400px"
            overflow="hidden"
          >
            <Image
              src={productImage.url}
              alt={productImage.imageName}
              layout="fill"
              objectFit="cover"
            />
          </Box>
        )}
        {cloudImage && (
          <Box
            position="relative"
            height="160px"
            width="auto"
            maxWidth="400px"
            overflow="hidden"
          >
            <Image
              src={cloudImage.url}
              alt="product"
              width={190}
              height={119}
            />
          </Box>
        )}
        <Box flex="1 1 0" textAlign="left" px={2}>
          <Box textStyle="subtitle2" color="black">
            {productName}
          </Box>

          <Box textStyle="price2" color="black">
            {formatCurrency(price)}
          </Box>
        </Box>
        <Box px={2} pb={4}>
          <Button
            variant="outline"
            onClick={() =>
              handleBuyButtonClick({
                productSlug,
                productName,
                price,
                unit,
                productCtaLink
              })
            }
          >
            {productCtaLabel ? productCtaLabel : 'BUY NOW'}
          </Button>
        </Box>
      </VStack>
    </GridItem>
  )
}

export default ProductCardNew

// <Link
// href={productLink ? productLink : 'http://officedepot.com'}
// target="_blank"
// passHref
// >
// <Button variant="outline">
//   {productCtaLabel ? productCtaLabel : 'Buy Now'}
// </Button>
// </Link>
