import React, { useState } from 'react'
import _ from 'lodash'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody
} from '@chakra-ui/react'
import { MDXRemote } from 'next-mdx-remote'
import NiceModal, {
  useModal,
  show,
  create,
  remove,
  register
} from '@ebay/nice-modal-react'
import { Box, VStack, Stack, Button } from '@chakra-ui/react'

function createMarkup(someHtml) {
  return { __html: someHtml }
}
const Body1 = (props) => {
  return <Box textStyle="body1" size="md2" {...props} />
}
const Body2 = (props) => {
  return <Box textStyle="body2" {...props} />
}
const components = {
  p: Body1
}

export default NiceModal.create(
  ({
    id,
    modalCopy,
    modalHeadline,
    modalSubheadline,
    modalRichContent,
    modalVariant,
    modalSize,
    hasExit
  }) => {
    // const [isOpenS, setIsOpen] = useState(true)
    const modal = useModal()
    const containerSize = modalSize
      ? modalSize.length === 2
        ? `container.${modalSize}`
        : modalSize
      : 'full'
    //console.log('containerSize', containerSize)
    const bvar = modalVariant ? _.toLower(modalVariant) : 'wide'

    return (
      <Modal
        size={containerSize}
        variant={bvar}
        visible={modal.visible}
        isOpen={() => modal.show()}
        onClose={() => modal.remove()}
      >
        <ModalOverlay />
        <ModalContent>
          {hasExit && <ModalCloseButton onClick={() => modal.hide()} />}
          <ModalBody>
            <VStack spacing="5" px="5">
              {modalHeadline && (
                <Box as="h3" textStyle="head3">
                  <MDXRemote {...modalHeadline.mdx} />
                </Box>
              )}
              {modalSubheadline && (
                <Box as="h4" textStyle="head4">
                  <MDXRemote {...modalSubheadline.mdx} />
                </Box>
              )}
              {modalCopy && (
                <MDXRemote {...modalCopy.mdx} components={components} />
              )}
              {modalRichContent && (
                <Box
                  dangerouslySetInnerHTML={createMarkup(modalRichContent.html)}
                />
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
)

// {modalHeadline && (
//   <Box as="h3" textStyle="head3">
//     <MDXRemote {....modalHeadline.mdx} />
//   </Box>
// )}
