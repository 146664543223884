import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Button,
  HStack,
  VStack
} from '@chakra-ui/react'
import NiceModal, {
  useModal,
  show,
  create,
  remove,
  register
} from '@ebay/nice-modal-react'
import ROUTES from '@/constants/routes'
import LinkExternal from 'elements/LinkExternal'

export default NiceModal.create(
  ({ name, variant, hasExit, onAction, onCancel }) => {
    const modal = useModal()

    return (
      <Modal
        variant={variant}
        isOpen={() => modal.show()}
        onClose={() => modal.remove()}
      >
        <ModalOverlay />
        <ModalContent>
          {hasExit ? <ModalCloseButton onClick={() => modal.hide()} /> : ''}
          <ModalBody>
            <VStack spacing="10">
              <Box>
                <Box textStyle="head3">Delete Business Account?</Box>
                <Box mt="10px">
                  The account admin must submit a request with our customer
                  service team. Account deletion can take up to 14 business
                  days. Click Contact Us to connect to customer service.
                </Box>
              </Box>

              <HStack spacing="28px">
                <Button variant="outline" onClick={() => modal.remove()}>
                  Cancel
                </Button>
                <LinkExternal variant="solid" href={ROUTES.CONTACT_US_FORM}>
                  Contact Us
                </LinkExternal>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
)
