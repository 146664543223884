import React, { useRef } from 'react'
import Image from 'next/image'
import { Loader } from '@googlemaps/js-api-loader'
import googleLogo from '@/assets/imgs/powered_by_google_on_white_hdpi.png'

import {
  Box,
  Input,
  useOutsideClick,
  ListItem,
  UnorderedList
} from '@chakra-ui/react'

import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'

const AutoCompletePlaces = (props) => {
  const [isReady, setIsReady] = React.useState(false)

  const loader = new Loader({
    apiKey: `${process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY}`,
    version: 'weekly',
    libraries: ['places']
  })
  loader
    .load()
    .then(() => {
      setIsReady(true)
    })
    .catch((e) => {
      // do something
    })
  if (!isReady) {
    return <p>loading...</p>
  }

  return <AutoPlaces {...props} />
}

const AutoPlaces = ({ currentLocation, updateLocation, setLocationReady }) => {
  const ref = useRef()
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'us' },

      types: ['address']
    },
    // initOnMount: false,
    debounce: 300,
    cache: 24 * 60 * 60
  })

  useOutsideClick({
    ref: ref,
    handler: () => clearSuggestions()
  })
  const handleLocationInput = (e) => {
    setValue(e.target.value)
  }

  const handleLocationSelect = (suggestion) => async () => {
    //console.log('description', suggestion)
    const parameter = {
      placeId: suggestion.place_id,
      fields: ['address_component', 'formatted_address']
    }
    try {
      const details = await getDetails(parameter)
      updateLocation(details)
      setValue(suggestion.description, false)
      setLocationReady(true)
      clearSuggestions()
    } catch (error) {
      //console.log('error', error)
    }
  }

  const renderLocationSuggestions = () =>
    data.map((suggestion) => {
      const locationRegExp = /^[0-9]+$/
      return (
        <ListItem
          key={suggestion.place_id}
          onClick={handleLocationSelect(suggestion)}
        >
          {locationRegExp.test(suggestion.description.charAt(0)) === true &&
            suggestion.description}
        </ListItem>
      )
    })
  return (
    <Box ref={ref}>
      <Input
        name="location"
        id="location"
        placeholder={currentLocation}
        value={value}
        onChange={handleLocationInput}
        autoComplete="off"
      />
      {status === 'OK' && (
        <Box>
          <UnorderedList>{renderLocationSuggestions()}</UnorderedList>
          <Box w="144px" h="18px" textAlign="right">
            <Image src={googleLogo} alt="google logo" />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default AutoCompletePlaces
