import React, { useState, useEffect } from 'react'
import { Box, HStack, VStack, Button } from '@chakra-ui/react'
import {
  removeConnectedAccount,
  deleteOfficeDepotVendorId,
  updateVendorAccount
} from '@/lib/teamAccounts'

const DisconnectOfficeDepotAccount = ({
  handleCancelAction,
  teamAccountId,
  account,
  vendorDescription
}) => {
  const [isWorking, setIsWorking] = useState(false)

  const { connectedAccountId } = account
  const handleRemove = async () => {
    setIsWorking(true)
    await deleteVendorAccount().catch((err) => {
      console.log('error removing vendor API', err)
    })
    await removeAccount()
      .then(() => {
        setIsWorking(false)
        handleCancelAction()
        //console.log("OD vendor account id removed")
      })
      .catch((err) => {
        setIsWorking(false)
        //console.log('Error removing account', err)
      })
  }
  const removeAccount = async () => {
    return await removeConnectedAccount({
      teamAccountId,
      accountData: account
    })
  }

  const deleteVendorAccount = async () => {
    //console.log("connectedAccountId", connectedAccountId)
    return deleteOfficeDepotVendorId({ connectedAccountId })
  }

  return (
    <Box>
      <Box textStyle="head3">Disconnect Account?</Box>
      <Box mt={2}>
        This will end future cash back and savings with {vendorDescription}.
      </Box>
      <VStack mt={8}>
        <HStack>
          <Button
            disabled={isWorking}
            variant="outline"
            onClick={handleCancelAction}
          >
            {' '}
            Cancel{' '}
          </Button>
          <Button isLoading={isWorking} variant="solid" onClick={handleRemove}>
            Yes, Disconnect
          </Button>
        </HStack>
      </VStack>
    </Box>
  )
}

export default DisconnectOfficeDepotAccount
