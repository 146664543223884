import React, { useState } from 'react'
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  VStack,
  Button
} from '@chakra-ui/react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import router from 'next/router'
import ROUTES from '@/constants/routes'
import { SpecialBonusCard } from '@/components'

export default NiceModal.create(({ id }) => {
  const [visibleState] = useState(true)

  const modal = useModal()

  return (
    <Modal variant={innerVariant} isOpen={visibleState} onClose={modal.remove}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton onClick={modal.hide} />
        <ModalBody>
          <Box overflowY="auto" p="4">
            <VStack spacing="10" pb="10">
              <Box>
                <Box textStyle="head3">Congrats! You unlocked $50! </Box>
                <Box textStyle="body1" mt="10px">
                  Thank you for joining Elevate Early Access. You’re all set to
                  start shopping for more deals to add to your Cash Back
                  balance. But first, click Claim It to get your $50 bonus.
                </Box>
              </Box>

              <Box layerStyle="claim-panel" w="100%">
                {id && <SpecialBonusCard />}
              </Box>

              <Button variant="solid" onClick={() => router.push(ROUTES.HOME)}>
                Start Shopping
              </Button>
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
})
