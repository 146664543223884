import _ from 'lodash'
import dayjs from 'dayjs'

export const formatDateNormal = ({ value, format = 'MM/DD/YYYY' }) => {
  console.log('value', value)
  const isFsDate = _.has(value, 'nanoseconds')
  //console.log('isFsDate', isFsDate)
  if (isFsDate) {
    return dayjs(value.toMillis()).format(format)
  }
  if (!value) {
    return 'x/xx/xxxx'
  }

  if (_.isString(value)) {
    //console.log('val2', _.isString(value))
    return dayjs(value).format(format)
  }
  return dayjs(value).format(format)
  // return dayjs(value.toMillis()).format('MM/DD/YYYY')
}
