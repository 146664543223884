import { Grid, Box } from '@chakra-ui/react'
import * as Columns from '@/columns'
import { RichText } from '@graphcms/rich-text-react-renderer'
//import { getGridSpacing } from '@/helpers'
import { MDXRemote } from 'next-mdx-remote'
import APPVARS from '@/constants/appvars'

const mdText = {
  h3: (props) => (
    <Box textStyle="head3" w="100%" textAlign="center" {...props} />
  ),
  p: (props) => (
    <Box mt="5" textStyle="body2" w="100%" textAlign="center" {...props} />
  )
}

function createMarkup(someHtml) {
  return { __html: someHtml }
}

export default function InfoCardBlockResponsive({
  columnComponent,
  columns,
  gridTag,
  gridName,
  gridGap,
  blockWidth = 'lg',
  cols = 1,
  spacing = '28',
  gridTextAlign,
  gridRichText,
  gridMdText,
  gridSpacing = 'md'
}) {
  const maxBlockWidth = APPVARS.MAX_BLOCK_WIDTH //'964px' //getBlockWidth(blockWidth)//
  return (
    <Box
      py="20"
      className={`INFO_CARD_BLOCK_RESP${gridName}`}
      overflow="hidden"
      width="100%"
      mx="auto"
    >
      {gridRichText && (
        <Box mb="0" textAlign={gridTextAlign}>
          <RichText
            content={gridRichText.json}
            renderers={{
              h3: ({ children }) => <Box textStyle="head3">{children}</Box>,
              bold: ({ children }) => <strong>{children}</strong>
            }}
          />
        </Box>
      )}
      {gridMdText && (
        <Box>
          <MDXRemote {...gridMdText.mdx} components={mdText} />
        </Box>
      )}
      <Grid
        className="INFO_CARD_BLOCK_RESP"
        w="100%"
        pt="60px"
        spacing="6"
        alignContent="center"
        maxWidth={maxBlockWidth}
        justifyItems="center"
        templateColumns={{
          base: '1fr',
          md: `repeat(${cols}, 1fr)`
        }}
      >
        {columns.map((column) => {
          const Component =
            Columns[columnComponent] || Columns[column.__typename]

          if (!Component) return null

          return <Component key={column.id} cols={cols} {...column} />
        })}
      </Grid>
    </Box>
  )
}
