import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import _ from 'lodash'
import uniqueId from 'lodash/uniqueId'
import { IoCloseSharp } from 'react-icons/io5'

import { AiOutlinePlusCircle } from 'react-icons/ai'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  IconButton,
  SimpleGrid,
  Box,
  Grid,
  Divider,
  GridItem,
  Spacer,
  Stack,
  VStack,
  HStack,
  Button as ChakraButton,
  Input,
  Flex,
  Text
} from '@chakra-ui/react'
import { useFieldArray, useForm, Controller } from 'react-hook-form'
import { inviteTeamMembers, updateTeamMembers } from '@/lib/teamAccounts'
import { useFireAuth } from '@/lib/fireAuth'

const defaultTeamMember = {
  email: '',
  firstName: '',
  lastName: ''
}
const TeamMemberSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required()
})
const OwnerTeamMemberForm = ({ memberData }) => {
  return (
    <Box w="100%">
      <Grid
        w="100%"
        h="24px"
        className="gridhead"
        justifyItems="start"
        templateColumns="200px 8px 200px 8px 1fr"
        templateRows="1fr"
      >
        <GridItem justifyContent="flex-start" colStart={1} colEnd={2}>
          <Box px="2" textStyle="btn-label1">
            First Name
          </Box>
        </GridItem>
        <GridItem colStart={3} colEnd={4}>
          <Box px="2" textStyle="btn-label1">
            Last Name
          </Box>
        </GridItem>
        <GridItem colStart={5} colEnd={6}>
          <Box px="2" textStyle="btn-label1">
            Email
          </Box>
        </GridItem>
      </Grid>
    </Box>
  )
}

const AddTeamMemberForm = ({ onAction }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(TeamMemberSchema)
  })
  const addTeamMemberAction = (data) => {
    //console.log(`data`, data)
    onAction({ action: 'add', data, teamIndex: null })
    reset()
  }

  return (
    <Box
      w="100%"
      as="form"
      autoComplete="off"
      onSubmit={handleSubmit(addTeamMemberAction)}
    >
      <Grid
        templateColumns="200px 8px 200px 8px 1fr"
        templateRows="40px"
        marginBottom="2"
        className="gridhRow1"
        justifyItems="start"
      >
        <GridItem w="100%" colStart={1} colEnd={2}>
          <Input
            w="100%"
            variant="outline"
            type="text"
            {...register('firstName')}
            placeholder="First Name"
            autoComplete="off"
          />
          {errors.firstName && <p>{errors.firstName.message}</p>}
        </GridItem>
        <GridItem w="100%" colStart={3} colEnd={4}>
          <Input
            variant="outline"
            type="text"
            autoComplete="off"
            {...register('lastName')}
            placeholder="Last Name"
          />
          {errors.lastName && <p>{errors.lastName.message}</p>}
        </GridItem>
        <GridItem w="100%" colStart={5} colEnd={6}>
          <Input
            variant="outline"
            type="email"
            autoComplete="off"
            {...register('email')}
            placeholder="Email"
          />
          {errors?.email && <p>{errors.email.message}</p>}
        </GridItem>
      </Grid>
      <Flex w="100%" my="8" justifyContent="center" alignItems="center">
        <ChakraButton
          leftIcon={<AiOutlinePlusCircle size="24px" />}
          variant="simple-icon"
          disabled={!isDirty || !isValid}
          type="submit"
        >
          Add to List
        </ChakraButton>
      </Flex>
    </Box>
  )
}
const EditTeamMemberForm = ({ teamIndex, memberData, onAction }) => {
  ////console.log('memberData', memberData)
  return (
    <Box padding="8px 6px 8px 10px" bg="#FAFAFA">
      <Flex justify="space-between" alignItems="flex-start" w="100%">
        <Box w="100%" textStyle="body2" size="md">
          {memberData.firstName} {memberData.lastName}
        </Box>
        {memberData.role !== 'owner' && (
          <IconButton
            p="0"
            height="26px"
            w="26px"
            variant="simple-icon"
            icon={<IoCloseSharp size="24px" />}
            onClick={onAction}
          />
        )}
      </Flex>
      <Box textStyle="body4" color="grey.300">
        {memberData.email}
      </Box>
    </Box>
  )
}

const AddTeamMember = ({
  handleNextAction,
  handleSkipAction,
  hasSkipBtn = false,
  onboarding = false
}) => {
  const { member, team, status } = useFireAuth()
  const [isReady, setIsReady] = useState(false)
  const [ownerData, setOwnerData] = useState()
  const [originalTeam, setOriginalTeam] = useState()
  const [currentTeamArray, setCurrentTeamArray] = useState([])
  const [newTeamArray, setNewTeamArray] = useState([])
  // const [hasInvitedMembersChange] = useState(false)
  useEffect(() => {
    if (status === 'loggedIn' && team?.teamMembers) {
      const ta = [...team.teamMembers]
      setOriginalTeam(ta)
      const ownerIndex = _.findIndex(ta, function (o) {
        return o.role == 'owner'
      })
      const ownerData = ta[ownerIndex]
      //console.log('owner', ownerData)
      const notowners = ta
        .filter(function (e) {
          return e.role !== 'owner'
        })
        .map((mem) => {
          return { ...mem, uid: mem.uid ? mem.uid : `team-${uniqueId()}` }
        })
      setOwnerData(ownerData)

      setCurrentTeamArray(notowners)

      setIsReady(true)
    }
  }, [status, team])

  const handleOnAction = ({ action, data, uid }) => {
    const uArray = [...newTeamArray]
    const arrayLength = uArray.length
    if (action === 'add') {
      const arrayLength = uArray.length
      //console.log(`data=> ${JSON.stringify(data)} arrayLength =${arrayLength}`)
      const teamMember = {
        uid: `team-${uniqueId()}`,
        ...data
      }
      setNewTeamArray((uArray) => [...uArray, teamMember])
    }
    //console.log('arrayLength', arrayLength)
    if (action === 'remove') {
      const changeArray = _.remove(uArray, function (n) {
        return n.uid === uid
      })
      uArray = uArray.splice(changeArray.uid)
      //console.log('changeArraylength', uArray.length)
      //console.log('changeArray', uArray)
      // newArry.splice(findx, 1, data)
      setNewTeamArray(() => [...uArray])
    }
  }
  const removeTeammember = (uid) => {
    const uArray = [...newTeamArray]
    const changeArray = _.remove(uArray, function (n) {
      return n.uid === uid
    })
    const updatedTeamArray = uArray.splice(changeArray.uid)
    //console.log('changeArraylength', updatedTeamArray.length)
    //console.log('changeArray', updatedTeamArray)
    // newArry.splice(findx, 1, data)
    setNewTeamArray(updatedTeamArray)
  }

  const sendInvites = async () => {
    const accid = member.teamAccountId
    //console.log(`accid=> ${accid}`)

    try {
      await inviteTeamMembers({
        teamAccountId: accid,
        teamOriginalArray: originalTeam,
        teamNewArray: newTeamArray
      }).then(() => {
        //console.log(`_______`)
        handleNextAction()
      })
    } catch (error) {}
  }

  const updateInvites = async () => {
    const accid = member.teamAccountId
    //console.log(`accid=> ${accid}`)
    try {
      await updateTeamMembers({
        teamAccountId: accid,
        teamMemberArray: invitedMembersArray
      })
    } catch (error) {}
  }

  if (!isReady) {
    return <div>Form not Ready</div>
  }
  return (
    <VStack w="100%" spacing="5" justifyContent="center" alignItems="center">
      {/* <Box textStyle="head5">Email Addresses</Box> */}
      {ownerData && <OwnerTeamMemberForm memberData={ownerData} />}
      <AddTeamMemberForm onAction={handleOnAction} />
      <SimpleGrid w="100%" minChildWidth="370px" spacing="3">
        {newTeamArray.length > 0 &&
          newTeamArray.map((item, index) => (
            <EditTeamMemberForm
              key={item.uid}
              teamIndex={item.email}
              memberData={item}
              onAction={() => removeTeammember(item.uid)}
            />
          ))}
      </SimpleGrid>
      <Flex
        width="100%"
        direction="row"
        justifyContent="center"
        alignItems="center"
        py="14"
      >
        <VStack spacing="4">
          <ChakraButton
            onClick={sendInvites}
            disabled={newTeamArray.length < 1}
          >
            Send Invites
          </ChakraButton>
          <Spacer />
          {hasSkipBtn && (
            <ChakraButton variant="form-link" onClick={handleNextAction}>
              SKIP FOR NOW
            </ChakraButton>
          )}
        </VStack>
      </Flex>
    </VStack>
  )
}

export default AddTeamMember
