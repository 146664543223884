import * as React from 'react'

function SvgLogoODMax(props) {
  return (
    <svg
      width="120"
      height="28"
      viewBox="0 0 120 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2173_7145)">
        <path
          d="M20.339 2.04904C19.9085 1.96454 19.52 1.91173 19.184 1.91173C18.302 1.91173 17.798 2.26028 17.798 3.07356V3.76009H19.835V5.58733H17.798V9.74877C18.365 9.80158 18.974 9.86496 19.478 9.94945V11.4809H13.178V9.93889C13.682 9.86496 14.291 9.79102 14.8685 9.73821V5.57676H13.0625V3.76009H14.8685V3.10524C14.8685 1.04564 16.097 0 18.8795 0C19.394 0 19.94 0.0316862 20.5175 0.126745L20.339 2.04904Z"
          fill="#B31917"
        />
        <path
          d="M27.7838 2.04904C27.3428 1.96454 26.9438 1.91173 26.6183 1.91173C25.7258 1.91173 25.2323 2.26028 25.2323 3.07356V3.76009H27.2483V5.58733H25.2323V9.74877C25.7993 9.80158 26.4083 9.86496 26.9123 9.94945V11.4809H20.6228V9.93889C21.1373 9.86496 21.7148 9.79102 22.3028 9.73821V5.57676H20.4863V3.76009H22.3028V3.10524C22.3028 1.04564 23.5418 0 26.3138 0C26.8283 0 27.3743 0.0316862 27.9518 0.126745L27.7838 2.04904Z"
          fill="#B31917"
        />
        <path
          d="M31.0797 2.95733C30.1032 2.95733 29.2842 2.33417 29.2842 1.56314C29.2842 0.781544 30.0927 0.168945 31.0797 0.168945C32.0772 0.168945 32.8752 0.781544 32.8752 1.56314C32.8752 2.3236 32.0772 2.95733 31.0797 2.95733ZM27.9297 11.4809V9.93884C28.4337 9.86491 29.0322 9.79098 29.5992 9.73817V5.55559C29.1477 5.49222 28.4862 5.42885 27.9297 5.40772V3.88679C29.3262 3.74948 30.9222 3.69667 32.5497 3.74948V9.73817C33.0852 9.79098 33.6522 9.85435 34.0932 9.93884V11.4703L27.9297 11.4809Z"
          fill="#B31917"
        />
        <path
          d="M38.7775 11.6287C35.6905 11.6287 34 10.3613 34 7.85807C34 5.53442 35.533 3.60156 39.1975 3.60156C39.9115 3.60156 40.7095 3.69662 41.4025 3.85505V6.49557H39.5125C39.439 6.1787 39.3865 5.89353 39.334 5.62948C39.187 5.57667 38.998 5.57667 38.809 5.57667C37.7905 5.57667 37.0555 6.30545 37.0555 7.57289C37.0555 8.89315 37.717 9.57968 39.439 9.57968C40.0375 9.57968 40.72 9.47406 41.2765 9.27339L41.476 11.3224C40.6045 11.5231 39.8065 11.6287 38.7775 11.6287Z"
          fill="#B31917"
        />
        <path
          d="M46.5887 5.302C45.7487 5.302 45.1292 5.8301 45.0557 6.62226H47.9537C47.9852 5.76673 47.4392 5.302 46.5887 5.302ZM50.7467 8.196H45.0242C45.0557 9.23108 45.8117 9.812 47.3972 9.812C48.3422 9.812 49.3292 9.65357 50.2217 9.32614L50.4842 11.1217C49.4027 11.4808 48.1112 11.6604 46.9352 11.6604C43.7747 11.6604 42.0527 10.4035 42.0527 7.69959C42.0527 5.33369 43.6907 3.56982 46.6622 3.56982C49.5602 3.56982 50.8307 5.14357 50.8307 7.17148C50.8307 7.49891 50.7992 7.82633 50.7467 8.196Z"
          fill="#B31917"
        />
        <path
          d="M3.381 5.95716C3.381 7.77383 4.494 9.16802 6.3315 9.16802C8.1585 9.16802 9.282 7.77383 9.282 5.95716C9.282 4.26723 8.253 2.70405 6.3315 2.70405C4.41 2.71461 3.381 4.27779 3.381 5.95716ZM12.663 5.97828C12.663 9.49544 9.828 11.5973 6.3315 11.5973C2.8455 11.5973 0 9.49544 0 5.97828C0 2.53505 2.856 0.295898 6.3315 0.295898C9.807 0.295898 12.663 2.53505 12.663 5.97828Z"
          fill="#B31917"
        />
        <path
          d="M58.8005 5.36545C58.4435 5.36545 58.538 5.36545 58.3175 5.39714V9.81208C58.4435 9.8332 58.37 9.8332 58.7375 9.8332C60.1025 9.8332 61.3415 9.04105 61.3415 7.59405C61.3415 6.40053 60.848 5.36545 58.8005 5.36545ZM58.349 11.5443C57.1625 11.5443 55.829 11.4809 55.4195 11.4809H54.002V10.224C54.4325 10.1395 54.905 10.0867 55.4195 10.0656V5.1859C54.905 5.17534 54.4325 5.12253 54.002 5.06972V3.74946H55.3355C56.249 3.74946 57.2045 3.71777 58.832 3.71777C62.0975 3.71777 64.376 4.64723 64.376 7.43562C64.376 10.0656 61.9715 11.5443 58.349 11.5443Z"
          fill="#B31917"
        />
        <path
          d="M64.7852 11.4704V10.2135C65.2052 10.129 65.6882 10.0762 66.1817 10.0551V5.18595C65.6777 5.17539 65.2052 5.12258 64.7852 5.06977V3.74951H73.3637V5.86192H71.1062C71.0432 5.67181 71.0222 5.49225 71.0012 5.32326L69.1007 5.3127V6.85476H70.8542C70.8962 6.70689 70.8962 6.62239 70.9592 6.48508H72.6287V8.57637H70.9592C70.8962 8.44962 70.8857 8.38625 70.8542 8.24895H69.1007V10.0128H70.9907C71.0327 9.80157 71.0852 9.51639 71.1377 9.31571H73.4267V11.4809C73.4057 11.4704 64.7852 11.4704 64.7852 11.4704Z"
          fill="#B31917"
        />
        <path
          d="M79.6423 5.36558C79.6003 5.36558 79.2538 5.36558 79.1068 5.38671V7.55193C79.2433 7.58362 79.5163 7.58362 79.5793 7.58362C80.4193 7.58362 81.0808 7.19282 81.0808 6.46404C81.0808 5.91481 80.7658 5.36558 79.6423 5.36558ZM79.4533 9.10455C79.3798 9.10455 79.3273 9.10455 79.1173 9.09399V10.0657C79.6213 10.0868 80.0833 10.1396 80.5243 10.2136V11.4705H74.8438V10.2241C75.2848 10.1502 75.7467 10.0868 76.2403 10.0657V5.17547C75.7467 5.1649 75.2848 5.11209 74.8438 5.05928V3.74959H76.2193C76.7548 3.74959 78.0568 3.69678 79.2748 3.69678C82.2673 3.69678 83.8948 4.59455 83.8948 6.27392C83.8843 7.77373 82.4458 9.10455 79.4533 9.10455Z"
          fill="#B31917"
        />
        <path
          d="M102.112 6.30553C102.059 6.05204 101.986 5.66124 101.954 5.37607H100.81V10.0656C101.303 10.0867 101.755 10.1396 102.185 10.2241V11.4704H96.5257V10.2241C96.9562 10.1396 97.4287 10.0867 97.9327 10.0656V5.37607H96.7672C96.7462 5.65068 96.6727 6.05204 96.6097 6.30553H94.6777V3.74951H104.054V6.30553H102.112Z"
          fill="#B31917"
        />
        <path
          d="M104.023 10.731H104.128C104.265 10.731 104.359 10.6782 104.359 10.5726C104.359 10.4881 104.296 10.4247 104.128 10.4247C104.086 10.4247 104.044 10.4247 104.023 10.4247V10.731ZM104.023 11.2063H103.876V10.3296C103.96 10.3191 104.023 10.3085 104.149 10.3085C104.286 10.3085 104.38 10.3402 104.443 10.3824C104.485 10.4141 104.517 10.4775 104.517 10.562C104.517 10.6887 104.443 10.7416 104.338 10.7732V10.7944C104.412 10.7944 104.464 10.8683 104.485 11.0056C104.517 11.1323 104.538 11.1957 104.548 11.2168H104.391C104.37 11.1957 104.359 11.1112 104.338 10.995C104.317 10.8789 104.244 10.826 104.128 10.826H104.034L104.023 11.2063ZM104.17 10.0867C103.813 10.0867 103.509 10.3824 103.509 10.7521C103.509 11.1112 103.813 11.4175 104.181 11.4175C104.559 11.4175 104.853 11.1112 104.853 10.7521C104.853 10.3824 104.559 10.0867 104.17 10.0867C104.17 10.0867 104.181 10.0867 104.17 10.0867ZM104.181 9.95996C104.653 9.95996 105 10.3191 105 10.7521C105 11.2063 104.653 11.5443 104.17 11.5443C103.719 11.5443 103.33 11.2063 103.33 10.7521C103.33 10.3191 103.719 9.95996 104.181 9.95996C104.181 9.95996 104.17 9.95996 104.181 9.95996Z"
          fill="#B31917"
        />
        <path
          d="M87.2232 7.60463C87.2232 8.8087 88.0107 9.84378 89.3547 9.84378C90.6987 9.84378 91.4862 8.81926 91.4862 7.60463C91.4862 6.48505 90.7617 5.37604 89.3547 5.37604C87.9477 5.37604 87.2232 6.48505 87.2232 7.60463ZM94.3527 7.62575C94.3527 10.224 92.2107 11.7766 89.3547 11.7766C86.4987 11.7766 84.3672 10.2135 84.3672 7.62575C84.3672 5.0803 86.5092 3.43262 89.3547 3.43262C92.2002 3.43262 94.3527 5.0803 94.3527 7.62575Z"
          fill="#B31917"
        />
        <path
          d="M18.8157 17.5435C19.8027 18.7159 20.2122 20.4058 20.2122 21.9162C20.2122 23.3209 19.8762 24.9897 19.0257 26.1304C18.0597 27.419 16.4217 28.0105 14.8467 28.0105C13.2822 28.0105 11.7492 27.588 10.6992 26.3311C9.74372 25.1904 9.38672 23.3738 9.38672 21.9056C9.38672 20.5114 9.70172 18.8849 10.5522 17.7442C11.5182 16.4873 13.1562 15.8853 14.6997 15.8853C16.2642 15.8853 17.7657 16.2866 18.8157 17.5435ZM12.5787 21.9373C12.5787 23.4794 12.7467 25.5918 14.7942 25.5918C16.7892 25.5918 17.0097 23.5956 17.0097 22.0324C17.0097 20.4903 16.8627 18.2934 14.8257 18.2934C12.7992 18.2934 12.5787 20.3424 12.5787 21.9373Z"
          fill="#B31917"
        />
        <path
          d="M24.6648 24.6203C24.6648 24.8421 24.6018 25.7926 24.9693 25.7926C25.1373 25.7926 25.3053 25.6765 25.5153 25.6765C26.0613 25.6765 26.2503 26.1306 26.2503 26.627C26.2503 27.2608 25.9668 27.7889 25.2633 27.7889C24.5493 27.7677 23.8773 27.6199 23.1948 27.6199C22.5648 27.6199 21.9243 27.7677 21.3783 27.7889C20.7483 27.7889 20.4648 27.2713 20.4648 26.627C20.4648 26.1201 20.6433 25.6765 21.1998 25.6765C21.4203 25.6765 21.5883 25.7926 21.7458 25.7926C22.1133 25.7926 22.0503 24.8421 22.0503 24.6203V21.5573C22.0503 20.9658 22.0818 20.9024 21.4833 20.9024C20.8323 20.9024 20.4648 20.7545 20.4648 20.0152C20.4648 19.3287 20.7168 19.0224 21.3993 19.0224C21.9138 19.0224 22.0503 19.0752 22.0608 18.4731C22.1238 16.6459 23.6988 15.896 25.3473 15.896C25.8933 15.896 26.5863 16.0016 27.0693 16.2445C27.6363 16.5508 28.0668 17.0895 28.0668 17.7338C28.0668 18.4731 27.5628 19.0435 26.8173 19.0435C26.2713 19.0435 25.6833 18.6633 25.6833 18.0507C25.6833 17.8711 25.7358 17.7655 25.8303 17.5965C25.7148 17.5437 25.6308 17.5331 25.4943 17.5331C24.8748 17.5331 24.6648 17.9028 24.6648 18.4943C24.6648 19.0329 24.6963 19.0329 25.2318 19.0329C25.9353 19.0329 26.4183 19.0646 26.4183 19.9413C26.4183 20.7123 26.1033 20.913 25.3683 20.913C24.6858 20.913 24.6648 20.8813 24.6648 21.5678V24.6203Z"
          fill="#B31917"
        />
        <path
          d="M31.2684 24.6203C31.2684 24.8421 31.2054 25.7926 31.5729 25.7926C31.7409 25.7926 31.9089 25.6765 32.1189 25.6765C32.6649 25.6765 32.8539 26.1306 32.8539 26.627C32.8539 27.2608 32.5704 27.7889 31.8669 27.7889C31.1529 27.7677 30.4809 27.6199 29.7984 27.6199C29.1684 27.6199 28.5384 27.7677 27.9819 27.7889C27.3519 27.7889 27.0684 27.2713 27.0684 26.627C27.0684 26.1201 27.2469 25.6765 27.8034 25.6765C28.0239 25.6765 28.1814 25.7926 28.3494 25.7926C28.7169 25.7926 28.6539 24.8421 28.6539 24.6203V21.5573C28.6539 20.9658 28.6854 20.9024 28.0869 20.9024C27.4359 20.9024 27.0684 20.7545 27.0684 20.0152C27.0684 19.3287 27.3204 19.0224 28.0029 19.0224C28.5174 19.0224 28.6539 19.0752 28.6644 18.4731C28.7274 16.6459 30.3024 15.896 31.9509 15.896C32.4969 15.896 33.1794 16.0016 33.6624 16.2445C34.2294 16.5508 34.6599 17.0895 34.6599 17.7338C34.6599 18.4731 34.1559 19.0435 33.4104 19.0435C32.8644 19.0435 32.2764 18.6633 32.2764 18.0507C32.2764 17.8711 32.3289 17.7655 32.4234 17.5965C32.3079 17.5437 32.2239 17.5331 32.0874 17.5331C31.4679 17.5331 31.2474 17.9028 31.2474 18.4943C31.2474 19.0329 31.2789 19.0329 31.8144 19.0329C32.5179 19.0329 33.0009 19.0646 33.0009 19.9413C33.0009 20.7123 32.6859 20.913 31.9509 20.913C31.2684 20.913 31.2474 20.8813 31.2474 21.5678L31.2684 24.6203Z"
          fill="#B31917"
        />
        <path
          d="M38.0091 24.6203C37.9881 24.8421 37.9251 25.7926 38.2926 25.7926C38.4606 25.7926 38.6286 25.6765 38.8386 25.6765C39.3846 25.6765 39.5736 26.1306 39.5736 26.6271C39.5736 27.2608 39.2901 27.7889 38.5866 27.7889C37.8726 27.7678 37.2006 27.6199 36.5181 27.6199C35.8881 27.6199 35.2476 27.7678 34.6491 27.7889C33.9456 27.7889 33.6621 27.2713 33.6621 26.6271C33.6621 26.1201 33.8406 25.6765 34.3971 25.6765C34.6176 25.6765 34.7751 25.7926 34.9431 25.7926C35.3106 25.7926 35.2476 24.8421 35.2476 24.6203V22.2016C35.2476 21.9797 35.3106 21.0292 34.9431 21.0292C34.7751 21.0292 34.6071 21.1453 34.3971 21.1453C33.8511 21.1453 33.6621 20.6912 33.6621 20.1842C33.6621 19.5505 33.9456 19.0224 34.6596 19.0224C35.1321 19.0224 35.5731 19.1914 36.0246 19.1914C36.5286 19.1914 37.0116 19.0224 37.4946 19.0224C37.9986 19.0224 37.9986 19.4977 37.9986 19.8779L38.0091 24.6203ZM38.0931 16.7832C38.0931 17.6599 37.3896 18.357 36.5286 18.357C35.6676 18.357 34.9641 17.6493 34.9641 16.7832C34.9641 15.9171 35.6676 15.2095 36.5286 15.2095C37.4001 15.2095 38.0931 15.9171 38.0931 16.7832Z"
          fill="#B31917"
        />
        <path
          d="M43.6053 20.9762C42.3663 20.9762 41.9358 22.2859 41.9358 23.321C41.9358 23.8596 42.0513 24.5462 42.3558 25.0003C42.6603 25.4334 43.2168 25.7397 43.7418 25.7397C44.5083 25.7397 44.7603 25.3172 45.0228 24.6835C45.1383 24.3983 45.1698 24.2188 45.4428 24.0498C45.6633 23.9019 45.9258 23.8491 46.1778 23.8491C46.8918 23.8491 47.4693 24.3244 47.4693 25.0848C47.4693 25.8876 46.9863 26.6269 46.3773 27.1128C45.6108 27.7465 44.6238 28 43.6473 28C42.4608 28 41.3163 27.6831 40.4658 26.8065C39.6153 25.9509 39.1953 24.6307 39.1953 23.4372C39.1953 20.5854 40.9278 18.7793 43.7838 18.7793C45.3378 18.7793 47.2698 19.5186 47.2698 21.3776C47.2698 22.2648 46.5558 22.8563 45.7053 22.8563C45.0228 22.8563 44.3718 22.5394 44.3718 21.7684C44.3718 21.5466 44.4243 21.4304 44.5398 21.2614C44.2353 21.0501 43.9833 20.9762 43.6053 20.9762Z"
          fill="#B31917"
        />
        <path
          d="M51.1442 23.8598C50.8082 23.8598 50.3987 23.7542 50.3987 24.2084C50.3987 25.1695 51.1442 25.8877 52.1207 25.8877C53.5382 25.8877 53.4332 24.5675 54.7352 24.5675C55.3862 24.5675 55.8797 24.9583 55.8797 25.6237C55.8797 26.363 55.3337 26.9651 54.7352 27.3347C53.9477 27.8206 53.0027 28.0107 52.0892 28.0107C49.3487 28.0107 47.6582 26.1624 47.6582 23.4479C47.6582 20.7968 49.2962 18.79 52.0262 18.79C53.0237 18.79 54.0947 19.0118 54.8507 19.7089C55.6382 20.4272 56.0477 21.5679 56.0477 22.6241C56.0477 23.6169 55.9427 23.8704 54.9452 23.8704L51.1442 23.8598ZM52.6982 22.0009C52.9712 22.0009 53.2862 22.0854 53.2862 21.7157C53.2862 21.4622 53.1182 21.1665 52.9397 20.9975C52.6877 20.7757 52.3727 20.6806 52.0367 20.6806C51.5852 20.6806 51.2177 20.7863 50.9027 21.1348C50.7557 21.2827 50.4827 21.5573 50.4827 21.7685C50.4827 22.0854 50.9972 22.0009 51.1862 22.0009H52.6982Z"
          fill="#B31917"
        />
        <path
          d="M66.3691 17.6284C66.5161 17.0052 66.6841 16.1074 67.6291 16.118C68.4796 16.118 69.3196 16.287 70.1596 16.287C70.7581 16.287 71.5981 16.118 72.4486 16.118C73.1941 16.118 73.6141 16.6778 73.6141 17.396C73.6141 17.903 73.3096 18.5156 72.7531 18.5156C72.5011 18.5156 72.2701 18.3994 72.0076 18.3994C71.5036 18.3994 71.5876 19.4345 71.5876 19.7619V24.1557C71.5876 24.4726 71.5036 25.5077 72.0076 25.5077C72.2701 25.5077 72.5116 25.3915 72.7531 25.3915C73.3201 25.3915 73.6141 26.0146 73.6141 26.5111C73.6141 27.2293 73.1941 27.7891 72.4486 27.7891C71.7346 27.7891 71.0101 27.6201 70.4326 27.6201C69.6976 27.6201 68.9836 27.7891 68.2591 27.7891C67.6291 27.7891 67.3456 27.2398 67.3456 26.5111C67.3456 26.0041 67.5451 25.3915 67.9336 25.3915C68.1016 25.3915 68.2696 25.5077 68.4481 25.5077C68.7946 25.5077 68.7526 24.4726 68.7526 24.1557V18.3677L66.4531 26.3104C66.3481 26.6589 66.2536 26.9652 66.0331 27.2715C65.8021 27.6095 65.5501 27.6095 65.1721 27.6095H64.6051C63.5866 27.6095 63.5446 27.3032 63.3031 26.3209L61.0666 18.3783V24.1663C61.0666 24.4831 61.0351 25.5182 61.3816 25.5182C61.5601 25.5182 61.7281 25.402 61.8961 25.402C62.2741 25.402 62.4841 26.0252 62.4841 26.5216C62.4841 27.2398 62.2006 27.7996 61.6861 27.7996C60.9721 27.7996 60.2581 27.6306 59.6701 27.6306C58.9351 27.6306 58.2211 27.7996 57.5071 27.7996C56.6251 27.7996 56.2051 27.2504 56.2051 26.5216C56.2051 26.0146 56.5096 25.402 57.0766 25.402C57.3286 25.402 57.5596 25.5182 57.8221 25.5182C58.3261 25.5182 58.2421 24.4831 58.2421 24.1663V19.7513C58.2421 19.4345 58.3261 18.3888 57.8221 18.3888C57.5596 18.3888 57.3286 18.505 57.0766 18.505C56.5096 18.505 56.2051 17.8819 56.2051 17.3854C56.2051 16.6672 56.6251 16.1074 57.7906 16.1074C58.6411 16.1074 59.4706 16.2764 60.3211 16.2764C60.9196 16.2764 61.7491 16.1074 62.5996 16.1074C63.3976 16.0863 63.4396 16.7412 63.5866 17.3643L64.9516 23.184L66.3691 17.6284Z"
          fill="#B31917"
        />
        <path
          d="M77.2692 21.1135C77.2692 21.8845 76.5342 22.2331 75.8412 22.2331C75.0222 22.2331 74.3082 21.6522 74.3082 20.7966C74.3082 19.0645 76.6707 18.7793 77.9412 18.7793C78.7707 18.7793 79.9047 18.8638 80.6397 19.2863C81.6267 19.8777 81.7107 20.8283 81.7107 21.8634V25.0215C81.7107 25.2433 81.6582 25.7925 81.9942 25.7925C82.1307 25.7925 82.2567 25.7819 82.3932 25.7819C82.9287 25.7819 83.2227 26.2361 83.2227 26.722C83.2227 27.662 82.2882 28.0105 81.5007 28.0105C80.7342 28.0105 79.8837 27.7253 79.6002 26.9332C78.8127 27.6831 77.8047 28.0105 76.7547 28.0105C75.2427 28.0105 73.7832 27.2078 73.7832 25.4968C73.7832 21.5254 78.9492 23.585 78.9492 21.367C78.9492 20.7333 78.3612 20.427 77.8152 20.427C77.5317 20.427 77.2692 20.4798 77.2692 20.8072V21.1135ZM78.9492 23.9441C78.4662 24.4617 76.5342 24.2082 76.5342 25.106C76.5342 25.6869 77.0067 25.8876 77.4897 25.8876C78.4662 25.8876 78.9492 25.3066 78.9492 24.3138V23.9441Z"
          fill="#B31917"
        />
        <path
          d="M85.3968 21.7475C85.2498 21.5257 84.8928 21.0293 84.6408 21.0293C84.4938 21.0293 84.3258 21.0926 84.1788 21.1454C83.6958 21.1454 83.5278 20.6913 83.5278 20.1843C83.5278 19.5506 83.7798 19.0225 84.4098 19.0225C84.8298 19.0436 85.2708 19.1915 85.7223 19.1915C86.2263 19.1915 86.7093 19.0436 87.2238 19.0225C87.7488 19.0225 88.0848 19.371 88.0848 19.9836C88.0848 20.554 87.6543 20.5856 87.6543 20.8603C87.6543 21.0926 88.0428 21.7158 88.1373 21.8848L88.3683 22.3178L88.6203 21.8848C88.7253 21.7158 89.0718 21.2088 89.0718 20.9764C89.0718 20.6385 88.5258 20.8391 88.5258 20.0153C88.5258 19.3921 88.8093 19.0225 89.2083 19.0225C89.6283 19.0436 90.0693 19.1915 90.5313 19.1915C91.0248 19.1915 91.5078 19.0436 92.0328 19.0225C92.6313 19.0225 92.8833 19.54 92.8833 20.1843C92.8833 20.6913 92.7363 21.1454 92.2533 21.1454C92.0853 21.0609 91.9173 21.0081 91.7703 21.0081C91.5183 21.0081 91.4553 21.1982 91.3188 21.4095L90.1848 23.1733L91.3188 25.0851C91.4553 25.2858 91.6968 25.7188 91.9383 25.7188C92.0538 25.7188 92.2008 25.6554 92.3583 25.666C92.8413 25.666 92.9883 26.1202 92.9883 26.6166C92.9883 27.2503 92.7363 27.7784 92.1378 27.7784C91.6233 27.7573 91.1403 27.6094 90.6363 27.6094C90.1848 27.6094 89.7333 27.7573 89.3238 27.7784C88.8198 27.7467 88.4523 27.2925 88.4523 26.7222C88.4523 26.437 88.5153 26.342 88.7043 26.173C88.8093 26.0885 89.0088 25.9406 89.0088 25.7716C89.0088 25.5709 88.6413 24.9689 88.5468 24.821L88.2948 24.388L87.8118 25.1801C87.7068 25.328 87.4758 25.666 87.4758 25.8455C87.4758 26.2469 88.0428 26.0251 88.0428 26.7222C88.0428 27.377 87.6543 27.7467 87.1083 27.789C86.5833 27.7678 86.1108 27.62 85.6068 27.62C85.1553 27.62 84.7038 27.7678 84.2943 27.789C83.7063 27.789 83.4648 27.2714 83.4648 26.6271C83.4648 26.1202 83.6118 25.6766 84.0948 25.6766C84.2838 25.6766 84.4413 25.7399 84.6303 25.7399C84.9138 25.7399 85.1133 25.3914 85.2603 25.1379L85.5438 24.7154L86.4468 23.2684L85.3968 21.7475Z"
          fill="#B31917"
        />
        <path
          d="M93.8484 19.9097C93.8484 19.445 94.1949 19.0859 94.6464 19.0859C95.0979 19.0859 95.4444 19.445 95.4444 19.9097C95.4444 20.385 95.0979 20.7547 94.6464 20.7547C94.1949 20.7441 93.8484 20.385 93.8484 19.9097ZM93.6699 19.9097C93.6699 20.4695 94.1214 20.892 94.6464 20.892C95.1819 20.892 95.6229 20.4695 95.6229 19.9097C95.6229 19.3605 95.1819 18.938 94.6464 18.938C94.1109 18.938 93.6699 19.3499 93.6699 19.9097ZM94.2684 20.4801H94.4469V19.9942H94.6359L94.9404 20.4801H95.1294L94.8039 19.9731C94.9719 19.9519 95.1084 19.8675 95.1084 19.6668C95.1084 19.445 94.9824 19.3393 94.7094 19.3393H94.2684C94.2684 19.3393 94.2684 20.4801 94.2684 20.4801ZM94.4469 19.4872H94.6779C94.7934 19.4872 94.9299 19.5083 94.9299 19.6668C94.9299 19.8463 94.7829 19.8569 94.6254 19.8569H94.4469V19.4872Z"
          fill="#B31917"
        />
      </g>
      <defs>
        <clipPath id="clip0_2173_7145">
          <rect width="105" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgLogoODMax
