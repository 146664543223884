import React from 'react'
import { LogoElevate } from '@/assets/svg'
import { Box, LinkBox, LinkOverlay, VisuallyHidden } from '@chakra-ui/react'
const TopBarLogo = ({ pos = 'absolute', logoLink = '' }) => {
  return (
    <Box position={pos} top="22px">
      <LinkBox>
        <LinkOverlay href={logoLink}>
          <VisuallyHidden>EA Elevate</VisuallyHidden>
          <LogoElevate height="36px" width="110px" />
        </LinkOverlay>
      </LinkBox>
    </Box>
  )
}

export default TopBarLogo
