import { gql } from 'graphql-request'

import { NavLinkFrag } from './models'
import {
  ModalBlockFrag,
  GridBlockFrag,
  HeroBlockFrag,
  CustomBlockFrag,
  InfoBlockFrag,
  CardBlockFrag,
  RichTextBlockFrag
} from './blocks'

/** MODEL: Seo */
export const SeoFrag = gql`
  fragment SeoFrag on Seo {
    id
    description
    image {
      fileName
      height
      id
      mimeType
      size
      url
      width
      imageName
    }
    keywords
    noIndex
    title
  }
`
/** MODEL: Footer */
export const FooterFrag = gql`
  fragment FooterFrag on Footer {
    id
    footerName
    footerSlug
    footerTagLine
    copyrightNotice
    footerLogo {
      fileName
      height
      id
      mimeType
      size
      url
      width
      imageName
    }
    navLinks {
      ...NavLinkFrag
    }
  }
  ${[NavLinkFrag]}
`
/** MODEL: Page */
export const PageFrag = gql`
  fragment PageFrag on Page {
    id
    slug
    pageName
    pageHeadline
    pageSubheadline
    pageCopy
    pageType
    pageCategory
    phase
    pageCopy
    seo {
      ...SeoFrag
    }

    footer {
      ...FooterFrag
    }
  }
  ${[SeoFrag, FooterFrag]}
`
/** MODEL: Section */
export const PageSectionFrag = gql`
  fragment PageSectionFrag on Section {
    id
    sectionName
    sectionSlug
    layerStyle
    sectionComponent
    sectionImage {
      id
      width
      height
      url
      imageName
      fileName
      mimeType
      size
    }
    sectionImageAlign
    sectionBackground {
      id
      width
      height
      url
      imageName
      fileName
      mimeType
      size
    }
    sectionBlocks {
      __typename
      ... on InfoBlock {
        ...InfoBlockFrag
      }
      __typename
      ... on Modal {
        ...ModalBlockFrag
      }
      __typename
      ... on CustomBlock {
        ...CustomBlockFrag
      }
      __typename
      ... on HeroBlock {
        ...HeroBlockFrag
      }
      __typename
      ... on CardBlock {
        ...CardBlockFrag
      }
      __typename
      ... on GridBlock {
        ...GridBlockFrag
      }
      __typename
      ... on RichTextBlock {
        ...RichTextBlockFrag
      }
    }
  }
  ${[
    InfoBlockFrag,
    ModalBlockFrag,
    GridBlockFrag,
    HeroBlockFrag,
    CardBlockFrag,
    CustomBlockFrag,
    RichTextBlockFrag
  ]}
`
/** MODEL: Section */
export const FooterSectionsFrag = gql`
  fragment FooterSectionsFrag on Section {
    id
    sectionName
    sectionSlug
    layerStyle
    sectionComponent
    sectionImageAlign
    sectionImage {
      id
      width
      height
      url
      imageName
      fileName
      mimeType
      size
    }
    sectionBackground {
      id
      width
      height
      url
      imageName
      fileName
      mimeType
      size
    }
    sectionBlocks {
      __typename
      ... on InfoBlock {
        ...InfoBlockFrag
      }
      __typename
      ... on Modal {
        ...ModalBlockFrag
      }
      __typename
      ... on CustomBlock {
        ...CustomBlockFrag
      }
      __typename
      ... on HeroBlock {
        ...HeroBlockFrag
      }
      __typename
      ... on CardBlock {
        ...CardBlockFrag
      }
      __typename
      ... on GridBlock {
        ...GridBlockFrag
      }
      __typename
      ... on RichTextBlock {
        ...RichTextBlockFrag
      }
    }
  }
  ${[
    InfoBlockFrag,
    ModalBlockFrag,
    GridBlockFrag,
    HeroBlockFrag,
    CardBlockFrag,
    CustomBlockFrag,
    RichTextBlockFrag
  ]}
`
