import React, { useState } from 'react'
import { Box, Button, Divider } from '@chakra-ui/react'
import { ButtonMain } from '@/components'
import { useFireAuth } from '@/lib/fireAuth'
import TeamAccountForm from 'modules/TeamAccountForm'
import { PanelForm } from '@/forms'
import ROUTES from '@/constants/routes'
const TeamAccountInformation = () => {
  const { member, team } = useFireAuth()
  const [isEditing, setIsEditing] = useState(false)
  const isOwner = member?.teamAccountRole === 'owner' ? true : false

  return (
    <PanelForm
      title="Business Information"
      actionBtn={
        <Button
          disabled={!isOwner}
          variant="form-link"
          size="sm"
          onClick={() => setIsEditing(!isEditing)}
        >
          {!isEditing ? 'EDIT' : 'CANCEL'}
        </Button>
      }
    >
      <Divider marginBottom={4} marginTop={2.5} />
      {team ? (
        <TeamAccountForm
          team={team}
          toggleEdit={() => setIsEditing(!isEditing)}
          isEditing={isEditing}
        />
      ) : (
        <Box width="100%" textAlign="center">
          <ButtonMain
            variant="primary"
            label="Create Account"
            size="md"
            href={ROUTES.ONBOARDING_NEW_MEMBER_CREATE_TEAM_ACCOUNT}
          >
            Create Account
          </ButtonMain>
        </Box>
      )}
    </PanelForm>
  )
}

export default TeamAccountInformation
