const spacingObject = {
  none: '0',
  xs: `{{base: '0', sm: '1', md: '2', lg: '4', xl: '8'}}`,
  sm: `{{base: '0', sm: '2', md: '4', lg: '8', xl: '16'}}`,
  md: `{{base: '3', sm: '6', md: '12', lg: '24', xl: '28'}}`,
  lg: `{{base: '6', sm: '8", md: '20', lg: '28', xl: '32'}}`,
  xl: `{{base: '10', sm: '12", md: '20', lg: '32', xl: '40'}}`
}
const spacingArrays = {
  none: '0',
  xs: ['1', '3', '6'],
  sm: ['3', '6', '12'],
  md: ['1', '5', '10'],
  lg: ['5', '10', '20'],
  xl: ['8', '16', '32']
}
export default function getGridSpacing(size) {
  return spacingArrays[size]
}
