import * as React from 'react'
import _ from 'lodash'
export function SavingsGraph(
  { elevateSpecials = 0, everydayDeals = 0 },
  props
) {
  const total = parseFloat(elevateSpecials) + parseFloat(everydayDeals)
  const percentage = everydayDeals / total

  return (
    <svg width={325} height={225}>
      <style>
        {`
            .cardTitle{
            font-size:14px;
            font-family:Roboto;
            line-height: 18px;
            text-transform: uppercase;
            }

            .cardBody {
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-transform: capitalize;
            }
        `}
      </style>

      <circle cx={150} cy={105} r={60} fill="transparent" />
      <path
        d="M 164.354 97.75
        L 160.193 93.5884
        C 160.321 92.8184 160.523 92.1034 160.779 91.48
        C 160.926 91.15 160.999 90.8017 160.999 90.4167
        C 160.999 88.895 159.771 87.6667 158.249 87.6667
        C 155.243 87.6667 152.584 89.115 150.916 91.3334
        H 141.749
        C 136.176 91.3334 131.666 95.8434 131.666 101.417
        C 131.666 106.274 135.147 118.679 136.042 121.786
        C 136.164 122.212 136.553 122.5 136.997 122.5
        H 145.333
        C 145.885 122.5 146.333 122.052 146.333 121.5
        V 120.667
        C 146.333 119.654 147.153 118.833 148.166 118.833
        C 149.179 118.833 149.999 119.654 149.999 120.667
        V 121.5
        C 149.999 122.052 150.447 122.5 150.999 122.5
        H 159.339
        C 159.78 122.5 160.17 122.211 160.297 121.788
        L 163.163 112.252
        L 166.281 111.212
        C 167.506 110.804 168.333 109.657 168.333 108.366
        V 100.75
        C 168.333 99.0932 166.99 97.75 165.333 97.75
        H 164.354 Z M 151.833 98.74 H 142.666 V 96.8334
        H 151.833 V 98.74
        Z
        M 157.333 104.167
        C 156.324 104.167 155.499 103.342 155.499 102.333
        C 155.499 101.325 156.324 100.5 157.333 100.5
        C 158.341 100.5 159.166 101.325 159.166 102.333
        C 159.166 103.342 158.341 104.167 157.333 104.167
        Z"
        fill="#FF8777"
      />
      {/* <circle
        cx={150}
        cy={105}
        r={70}
        fill="transparent"
        strokeWidth={40}
        stroke="gray"
      /> */}
      <circle
        cx={150}
        cy={105}
        r={70}
        fill="transparent"
        strokeWidth={40}
        opacity={0.5}
        stroke="#C7E4E9"
        strokeDasharray={439.5}
      />
      <circle
        cx={150}
        cy={105}
        r={70}
        fill="transparent"
        strokeWidth={40}
        transform="rotate(96 150 105)"
        stroke="#23B589"
        opacity={0.5}
        strokeDasharray={439.5}
        strokeDashoffset={_.toString(percentage * 439.5)}
      />
      <rect
        xmlns="http://www.w3.org/2000/svg"
        width="130"
        height="52"
        transform="translate(175 140)"
        fill="#C7E4E9"
      />
      <text x={182} y={160} className="cardTitle">
        EVERYDAY DEALS
      </text>
      <text x={182} y={180} className="cardBody">
        ${everydayDeals}
      </text>

      <rect
        xmlns="http://www.w3.org/2000/svg"
        width="136"
        height="52"
        x="8"
        y="20"
        fill="#23B589"
      />
      <text x={14} y={40} fill="#000000" className="cardTitle">
        Elevate Specials
      </text>
      <text x={14} y={60} fill="#000000" className="cardBody">
        ${elevateSpecials}
      </text>
    </svg>
  )
}

export function CashbackGraph({ vendors = [] }, props) {
  //const hasTotal = vendors.length > 0
  const vendor1 = vendors[0] ? vendors[0] : { vendor: '', cashback: 0 }
  // console.//log('vendor1', vendor1)
  const vendorNameCode = vendor1.vendor ? vendor1.vendor : ''
  const vendorName = vendorNameCode === 'OD' ? 'Office Depot' : 'Elevate'
  const vendorColor = vendorNameCode === 'OD' ? '#FF8777' : '#757EFF'
  const vendorTotal = vendor1.cashBack ? vendor1.cashBack : 0
  const vendorSum = vendorTotal.toFixed(2)

  return (
    <svg width={325} height={225}>
      <style>{`
        .cardTitle{
            font-size:14px;
             font-family:Roboto;
             line-height: 18px;
             text-transform: uppercase;
          }

   .cardBody {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-transform: capitalize;
   }
  `}</style>

      <circle cx={150} cy={105} r={60} fill="transparent" />
      <path
        d="M131.566 104.684a.802.802 0 00-1.132 0l-5.091 5.091a.802.802 0 001.132 1.132l4.525-4.526 4.525 4.526a.802.802 0 001.132-1.132l-5.091-5.091zm2.988 12.233l.664-.446-.664.446zm9.464 7.734l.305-.739-.305.739zm12.184 1.195l.156.785-.156-.785zm11.362-5.18a.799.799 0 10-1.128-1.134l1.128 1.134zM130.2 105.25c0 4.312 1.284 8.528 3.69 12.113l1.328-.892a20.137 20.137 0 01-3.418-11.221h-1.6zm3.69 12.113a21.869 21.869 0 009.823 8.028l.61-1.479a20.271 20.271 0 01-9.105-7.441l-1.328.892zm9.823 8.028a21.967 21.967 0 0012.645 1.24l-.311-1.569a20.38 20.38 0 01-11.724-1.15l-.61 1.479zm12.645 1.24a21.91 21.91 0 0011.206-5.965l-1.128-1.134a20.32 20.32 0 01-10.389 5.53l.311 1.569zM172.434 107.316a.802.802 0 001.132 0l5.091-5.091a.802.802 0 00-1.132-1.132L173 105.619l-4.525-4.526a.802.802 0 00-1.132 1.132l5.091 5.091zm-2.988-12.233l-.664.446.664-.446zm-9.464-7.734l-.305.74.305-.74zm-12.184-1.196l.155.785-.155-.785zm-11.362 5.18a.799.799 0 101.128 1.135l-1.128-1.134zM173.8 106.75c0-4.312-1.284-8.528-3.69-12.113l-1.328.892a20.137 20.137 0 013.418 11.221h1.6zm-3.69-12.113a21.866 21.866 0 00-9.823-8.028l-.61 1.48a20.273 20.273 0 019.105 7.44l1.328-.892zm-9.823-8.028a21.971 21.971 0 00-12.645-1.24l.311 1.57c3.936-.78 8.016-.38 11.724 1.15l.61-1.48zm-12.645-1.24a21.914 21.914 0 00-11.206 5.965l1.128 1.134a20.321 20.321 0 0110.389-5.53l-.311-1.57zM152 91.625c-7.935 0-14.375 6.44-14.375 14.375s6.44 14.375 14.375 14.375 14.375-6.44 14.375-14.375-6.44-14.375-14.375-14.375zm1.265 22.655v1.783h-2.516v-1.855c-1.063-.259-3.435-1.107-4.341-4.255l2.372-.963c.086.316.834 3.004 3.45 3.004 1.337 0 2.846-.69 2.846-2.314 0-1.38-1.006-2.099-3.277-2.918-1.582-.561-4.816-1.481-4.816-4.758 0-.144.014-3.45 3.766-4.255v-1.811h2.516v1.782c2.645.46 3.608 2.573 3.824 3.206l-2.272.963c-.158-.503-.848-1.926-2.731-1.926-1.006 0-2.602.531-2.602 1.998 0 1.365 1.237 1.883 3.795 2.731 3.45 1.193 4.327 2.947 4.327 4.959 0 3.781-3.594 4.5-4.341 4.629z"
        fill="#76CBE2"
      />
      {/* <circle
          cx={150}
          cy={105}
          r={70}
          fill="transparent"
          strokeWidth={40}
          stroke="gray"
        /> */}
      <circle
        cx={150}
        cy={105}
        r={70}
        fill="transparent"
        strokeWidth={40}
        stroke={vendorColor}
        opacity=".5"
        strokeDasharray={439.5}
      />

      <rect
        xmlns="http://www.w3.org/2000/svg"
        width="130"
        height="52"
        x="8"
        y="20"
        fill={vendorColor}
      />
      <text x={16} y={40} fill="#000000" className="cardTitle">
        {vendorName}
      </text>
      <text x={16} y={60} fill="#000000" className="cardBody">
        ${vendorSum}
      </text>
    </svg>
  )
}
