import _ from 'lodash'

const catFormat = (cat) => {
  const newcat = _.replace(cat, '_', ' ').toLowerCase()
  ////console.log('newcat', newcat)
  return newcat
}

const stickerFormat = (val) => {
  const newval = _.replace(val, '_', ' ').toUpperCase()
  ////console.log('newcat', newval)
  return newval
}

export { catFormat, stickerFormat }
