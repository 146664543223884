import { Box, Link, ListItem, OrderedList, VStack } from '@chakra-ui/react'
import APPVARS from '@/constants/appvars'
import { MDXRemote } from 'next-mdx-remote'
import _ from 'lodash'
import { RichText } from '@graphcms/rich-text-react-renderer'
import { ButtonMain } from '@/components'
import { getPaddingY, getPaddingX } from '@/theme/helpers'
const mdText = {
  h3: (props) => (
    <Box textStyle="head3" w="100%" textAlign="center" {...props} />
  ),
  p: (props) => (
    <Box
      pt={APPVARS.HRZ.XL}
      textStyle="body2"
      w="100%"
      textAlign="center"
      {...props}
    />
  )
}

function createMarkup(someHtml) {
  return { __html: someHtml }
}

export default function InfoBlock({
  infoBlockName,
  infoBlockSlug,
  infoBlockTextAlign = 'left',
  infoBlockSize = 'lg',
  infoBlockWidth = 'lg',
  infoBlockSpaceX = 'lg',
  infoBlockSpaceY = 'none',
  infoBlockLayerStyle,
  infoBlockRichText,
  infoBlockMdText,
  infoBlockButton
}) {
  //const maxBlockWidth = APPVARS.MAX_BLOCK_WIDTH // getBlockWidth(infoBlockWidth)

  const paddingY = getPaddingY(infoBlockSpaceY)
  const paddingX = getPaddingX(infoBlockSpaceX)

  //console.log(`infoBlockButton`, infoBlockButton)
  return (
    <Box
      className="INFO_BLOCK"
      w="100%"
      py={paddingY}
      layerStyle={infoBlockLayerStyle ? infoBlockLayerStyle : 'default'}
    >
      <VStack w="100%">
        {infoBlockRichText && (
          <Box
            className="infoBlockRichText"
            w="100%"
            textAlign={infoBlockTextAlign}
          >
            <RichText
              content={infoBlockRichText.json}
              renderers={{
                a: ({ children, openInNewTab, href, rel, ...rest }) => (
                  <Link
                    variant="rich"
                    href={href}
                    target={openInNewTab ? '_blank' : '_self'}
                    rel={rel || 'noopener noreferrer'}
                    {...rest}
                  >
                    {children}
                  </Link>
                ),
                h1: ({ children }) => (
                  <Box mb="3" textStyle="head1" textAlign={infoBlockTextAlign}>
                    {children}
                  </Box>
                ),

                h2: ({ children }) => (
                  <Box textStyle="head3" textAlign={infoBlockTextAlign}>
                    {children}
                  </Box>
                ),
                h3: ({ children }) => (
                  <Box mb="5" textStyle="head3" textAlign={infoBlockTextAlign}>
                    {children}
                  </Box>
                ),
                h5: ({ children }) => (
                  <Box mb="3" textStyle="head5" textAlign={infoBlockTextAlign}>
                    {children}
                  </Box>
                ),

                class: ({ className, children, ...rest }) => {
                  if (className === 'details') {
                    return (
                      <Box
                        textStyle="head5"
                        className={className}
                        my="4"
                        {...rest}
                      >
                        {children}
                      </Box>
                    )
                  } else {
                    return (
                      <Box className={className} my="4" {...rest}>
                        {children}
                      </Box>
                    )
                  }
                },
                bold: ({ children }) => <strong>{children}</strong>,
                p: ({ children }) => (
                  <Box as="p" mt={APPVARS.HRZ.LG} textStyle="body2">
                    {children}
                  </Box>
                ),
                ol: ({ children }) => (
                  <OrderedList className="OL" textStyle="body2" w="100%">
                    {children}
                  </OrderedList>
                ),
                li: ({ children }) => (
                  <ListItem className="LI" textStyle="body2" w="100%">
                    {children}
                  </ListItem>
                )
              }}
            />
          </Box>
        )}
        {infoBlockMdText && (
          <Box w="100%" textAlign={infoBlockTextAlign}>
            <MDXRemote {...infoBlockMdText.mdx} />
          </Box>
        )}
      </VStack>
      {infoBlockButton && (
        <Box w="100%" align="center" mt={APPVARS.HRZ.XL}>
          <ButtonMain {...infoBlockButton} />
        </Box>
      )}
      {infoBlockButton?.info && (
        <Box
          id="infoBlockButtonBox"
          textStyle="body4"
          color="white"
          textAlign={infoBlockTextAlign}
        >
          {infoBlockButton.info}
        </Box>
      )}
    </Box>
  )
}

// <Grid
// maxWidth="container.lg"
// className="INFO_BLOCK"
// w="100%"
// templateColumns="[center-start] auto [center-end]"
// alignItems="center"
// justifyContent="center"
// gap={{ base: '3', lg: '4', xl: '6' }}
// >
// <Box
//   gridColumn="center-start"
//   overflow="auto"
//   mx={{ base: '3', sm: 'auto' }}
//   mb="14"
//   className={`INFO_BLOCK`}
//   layerStyle={infoBlockLayerStyle ? infoBlockLayerStyle : 'default'}
// >
//   {infoBlockRichText && (
//     <Box dangerouslySetInnerHTML={createMarkup(infoBlockRichText.html)} />
//   )}
//   {infoBlockMdText && (
//     <MDXRemote {...infoBlockMdText.mdx} components={mdText} />
//   )}
// </Box>
// </Grid>
