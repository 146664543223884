import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  VStack,
  Text,
  Box,
  ModalHeader
} from '@chakra-ui/react'
import NiceModal, {
  useModal,
  show,
  create,
  remove,
  register
} from '@ebay/nice-modal-react'
import { ConnectVendorAccount, CreateNewVendorAccount } from '@/modules'
import { useFireAuth } from '@/lib/fireAuth'

export default NiceModal.create(
  ({ modalName, vendorName, modalSize = 'container.lg' }) => {
    const { team } = useFireAuth()
    //console.log('team', team)
    const modal = useModal()
    // const [step, setStep] = useState(0)
    const [hasODA, setHasODA] = useState(true)
    //console.log('In create OD')
    const onNextAction = () => {
      //console.log(' onNextAction')
      modal.hide()
    }
    return (
      <Modal
        default
        variant="wide"
        size={modalSize}
        isOpen={() => modal.show()}
        onClose={() => modal.remove()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton onClick={() => modal.remove()} />

          <ModalBody>
            <VStack spacing="10">
              {hasODA ? (
                <ConnectVendorAccount
                  vendorName={vendorName}
                  teamAccountId={team.teamAccountId}
                  handleNextAction={onNextAction}
                />
              ) : (
                <CreateNewVendorAccount handleNextAction={onNextAction} />
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
)
