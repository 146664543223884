import { SiteLayout } from '@/layout'
import { ErrorBoundary } from 'react-error-boundary'
import { ChakraProvider } from '@chakra-ui/react'
import theme from '@/theme'
import { FireAuthProvider } from '@/lib/fireAuth'
import { DefaultSeo } from 'next-seo'
import '../styles/globals.css'
import { ModalsReduxProvider } from '@/providers'
import { defaultSEO } from '@/config/next-seo.config'
import Script from 'next/script'

function MyFallbackComponent({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

const myErrorHandler = ({ error, errorInfo }) => {
  // Do something with the error
  console.log('myerror')
  console.log('error', error)
  console.log('errorInfo', errorInfo)
  rg4js('send', {
    error: new Error(error)
  })
}

export default function App({ Component, pageProps }) {
  const getLayout =
    Component.getLayout ||
    ((page) => <SiteLayout {...pageProps}>{page}</SiteLayout>)

  return (
    <ChakraProvider theme={theme}>
      <Script src="https://cdn.dwolla.com/1/dwolla.js" />
      <ErrorBoundary
        FallbackComponent={MyFallbackComponent}
        onError={(error, errorInfo) => myErrorHandler({ error, errorInfo })}
        onReset={() => {
          // reset the state of your app
        }}
      >
        <FireAuthProvider>
          <ModalsReduxProvider>
            <DefaultSeo {...defaultSEO} />
            {getLayout(<Component {...pageProps} />)}
          </ModalsReduxProvider>
        </FireAuthProvider>
      </ErrorBoundary>
    </ChakraProvider>
  )
}
