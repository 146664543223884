import React from 'react'
import APPVARS from '@/constants/appvars'
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  VStack,
  HStack,
  Box,
  Flex
} from '@chakra-ui/react'
import { FormFieldGrid } from '@/forms'
const FieldInputEmail = ({
  name,
  label,
  error,
  children,

  labelInline,
  showLabel = true
}) => {
  return (
    <FormControl isInvalid={error}>
      <Flex
        display="flex"
        direction={labelInline ? 'row' : 'column'}
        justifyContent="flex-start"
        alignItems="center"
      >
        {showLabel && (
          <FormLabel
            display="flex"
            alignItems={labelInline ? 'center' : 'flex-start'}
            textStyle="fieldLabel"
            htmlFor={name}
            h={APPVARS.FORM_FIELD_VALUES.LABEL_HEIGHT}
            w={labelInline ? 40 : 'full'}
            m={0}
          >
            {showLabel ? label : ''}
          </FormLabel>
        )}
        {children}
      </Flex>

      <FormErrorMessage textStyle="body3" fontStyle="italic" pl="12px">
        {/* {error && error.message} */}
        Enter valid email address
      </FormErrorMessage>
    </FormControl>
  )
}
export default FieldInputEmail
