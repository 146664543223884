import {
  Box,
  Grid,
  GridItem,
  Flex,
  Link,
  Stack,
  Text,
  Heading,
  Spacer,
  VStack,
  Button,
  Divider,
  StackDivider,
  AspectRatio
} from '@chakra-ui/react'

import Image from 'next/image'
import APPVARS from '@/constants/appvars'
import { ButtonMain } from '@/components'
import { Headline, Subheadline, Copy } from '@/elements'
import { getPaddingY } from 'theme/helpers/get-padding'

const templateAreas = {
  base: `'media' 'content'`,
  md: `'media  content  content'`
}
const flipTemplateAreas = {
  base: `'media' 'content'`,
  md: `'content content   media'`
}
export default function FlipResourceColumn({
  resourceSlug,
  resourceHeadline,
  resourceSubheadline,
  resourceCopy,
  resourceCtaLink,
  resourceCtaLabel,
  resourceImage,
  flip
}) {
  return (
    <Grid
      py={APPVARS.HRZ.XL}
      w="100%"
      gap="6"
      className="FLIP_RESOURCE_COLUMN"
      templateColumns={{ base: '1fr', md: '1fr  1fr  1fr' }}
      templateRows="1fr"
      templateAreas={flip ? flipTemplateAreas : templateAreas}
    >
      <VStack
        textAlign="left"
        spacing={APPVARS.HRZ.LG}
        gridArea="content"
        alignItems="flex-start"
      >
        <Box>
          {resourceHeadline && (
            <Headline source={resourceHeadline} textStyle="head5" />
          )}
          {resourceSubheadline && (
            <Subheadline
              mt={APPVARS.HRZ.LG}
              source={resourceSubheadline}
              textStyle="body1"
              color="gray.400"
            />
          )}
          {resourceCopy && (
            <Copy mt={APPVARS.HRZ.MD} source={resourceCopy} textStyle="body2" />
          )}
        </Box>

        <Flex w="100%">
          <Link href={resourceCtaLink} target="_blank" m={0} p={0}>
            <Button
              mt={{ base: 2, md: 5 }}
              w={{ base: '100%', sm: 32 }}
              variant="outline"
              target="_blank"
            >
              {resourceCtaLabel ? resourceCtaLabel : 'Read More'}
            </Button>
          </Link>
        </Flex>
      </VStack>

      <AspectRatio gridArea="media" ratio={{ base: 16 / 9, md: 1 }}>
        <Image
          src={resourceImage.url}
          alt={resourceImage.title}
          title={resourceImage.title}
          layout="fill"
          objectFit="cover"
        />
      </AspectRatio>
    </Grid>
  )
}
