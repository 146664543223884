import { useState, useEffect } from 'react'
import { useFireAuth } from '@/lib/fireAuth'

const useAccountActivity = () => {
  const [specialData, setSpecialData] = useState()
  const [hookStatus, setHookStatus] = useState('loading')
  const { status, member, team } = useFireAuth()

  useEffect(() => {
    if (status === 'loggedIn' && team) {
      if (team?.specials?.length > 0) {
        setSpecialData(team.specials)
      }
      //const sdata = team.specials.length > 0 ? team.specials : []
      //setSpecialData ([])
      setHookStatus('ready')
    }
  }, [setHookStatus, status, team, specialData])

  return {
    hookStatus,
    member,
    team,
    specialData
  }
}

export default useAccountActivity
