import React from 'react'
import { Flex, Button } from '@chakra-ui/react'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'

import { fsdb } from '@/lib/firebase'

import NiceModal from '@ebay/nice-modal-react'
import MODALS from '@/constants/modals'

import { useCheckBeforeBuy } from '@/hooks'
import modal from 'theme/components/modal'

const BuyProductPanel = ({ product }) => {
  const { team, productObject, odAccountStatus } = useCheckBeforeBuy(product)

  const handleBlockerModal = async () => {
    NiceModal.show(`${MODALS.BUY_BLOCKER_MODAL}`, {
      variant: 'medium',
      hasExit: true
    })
  }

  const continueBuyClick = async () => {
    try {
      await addDoc(collection(fsdb, 'productClicks'), {
        ...productObject,
        orderCreateDate: serverTimestamp()
      }).then(() => {
        window.open(productObject.productCtaLink, '_blank').then(() => {
          modal.remove()
        })
      })
    } catch (error) {
      console.log('error', error)
    }
  }
  const clickClaimItFirst = async (specialId) => {
    try {
      NiceModal.show(`${MODALS.CASHBACK_ARTICLE_MODAL}`, {
        id: specialId,
        variant: 'normal',
        hasExit: true
      }).then(() => {
        continueBuyClick()
      })
    } catch (error) {
      console.log('error', error)
    }
  }
  const handleClickBuy = async () => {
    const specialType = _.get(productObject, 'specialType', null)
    const currentSpecialId = _.get(productObject, 'specialId', null)
    const specials = _.get(team, 'specials', [])
    const someLeft = _.get(productObject, 'someLeft', 0)
    const findSpecial = _.find(specials, function (o) {
      return o.id === currentSpecialId
    })
    const isSpecialClaimed = _.isUndefined(findSpecial) ? false : true
    if (specialType === 'CASH_BACK' && !isSpecialClaimed && someLeft > 0) {
      clickClaimItFirst(currentSpecialId)
    } else {
      continueBuyClick()
    }
  }

  if (_.get(productObject, 'productVendor') === 'OD') {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        className="BuyButtonBox"
        w="100%"
      >
        {odAccountStatus === 'CONNECTED' ? (
          <Button size="lg" variant="buy-fprod" onClick={handleClickBuy}>
            Buy Now
          </Button>
        ) : (
          <Button size="lg" variant="buy-fprod" onClick={handleBlockerModal}>
            Buy Now
          </Button>
        )}
      </Flex>
    )
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      className="BuyButtonBox"
      w="100%"
    >
      <Button size="lg" variant="buy-fprod" onClick={handleClickBuy}>
        Buy Now
      </Button>
    </Flex>
  )
}
export default BuyProductPanel
