import { gql } from 'graphql-request'

import { PersonFrag, PartnershipFrag } from './models'
import {
  ModalBlockFrag,
  GridBlockFrag,
  HeroBlockFrag,
  CustomBlockFrag,
  InfoBlockFrag,
  CardBlockFrag,
  RichTextBlockFrag
} from './blocks'

/** MODEL: Article */
export const ArticleFeaturedFrag = gql`
  fragment ArticleFeaturedFrag on Article {
    id
    slug
    articleName
    articleHomeSubtitle
    articleCategory
    articleHeadline
    articleSubheadline
    articleNote
    articleCopy
    articleBlockWidth
    publishedOn
    sortRank
    popularity
    phase
    articleSticker
    articleTitleRich {
      raw
      html
      markdown
      text
    }
    articleImages(first: 1) {
      id
      width
      height
      url
      imageName
      fileName
      mimeType
      size
    }
  }
`
export const ArticleFrag = gql`
  fragment ArticleFrag on Article {
    id
    slug
    articleName
    articleHomeSubtitle
    articleCategory
    articleHeadline
    articleSubheadline
    articleNote
    articleCopy
    articleBlockWidth
    publishedOn
    sortRank
    popularity
    phase
    articleSticker
    articleTitleRich {
      raw
      html
      markdown
      text
    }
    articleImages(first: 1) {
      id
      width
      height
      url
      imageName
      fileName
      mimeType
      size
    }
    articleAuthors {
      ... on Person {
        ...PersonFrag
      }
    }
    partnership {
      ...PartnershipFrag
    }
  }
  ${[PersonFrag, PartnershipFrag]}
`

export const ArticleHomePopFrag = gql`
  fragment ArticleHomePopFrag on Article {
    id
    slug
    articleName
    articleHeadline
    articleSubheadline
    articleCopy
    articleHomeSubtitle
    articleBlockWidth
    articleCategory
    featured
    publishedOn
    sortRank
    popularity
    phase
    articleSticker
    articleImages(first: 1) {
      id
      width
      height
      url
      imageName
      fileName
      mimeType
      size
    }
  }
`
export const ArticlesIndexFrag = gql`
  fragment ArticlesIndexFrag on Article {
    id
    slug
    articleName
    articleHeadline
    articleSubheadline
    articleHomeSubtitle
    articleCategory
    featured
    publishedOn
    sortRank
    popularity
    articleSticker
    articleImages(first: 1) {
      id
      width
      height
      url
      imageName
      fileName
      mimeType
      size
    }
  }
`

export const ArticleSectionFrag = gql`
  fragment ArticleSectionFrag on Section {
    id
    sectionName
    sectionSlug
    layerStyle
    sectionComponent
    sectionImageAlign
    sectionImage {
      id
      width
      height
      url
      imageName
      fileName
      mimeType
      size
    }
    sectionBackground {
      id
      width
      height
      url
      imageName
      fileName
      mimeType
      size
    }
    sectionBlocks {
      __typename
      ... on CardBlock {
        ...CardBlockFrag
      }
      __typename
      ... on InfoBlock {
        ...InfoBlockFrag
      }
      __typename
      ... on RichTextBlock {
        ...RichTextBlockFrag
      }
      __typename
      ... on Modal {
        ...ModalBlockFrag
      }
      __typename
      ... on CustomBlock {
        ...CustomBlockFrag
      }
      __typename
      ... on HeroBlock {
        ...HeroBlockFrag
      }
      __typename
      ... on GridBlock {
        ...GridBlockFrag
      }
    }
  }
  ${[
    CardBlockFrag,
    InfoBlockFrag,
    ModalBlockFrag,
    GridBlockFrag,
    HeroBlockFrag,
    CustomBlockFrag,
    RichTextBlockFrag
  ]}
`
