import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  VStack,
  Text,
  Box,
  HStack,
  ModalHeader
} from '@chakra-ui/react'
import NiceModal, {
  useModal,
  show,
  create,
  remove,
  register
} from '@ebay/nice-modal-react'
import { removeConnectedAccount, addConnectedAccount } from '@/lib/teamAccounts'
import {
  ConnectVendorAccount,
  CreateNewVendorAccount,
  DisconnectOfficeDepotAccount
} from '@/modules'
import { useFireAuth } from '@/lib/fireAuth'

export default NiceModal.create(
  ({ modalSize = 'container.md', teamAccountId, account }) => {
    const [isWorking, setIsWorking] = useState(false)
    const { team } = useFireAuth()
    //console.log('account', account)
    const { status, vendorName, vendorDescription } = account

    const modal = useModal()
    // const [step, setStep] = useState(0)
    const [hasODA, setHasODA] = useState(true)
    //console.log('In create OD')

    const showDisconnect = status === 'CONNECTED' ? true : false

    return (
      <Modal
        default
        variant="wide"
        size={showDisconnect ? 'xl' : '5xl'}
        isOpen={() => modal.show()}
        onClose={() => modal.remove()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton onClick={() => modal.remove()} />
          {showDisconnect ? (
            <ModalBody>
              <DisconnectOfficeDepotAccount
                handleCancelAction={() => modal.remove()}
                teamAccountId={team.teamAccountId}
                account={account}
                vendorDescription={vendorDescription}
              />
            </ModalBody>
          ) : (
            <ModalBody>
              <VStack spacing="10">
                {hasODA ? (
                  <ConnectVendorAccount
                    onAction={() => setHasODA(false)}
                    fromModal={true}
                    vendorName={vendorName}
                    teamAccountId={team.teamAccountId}
                    handleNextAction={() => modal.remove()}
                  />
                ) : (
                  <CreateNewVendorAccount
                    fromModal={true}
                    handleNextAction={() => modal.remove()}
                    handBackAction={() => setHasODA(true)}
                  />
                )}
              </VStack>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    )
  }
)
