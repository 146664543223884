import React, { useState, useEffect, useCallback } from 'react'
import router, { useRouter } from 'next/router'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Button,
  VStack,
  HStack
} from '@chakra-ui/react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { ButtonLink, ButtonMain } from '@/components'
import ROUTES from '@/constants/routes'
import { LinkExternal } from '@/elements'

export default NiceModal.create(({ name, variant, hasExit = false }) => {
  const modal = useModal()

  return (
    <Modal
      closeOnOverlayClick={false}
      blockScrollOnMount={true}
      closeOnEsc={false}
      variant={variant}
      isOpen={() => modal.show()}
      onClose={() => modal.remove()}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <VStack spacing={10} align="center">
            <VStack spacing="10px">
              <Box textStyle="head3">Create An account</Box>
              <Box textStyle="body1">
                You still need to register your business to create an account.
                If you are having trouble, our customer service team can help.
              </Box>
            </VStack>
            <ButtonMain
              variant="solid"
              label="Create Account"
              href={ROUTES.ONBOARDING_NEW_MEMBER_CREATE_TEAM_ACCOUNT}
            >
              Create Account
            </ButtonMain>

            <HStack spacing="10">
              <LinkExternal variant="form-link" href={ROUTES.CONTACT_US}>
                CONTACT SUPPORT
              </LinkExternal>
              <ButtonLink variant="form-link" to={ROUTES.HOME}>
                BACK TO HOME
              </ButtonLink>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
})
