import React from 'react'
import { FormErrorMessage, FormLabel, FormControl } from '@chakra-ui/react'

const FieldInput = ({ name, label, error, children }) => {
  return (
    <FormControl isInvalid={error}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      {children}
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}
export default FieldInput
