import { Box, VStack, useToken } from '@chakra-ui/react'

import _ from 'lodash'

import { SectionImage } from '@/elements'
import { BlockWrapper } from '@/wrappers'
const _getBg = (sectionBackground, bg) => {
  if (!sectionBackground) {
    return {
      bg: { bg }
    }
  }
  const { url } = sectionBackground
  return {
    backgroundImage: `url(${url})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }
}

export function FooterSection({ section }) {
  const [bg] = useToken('layerStyle', [_.toLower(section.layerStyle)])
  const backclass = _getBg(section.sectionBackground, bg)

  return (
    <Box
      className="FOOTER_SECTION"
      id={section.sectionSlug}
      layerStyle={section.layerStyle ? section.layerStyle : 'default'}
      w="100%"
      {...backclass}
    >
      {section?.sectionImage && (
        <Box justifyContent="center" mx="auto" w="100%">
          {section?.sectionImage && <SectionImage {...section.sectionImage} />}
        </Box>
      )}

      <VStack className="FOOTER_SECTION_BLOCKS" spacing={{ base: 5, sm: 10 }}>
        {section.sectionBlocks && (
          <BlockWrapper blocks={section.sectionBlocks} />
        )}
      </VStack>
    </Box>
  )
}

export default function FooterSectionsContainer({ sections }) {
  if (!sections) {
    return null
  }
  return (
    <Box className="FOOTER_SECTIONS_CONTAINER" flexGrow="1" w="100%">
      {sections.map((section) => (
        <FooterSection key={section.id} section={section} />
      ))}
    </Box>
  )
}
