import { MDXRemote } from 'next-mdx-remote'
import { RichText } from '@graphcms/rich-text-react-renderer'
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react'
import APPVARS from '@/constants/appvars'
const copycomp = {
  p: (props) => <Box textStyle="body2" w="100%" textAlign="left" {...props} />
}
const headcomp = {
  p: (props) => <Box textStyle="head5" w="100%" textAlign="left" {...props} />
}
const subcomp = {
  p: (props) => <Box textStyle="head6" w="100%" textAlign="left" {...props} />
}
// const cardcolhead = {
//   ol: (props) => <Box m="0" p="0" {...props} />,
//   li: (props) => <ListItem {...props} />
// }
export default function FAQBlock({
  columnComponent,
  columns,
  gridTextAlign,
  gridRichText,
  gridMdText,
  gridSlug,
  gridTag,
  gridName,
  gridGap,
  blockWidth,
  gridSpacing = 'md',
  layout = 'STACK',
  cols = 1,
  spacing = '28'
}) {
  //const maxBlockWidth = APPVARS.MAX_BLOCK_WIDTH
  //const paddingY = getPaddingY(gridSpacing)
  return (
    <Box
      // py={paddingY}
      py="20"
      className={gridSlug}
      width="100%"
      overflow="hidden"
      px={{ base: '20px', md: 0 }}
    >
      {gridRichText && (
        <Box mb="10" textAlign={gridTextAlign}>
          <RichText
            content={gridRichText.json}
            renderers={{
              h3: ({ children }) => <Box textStyle="head3">{children}</Box>,
              bold: ({ children }) => <strong>{children}</strong>
            }}
          />
        </Box>
      )}
      {gridMdText && (
        <Box mb="10">
          <MDXRemote {...gridMdText.mdx} components={mdText} />
        </Box>
      )}
      <Box className={`GRID_COL_${columnComponent}`} position="relative">
        <Accordion allowMultiple allowToggle>
          {columns.map((column, index) => (
            <AccordionItem key={column.id}>
              <Box textStyle="head2">
                <AccordionButton py="5" px="0">
                  <Box flex="1" textAlign="left">
                    {column.faqHeadline && (
                      <MDXRemote
                        {...column.faqHeadline.mdx}
                        components={headcomp}
                      />
                    )}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Box>
              <AccordionPanel pb={4}>
                {column.faqSubheadline && (
                  <MDXRemote
                    {...column.faqSubheadline.mdx}
                    components={subcomp}
                  />
                )}
                {column.faqCopy && (
                  <MDXRemote
                    w="100%"
                    {...column.faqCopy.mdx}
                    components={copycomp}
                  />
                )}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
    </Box>
  )
}
