import APPVARS from '@/constants/appvars'

import { PopularWrapper, LatestWrapper, FeaturedWrapper } from '@/wrappers'

export default function HomeSectionsContainer({
  sections,
  featuredArticles,
  mostPopularArticles,
  latestArticles
}) {
  const yPad = APPVARS.VRT['2XL']
  const xPad = APPVARS.VRT['2XL']

  if (!sections) {
    return null
  }
  const homeSections = sections.map((section) => {
    const secComp = section.sectionComponent
    if (secComp === 'HomeSectionFeatured') {
      return (
        <FeaturedWrapper
          key="featured"
          section={section}
          articles={featuredArticles}
          yPad={yPad}
          xPad={xPad}
        />
      )
    }
    if (secComp === 'HomeSectionPopular') {
      return (
        <PopularWrapper
          key="popular"
          section={section}
          articles={mostPopularArticles}
          yPad={yPad}
          xPad={xPad}
        />
      )
    }
    return (
      <LatestWrapper
        key="latest"
        section={section}
        articles={latestArticles}
        yPad={yPad}
        xPad={xPad}
      />
    )
  })

  return <>{homeSections}</>
}
