import {
  Box,
  VStack,
  Grid,
  Stack,
  GridItem,
  AspectRatio
} from '@chakra-ui/react'
import Image from 'next/image'
import _ from 'lodash'
import { MDXRemote } from 'next-mdx-remote'
import { useStyleConfig } from '@chakra-ui/react'
// import Button from '@/components/button'
import { ButtonMain } from '@/components'
import { Headline, Subheadline, Copy } from '@/elements'
import { APPVARS } from '@/constants/appvars'

const copyComponents = {
  p: (props) => (
    <Box layerStyle="hero" textStyle="body1" w="100%" mx="auto" {...props} />
  )
}
const headlineComponents = {
  p: (props) => (
    <Box w="100%" layerStyle="hero" textStyle="head1" mx="auto" {...props} />
  )
}

const subheadlineComponents = {
  p: (props) => (
    <Box layerStyle="hero" textStyle="body1" w="100%" mx="auto" {...props} />
  )
}

const HeroBlock = ({
  cardName,
  heroHeadline,
  heroSubheadline,
  heroCopy,
  heroNote,
  heroImage,
  heroTextAlign,
  buttons
}) => {
  //console.log('heroTextAlign', heroTextAlign)
  //const heroStyles = useStyleConfig('Card', { variant: 'hero' })

  return (
    <Box className="HERO_BLOCK" w="100%" mx="auto">
      <Grid
        className="HERO_BLOCK_GRIDDDDD"
        w="100%"
        templateColumns="repeat(auto-fit, minmax(300px, 394px))"
        alignItems="center"
        justifyContent="center"
        gap={{ base: 0, lg: 4, xl: 6 }}
      >
        <GridItem alignItems="stretch" sx={{ textAlign: `${heroTextAlign}` }}>
          {heroHeadline && (
            <Box textAlign={heroTextAlign} marginBottom="5">
              <MDXRemote
                {...heroHeadline.mdx}
                components={headlineComponents}
              />
            </Box>
          )}
          {heroSubheadline && (
            <Box textAlign={heroTextAlign} marginBottom="5">
              <MDXRemote
                textAlign={heroTextAlign}
                {...heroSubheadline.mdx}
                components={heroSubheadline}
              />
            </Box>
          )}
          {heroCopy && (
            <Box textAlign={heroTextAlign} marginBottom="5">
              <MDXRemote
                {...heroCopy.mdx}
                components={copyComponents}
                textAlign={heroTextAlign}
              />
            </Box>
          )}
        </GridItem>
        {heroImage && (
          <AspectRatio maxWidth="395px">
            {heroImage && (
              <Image
                src={heroImage.url}
                alt={heroImage.imageName}
                title={heroImage.imageName}
                layout="fill"
                priority={true}
                objectFit="contain"
              />
            )}
          </AspectRatio>
        )}

        {buttons && (
          <VStack justifyContent="center" spacing="3">
            {buttons &&
              buttons.map((button) => {
                return (
                  <Box key={button.id} className="bbb">
                    <ButtonMain {...button} />
                  </Box>
                )
              })}
            {heroNote && (
              <Copy
                layerStyle="hero"
                textStyle="body3"
                color="white"
                fontStyle="italic"
                source={heroNote}
              />
            )}
          </VStack>
        )}
      </Grid>
    </Box>
  )
}
export default HeroBlock
