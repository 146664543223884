import React, { useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useRouter } from 'next/router'
import { signInSimple } from '@/lib/firebase'
import { AlertBox } from '@/elements'
import { ForgotPassword } from '@/modules'
import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri'
import { signInSchema } from '@/constants/form-schemas'
import { FieldInputEmail, FieldInputPassword, SubmitBtn } from '@/forms'
import {
  IconButton,
  Link as ChakraLink,
  Flex,
  useBreakpointValue
} from '@chakra-ui/react'
import { getAlertHtmlContent } from '@/helpers'
import {
  Box,
  Text,
  Input,
  Button,
  InputRightElement,
  VStack,
  InputGroup
} from '@chakra-ui/react'

const signInDefaults = yup.object().shape({
  email: '',
  password: ''
})
const SignInForm = ({ onFormComplete, switchScreen }) => {
  const [errorBox, setErrorBox] = useState()

  const [showAlertBox, setShowAlertBox] = useState(false)
  const [show, setShow] = React.useState(false)

  const handleShowPassword = () => setShow(!show)

  const {
    register,
    formState: { errors, isSubmitting, isValid, isDirty },
    handleSubmit
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(signInSchema)
  })

  const onSignInSubmit = async (data) => {
    const { email, password } = data

    signInSimple(email, password)
      .then(() => {
        // Signed in
        onFormComplete()
        // ...
      })
      .catch((error) => {
        const errorCode = error.code
        console.log('errorCode', errorCode)
        const errorHtml = getAlertHtmlContent({
          type: 'AUTH',
          code: errorCode,
          msg: error.message
        })

        setErrorBox({ type: 'error', msg: errorHtml })
        setShowAlertBox(true)
      })
  }

  return (
    <VStack
      as="form"
      spacing="10"
      onSubmit={handleSubmit(onSignInSubmit)}
      align="start"
    >
      <Box textStyle="head3">Welcome Back to Elevate</Box>

      <VStack w="100%" spacing="3">
        <FieldInputEmail
          // labelInline={false}
          name="email"
          label="Email"
          error={errors.email}
        >
          <Input
            name="email"
            placeholder="email@domain.com"
            {...register('email')}
          />
        </FieldInputEmail>

        <FieldInputPassword
          label="Password"
          error={errors.password}
          // labelInline={false}
        >
          <InputGroup>
            <Input
              name="password"
              type={show ? 'text' : 'password'}
              placeholder="********"
              {...register('password')}
            />
            {/* insert checkbox to show password*/}

            <InputRightElement>
              <IconButton
                aria-label="hide password"
                onClick={handleShowPassword}
                variant="icon-simple"
                icon={show ? <RiEyeCloseLine /> : <RiEyeLine />}
              />
            </InputRightElement>
          </InputGroup>
        </FieldInputPassword>
        <Box alignSelf="flex-end">
          <Button
            id="signin-forgot-psswd"
            variant="link"
            size="sm"
            onClick={switchScreen}
          >
            Forgot Password?
          </Button>
        </Box>

        {showAlertBox && (
          <AlertBox status={errorBox.type}>
            <Box textStyle="body1" dangerouslySetInnerHTML={errorBox.msg} />
          </AlertBox>
        )}
      </VStack>
      <SubmitBtn
        type="submit"
        label="Log In"
        id="signin-submit"
        isDisabled={!isDirty || !isValid}
        isWorking={isSubmitting}
        variant="solid"
      />
    </VStack>
  )
}
export default SignInForm
