import React from 'react'
import { Box, StackDivider } from '@chakra-ui/react'
const NavMenuDivider = ({ isVertical }) => {
  if (isVertical) {
    return <StackDivider borderColor="gray.200" />
  }
  return (
    <Box mx="10px">
      <Box textStyle="nav-text1" color="grey.200">
        |
      </Box>
    </Box>
  )
}

export default NavMenuDivider
