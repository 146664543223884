import * as React from "react";

function SvgArrowDown(props) {
  return (
    <svg
      width={21}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.088 7.745a.831.831 0 011.175 0l2.884 2.878a.5.5 0 00.706 0l2.884-2.878a.831.831 0 011.175 1.176l-4.058 4.059a.5.5 0 01-.708 0L6.088 8.92a.831.831 0 010-1.176z"
        fill="#6E7685"
      />
    </svg>
  );
}

export default SvgArrowDown;
