import React from 'react'

import { LogoElevate } from '@/assets/svg'
import APPVARS from '@/constants/appvars'

import {
  Box,
  Flex,
  VisuallyHidden,
  LinkBox,
  LinkOverlay
} from '@chakra-ui/react'

import { NavBar } from '@/components'

const AppBarContainer = ({ children, navType, justify }) => {
  return (
    <Box
      className="APP_BAR_CONTAINER"
      as="header"
      pos="fixed"
      top="0"
      w="100%"
      h={APPVARS.NAV_BAR_HEIGHT}
      zIndex="999"
      align="center"
      bg="white"
      boxShadow="base"
    >
      <Flex
        maxW={{
          base: APPVARS.BLOCK_MAX_WIDTH_BASE,
          lg: APPVARS.BLOCK_MAX_WIDTH_FULL
        }}
        mx={{ base: 'auto' }}
        w="100%"
        h="100%"
        pt="22px"
        pb="18px"
        justifyContent="center"
      >
        {children}
      </Flex>
    </Box>
  )
}
const AppBar = ({ navType }) => {
  if (navType === 'public') {
    return (
      <AppBarContainer navType={navType} justify="space-between">
        <Flex
          dir="row"
          justifyContent={{ base: 'center', md: 'flex-start' }}
          flexGrow="1"
          height="36px"
        >
          <Box>
            <VisuallyHidden>EA</VisuallyHidden>
            <LogoElevate height="36px" width="110px" />
          </Box>
        </Flex>
      </AppBarContainer>
    )
  }

  if (navType === 'onboarding' || navType === 'landing') {
    return (
      <AppBarContainer navType={navType} justify="center">
        <Flex dir="row" justifyContent="center" flexGrow="0" height="36px">
          <LinkBox>
            <LinkOverlay href="/">
              <VisuallyHidden>EA</VisuallyHidden>
              <LogoElevate height="36px" width="110px" />
            </LinkOverlay>
          </LinkBox>
        </Flex>
      </AppBarContainer>
    )
  }

  return (
    <AppBarContainer navType={navType} justify="space-between">
      <Flex
        dir="row"
        justifyContent={{ base: 'center', md: 'flex-start' }}
        flexGrow="0"
        height="36px"
        pe={6}
      >
        <LinkBox>
          <LinkOverlay href="/">
            <VisuallyHidden>EA</VisuallyHidden>
            <LogoElevate height="36px" width="110px" />
          </LinkOverlay>
        </LinkBox>
      </Flex>
      <NavBar forModal={false} />
    </AppBarContainer>
  )
}

export default AppBar

// <Flex
//       className={`APP_BAR_CONTAINER-${navType}`}
//       as="header"
//       pos="fixed"
//       top="0"
//       w="100%"
//       minWidth="100vw"
//       h={APPVARS.NAV_BAR_HEIGHT}
//       zIndex="999"
//       justifyContent={justify}
//       align="center"
//       py={3}
//       px={6}
//       bg="white"
//       boxShadow="base"
//     > */}
