import { gql } from 'graphql-request'

const mutationSpecialProduct = gql`
  mutation mutationSpecialProduct(
    $id: ID!
    $claimedBy: [String!]
    $totalAvailable: Int
    $totalClaimed: Int
  ) {
    updateSpecialProduct(
      where: { id: $id }
      data: {
        claimedBy: $claimedBy
        totalAvailable: $totalAvailable
        totalClaimed: $totalClaimed
      }
    ) {
      id
      claimedBy
      totalAvailable
      totalClaimed
      totalIssued
    }
  }
`
export { mutationSpecialProduct }
