//import { Box } from '@chakra-ui/react'
import Button from './button'
import Link from './link'
import Box from './box'
import Card from './card'
import Modal from './modal'
import Input from './input'
import Tag from './tag'
import Checkbox from './checkbox'
import Divider from './divider'
// import Subtitle from './subtitle'
// import Bodytext from './bodytext'
// import Title from './title'
// import Pricetext from './pricetext'

const components = {
  Checkbox,
  Button,
  Link,
  Box,
  Card,
  Modal,
  Input,
  Tag,
  Divider

  // Subtitle,
  // Bodytext,
  // Title,
  // Pricetext
}
export default components
