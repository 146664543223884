import { mode } from '@chakra-ui/theme-tools'
// color: mode('black', 'whiteAlpha.900')(props),
// bg: mode('white', 'gray.800')(props),
const globalStyles = {
  global: (props) => ({
    body: {
      margin: 0,
      fontFamily: 'body',
      color: 'black',
      bg: 'white',
      transitionProperty: 'background-color',
      transitionDuration: 'normal',
      lineHeight: 'base'
    },
    h1: {
      fontFamily: 'heading',
      fontSize: ['lg2', 'lg3'],
      fontWeight: 'bold',
      lineHeight: 'base1'
    },
    h2: {
      fontFamily: 'heading',
      fontSize: ['lg1', 'lg2'],
      fontWeight: 'semibold',
      lineHeight: 'short2'
    },
    '*::placeholder': {
      color: mode('gray.400', 'whiteAlpha.400')(props)
    },
    '*, *::before, &::after': {
      borderColor: mode('gray.200', 'whiteAlpha.300')(props),
      wordWrap: 'break-word'
    }
  })
}
export default globalStyles
