import React, { useState } from 'react'
import { Button, Divider, Grid, GridItem } from '@chakra-ui/react'

import { updateMember } from '@/lib/firebase'

import { useFireAuth } from '@/lib/fireAuth'
import { MemberForm } from '@/modules'
import { PanelForm } from '@/forms'

const ProfileInformation = () => {
  const { member } = useFireAuth()
  const [isEditing, setIsEditing] = useState(false)
  const isOwner = member?.teamAccountRole === 'owner' ? true : false

  const onUpdate = async (data) => {
    //console.log('Data', data)
    const profileData = {
      location: data.location === '' ? member.location : data.location,
      phoneNumber:
        data.phoneNumber === '' ? member.phoneNumber : data.phoneNumber,
      email: data.email === '' ? member.email : data.email,
      firstName: data.firstName === '' ? member.firstName : data.firstName,
      lastName: data.lastName === '' ? member.lastName : data.lastName
      // stage: data.stage === '' ? teamAccount.stage : data.stage,
      // industry: data.industry === '' ? teamAccount.industry : data.location
    }
    await updateMember({ memberId: member.uid, memData: profileData })
    setIsEditing(!isEditing)
  }

  return (
    <PanelForm
      width="100%"
      className="member-information"
      title="Profile Information"
      actionBtn={
        <Button
          disabled={!isOwner}
          variant="form-link"
          size="sm"
          onClick={() => setIsEditing(!isEditing)}
        >
          {!isEditing ? 'EDIT' : 'CANCEL'}
        </Button>
      }
    >
      <Divider marginBottom={4} marginTop={2.5} />
      {member && (
        <MemberForm
          isEditing={isEditing}
          member={member}
          handleUpdate={onUpdate}
        />
      )}
    </PanelForm>
  )
}

export default ProfileInformation
