import { Box, VStack, HStack, Button } from '@chakra-ui/react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { MDXRemote } from 'next-mdx-remote'
import MODALS from '@/constants/modals'

import { ButtonMain } from '@/components'
const Body1 = (props) => {
  return <Box textStyle="body1" size="md2" {...props} />
}
const Body2 = (props) => {
  return <Box textStyle="body2" {...props} />
}
const components = {
  p: Body1
}

function createMarkup(someHtml) {
  return { __html: someHtml }
}

const ModalBlock = ({
  id,
  modalButtons,
  modalCopy,
  modalHeadline,
  modalSubheadline,
  modalRichContent,
  modalVariant,
  modalSize,
  modalNote
}) => {
  //const modal = useModal(`${MODALS.CUSTOM_MODAL}`)

  const handleModalOpen = async () => {
    NiceModal.show(`${MODALS.CUSTOM_MODAL}`, {
      id,
      modalButtons,
      modalCopy,
      modalHeadline,
      modalSubheadline,
      modalRichContent,
      modalVariant,
      modalSize,
      hasExit: true
    }).then((path) => {
      router.push(path)
    })
  }

  return (
    <Box mt="0" className="MODAL_BLOCK" align="center" w="100%">
      {modalButtons &&
        modalButtons.map((button) => (
          <Box key={button.id}>
            <ButtonMain {...button} onAction={handleModalOpen} />
          </Box>
        ))}

      {modalNote && (
        <Box mt="3" textStyle="note1">
          {modalNote}
        </Box>
      )}
    </Box>
  )
}
export default ModalBlock

// onClick={(e) =>
//   handleModalOpen(e, {
//     copy: modalCopy,
//     headline: modalHeadline,
//     subheadline: modalSubheadline,
//     content: modalRichContent,
//     size: modalSize
//   })
// }
