import React from 'react'
import _ from 'lodash'

import { Box, Flex, VStack, Button, Tooltip, Icon } from '@chakra-ui/react'
import { IoHelpCircleSharp } from 'react-icons/io5'
import useSWR from 'swr'
import NiceModal from '@ebay/nice-modal-react'
import { BsCheck } from 'react-icons/bs'
import MODALS from '@/constants/modals'
import { useFireAuth } from '@/lib/fireAuth'
import { getCashbackTitle } from '@/utils/_articleTools'

import { graphcmsMutantClient } from '@/graphql/_client'
import { getSpecialProductById } from '@/graphql/queries'
import { mutationSpecialProduct } from '@/graphql/mutations'
import { AlertBox } from '@/elements'
import { formatDateNormal } from '@/utils/_formatDates'

import { addSpecialProduct } from '@/lib/teamAccounts'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
// dayjs.extend(isSameOrBefore)

const SpecialTooltip = ({ tooltip }) => {
  if (!tooltip) {
    return null
  }
  const { tooltipCopy } = tooltip
  if (!tooltipCopy) {
    return null
  }

  return (
    <Tooltip hasArrow label={tooltipCopy} fontSize="sm3" bg="purple">
      <Box>
        <Icon as={IoHelpCircleSharp} w="24px" h="24px" color="grey.250" />
      </Box>
    </Tooltip>
  )
}
// const getSpecialProductById = `query getSpecialProductById($id: ID!) {
//   specialProduct(where: { id: $id }) {
//       dateActivated
//       dateDeactivated
//       id
//       specialLimit
//       specialMod
//       specialProductCopy
//       specialProductName
//       slug
//       specialProductRestrictions
//       specialType
//       tooltip {
//         id
//         tooltipAction
//         tooltipCopy
//         tooltipName
//         tooltipSlug
//         tooltipLayerStyle
//       }
//       totalAvailable
//       totalClaimed
//       totalIssued
//       claimedBy
//       product {
//         productName
//         price
//         productId
//         productSlug
//       }
//     }
//   }`

// const mutationSpecialProduct = gql`
//   mutation mutationSpecialProduct(
//     $id: ID!
//     $claimedBy: [String!]
//     $totalAvailable: Int
//     $totalClaimed: Int
//   ) {
//     updateSpecialProduct(
//       where: { id: $id }
//       data: {
//         claimedBy: $claimedBy
//         totalAvailable: $totalAvailable
//         totalClaimed: $totalClaimed
//       }
//     ) {
//       id
//       claimedBy
//       totalAvailable
//       totalClaimed
//       totalIssued
//     }
//   }
// `
const SpecialProductCard = ({ id, onFormComplete }) => {
  //console.log(`id`, id)
  const { team } = useFireAuth()
  const client = graphcmsMutantClient()

  const { data, error, mutate, isValidating } = useSWR(
    [getSpecialProductById, id],
    (query, id) => client.request(query, { id }),
    { revalidateOnFocus: false }
  )
  const claimCode = (variables) => {
    return client.request(mutationSpecialProduct, variables)
  }
  const handleBlockerModal = async () => {
    NiceModal.show(`${MODALS.BUY_BLOCKER_MODAL}`, {
      variant: 'medium',
      hasExit: true,
      fromClaim: true
    })
  }
  // vendorName,
  // orderCreateDate,
  // productId,
  // productName,
  // qtySold,
  // productPrice,
  // productProgramPrice,
  // listPrice = productPrice,
  // specialType,
  // specialMod,
  // specialLimit = 1
  async function handleClick() {
    const odAccountStatus = _.get(team, 'odAccountStatus', null)

    if (odAccountStatus !== 'CONNECTED') {
      return handleBlockerModal()
    }

    const vendorAccountId = _.get(team, 'vendorAccounts.OD.vendorAccountId')
    const teamAccountId = team.teamAccountId
    const numberIssued = data.specialProduct.totalIssued
    const idsClaimedAlready = data.specialProduct.claimedBy
    const alreadyClaimed = _.indexOf(idsClaimedAlready, teamAccountId)
    const claimedSpecials = _.get(team, 'specials', [])
    //console.log(`alreadyClaimed`, alreadyClaimed)
    if (alreadyClaimed === -1) {
      const newClaimedBy = idsClaimedAlready.concat(teamAccountId)
      const totalClaimed = newClaimedBy.length
      const totalAvailable = numberIssued - totalClaimed

      await claimCode({
        claimedBy: newClaimedBy,
        totalAvailable: totalAvailable,
        totalClaimed: totalClaimed,
        id
      })
      mutate()

      const specialProduct = data.specialProduct
      const productInfo = specialProduct.product
      dayjs.extend(utc)
      const currentSpecialRow = {
        vendorAccountId: vendorAccountId,
        teamAccountId: teamAccountId,
        dateClaimed: dayjs.utc().format(),
        id: specialProduct.id,
        slug: specialProduct.slug,
        specialProductName: specialProduct.specialProductName,
        specialType: specialProduct.specialType,
        specialMod: specialProduct.specialMod,
        productName: productInfo.productName,
        productPrice: productInfo.price,
        productId: productInfo.productId,
        productSlug: productInfo.productSlug,
        vendorCode: specialProduct.vendorCode
      }

      await addSpecialProduct({
        teamAccountId,
        claimedSpecials: claimedSpecials,
        newSpecial: currentSpecialRow
      })
    }
  }

  if (error) {
    return <AlertBox>{error.message}</AlertBox>
  }

  if (!data) return 'loading...'
  //console.log('data', data)
  const isClaimed = _.includes(
    data.specialProduct.claimedBy,
    _.get(team, 'teamAccountId')
  )
  const claimsLeft =
    data.specialProduct.totalIssued - data.specialProduct.totalClaimed
  //console.log(`claimsLeft`, claimsLeft)
  dayjs.extend(isSameOrBefore)

  const dateDeactivated = _.get(data, 'specialProduct.dateDeactivated', dayjs())
  const isActive = dayjs().isSameOrBefore(dayjs(dateDeactivated), 'day')
  //console.log(`isActive`, isActive)
  const claimsAvailable = claimsLeft > 0 ? claimsLeft : 0
  const isAvailable = claimsAvailable > 0 && isActive
  return (
    <VStack
      bg="white"
      w="100%"
      align="stretch"
      spacing={4}
      borderWidth="1px"
      borderStyle="solid"
      borderColor="grey.100"
      p="18px"
    >
      <Flex dir="column" justify="space-between" w="100%">
        <VStack spacing="0" align="stretch">
          <Box textStyle="subtitle2">
            {getCashbackTitle(data.specialProduct.specialMod)}
          </Box>
          <Box textStyle="body4" color="grey.300">
            {claimsAvailable} claims left
          </Box>
        </VStack>

        <Flex justify="space-between">
          {isAvailable && !isClaimed && (
            <Button
              size="sm"
              disabled={claimsAvailable < 1}
              isLoading={isValidating}
              variant="claimit"
              onClick={handleClick}
            >
              Claim It
            </Button>
          )}
          {isClaimed && (
            <Button
              size="sm"
              disabled={true}
              variant="claimit"
              rightIcon={<BsCheck ml="10px" size="24px" />}
            >
              Claimed
            </Button>
          )}
          {!isAvailable && !isClaimed && (
            <Button disabled={true} size="sm" variant="not-available">
              Not Available
            </Button>
          )}
        </Flex>
      </Flex>
      {isClaimed && (
        <Box textStyle="claimit" color="blue.400">
          You got it! Cash Back will be added to your balance if you purchase
          this item.
        </Box>
      )}
      <Flex dir="row" justify="space-between" alignItems="center" w="100%">
        {isActive ? (
          <Box textStyle="body4" color="grey.300">
            Expires on{' '}
            {formatDateNormal({ value: data.specialProduct.dateDeactivated })}
          </Box>
        ) : (
          <Box textStyle="body4" color="grey.300">
            Expired
          </Box>
        )}

        {data?.specialProduct && <SpecialTooltip {...data.specialProduct} />}
      </Flex>
    </VStack>
  )
}
export default SpecialProductCard
