import { Box } from '@chakra-ui/react'

const copycomp = {
  p: (props) => <Box textStyle="body2" w="100%" textAlign="center" {...props} />
}
const headcomp = {
  p: (props) => <Box textStyle="head5" w="100%" textAlign="center" {...props} />
}
const subcomp = {
  p: (props) => <Box textStyle="head6" w="100%" textAlign="center" {...props} />
}

export default function FAQColumn({ faqHeadline, faqSubheadline, faqCopy }) {
  return null
}
