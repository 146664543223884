import React from 'react'
import { Flex, Button } from '@chakra-ui/react'
import { BuyButton } from '@/components'

import { useRouter } from 'next/router'

import NiceModal from '@ebay/nice-modal-react'
import MODALS from '@/constants/modals'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { fsdb } from '@/lib/firebase'
import { useCheckBeforeBuy } from '@/hooks'

import dayjs from 'dayjs'

dayjs.extend(isSameOrBefore)
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

const BuyProduct = ({ product }) => {
  const { isReady, vendorCode, productObject, odAccountStatus } =
    useCheckBeforeBuy(product)

  const router = useRouter()
  const handlePurchaseBlocker = async () => {
    NiceModal.show(`${MODALS.BUY_BLOCKER_MODAL}`, {
      variant: 'medium',
      hasExit: true
    }).then((path) => {
      router.push(path)
    })
  }

  const handleODClickBuy = async () => {
    if (odAccountStatus === 'CONNECTED') {
      handleClickBuy()
    } else {
      handlePurchaseBlocker()
    }
  }

  const handleClickBuy = async () => {
    try {
      await addDoc(collection(fsdb, 'productClicks'), {
        ...productObject,
        orderCreateDate: serverTimestamp()
      }).then(() => {
        window.open(productObject.productCtaLink, '_blank').then(() => {
          modal.remove()
        })
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  if (!isReady) {
    return <BuyButton disabled label="Buy Now" />
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      className="BuyButtonBox"
      w="100%"
    >
      {vendorCode === 'OD' ? (
        <Button size="md" variant="buy-prod" onClick={handleODClickBuy}>
          Buy Now
        </Button>
      ) : (
        <Button size="md" variant="buy-prod" onClick={handleClickBuy}>
          Buy Now
        </Button>
      )}
    </Flex>
  )
}
export default BuyProduct
