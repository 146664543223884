const STAGES = [
  {
    value: 'idea-development',
    label: 'Idea Development'
  },
  {
    value: 'start-up',
    label: 'Start-up'
  },
  {
    value: 'growth',
    label: 'Growth'
  },
  {
    value: 'expansion',
    label: 'Expansion'
  },
  {
    value: 'maturity',
    label: 'Maturity'
  }
]
const INDUSTRIES = [
  {
    value: 'accounting-finance',
    label: 'Accounting/Finance'
  },
  {
    value: 'advertising-pr',
    label: 'Advertising/PR'
  },
  {
    value: 'agriculture',
    label: 'Agriculture'
  },
  {
    value: 'apparel-fashion',
    label: 'Apparel/Fashion'
  },
  {
    value: 'architecture-planning',
    label: 'Architecture/Planning'
  },
  {
    value: 'arts-crafts',
    label: 'Arts/Crafts'
  },
  {
    value: 'automotive',
    label: 'Automotive'
  },
  {
    value: 'banking-mortgage',
    label: 'Banking/Mortgage'
  },
  {
    value: 'building-construction',
    label: 'Building/Construction'
  },
  {
    value: 'business-services',
    label: 'Business Services'
  },
  {
    value: 'business-supplies-equipment',
    label: 'Business Supplies/Equipment'
  },
  {
    value: 'cannabis-products-services',
    label: 'Cannabis Products/Services'
  },
  {
    value: 'capital-markets-hedge-fund-private-equity',
    label: 'Capital Markets/Hedge Fund/Private Equity'
  },
  {
    value: 'civic-social-organization',
    label: 'Civic/Social Organization'
  },
  {
    value: 'computer-software-engineering',
    label: 'Computer Software/Engineering'
  },
  {
    value: 'consumer-electronics',
    label: 'Consumer Electronics'
  },
  {
    value: 'consumer-services',
    label: 'Consumer Services'
  },
  {
    value: 'defense-space',
    label: 'Defense/Space'
  },
  {
    value: 'design-graphic-design-web-design',
    label: 'Design/Graphic Design/Web Design'
  },
  {
    value: 'education',
    label: 'Education'
  },
  {
    value: 'entertainment',
    label: 'Entertainment'
  },
  {
    value: 'facilities-services',
    label: 'Facilities Services'
  },
  {
    value: 'fine-art',
    label: 'Fine Art'
  },
  {
    value: 'farming',
    label: 'Farming'
  },
  {
    value: 'food-beverage',
    label: 'Food/Beverage'
  },
  {
    value: 'health-fitness',
    label: 'Health/Fitness'
  },
  {
    value: 'hospitality',
    label: 'Hospitality'
  },
  {
    value: 'information-technology-it',
    label: 'Information Technology/IT'
  },
  {
    value: 'internet',
    label: 'Internet'
  },
  {
    value: 'legal-law',
    label: 'Legal/Law'
  },
  {
    value: 'leisure-travel',
    label: 'Leisure/Travel'
  },
  {
    value: 'manufacturing',
    label: 'Manufacturing'
  },
  {
    value: 'marketing-sales',
    label: 'Marketing/Sales'
  },
  {
    value: 'media',
    label: 'Media'
  },
  {
    value: 'medical',
    label: 'Medical'
  },
  {
    value: 'newspapers-journalism',
    label: 'Newspapers/Journalism'
  },
  {
    value: 'photography',
    label: 'Photography'
  },
  {
    value: 'packaging-containers',
    label: 'Packaging/Containers'
  },
  {
    value: 'printing',
    label: 'Printing'
  },
  {
    value: 'real-estate',
    label: 'Real Estate'
  },
  {
    value: 'restaurants',
    label: 'Restaurants'
  },
  {
    value: 'recreational-facilities-services',
    label: 'Recreational Facilities/Services'
  },
  {
    value: 'retail',
    label: 'Retail'
  },
  {
    value: 'staffing-recruiting',
    label: 'Staffing/Recruiting'
  },
  {
    value: 'tourism',
    label: 'Tourism'
  },
  {
    value: 'transportation',
    label: 'Transportation'
  },
  {
    value: 'utilities',
    label: 'Utilities'
  }
]

const EMPLOYEE_NUM = [
  {
    value: '0',
    label: 'Self Employeed'
  },
  {
    value: '10',
    label: '1 - 10'
  },
  {
    value: '24',
    label: '11 - 24'
  },
  {
    value: '50',
    label: '25 - 50'
  },
  {
    value: '200',
    label: '51 - 200'
  },
  {
    value: '200+',
    label: '200+'
  }
]

const ESTIMATED_SPEND = [
  {
    value: '10,000',
    label: '0 - $10,000'
  },
  {
    value: '25,000',
    label: '$10,001 - $25,000'
  },
  {
    value: '50,000',
    label: '$25,001 - $50,000'
  },
  {
    value: '100,000',
    label: '$50,001 - $100,000'
  },
  {
    value: '500,000',
    label: '$500,000+'
  }
]

const REVENUE = [
  {
    value: '500000',
    label: '0 - $500,000'
  },
  {
    value: '1000000',
    label: '$500,001 - $1,000,000'
  },
  {
    value: '5000000',
    label: '$1,000,001 - $5,000,000'
  },
  {
    value: '5000000+',
    label: '$5,000,000+'
  }
]

const TERMS_NEW_ODACCOUNT =
  "By clicking Submit, you will be allowing your account to share purchase history  with Excelerate America as part of your Elevate account. Data shared will be subject to Excelerate America's Privacy Policy. This allows Elevate to automatically verify that you have earned Cash Back."

const TERMS_CONNECT_ODACCOUNT =
  "By clicking Verify, you will be allowing your account to share purchase history  with Excelerate America as part of your Elevate account. Data shared will be subject to Excelerate America's Privacy Policy. This allows Elevate to automatically verify that you have earned Cash Back. "
export {
  REVENUE,
  ESTIMATED_SPEND,
  EMPLOYEE_NUM,
  STAGES,
  INDUSTRIES,
  TERMS_NEW_ODACCOUNT,
  TERMS_CONNECT_ODACCOUNT
}
