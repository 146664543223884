import React from 'react'

import { Center, Image, Box } from '@chakra-ui/react'

const LogoColumn = ({ companyName, companyLogo }) => {
  const {
    url,
    imageName = 'Logo',
    width = '144px',
    height = '44px'
  } = companyLogo
  return (
    <Center>
      <Image objectFit="fit" src={url} alt={imageName} />
    </Center>
  )
}

export default LogoColumn
