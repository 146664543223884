import { borderRadius } from 'polished'

const link = {
  baseStyle: {
    transitionProperty: 'common',
    transitionDuration: 'fast',
    transitionTimingFunction: 'ease-out',
    cursor: 'pointer',
    textDecoration: 'none',
    outline: 'none',
    fontWeight: 'semibold',
    _disabled: {
      color: 'gray.250',
      border: `2px solid`,
      borderColor: `gray.250`,
      cursor: 'not-allowed',
      boxShadow: 'none'
    },
    _hover: {
      textDecoration: 'none'
    },
    _focus: {
      boxShadow: 'outline'
    }
  },
  sizes: {
    lg: {
      minW: 12,
      fontSize: 'md1',
      px: '24px',
      py: '14px'
    },
    md: {
      minW: 25,
      fontSize: 'sm3'
    },
    sm: {
      h: 8,
      minW: 8,
      fontSize: 'sm2',
      px: 3
    }
  },
  variants: {
    navbtn: (props) => ({
      color: 'black',
      fontFamily: 'heading',
      textTransform: 'uppercase',
      textDecoration: 'none',
      lineHeight: 'base1',
      fontWeight: 400,
      _hover: {
        color: 'brand.primary',
        textDecoration: 'none'
      },
      _active: {
        color: 'brand.primary',
        fontWeight: 'semibold'
      },
      _disabled: {
        color: 'gray.250'
      }
    }),
    outline: (props) => ({
      color: 'brand.primary',
      border: `2px solid`,
      borderColor: `brand.primary`,
      fontWeight: 'medium',
      textDecoration: 'none',
      borderRadius: '4',
      _hover: {
        bg: 'yellow.500',
        color: 'brand.800',
        borderColor: 'brand.800',
        textDecoration: 'none'
      },
      _active: {
        color: 'brand.400',
        borderColor: `brand.400`
      },
      _disabled: {
        color: 'gray.250',
        border: `2px solid`,
        borderColor: 'gray.250'
      }
    }),
    normal: (props) => ({
      fontWeight: 'bold',
      bg: 'transparent',
      color: 'brand.primary',
      border: 'none',
      textDecoration: 'underline',
      _hover: {
        textDecoration: 'underline'
      }
    }),
    primary: (props) => ({
      fontWeight: 'medium',
      color: 'white',
      bg: 'brand.primary',
      border: `2px solid`,
      padding: '14px 24px',
      borderRadius: '4px',
      borderColor: `brand.primary`,
      _hover: {
        bg: 'brand.800',
        border: `2px solid`,
        borderColor: `brand.800`,
        textDecoration: 'none'
      },
      _active: {
        bg: 'brand.400',
        border: `2px solid`,
        borderColor: 'brand.400'
      },
      _disabled: {
        bg: 'gray.250',
        color: 'gray.300',
        border: `2px solid`,
        borderColor: 'gray.250'
      }
    }),
    link_footer: (props) => ({
      color: 'white',
      fontWeight: 'normal',
      textDecoration: 'none',
      textTransform: 'uppercase',
      _hover: {
        textDecoration: 'underline'
      },
      _active: {
        fontWeight: 'semibold'
      },
      _disabled: {
        color: 'gray.250',
        border: '0px'
      }
    }),
    'form-link': (props) => ({
      color: 'brand.primary',
      fontWeight: 'bold',
      textDecoration: 'underline',
      _hover: {
        borderColor: 'brand.primary'
      },
      _active: {
        fontWeight: 'bold'
      },
      _disabled: {
        color: 'gray.250',
        border: '0px'
      }
    }),
    'button-link': (props) => ({
      fontFamily: 'body',
      color: `brand.primary`,
      textDecoration: 'none',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      _hover: {
        textDecoration: 'underline'
      },
      _active: {
        fontWeight: 'normal'
      },
      _disabled: {
        color: 'gray.250',
        border: '0px'
      }
    }),
    'no-caps': (props) => ({
      fontFamily: 'body',
      color: 'blue.500',
      textDecoration: 'none',
      fontSize: 'md1',
      fontWeight: 'normal',
      _hover: {
        textDecoration: 'underline'
      },
      _active: {
        fontWeight: 'normal'
      },
      _disabled: {
        color: 'gray.250',
        border: '0px'
      }
    }),
    'contact-us': (props) => ({
      color: 'black',
      fontFamily: 'body',
      textTransform: 'uppercase',
      textDecoration: 'underline',
      fontWeight: 700,
      _hover: {
        textDecoration: 'underline'
      },
      _active: {
        color: 'brand.primary',
        fontWeight: 'semibold'
      },
      _disabled: {
        color: 'gray.250'
      }
    }),
    ghost: (props) => ({
      color: 'white',
      fontWeight: 'medium',
      textDecoration: 'none',
      border: '2px solid',
      borderRadius: '4px',
      borderColor: 'white',
      backgroundColor: 'transparent',
      _hover: {
        color: `brand.primary`,
        textDecoration: 'none',
        backgroundColor: 'white',
        borderColor: 'white'
      },
      _active: {
        color: `brand.primary`,
        textDecoration: 'none',
        backgroundColor: 'white',
        borderColor: 'white'
      },
      _disabled: {
        color: 'gray.250',
        border: '0px'
      }
    }),
    popup: (props) => ({
      color: 'elevate.500',
      fontWeight: 'normal',
      textDecoration: 'none',
      textTransform: 'uppercase',
      border: 'none',
      _hover: {
        textDecoration: 'underline'
      }
    }),

    rich: (props) => ({
      color: 'blue.600',
      fontWeight: 'normal',
      // textDecoration: 'underline',
      backgroundColor: 'transparent',
      margin: '0px',
      padding: '0px',
      fontSize: 'inherit',
      _hover: {
        color: `brand.primary`,
        textDecoration: 'none'
      },
      _active: {},
      _disabled: {
        color: 'gray.250',
        border: '0px'
      }
    })
    // 4. We can override existing variants
    // outline: (props) => ({
    //   bg: props.colorMode === 'dark' ? 'gray.300' : 'blue.400',
    //   color: props.colorMode === 'dark' ? 'blue.600' : 'white',
    // }),
  },
  defaultProps: {
    size: 'md',
    variant: 'navbtn'
  }
}
export default link
