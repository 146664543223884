import _ from 'lodash'
export function getArticleType(type) {
  switch (type) {
    case 'NLN':
      return 'Next Level News'

    case 'SEASONAL':
      return 'SEASONAL'

    default:
      return _.upperCase(type)
  }
}

export function getCashbackTitle(value) {
  const percent = value * 100
  return `Earn ${percent}% Cash Back`
}
