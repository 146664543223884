import React, { useState, useCallback } from 'react'
import { useRouter } from 'next/router'
import { LinkExternal } from '@/elements'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Button,
  VStack,
  HStack,
  Link
} from '@chakra-ui/react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import ROUTES from '@/constants/routes'

import { useFireAuth } from '@/lib/fireAuth'
const noVendorModalHeadline = 'Set Up Cash Back'
const noVendorModalCopy =
  'In order to earn Cash Back on this Office Depot purchase, you need to verify your Office Depot account number. Go to account settings to get set up.'
const noVendorModalCopyClaim =
  'In order to earn Cash Back and Claim It, you need to go to account settings to get set up.'
const PendingVendorModal = ({ handleAction }) => {
  return (
    <VStack spacing={10} align="center">
      <Box>
        <Box textStyle="head2">We’re working on it!</Box>
        <Box textStyle="body2">
          We’re working to create your Cash Back account. We will contact you as
          soon as your account is set up.
        </Box>
      </Box>

      <LinkExternal variant="form-link" href={ROUTES.CONTACT_US}>
        CONTACT SUPPORT
      </LinkExternal>
    </VStack>
  )
}

const NoVendorModal = ({ handleAction, fromClaim = false }) => {
  return (
    <VStack spacing={10} align="center">
      <Box>
        <Box textStyle="head2">Set Up Cash Back</Box>
        <Box textStyle="body2">
          {fromClaim ? noVendorModalCopyClaim : noVendorModalCopy}
        </Box>
      </Box>
      <Button
        variant="solid"
        onClick={() => handleAction(ROUTES.ACCOUNT_SETTINGS)}
      >
        Account Settings
      </Button>

      <LinkExternal variant="form-link" href={ROUTES.CONTACT_US}>
        CONTACT SUPPORT
      </LinkExternal>
    </VStack>
  )
}
export default NiceModal.create(
  ({ name, variant, hasExit = true, fromClaim = false }) => {
    const modal = useModal()
    const router = useRouter()
    const { status, team } = useFireAuth()

    const changeActionPath = useCallback(
      (path) => {
        // console.log('handleAction', path)
        router.push(path)
      },
      [router]
    )

    if (status === 'loading') {
      return <Box>Loading...</Box>
    }

    const odAccountStatus = _.get(team, 'odAccountStatus', null)

    return (
      <Modal
        closeOnOverlayClick={true}
        blockScrollOnMount={true}
        closeOnEsc={false}
        variant={variant}
        isOpen={() => modal.show()}
        onClose={() => modal.remove()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody className="BuyBlockerModal">
            {hasExit && <ModalCloseButton onClick={() => modal.hide()} />}
            {odAccountStatus === 'PENDING' ? (
              <PendingVendorModal handleAction={changeActionPath} />
            ) : (
              <NoVendorModal
                handleAction={changeActionPath}
                fromClaim={fromClaim}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
)
