import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { confirmEmailIsVerified } from '@/lib/firebase'

const RedirectToConnectBank = ({ qparams }) => {
  const router = useRouter()
  const [isActionCode, setActionCode] = useState()

  useEffect(() => {
    const oobCode = qparams
    setActionCode(oobCode)
    const onEmailIsVerified = async () => {
      confirmEmailIsVerified(oobCode)
        .then(() => {
          router.push('/account/connect-bank-account')
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
    onEmailIsVerified()
  }, [qparams, router])

  return <h1>loading</h1>
}
export default RedirectToConnectBank
