import React from 'react'

import _ from 'lodash'
import { Box, Switch, Text, HStack } from '@chakra-ui/react'

function vendorColor(status) {
  switch (status) {
    case 'connected':
      return '#1D4797'
    case 'ready':
    case 'not connected':
    case 'pending':
      return 'grey'
    case 'not verified':
      return '#FF0000' // red
    default:
      return 'blue'
  }
}

const VendorSwitch = ({ status, onChangeAction }) => {
  const vendorStatus = _.toLower(status)
  const vColor = vendorColor(vendorStatus)
  const isConnected = _.toLower(status) === 'connected'
  const isPending = _.toLower(status) === 'pending'
  const notVerified = _.toLower(status) === 'not verified'
  const isReady = _.toLower(status) === 'ready'
  const notConnected = _.toLower(status) === 'not connected'
  const alreadyConnected = _.toLower(status) === 'already connected'
  return (
    <>
      {isConnected && (
        <HStack>
          <Text textStyle="body2" textColor={vColor}>
            {_.toUpper(vendorStatus)}
          </Text>
          <Switch
            size="lg"
            colorScheme="blue"
            onChange={onChangeAction}
            isChecked={isConnected}
          />
        </HStack>
      )}

      {(isReady || notConnected || isPending || alreadyConnected) && (
        <HStack>
          <Text textStyle="body2" textColor={vColor}>
            {notConnected && _.toUpper(status)}
            {isPending && _.toUpper(status)}
            {alreadyConnected && _.toUpper(status)}
          </Text>
          <Switch
            size="lg"
            colorScheme={vColor}
            onChange={onChangeAction}
            isChecked={false}
          />
        </HStack>
      )}
      {(notVerified) && (
        <HStack>
          <Text textStyle="body2" textColor={vColor}>
            {_.toUpper(vendorStatus)}
          </Text>
          <Switch
            size="lg"
            colorScheme={vColor}
            onChange={onChangeAction}
            isChecked={false}
          />
        </HStack>
      )}
    </>
  )
}

export default VendorSwitch
