import { Box } from '@chakra-ui/react'
import * as Blocks from '@/blocks'
import APPVARS from '@/constants/appvars'

export default function BlockWrapper({ blocks, pageType = 'page' }) {
  // const maxBlockWidth = pageType === 'DASHBOARD' ? '964px' : '812px'
  //const maxBlockWidth = '964px'
  return (
    <Box
      className="BLOCK_WRAPPER"
      maxW={{
        base: APPVARS.BLOCK_MAX_WIDTH_BASE,
        lg: APPVARS.BLOCK_MAX_WIDTH_FULL
      }}
      mx={{ base: 'auto' }}
      w="100%"

      // spacing={{ base: '2', sm: '4', md: '6', lg: '8' }}
    >
      {blocks.map((block) => {
        //console.log('block', block)
        const blockComponent = block.blockComponent
          ? block.blockComponent
          : block.gridComponent

        const blockTypename = block?.__typename

        const blockRef = blockComponent ? blockComponent : blockTypename
        //console.log('blockREf', blockRef)
        const Component = Blocks[blockRef]
        if (!Component) return null

        return <Component key={block.id} {...block} />
      })}
    </Box>
  )
}
