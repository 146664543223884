import React from 'react'

import {
  FormControl,
  FormLabel,
  InputGroup,
  FormErrorMessage,
  Flex,
  Stack
} from '@chakra-ui/react'
import APPVARS from '@/constants/appvars'
const FieldInputPassword = ({
  name,
  label,
  error,
  children,
  labelInline = false
}) => {
  return (
    <FormControl isInvalid={error}>
      <Flex
        w={labelInline ? '100%' : 'auto'}
        direction={labelInline ? 'row' : 'column'}
        justifyContent="flex-start"
        alignItems="center"
      >
        {label && (
          <FormLabel
            display="flex"
            alignItems={labelInline ? 'center' : 'flex-start'}
            textStyle="fieldLabel"
            htmlFor={name}
            h={APPVARS.FORM_FIELD_VALUES.LABEL_HEIGHT}
            w={labelInline ? 40 : 'full'}
            m={0}
          >
            {label}
          </FormLabel>
        )}

        {children}
      </Flex>
      <FormErrorMessage textStyle="body3" fontStyle="italic" pl="12px">
        {error && error.message}
        {/* Enter valid password */}
      </FormErrorMessage>
    </FormControl>
  )
}

export default FieldInputPassword
