const MODALS = {
  SIGN_IN: 'sign-in-modal',
  SCROLL_BLOCKER_MODAL: 'scroll-blocker-modal',
  BUY_BLOCKER_MODAL: 'buy-blocker-modal',
  CUSTOM_MODAL: 'custom-modal',
  ONBOARD_NEW_MODAL: 'onboard-new-modal',
  CREATE_TEAM_TO_CONTINUE: 'create-team-to-continue',
  INVITE_YOUR_TEAM: 'invite-your-team',
  VENDOR_ACCOUNT_MODAL: 'vendor-account-modal',
  DELETE_YOUR_TEAM_MEMBER: 'delete-your-team-member',
  DELETE_BUSINESS_ACCOUNT: 'delete-business-account',
  DISCONNECT_VENDOR: 'disconnect-vendor',
  SET_UP_CASHBACK: 'set-up-cashback',
  CASHBACK_ARTICLE_MODAL: 'cashback-article-modal'
}

export default MODALS
// ALREADY_REGISTERED_BIZ: 'already-registered-biz',

// CREATE_VENDOR: 'create-vendor-modal',
// BLOCKER_MODAL: 'blocker-modal',
