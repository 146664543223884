function Number1() {
  return (
    <path
      d="M13.595 16.987H11.94v-7.99L9.5 9.83V8.43L13.383 7h.212v9.987z"
      fill="#fff"
    />
  )
}

function Number2() {
  return (
    <path
      d="M15.684 17.09H8.99v-1.142l3.316-3.616c.478-.533.818-.978 1.018-1.333.206-.36.308-.72.308-1.08 0-.474-.134-.857-.403-1.148-.265-.292-.622-.438-1.074-.438-.537 0-.954.164-1.25.492-.297.328-.445.777-.445 1.347H8.8c0-.606.137-1.15.41-1.634a2.869 2.869 0 011.183-1.135C10.908 7.134 11.5 7 12.17 7c.966 0 1.727.244 2.283.731.56.484.841 1.153.841 2.01 0 .497-.141 1.019-.424 1.566-.278.542-.736 1.16-1.374 1.852l-2.433 2.605h4.62v1.326z"
      fill="#fff"
    />
  )
}

function Number3() {
  return (
    <path
      d="M10.926 11.354h1.012c.51-.004.913-.136 1.21-.396.3-.26.45-.636.45-1.128 0-.474-.125-.84-.375-1.1-.246-.265-.625-.397-1.135-.397-.446 0-.813.13-1.1.39-.288.255-.431.59-.431 1.005H8.896c0-.51.134-.976.403-1.395.273-.42.652-.745 1.135-.978A3.688 3.688 0 0112.068 7c.993 0 1.772.25 2.338.752.57.497.854 1.19.854 2.078 0 .447-.144.868-.43 1.265-.283.392-.65.688-1.101.888.547.187.961.481 1.244.882.287.401.43.88.43 1.436 0 .893-.307 1.604-.922 2.133-.611.528-1.415.793-2.413.793-.957 0-1.741-.256-2.352-.766-.61-.51-.916-1.19-.916-2.037h1.661c0 .437.146.793.438 1.066.296.274.692.41 1.19.41.514 0 .92-.136 1.216-.41.296-.273.444-.67.444-1.19 0-.523-.155-.927-.465-1.21-.31-.282-.77-.423-1.38-.423h-.978v-1.313z"
      fill="#fff"
    />
  )
}
function Number4() {
  return (
    <path
      d="M14.146 13.207h1.127V14.8h-1.127v2.153H12.17V14.8H8.089L8 13.556 12.15 7h1.995v6.207zm-4.177 0h2.2V9.693l-.129.226-2.071 3.288z"
      fill="#fff"
    />
  )
}
const StepIcon = ({ stepNum, activeColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx={12} cy={12} r={12} fill={activeColor} />
      {stepNum === 1 && <Number1 />}
      {stepNum === 2 && <Number2 />}
      {stepNum === 3 && <Number3 />}
      {stepNum === 4 && <Number4 />}
    </svg>
  )
}
export default StepIcon
