import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  FormErrorMessage,
  FormControl,
  Button,
  VStack,
  Box,
  Grid,
  Flex,
  Text,
  Stack
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useFireAuth } from '@/lib/fireAuth'
//import SignupToSeeBenefitForm from 'modules/SelectBenefitForm'
import { ButtonMain } from '@/components'
import ROUTES from '@/constants/routes'
import NiceModal from '@ebay/nice-modal-react'
import MODALS from '@/constants/modals'
import Image from 'next/image'
import { BenefitsCtaArrow } from '@/assets/svg'
// import Benefit_ArrowCTA from '@/assets/imgs/CTA-arrow.svg'
import Benefit_Esther from '@/assets/imgs/Esther.png'
import blueCheck from '@/assets/icons/bluecheckmark.svg'
import { addBenefitsRequests } from '@/lib/teamAccounts'
import APPVARS from '@/constants/appvars'

const BenefitsItem = (props) => {
  const [clicked, setClicked] = useState(false)
  const { selectedList, setSelectedList, benefit, setIsButtonPressed } = props

  // console.log('benefits item props', props)
  const selectItem = () => {
    var array = selectedList
    if (clicked) {
      setClicked(false)
      setIsButtonPressed(false)
      array = array.filter(function (item) {
        return item !== benefit.benefitPartnerName
      })
      setSelectedList(array)
      // console.log('selectedList: ', selectedList)
    } else {
      if (benefit.benefitPartnerName) {
        array.push(benefit.benefitPartnerName)
      }
      setClicked(true)
      setIsButtonPressed(true)
      setSelectedList(array)
    }
  }

  return (
    <Button
      variant="pillbtn"
      mr={APPVARS.VRT.MD}
      mt={APPVARS.HRZ.SM}
      height="32px"
      isActive={clicked}
      onClick={selectItem}
    >
      {benefit.benefitPartnerName}
    </Button>
  )
}

const SelectBenefitForm = ({
  id,
  customBlockName,
  customBlockSlug,
  customBlockHeadline,
  customBlockSubheadline,
  customBlockCopy,
  customBlockLayerStyle,
  customBlockContent,
  customBlockJson,
  customBlockClass,
  customBlockSize,
  customBlockButtons,
  benefitFormBlockLayerStyle
}) => {
  const benefits = customBlockJson
  // console.log('Benefits customBlockJson', benefits)
  const { status, member, team } = useFireAuth()

  const {
    formState: { errors }
  } = useForm()

  const [list, setList] = useState([])
  const [selectedList, setSelectedList] = useState([])
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isButtonPressed, setIsButtonPressed] = useState(false)

  useEffect(() => {
    if (benefits) {
      setList(benefits)
    }
  }, [benefits])

  const router = useRouter()
  const goSignUp = () => {
    router.push(ROUTES.ONBOARDING_NEW_MEMBER_SIGN_UP)
  }
  const goSignIn = () => {
    const routerPath = router.asPath
    NiceModal.show(`${MODALS.SIGN_IN}`, {
      variant: 'normal',
      size: 'md',
      slug: routerPath
    })
      .then(() => {
        if (router.asPath === '/' || router.asPath === '/home') {
          NiceModal.remove(`${MODALS.SIGN_IN}`)
        } else {
          router.push(`${ROUTES.HOME}`)
        }
      })

      .catch(() => {
        //console.log('error', error)
      })
  }

  const submitList = async () => {
    const teamAccountId = member.teamAccountId
    try {
      await addBenefitsRequests({ selectedList, teamAccountId })
      setIsSubmitted(true)
    } catch (error) {
      console.log('error', error)
    }
  }

  if (status === 'loggedIn' && team) {
    return (
      <Grid
        layerStyle="form-panel"
        mt={APPVARS.HRZ.XL}
        mb={APPVARS.HRZ['3XL']}
        p={APPVARS.VRT.LG}
        w="100%"
        columnGap={APPVARS.VRT['2XL']}
        templateColumns={{
          base: '1fr',
          md: '244px  auto',
          lg: '244px  auto'
        }}
        // templateColumns="repeat(auto-fit, minmax(320px, 394px))"
        templateAreas={{
          base: `'media' 'content'`,
          md: `'media content'`,
          lg: `'media content'`
        }}
        templateRows={{ base: 'auto', md: '1fr' }}
        justifyItems="center"
      >
        <Box
          position="relative"
          gridArea="media"
          h={{ base: '394px', sm: '330px' }}
          w={{ base: '212px', sm: '244px' }}
          minWidth={{ base: '212px', sm: '244px' }}
          mb={APPVARS.HRZ.XL}
        >
          <Image
            layout="fill"
            objectFit="contain"
            alt="Esther"
            src={Benefit_Esther}
          />
        </Box>

        <VStack gridArea="content" as="form" spacing={APPVARS.HRZ.XL}>
          <VStack spacing={APPVARS.HRZ.LG}>
            <Text textStyle="head2b" width="100%">
              Interested in any of these benefits?
            </Text>
            <Text textStyle="body1">
              Our Elevate Service team lead, Esther Wickersham, can connect you
              to more information about each benefit. Simply select the benefits
              you&apos;d like to explore and click I&apos;m Interested. Esther
              will email you the details and happily answer any questions.
            </Text>
          </VStack>
          {isSubmitted === false ? (
            <FormControl isInvalid={errors.name}>
              {list.map((item, index) => {
                return (
                  <BenefitsItem
                    key={index}
                    benefit={item}
                    benefits={benefits}
                    selectedList={selectedList}
                    setSelectedList={setSelectedList}
                    isButtonPressed={isButtonPressed}
                    setIsButtonPressed={setIsButtonPressed}
                  />
                )
              })}
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
          ) : null}
          {isSubmitted === false ? (
            <Box w="100%">
              <Button
                variant="primary"
                h="46px"
                w="147px"
                isDisabled={!selectedList.length}
                onClick={submitList}
              >
                I&apos;m Interested
              </Button>
            </Box>
          ) : (
            <Flex w="100%">
              <Image alt="checkmark" src={blueCheck} h="24px" w="24px" />
              <Text textStyle="body2" color="brand.primary" ml="8px">
                Esther will send you an email with all the details shortly
              </Text>
            </Flex>
          )}
        </VStack>
      </Grid>
    )
  }
  return (
    <Grid
      layerStyle="form-panel"
      mt={APPVARS.HRZ.XL}
      mb={APPVARS.HRZ['3XL']}
      p={APPVARS.VRT.LG}
      w="100%"
      columnGap={APPVARS.VRT['2XL']}
      templateColumns={{
        base: '1fr',
        md: '244px  auto',
        lg: '244px  auto'
      }}
      // templateColumns="repeat(auto-fit, minmax(320px, 394px))"
      templateAreas={{
        base: `'media' 'content'`,
        md: `'media content'`,
        lg: `'media content'`
      }}
      templateRows={{ base: 'auto', md: '1fr' }}
      justifyItems="center"
    >
      <Box
        position="relative"
        gridArea="media"
        h={{ base: '212px', sm: '244px' }}
        w={{ base: '212px', sm: '244px' }}
        minWidth={{ base: '212px', sm: '244px' }}
        mb={{ base: APPVARS.HRZ.XL, md: '0' }}
      >
        <BenefitsCtaArrow width={244} height={244} />
        {/* <Image
          layout="fill"
          objectFit="contain"
          alt="Benefits"
          src={BenefitsCtaArrow}
        /> */}
      </Box>

      <VStack gridArea="content" as="form" spacing={APPVARS.HRZ.XL}>
        <VStack spacing={APPVARS.HRZ.LG}>
          {status === 'loggedIn' && !team ? (
            <Text textStyle="head2b" width="100%">
              Create An Account to Access These Benefits
            </Text>
          ) : (
            <Text textStyle="head2b" width="100%">
              Sign Up for Elevate to Access These Benefits
            </Text>
          )}

          {status === 'loggedIn' && !team ? (
            <Text textStyle="body1" width="100%" pb={APPVARS.HRZ.LG}>
              You still need to register your business to create an account.
              You&apos;ll also get Cash Back offers, exclusive specials, and
              product discovery when you join our small business subscription.
            </Text>
          ) : (
            <Text textStyle="body1" width="100%" pb={APPVARS.HRZ.LG}>
              You&apos;ll get Cash Back offers, exclusive specials, and product
              discovery when you join our small business subscription.
            </Text>
          )}

          <Stack
            direction={{ base: 'column', sm: 'row' }}
            spacing={APPVARS.HRZ.LG}
            mb={APPVARS.VRT.LG}
            w="100%"
          >
            {status === 'loggedIn' && !team ? (
              <ButtonMain
                size="md"
                variant="primary"
                label="Create Account"
                href={ROUTES.ONBOARDING_NEW_MEMBER_CREATE_TEAM_ACCOUNT}
              >
                Create Account
              </ButtonMain>
            ) : (
              <>
                <ButtonMain
                  variant="primary"
                  label="Sign Up"
                  size="md"
                  href={ROUTES.ONBOARDING_NEW_MEMBER_SIGN_UP}
                >
                  Sign Up
                </ButtonMain>
                <ButtonMain
                  variant="default"
                  label="Log In"
                  onAction={goSignIn}
                  size="md"
                >
                  Log In
                </ButtonMain>
              </>
            )}
          </Stack>
        </VStack>
      </VStack>
    </Grid>
  )
}

export default SelectBenefitForm
