import React from 'react'
import {
  Box,
  Button,
  VStack,
  Input,
  HStack,
  Select,
  FormControl
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { FieldInput, FormFieldGroup } from '@/forms'
import { updateTeamAccount } from '@/lib/teamAccounts'

import INDUSTRYSTAGE from '@/constants/industry-stage'
import MODALS from '@/constants/modals'

const TeamAccountForm = ({ isEditing, toggleEdit, team }) => {
  const {
    register,
    formState: { errors, isDirty, isSubmitting, touchedFields, submitCount },
    handleSubmit
  } = useForm({
    mode: 'onChange',
    defaultValues: { ...team }
  })

  var listStages = INDUSTRYSTAGE.STAGES
  var listIndustries = INDUSTRYSTAGE.INDUSTRIES

  const openDeleteModal = (value) => {
    NiceModal.show(`${MODALS.DELETE_BUSINESS_ACCOUNT}`, {
      modalSize: 'container.lg',
      team,
      variant: 'normal'
    })
  }

  const onUpdate = async (data) => {
    console.log('data', data)
    await updateTeamAccount({
      teamAccountId: team.teamAccountId,
      teamData: data
    })
    //setCurrentTeam(data)

    toggleEdit(false)
  }
  console.log(isDirty, isSubmitting, touchedFields, submitCount)
  return (
    <Box as="form" onSubmit={handleSubmit(onUpdate)}>
      {isEditing ? (
        <VStack spacing="8">
          <FormFieldGroup
            label="Business Name"
            minChildWidth="360px"
            spacing="2"
          >
            <FormControl id="company" error={errors.company}>
              <Input {...register('company')} name="company" variant="filled" />
            </FormControl>
          </FormFieldGroup>
          <FormFieldGroup
            label="Business Website"
            minChildWidth="360px"
            spacing="2"
          >
            <FieldInput name="domain">
              <Input
                variant="filled"
                focusBorderColor="gray.100"
                id="domain"
                placeholder="Enter business website"
                isReadOnly={true}
              />
            </FieldInput>
          </FormFieldGroup>

          <FormFieldGroup
            label="Business Stage / Business Industry"
            minChildWidth="360px"
            spacing="2"
          >
            <FieldInput name="businessIndustry" error={errors.businessStage}>
              <HStack>
                <Select
                  mt={0}
                  {...register('stage')}
                  errorBorderColor="red.500"
                  focusBorderColor="blue.400"
                  variant="outline"
                  key={listStages}
                  sx={{ option: { backgroundColor: 'white' } }}
                >
                  {listStages.map((item) => {
                    return (
                      <option key={item.value} value={`${item.value}`}>
                        {item.label}
                      </option>
                    )
                  })}
                </Select>

                <Select
                  mt={2}
                  {...register('industry')}
                  errorBorderColor="red.500"
                  focusBorderColor="blue.400"
                  variant="outline"
                  // color="grey"
                  _placeholder={{ color: 'red.500' }}
                  key={listIndustries}
                  sx={{ option: { bgColor: 'white' } }}
                >
                  {listIndustries.map((item) => {
                    return (
                      <option key={item.value} value={`${item.value}`}>
                        {item.label}
                      </option>
                    )
                  })}
                </Select>
              </HStack>
            </FieldInput>
          </FormFieldGroup>
          <FormFieldGroup
            label="Business Address"
            minChildWidth="360px"
            spacing="2"
          >
            <FieldInput>
              <HStack>
                <FieldInput errors={errors.address1}>
                  <Input
                    id="address1"
                    variant="outline"
                    {...register('address1')}
                    placeholder={team.address1}
                    //placeholder="Address 1"
                  />
                </FieldInput>
                <FieldInput errors={errors.address2}>
                  <Input
                    variant="outline"
                    id="address2"
                    {...register('address2')}
                    placeholder={team.address2 || 'Address 2 (Optional)'}
                    //placeholder="Address 2"
                  />
                </FieldInput>
              </HStack>
              <HStack mt="2">
                <FieldInput errors={errors.city}>
                  <Input
                    variant="outline"
                    id="city"
                    {...register('city')}
                    placeholder={team.city}
                    //placeholder="City"
                  />
                </FieldInput>
                <FieldInput errors={errors.state}>
                  <Input
                    variant="outline"
                    id="state"
                    {...register('state')}
                    placeholder={team.state}
                    //placeholder="State"
                  />
                </FieldInput>
                <FieldInput errors={errors.zipcode}>
                  <Input
                    id="zipcode"
                    variant="outline"
                    {...register('zipcode')}
                    placeholder={team.zipcode}
                    // placeholder="ZIP Code"
                  />
                </FieldInput>
              </HStack>
            </FieldInput>
          </FormFieldGroup>
          <Box>
            <VStack spacing={10}>
              <Button type="submit" width="83px" height="46px" variant="solid">
                Save
              </Button>
              <Button variant="form-link" onClick={() => openDeleteModal(team)}>
                DELETE BUSINESS ACCOUNT
              </Button>
            </VStack>
          </Box>
        </VStack>
      ) : (
        <VStack spacing="8">
          <FormFieldGroup
            label="Business Name"
            minChildWidth="360px"
            spacing="2"
          >
            <FieldInput name="company">
              <Input
                defaultValue={team.company}
                variant="filled"
                isReadOnly={true}
                focusBorderColor="gray.100"
              />
            </FieldInput>
          </FormFieldGroup>
          <FormFieldGroup
            label="Business Website"
            minChildWidth="360px"
            spacing="2"
          >
            <FieldInput name="domain">
              <Input
                // isDisabled={true}

                defaultValue={team.domain}
                variant="filled"
                isReadOnly={true}
                focusBorderColor="gray.100"
              />
            </FieldInput>
          </FormFieldGroup>

          <FormFieldGroup
            label="Business Stage / Business Industry"
            minChildWidth="360px"
            spacing="2"
          >
            <HStack>
              <FieldInput>
                <Input
                  // isDisabled={true}

                  placeholder="Stage"
                  defaultValue={
                    team.stage.charAt(0).toUpperCase(0) + team.stage.slice(1)
                  }
                  variant="filled"
                  isReadOnly={true}
                  focusBorderColor="gray.100"
                />
              </FieldInput>
              <FieldInput>
                <Input
                  // isDisabled={true}
                  id="industry"
                  placeholder="Industry"
                  defaultValue={
                    team.industry.charAt(0).toUpperCase(0) +
                    team.industry.slice(1)
                  }
                  variant="filled"
                  isReadOnly={true}
                  focusBorderColor="gray.100"
                />
              </FieldInput>
            </HStack>
          </FormFieldGroup>
          <FormFieldGroup
            label="Business Address"
            minChildWidth="360px"
            spacing="2"
          >
            <FieldInput>
              <HStack>
                <FieldInput>
                  <Input
                    // isDisabled={true}
                    name="address1"
                    placeholder="Address 1"
                    defaultValue={team.address1}
                    variant="filled"
                    isReadOnly={true}
                    focusBorderColor="gray.100"
                  />
                </FieldInput>
                <FieldInput>
                  <Input
                    // isDisabled={true}
                    name="address2"
                    placeholder="Address 2 (Optional)"
                    defaultValue={team.address2}
                    variant="filled"
                    isReadOnly={true}
                    focusBorderColor="gray.100"
                  />
                </FieldInput>
              </HStack>
              <HStack mt="2">
                <FieldInput>
                  <Input
                    // isDisabled={true}
                    name="city"
                    placeholder="City"
                    defaultValue={team.city}
                    variant="filled"
                    isReadOnly={true}
                    focusBorderColor="gray.100"
                  />
                </FieldInput>
                <FieldInput>
                  <Input
                    // isDisabled={true}
                    name="state"
                    defaultValue={team.state}
                    variant="filled"
                    isReadOnly={true}
                    focusBorderColor="gray.100"
                  />
                </FieldInput>
                <FieldInput>
                  <Input
                    // isDisabled={true}
                    name="zipcode"
                    defaultValue={team.zipcode}
                    variant="filled"
                    isReadOnly={true}
                    focusBorderColor="gray.100"
                  />
                </FieldInput>
              </HStack>
            </FieldInput>
          </FormFieldGroup>
        </VStack>
      )}
    </Box>
  )
}
export default TeamAccountForm
