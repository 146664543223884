import { useState, useRef, useEffect } from 'react'
import { getSiteLayout } from '@/layout'
import { Box } from '@chakra-ui/layout'
import { useFireAuth } from '@/lib/fireAuth'
import { CheckWindowScroll } from '@/modules'
import { useRouter } from 'next/router'
// import { PreviewBanner } from '@/elements'
export default function ArticleLayout(props) {
  //console.log(`props`, props)
  const { status, team } = useFireAuth()

  const router = useRouter()
  const [height, setHeight] = useState()
  const articleContainer = useRef(null)
  //console.log('status', status)

  useEffect(() => {
    if (articleContainer.current) {
      setHeight(300)
    } else {
      setHeight(0)
    }
  }, [router])
  //console.log('height', height)
  if (status === 'loading') return null
  return (
    <>
      {!props.preview && (
        <>
          {status === 'loggedOut' && height > 100 && (
            <CheckWindowScroll scrollHeight={height} />
          )}
          {status === 'loggedIn' && team === null && (
            <CheckWindowScroll scrollHeight={height} />
          )}
        </>
      )}
      <Box
        ref={articleContainer}
        className="ARTICLE_LAYOUT"
        flexGrow="1"
        w="100%"
      >
        {props.children}
      </Box>
    </>
  )
}

export const getLayout = (page) => {
  return getSiteLayout(<ArticleLayout {...page.props}>{page}</ArticleLayout>)
}
