import React, { useState } from 'react'
import {
  Flex,
  Button,
  Box,
  Grid,
  GridItem,
  Divider,
  IconButton,
  Text,
  Tag,
  TagLabel
} from '@chakra-ui/react'
import { ButtonMain } from '@/components'
import { useFireAuth } from '@/lib/fireAuth'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import MODALS from '@/constants/modals'
import { GrClose } from 'react-icons/gr'
// import { updateTeamMembers } from '@/lib/teamAccounts'
import PanelForm from 'forms/PanelForm'

const TeamAccountMembers = () => {
  // const { isOpen, onOpen, onClose } = useDisclosure()
  const { member, team } = useFireAuth()
  // TODO
  // Create a listener for teamAccount team members field
  // Read each member's collection and display below

  // const { register, handleSubmit } = useForm({mode: 'onChange'})
  //const modal = useModal(`${MODALS.INVITE_YOUR_TEAM}`)

  const openModal = () => {
    NiceModal.show(`${MODALS.INVITE_YOUR_TEAM}`, { hasExit: true })
  }

  const [isEditing, setIsEditing] = useState(false)

  const DeleteTeamMemberForm = ({ teamIndex }) => {
    const DeleteMemberModal = useModal(`${MODALS.DELETE_YOUR_TEAM_MEMBER}`)
    const DeleteMemberOpenModal = (data) => {
      DeleteMemberModal.show({
        modalName: 'delete-your-team-member',
        variant: 'default',
        hasExit: true,
        // onAction: {handleOnAction},
        data: data
      })
    }

    return (
      <Flex as="form" onClick={DeleteMemberOpenModal}>
        <IconButton icon={<GrClose />} type="submit" variant="unstyled" />
      </Flex>
    )
  }
  //const isOwner = member?.teamAccountRole === 'owner' ? true : false
  return (
    <PanelForm
      title="Business Account Members"
      // actionBtn={
      //   <Button
      //     disabled={!isOwner}
      //     variant="form-link"
      //     size="sm"
      //     onClick={() => setIsEditing(!isEditing)}
      //   >
      //     {!isEditing ? 'EDIT' : 'CANCEL'}
      //   </Button>
      // }
    >
      <Grid
        h="24px"
        justifyContent="center"
        templateColumns={{ base: '1fr 100px', md: '1fr auto 100px' }}
        templateAreas={{
          base: `'head-team-member-name head-team-member-role'`,

          md: `'head-team-member-name . head-team-member-role'`
        }}
        templateRows="2fr"
      >
        <GridItem area="head-team-member-name">
          <Box textStyle="subtitle2">Name/Email</Box>
        </GridItem>
        <GridItem
          area="head-team-member-role"
          alignSelf="end"
          justifySelf="end"
        >
          <Box textStyle="subtitle2">Role</Box>
        </GridItem>
      </Grid>

      <Divider my="2" />
      <Box maxH="auto" pb="5">
        {team?.teamMembers.map((tmember, index) => (
          <Grid
            key={index}
            h="24px"
            justifyContent="center"
            templateColumns={{ base: '1fr 100px', md: '1fr 20px 100px' }}
            templateAreas={{
              base: `'team-member-name team-member-role'`,
              md: `'team-member-name space team-member-role'`
            }}
            templateRows="2fr"
          >
            <GridItem colStart={1} colEnd={5}>
              <Box w="100%" textStyle="bt2">
                <Text as="b">
                  {' '}
                  {tmember.firstName + ' ' + tmember.lastName + ' '}{' '}
                </Text>
              </Box>
              <Box textStyle="bt2">
                {tmember.email}{' '}
                {tmember.status === 'pending' && (
                  <Tag ml="3" size="sm" variant="outline">
                    <TagLabel>Pending</TagLabel>
                  </Tag>
                )}
              </Box>
            </GridItem>
            <GridItem colStart={6} colEnd={7}>
              <Box textStyle="bt2">{tmember.role}</Box>
            </GridItem>
            {isEditing && (
              <GridItem colStart={7} colEnd={8}>
                <Box textStyle="bt2">X </Box>
              </GridItem>
            )}
          </Grid>
        ))}
      </Box>
      <Flex justifyContent="center" mt={7}>
        <ButtonMain
          variant="primary"
          isDisabled={true}
          label="Invite Your Team - Coming Soon"
          size="md"
        />
      </Flex>
    </PanelForm>
  )
}

export default TeamAccountMembers

// const AddYourTeamMembers = ({ isEditing, teamMembers, handleUpdate }) => {
//   return (
//     <>
//       <Grid
//         h="24px"
//         justifyContent="center"
//         templateColumns="180px 30px 240px 30px 1fr"
//         templateRows="1fr"
//         gap={2}
//       >
//         <GridItem colStart={1} colEnd={2}>
//           <Box textStyle="subtitle2">Name</Box>
//         </GridItem>
//         <GridItem colStart={3} colEnd={4}>
//           <Box textStyle="subtitle2">Email</Box>
//         </GridItem>
//         <GridItem colStart={5} colEnd={6}>
//           <Box textStyle="subtitle2">Role</Box>
//         </GridItem>
//       </Grid>

//       <Divider my="2" />
//       <Box maxH="220px" overflow="scroll">
//         {team?.teamMembers.map((member, index) => (
//           <Grid
//             key={index}
//             h="24px"
//             justifyContent="center"
//             templateColumns="180px 30px 240px 30px 1fr"
//             templateRows="1fr"
//             gap={2}
//           >
//             <GridItem colStart={1} colEnd={2}>
//               <Box w="100%" textStyle="bt2">
//                 {member.firstName + ' ' + member.lastName + ' '}
//                 {member.status === 'pending' && (
//                   <Tag ml="3" size="sm" variant="outline">
//                     <TagLabel>Pending</TagLabel>
//                   </Tag>
//                 )}
//               </Box>
//             </GridItem>
//             <GridItem colStart={3} colEnd={4}>
//               <Box textStyle="bt2">{member.email}</Box>
//             </GridItem>
//             <GridItem colStart={5} colEnd={6}>
//               <Box textStyle="bt2">{member.role}</Box>
//             </GridItem>

//             <GridItem colStart="4"></GridItem>
//           </Grid>
//         ))}
//       </Box>
//     </>
//   )
// }
