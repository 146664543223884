import React from 'react'
import { Button } from '@chakra-ui/react'
const BuyButton = ({ label, isFeatured, handleClick, isDisabled = false }) => {
  const size = isFeatured ? 'lg' : 'md'
  const vart = isFeatured ? 'buy-fprod' : 'buy-prod'
  return (
    <Button
      disabled={isDisabled}
      size={size}
      variant={vart}
      onClick={handleClick}
    >
      {label}
    </Button>
  )
}

export default BuyButton
