import React from 'react'
import APPVARS from '@/constants/appvars'
import { Box, VisuallyHidden, Grid, GridItem } from '@chakra-ui/react'

import {
  FooterLogo,
  FooterTagline,
  FooterCopyright,
  FooterNavLinks
} from 'elements'

const FooterContainer = ({
  footerLogo,
  copyrightNotice,
  footerTagLine,
  navLinks
}) => {
  return (
    <Box
      width="100%"
      as="footer"
      layerStyle="footer"
      aria-labelledby="footerHeading"
      py={APPVARS.HRZ['2XL']} // '72px', //lt blue}
    >
      <Box
        maxW={{
          base: APPVARS.BLOCK_MAX_WIDTH_BASE,
          lg: APPVARS.BLOCK_MAX_WIDTH_FULL
        }}
        mx={{ base: 'auto' }}
        w="100%"
      >
        <VisuallyHidden as="h2" id="footerHeading">
          Footer
        </VisuallyHidden>
        <Grid
          templateColumns={{ base: '1fr', md: '250px auto 190px' }}
          templateRows="100 100"
          gap={10}
          templateAreas={{
            base: `'brand' 'navs' 'copyright'`,
            md: `'brand . navs' 'copyright . navs'`
          }}
        >
          <GridItem
            gridArea="brand"
            alignContent={{ base: 'center', md: 'flex-start' }}
          >
            {footerLogo && <FooterLogo logo={footerLogo} />}

            {footerTagLine && <FooterTagline content={footerTagLine} />}
          </GridItem>
          <GridItem gridArea="navs">
            {navLinks && <FooterNavLinks navlinks={navLinks} />}
          </GridItem>

          <GridItem gridArea="copyright" justifyItems="end">
            {copyrightNotice && (
              <FooterCopyright content={copyrightNotice} textAlign="center" />
            )}
          </GridItem>
        </Grid>
      </Box>
    </Box>
  )
}
export default FooterContainer
