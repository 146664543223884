import React, { useState } from 'react'
import * as yup from 'yup'
import {
  Box,
  Checkbox,
  Button,
  Input,
  VStack,
  HStack,
  Flex,
  Wrap,
  WrapItem,
  FormErrorMessage
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'

import {
  LogoOfficeDepotMax,
  LogoHP_BW,
  LogoDELL_BW,
  LogoMicrosoft_BW,
  LogoMethodBW,
  LogoLogiTech_BW,
  LogoHon_BW,
  LogoAdobe_BW,
  LogoKeurig_BW
} from '@/logos'

import { FieldInput } from '@/forms'
import { verifyVendorAccountId, updateVendorAccount } from '@/lib/teamAccounts'

import Verified from 'icons/verified'
import { useFireAuth } from '@/lib/fireAuth'

import { TERMS_CONNECT_ODACCOUNT } from '@/constants/form-options'

import { onboardScreensCopy } from '@/constants/onboard-screens-copy'
import APPVARS from '@/constants/appvars'

const ConnectAccountSchema = yup
  .object()
  .shape({
    vendorAccountId: yup
      .number()
      .typeError('Your Office Depot account must be a number')
      .integer()
      .typeError('Your Office Depot account number must contain only numbers')
      .positive()
      .typeError('Your Office Depot account number must contain only numbers')
      .required('Your Office Depot account is required'),
    terms: yup.bool().oneOf([true], 'Please accept T&Cs').required()
  })
  .required('required')

const FormResponseBox = ({ isFound, message }) => {
  if (typeof isFound !== 'undefined' && isFound !== null)
    return (
      <Box
        w="100%"
        pt={2}
        pb={2}
        pl={2.5}
        pr={2.5}
        bg={isFound ? 'rgba(199, 228, 233, 0.3)' : ' rgba(255, 135, 119, 0.3)'}
      >
        {message}
      </Box>
    )
  return null
}

const FormResponseIcon = ({ isFound }) => {
  if (!isFound) return <Verified isVerified={false} />
  if (typeof isFound !== 'undefined' && isFound !== null)
    return <Verified isVerified={isFound} />
  return null
}

const ConfirmVendorConnect = ({ onNextAction, onAction }) => {
  const [termsChecked, setTermsChecked] = useState(false)
  return (
    <HStack w="100%" spacing="10" justify="flex-start">
      <Checkbox
        name="terms"
        alignItems="flex-start"
        isChecked={termsChecked}
        onChange={(e) =>
          setTermsChecked((termsChecked) => setTermsChecked(!termsChecked))
        }
      >
        {`By clicking Verify, you will be allowing your account to share purchase history with Excelerate America as part of your Elevate account.
        Data shared will be subject to Excelerate America's Privacy Policy.
        This allows Elevate to automatically verify that you have earned Cash Back. `}
      </Checkbox>
    </HStack>
  )
}
const ConnectVendorAccount = ({
  fromModal = false,
  modalSize = 'container.lg',
  handleNextAction,
  onAction,
  vendorName = 'OD'
}) => {
  const { team } = useFireAuth()
  const [returnData, setReturnData] = useState()
  const [isAccountFound, setIsAccountFound] = useState({
    isFound: false,
    message: ''
  })
  //console.log(isAccountFound)
  const {
    register,
    control,
    formState: { errors, isValid, isDirty, isSubmitting, isSubmitted },
    reset,
    handleSubmit
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ConnectAccountSchema),
    defaultValues: { vendorAccountId: '', terms: false }
  })

  const onResetForm = () => {
    reset({ vendorAccountId: '', terms: false })
  }

  const onRegisterAccountNumber = async (data) => {
    //for testing
    const { vendorAccountId } = data
    const teamAccountId = team.teamAccountId

    try {
      const result = await verifyVendorAccountId({
        vendorAccountId,
        vendorName,
        teamAccountId
      })

      const { resStatus, resSuccess, resData, resError } = result

      const isFound = resSuccess
      let message
      if (!resError) {
        await updateVendorAccount({
          teamAccountId,
          accountData: resData,
          odAccountStatus: resStatus
        })
        if (resStatus === 'NOT VERIFIED') {
          message =
            "We're sorry, but we can't verify your Office Depot account number. Our customer service team will contact you within 24 hours. Help is on the way!"
        }
        if (resStatus === 'ALREADY CONNECTED') {
          message =
            "We're sorry, but that Office Depot account number is already in use. Our customer service team will contact you within 24 hours. Help is on the way!"
        }
        if (resStatus === 'CONNECTED') {
          message = "You're all set to start earning Cash Back!"
        }
        setIsAccountFound({ isFound: isFound, message: message })
      } else {
        setIsAccountFound({
          isFound: false,
          message: "We're sorry. Please Try again later."
        })
      }
    } catch (error) {
      console.log('error', error)
      setIsAccountFound({
        isFound: false,
        message: "We're sorry. Please Try again later."
      })
    }
  }

  console.log(`errors`, errors)
  return (
    <VStack
      w="100%"
      spacing="8"
      as="form"
      onSubmit={handleSubmit(onRegisterAccountNumber)}
    >
      <VStack
        spacing={APPVARS.HRZ.LG}
        maxWidth="100vw"
        px={{ base: '20px', lg: '0' }}
      >
        <Box textStyle="head4">
          {onboardScreensCopy.VENDOR_SETUP_MAIN_SCREEN.HEADLINE}
        </Box>
        <Box w="100%" textStyle="body2">
          {onboardScreensCopy.VENDOR_SETUP_MAIN_SCREEN.COPY}
        </Box>

        <Wrap w="100%" spacing={APPVARS.VRT.LG} align="center" justify="center">
          <WrapItem>
            <LogoHP_BW />
          </WrapItem>
          <WrapItem>
            <LogoDELL_BW />
          </WrapItem>
          <WrapItem>
            <LogoLogiTech_BW />
          </WrapItem>
          <WrapItem>
            <LogoMicrosoft_BW />
          </WrapItem>
          <WrapItem>
            <LogoAdobe_BW />
          </WrapItem>
          <WrapItem>
            <LogoHon_BW />
          </WrapItem>
          <WrapItem>
            <LogoKeurig_BW />
          </WrapItem>
          <WrapItem>
            <LogoMethodBW />
          </WrapItem>
        </Wrap>

        <Box w="100%" textStyle="bt2">
          {onboardScreensCopy.VENDOR_SETUP_MAIN_SCREEN.COPY2}
        </Box>

        <VStack
          maxWidth="100vw"
          layerStyle="form1"
          alignItems="flex-start "
          spacing="5"
          px={{ base: '20px' }}
        >
          <Box w="180px" h="52px">
            <LogoOfficeDepotMax />
          </Box>

          <Box textStyle="head5">
            {onboardScreensCopy.VENDOR_SETUP_FORM_SCREEN.HEADLINE}
          </Box>
          <Box textStyle="body2">
            {onboardScreensCopy.VENDOR_SETUP_FORM_SCREEN.COPY}
          </Box>

          <FieldInput
            name="vendorAccountIdlabel"
            error={errors.vendorAccountId}
          >
            <HStack align="center">
              <Input
                id="vendorAccountId"
                name="vendorAccountId"
                w="320px"
                variant="outline"
                readOnly={isSubmitted}
                placeholder="#000000000"
                {...register('vendorAccountId')}
              ></Input>
              {errors.vendorAccountId && (
                <FormErrorMessage>
                  {errors.vendorAccountId.message}
                </FormErrorMessage>
              )}

              {isSubmitted && (
                <FormResponseIcon isFound={isAccountFound.isFound} />
              )}
            </HStack>
          </FieldInput>

          {isSubmitted && <FormResponseBox {...isAccountFound} />}
          {!isSubmitted && (
            <HStack
              w="100%"
              spacing="10px"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Controller
                control={control}
                name="terms"
                render={({ field, name }) => (
                  <Checkbox
                    name={name}
                    onChange={(e) => field.onChange(e.target.checked)}
                    isChecked={field.value}
                  />
                )}
                defaultValue={false}
              />
              <Box textStyle="fine-print">
                {`By clicking Verify, you will be allowing your account to share
                purchase history with Excelerate America as part of your Elevate
                account. Data shared will be subject to Excelerate America's
                Privacy Policy. This allows Elevate to automatically verify that
                you have earned Cash Back.`}
              </Box>
            </HStack>
          )}

          <Flex width="100%" alignItems="center" justify="center">
            {!isSubmitted && (
              <Button
                id="connnect-vendor-account-verify"
                variant="solid"
                isLoading={isSubmitting}
                type="submit"
                disabled={!isDirty || !isValid}
                alignSelf="flex-end"
              >
                Verify
              </Button>
            )}
          </Flex>
        </VStack>
        {isSubmitted && isAccountFound.isFound && (
          <Flex width="100%" alignItems="center" justify="center">
            <Button
              id="connnect-vendor-account-finish"
              variant="solid"
              onClick={() => handleNextAction()}
              mb={5}
            >
              Finish
            </Button>
          </Flex>
        )}
        {!isSubmitted && (
          <VStack w="100%" spacing="5" align="center" py="8">
            <Button
              id="connect-vendor-account-switch"
              my="4"
              variant="solid"
              onClick={onAction}
            >
              I Don’t Have An Office Depot Account
            </Button>

            {!fromModal && (
              <Button
                id="connect-vendor-account-skip-for-now"
                variant="form-link"
                onClick={handleNextAction}
              >
                SKIP FOR NOW
              </Button>
            )}
          </VStack>
        )}
        {isSubmitted && !isAccountFound.isFound && (
          <VStack maxWidth="100vw">
            <HStack w="100%" spacing="5" align="center">
              <Button
                id="connect-vendor-account-try-again"
                variant="outline"
                onClick={() => onResetForm()}
              >
                Try Again
              </Button>
              <Button
                id="connect-vendor-account-next"
                variant="solid"
                onClick={handleNextAction}
              >
                Next
              </Button>
            </HStack>
          </VStack>
        )}
      </VStack>
    </VStack>
  )
}

export default ConnectVendorAccount
