import { useEffect, useState, createContext, useContext } from 'react'

import { fbauth, fsdb } from '@/lib/firebase'
import { doc, onSnapshot } from 'firebase/firestore'

import { useAuthState } from 'react-firebase-hooks/auth'
import { useMemoCompare } from '@/hooks'
const FireAuthContext = createContext({
  user: null,
  member: null,
  status: null,
  error: null
})
FireAuthContext.displayName = '_FIRE_AUTH_'

export const FireAuthProvider = ({ children }) => {
  const value = useFireAuthStatus()

  return (
    <FireAuthContext.Provider value={value}>
      {children}
    </FireAuthContext.Provider>
  )
}

// Custom hook to read  auth record and user profile doc
export function useFireAuthStatus() {
  const [user, loading, error] = useAuthState(fbauth)
  const [member, setMember] = useState(null)
  const [teamId, setTeamId] = useState(null)
  const [team, setTeam] = useState(null)
  const [status, setStatus] = useState('loading')
  // console.log('status', status)
  // console.log('member', member)
  // console.log('teamId', teamId)
  // console.log('team', team)

  useEffect(() => {
    // turn off realtime subscription
    let unsubscribe

    if (user) {
      unsubscribe = onSnapshot(doc(fsdb, 'members', user.uid), (mbrDoc) => {
        const { emailVerified } = user
        const memData = mbrDoc.data()
        const teamAccountId = memData?.teamAccountId
          ? memData.teamAccountId
          : null
        if (teamAccountId) {
          setTeamId(teamAccountId)
        }
        const memberData = mbrDoc.data()

        setMember({ ...memberData, emailVerified })
      })
    } else {
      setMember(null)
      setTeamId(null)
    }

    return unsubscribe
  }, [setTeamId, user])

  useEffect(() => {
    // turn off realtime subscription
    let unsubteam

    if (teamId) {
      unsubteam = onSnapshot(doc(fsdb, 'teamAccounts', teamId), (teamDoc) => {
        const teamData = teamDoc.data()
        setTeam(teamData)
      })
    } else {
      setTeam(null)
    }

    return unsubteam
  }, [setTeamId, teamId])

  useEffect(() => {
    if (!loading && member) {
      rg4js('setUser', {
        identifier: `${member.uid}`,
        isAnonymous: false,
        email: `${member.email}`
      })

      heap.identify(member.email)
      heap.addUserProperties({ eaid: member.uid })
      heap.addUserProperties({ email: member.email })
      setStatus('loggedIn')
    }
    if (!loading && !member) {
      setStatus('loggedOut')
    }
    if (loading) {
      setStatus('loading')
    }
  }, [loading, member])

  return { member, team, status, error }
}

export function useFireAuth() {
  const context = useContext(FireAuthContext)
  if (context === undefined) {
    throw new Error('useFireAuth must be used within an FireAuthProvider')
  }

  return context
}
