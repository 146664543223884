import { useEffect, useState, useCallback } from 'react'

import _ from 'lodash'

import { formatDateNormal } from '@/utils/_formatDates'
const useCleanAccountActivity = ({ data, team }) => {
  const [runOnce, setRunOnce] = useState(false)
  const [recordsReady, setRecordsReady] = useState(false)
  const [cashBackRecords, setCashBackRecords] = useState([])
  const [cashBackTotal, setCashBackTotal] = useState(0)
  const [specialSavingsTotal, setSpecialSavingsTotal] = useState(0)
  const [everydaySavingsTotal, setEverydaySavingsTotal] = useState(0)
  const [records, setRecords] = useState([])

  const cleanTheData = useCallback(
    ({
      vendorName,
      orderCompleteDate,
      odProductCode,
      productDescription,
      qtySold,
      netSales,
      listPrice,
      programPrice,
      retailListPrice,
      specialType,
      specialMod,
      specialLimit = 1,
      dateClaimed,
      productId,
      productName,
      productPrice
    }) => {
      //const dateOrderComplete = formatDateNormal({ value: orderCompleteDate })
      // const dateSpecialClaimed = formatDateNormal({ value: dateClaimed })

      const whichDate = orderCompleteDate ? orderCompleteDate : dateClaimed
      const date = formatDateNormal({ value: whichDate })
      const vendorCode = vendorName ? vendorName : 'ELE'
      const prodDesc = productDescription ? ` ${productDescription}` : ''
      const prodname = productName ? ` ${productName}` : ''
      const description = `${prodname}${prodDesc}`
      let adjQtySold = qtySold ? qtySold : 1
      let mainPrice = productPrice ? productPrice : programPrice
      let mainListPrice = listPrice ? listPrice : productPrice
      console.log('description', description)
      let listTotal = adjQtySold * mainListPrice
      let payTotal = adjQtySold * mainPrice
      let cashBack = 0
      let specialSavings = 0
      const savingsCalc = listTotal - payTotal
      let savings = savingsCalc > 0 ? savingsCalc : 0
      if (specialType === 'ELEVATE_SPECIAL') {
        const spec = adjQtySold * specialMod
        specialSavings = listTotal - spec
        savings = 0
      }
      if (specialType === 'CASH_BACK') {
        const pt = mainPrice * specialMod
        let cbfactor
        if (adjQtySold > specialLimit) {
          cbfactor = specialLimit
        }
        if (adjQtySold === specialLimit) {
          cbfactor = specialLimit
        }
        if (adjQtySold < specialLimit) {
          cbfactor = adjQtySold
        }
        cashBack = cbfactor * pt
      }

      return {
        vendorCode,
        specialType,
        date,
        description,
        listTotal,
        payTotal,
        savings,
        specialSavings,
        cashBack: cashBack
      }
    },
    []
  )

  useEffect(() => {
    const getCleanRecords = async (dirtyData) => {
      const cleanData = await dirtyData.map((doc) => {
        return cleanTheData(doc)
      })
      const recs = [...cleanData]

      const eds = recs.reduce((accum, item) => accum + item.savings, 0)

      const sst = recs.reduce((accum, item) => accum + item.specialSavings, 0)

      const cbTotal = recs.reduce((accum, item) => accum + item.cashBack, 0)

      const cashbackfilter = _.filter(recs, function (o) {
        return o.specialType === 'CASH_BACK'
      })

      const cbRecords = _.groupBy(cashbackfilter, 'vendorCode')
      setRecords([...cleanData])
      console.log(`cbTotal`, cbTotal)
      console.log(`eds`, eds)
      console.log(`sst`, sst)
      setCashBackTotal(cbTotal)
      setEverydaySavingsTotal(eds)
      setSpecialSavingsTotal(sst)
      setCashBackRecords(cbRecords)
      console.log(`recs`, recs.length)
      console.log('recs', recs)
      setRecordsReady(true)
    }

    if (data.length > 0 && !runOnce) {
      console.log(`data.length: ${data.length}`)
      getCleanRecords(data)
      setRunOnce(true)
    } else {
      setCashBackTotal(0)
      setEverydaySavingsTotal(0)
      setSpecialSavingsTotal(0)
      setCashBackRecords()
      setRecordsReady(false)
    }
  }, [cleanTheData, data, runOnce])

  return {
    team,
    recordsReady,
    records,
    cashBackRecords,
    cashBackTotal,
    specialSavingsTotal,
    everydaySavingsTotal
  }
}

export default useCleanAccountActivity
