import {
  Flex,
  LinkOverlay,
  LinkBox,
  Text,
  VStack,
  StackDivider
} from '@chakra-ui/react'
import NextLink from 'next/link'

import Headline from 'elements/Headline'

export default function ArticleCard({
  slug,
  articleName,
  articleCategory,
  articleHeadline,
  articleSubheadline,
  articleNote,
  articleCopy,
  publishedOn
}) {
  return (
    <LinkBox as="article" borderWidth="1px" p={2}>
      <VStack
        h="100%"
        spacing={3}
        divider={<StackDivider borderColor="gray.200" />}
        align="stretch"
      >
        <Text>{articleCategory}</Text>
        <Text>{articleName}</Text>

        <Flex dir="column" justifyContent="flex-end">
          <NextLink href={`/articles/${slug}`} passHref>
            <LinkOverlay>
              {articleHeadline && (
                <Headline
                  source={articleHeadline}
                  textStyle="head6"
                  m={0}
                  color="blue.800"
                />
              )}
            </LinkOverlay>
          </NextLink>
        </Flex>
      </VStack>
    </LinkBox>
  )
}
