import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  VStack,
  Button,
  List,
  ListIcon,
  ListItem,
  useDisclosure,
  useBreakpointValue
} from '@chakra-ui/react'

import { useGetModalData } from '@/hooks'
import yellowTick from 'icons/yellowTick'

const OnboardNewModal = ({ modalName }) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  const popUpInfo = useGetModalData('newMemberSignUp')
  const innnerVariant = useBreakpointValue({ base: 'mobile-alt', md: 'alt' })
  return (
    <Modal variant={innnerVariant} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          <VStack spacing={10}>
            <Box w="100%">
              {popUpInfo?.title && (
                <Box textStyle="head2">{popUpInfo.title} </Box>
              )}
              {popUpInfo?.subTitle && (
                <Box textStyle="bt2">{popUpInfo.subTitle}</Box>
              )}
            </Box>
            {popUpInfo?.checkList && (
              <Box w="100%">
                <List>
                  <ListItem>
                    <ListIcon as={yellowTick} />
                    {popUpInfo.checkList[1]}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={yellowTick} />
                    {popUpInfo.checkList[2]}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={yellowTick} />
                    {popUpInfo.checkList[3]}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={yellowTick} />
                    {popUpInfo.checkList[4]}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={yellowTick} />
                    {popUpInfo.checkList[5]}
                  </ListItem>
                </List>
              </Box>
            )}
            <Button variant="solid" onClick={() => onClose()}>
              Start Free Trial
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
export default OnboardNewModal
