import React, { useState, useEffect, useCallback } from 'react'
import { Box, Grid, GridItem, Button } from '@chakra-ui/react'
import ROUTES from '@/constants/routes'
import NiceModal from '@ebay/nice-modal-react'
import MODALS from '@/constants/modals'
// const specialId = 'ckwvrzltkpgnc0c72wxcuinmg'
const OnboardingComplete = ({ handleNextAction }) => {
  // const [firstLoad, setFirstLoad] = useState(true)

  // const openModalOnce = useCallback(() => {
  //   // do something!
  //   if (firstLoad) {
  //     NiceModal.show(`${MODALS.EARLY_CASH_BACK_50}`, {
  //       id: specialId,
  //       variant: 'medium',
  //       hasExit: false
  //     })

  //     setFirstLoad(false)
  //   }
  // }, [firstLoad])

  // useEffect(() => {
  //   //console.log('modals', modals)
  //   if (firstLoad) {
  //     openModalOnce()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [openModalOnce])

  return (
    <Grid
      templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
      templateRows="1fr"
      templateAreas={{ base: "'account' 'discover'", md: "'account discover'" }}
      columnGap="3"
      px={{ base: '20px', md: '0' }}
    >
      <Grid
        p={8}
        templateRows="[gl-title-top] auto [gl-title-bot] 10px [gl-copy-top] 1fr [gl-copy-bot] 30px [gl-cta-top] auto [gl-cta-bot]"
        templateColumns="[outer-l] 100% [outer-r]"
        border="1px solid #FAFAFA"
        boxShadow="base"
        rounded="lg"
      >
        <GridItem
          textStyle="head5"
          gridArea="gl-title-top / outer-l / gl-title-bot / outer-r"
        >
          Manage Account
        </GridItem>
        <GridItem gridArea="gl-copy-top / outer-l / gl-copy-bot / outer-r">
          <Box textStyle="body1">
            Update your profile, invite team members to join Elevate, and check
            your Cash Back Connections.
          </Box>
        </GridItem>
        <GridItem
          gridArea="gl-cta-top / outer-l / gl-cta-bot / outer-r"
          display="flex"
          justifyContent="flex-start"
        >
          <Button
            id="onbaording-complete-account-settings"
            onClick={(e) => handleNextAction(e, ROUTES.ACCOUNT_SETTINGS)}
          >
            Account Settings
          </Button>
        </GridItem>
      </Grid>
      <Grid
        p={8}
        templateRows="[gl-title-top] auto [gl-title-bot] 1rem [gl-copy-top] 1fr [gl-copy-bot] 10px [gl-cta-top] auto [gl-cta-bot]"
        templateColumns="[outer-l] 100% [outer-r]"
        border="1px solid #FAFAFA"
        boxShadow="base"
        rounded="lg"
      >
        <GridItem
          textStyle="head5"
          gridArea="gl-title-top / outer-l / gl-title-bot / outer-r"
        >
          How It Works
        </GridItem>
        <GridItem
          textStyle="body1"
          gridArea="gl-copy-top / outer-l / gl-copy-bot / outer-r"
        >
          Learn how to claim Cash Back, access Elevate Specials, and get small
          business benefits.
        </GridItem>

        <GridItem
          gridArea="gl-cta-top / outer-l / gl-cta-bot / outer-r"
          display="flex"
          justifyContent="flex-start"
        >
          <Button
            id="onbaording-complete-discover"
            onClick={(e) => handleNextAction(e, ROUTES.DISCOVER)}
          >
            Discover Elevate
          </Button>
        </GridItem>
      </Grid>
    </Grid>
  )
}

export default OnboardingComplete
