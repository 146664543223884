import { Box, VStack, Grid } from '@chakra-ui/react'
import Image from 'next/image'
import { MDXRemote } from 'next-mdx-remote'

import { ButtonMain } from '@/components'
const Body2 = (props) => {
  return <Box textStyle="body2" {...props} />
}

const components = {
  p: Body2
}
const ErrorCardBlock = ({
  headline,
  subheadline,
  copy,
  note,
  cardMedia,
  cardVariant,
  cardTheme,
  cardSize = 'full',
  cardButton
}) => {
  return (
    <Grid
      className="ERROR_CARD_BLOCK"
      templateColumns="repeat(auto-fill, minmax(384px, 1fr))"
      columnGap="0"
    >
      <Box position="relative" height="380px">
        {cardMedia && (
          <Image
            src={cardMedia.url}
            alt={cardMedia.title}
            title={cardMedia.title}
            layout="fill"
            objectFit="contain"
          />
        )}
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <VStack spacing="5" px="6" align="flex-start">
          {headline && (
            <Box as="h3" textStyle="head3">
              <MDXRemote {...headline.mdx} />
            </Box>
          )}
          {subheadline && (
            <Box as="h4" textStyle="head4">
              <MDXRemote {...subheadline.mdx} />
            </Box>
          )}
          {copy && (
            <Box>
              <MDXRemote {...copy.mdx} components={components} />
            </Box>
          )}
          {cardButton && <ButtonMain {...cardButton} />}
        </VStack>
      </Box>
    </Grid>
  )
}
export default ErrorCardBlock
