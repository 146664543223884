import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  Text
} from '@chakra-ui/react'
import NiceModal, {
  useModal,
  show,
  create,
  remove,
  register
} from '@ebay/nice-modal-react'

export default NiceModal.create(
  ({ name, variant, hasExit, onAction, onCancel, data }) => {
    const modal = useModal()
// console.log("DATA sent to modal:",data)
    return (
      <Modal
        variant={variant}
        isOpen={() => modal.show()}
        onClose={() => modal.remove()}
      >
        <ModalOverlay />
        <ModalContent>
          {hasExit ? <ModalCloseButton onClick={() => modal.hide()} /> : ''}
          <ModalBody>
            <Text textStyle="head3">Delete Account Member?</Text>
            <Text>
              {name} will lose access to this account. Click Confirm to delete this
              member.
            </Text>
            <Button variant="solid" onClick= {onAction}> 
              Confirm
            </Button>
            <Button variant="outline" onClick={() => modal.remove()}>
              Cancel
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
)
