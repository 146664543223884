import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { TopBarLogo } from '@/elements'
import { NavBar } from '@/components'
import { motion } from 'framer-motion'
import APPVARS from '@/constants/appvars'

const MotionFlex = motion(Flex)
const MotionBox = motion(Box)
const variantLogo = {
  start: { opacity: 0 },
  end: { opacity: 1 }
}
const HomeBarContainer = ({ animToggle, children }) => {
  return (
    <Box
      className="HOME_BAR_CONTAINER"
      as="header"
      pos="fixed"
      top="0"
      w="100%"
      h={APPVARS.NAV_BAR_HEIGHT}
      zIndex="999"
      align="center"
      bg="white"
      boxShadow="base"
    >
      <Box
        className="HOME_BAR_CONTAINER_FLEX"
        maxW={{
          base: APPVARS.BLOCK_MAX_WIDTH_BASE,
          lg: APPVARS.BLOCK_MAX_WIDTH_FULL
        }}
        mx={{ base: 'auto' }}
        w="100%"
        h="100%"
        position="relative"
      >
        <MotionBox
          initial="start"
          variants={variantLogo}
          animate={animToggle ? 'end' : 'start'}
        >
          <TopBarLogo logoLink="/home" />
        </MotionBox>

        <MotionFlex
          h={APPVARS.NAV_BAR_HEIGHT}
          py="6"
          justifyContent={animToggle ? 'flex-end' : 'center'}
          layout
        >
          {children}
        </MotionFlex>
      </Box>
    </Box>
  )
}

const HomeBar = ({ navType, activeSection }) => {
  const animToggle = activeSection < 2 ? false : true

  return (
    <HomeBarContainer animToggle={animToggle}>
      <NavBar forModal={false} />
    </HomeBarContainer>
  )
}

export default HomeBar
