import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import _ from 'lodash'
import uniqueId from 'lodash/uniqueId'
import { GrFormAdd, GrFormEdit, GrFormTrash } from 'react-icons/gr'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  IconButton,
  Box,
  Spacer,
  VStack,
  HStack,
  Button as ChakraButton,
  Input,
  Flex
} from '@chakra-ui/react'
import { useFieldArray, useForm, Controller } from 'react-hook-form'
import { inviteTeamMembers, updateTeamMembers } from '@/lib/teamAccounts'
import { useFireAuth } from '@/lib/fireAuth'

const defaultTeamMember = {
  email: '',
  firstName: '',
  lastName: ''
}
const TeamMemberSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required()
})

const OwnerTeamMemberForm = ({ memberData }) => {
  return (
    <Flex dir="row" w="100%" justifyContent="space-between" as="form">
      <VStack align="flex-start">
        <Box textStyle="btn-label1">First Name</Box>

        <Input
          variant="outline"
          type="text"
          placeholder={memberData.firstName}
          readOnly
        />
      </VStack>
      <VStack align="flex-start">
        <Box textStyle="btn-label1">Last Name</Box>
        <Input
          variant="outline"
          type="text"
          placeholder={memberData.lastName}
          readOnly
        />
      </VStack>
      <VStack align="flex-start">
        <Box textStyle="btn-label1">Email</Box>
        <Input variant="outline" type="email" placeholder={memberData.email} />
      </VStack>
      <VStack w="40px"></VStack>
    </Flex>
  )
}
const AddTeamMemberForm = ({ onAction }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(TeamMemberSchema)
  })
  const addTeamMember = (data) => {
    onAction({ action: 'add', data, teamIndex: null })
    reset()
  }

  return (
    <Flex
      dir="row"
      w="100%"
      justifyContent="space-between"
      as="form"
      onSubmit={handleSubmit(addTeamMember)}
    >
      <HStack> 
      <VStack align="flex-start">
        <Input variant="outline" type="text" {...register('firstName')} />
        {errors.firstName && <p>{errors.firstName.message}</p>}
      </VStack>
      <VStack align="flex-start">
        <Input variant="outline" type="text" {...register('lastName')} />
        {errors.lastName && <p>{errors.lastName.message}</p>}
      </VStack>
      <VStack align="flex-start">
        <Input variant="outline" type="email" {...register('email')} />
        {errors?.email && <p>{errors.email.message}</p>}
      </VStack>
      </HStack> 
      <HStack>
        <Button
          disabled={!isDirty || !isValid}
          icon={<GrFormAdd />}
          type="submit"
        >
          + Add to List
          </Button>
      </HStack>
    </Flex>
  )
}
const EditTeamMemberForm = ({ teamIndex, memberData, onAction }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm({
    resolver: yupResolver(TeamMemberSchema),
    defaultValues: memberData,
    mode: 'onChange'
  })
  const removeTeamMember = (data) => {
    onAction({ action: 'remove', data, teamIndex })
  }
  ////console.log('memberData', memberData)
  return (
    <Flex
      dir="row"
      w="100%"
      justifyContent="space-between"
      as="form"
      onSubmit={handleSubmit(removeTeamMember)}
    >
      <VStack align="flex-start">
        <Input
          variant="outline"
          type="text"
          placeholder={memberData.firstName}
          readOnly
        />
      </VStack>
      <VStack align="flex-start">
        <Input
          variant="outline"
          type="text"
          placeholder={memberData.lastName}
          readOnly
        />
      </VStack>
      <VStack align="flex-start">
        <Input
          variant="outline"
          type="email"
          placeholder={memberData.email}
          readOnly
        />
      </VStack>
      <VStack w="40px">
        {memberData.role !== 'owner' && (
          <IconButton icon={<GrFormTrash />} type="submit" />
        )}
      </VStack>
    </Flex>
  )
}

const InviteYourTeam = ({
  handleNextAction,
  handleSkipAction,
  hasSkipBtn = false,
  onboarding = false
}) => {
  const { member, team, status } = useFireAuth()
  const [isReady, setIsReady] = useState(false)
  const [ownerData, setOwnerData] = useState()
  const [currentTeamArray, setCurrentTeamArray] = useState([])
  const [newTeamArray, setNewTeamArray] = useState([])
  // const [hasInvitedMembersChange] = useState(false)
  useEffect(() => {
    if (status === 'loggedIn' && team?.teamMembers) {
      const ta = [...team.teamMembers]
      const ownerIndex = _.findIndex(ta, function (o) {
        return o.role == 'owner'
      })
      const ownerData = ta[ownerIndex]
      //console.log('owner', ownerData)
      const notowners = ta
        .filter(function (e) {
          return e.role !== 'owner'
        })
        .map((mem) => {
          return { ...mem, uid: mem.uid ? mem.uid : `team-${uniqueId()}` }
        })
      setOwnerData(ownerData)

      setCurrentTeamArray(notowners)

      setIsReady(true)
    }
  }, [status, team])

  const handleOnAction = ({ action, data, uid }) => {
    const uArray = [...newTeamArray]

    if (action === 'add') {
      const arrayLength = uArray.length
      //console.log(`data=> ${JSON.stringify(data)} arrayLength =${arrayLength}`)
      const teamMember = {
        uid: `team-${uniqueId()}`,
        ...data
      }
      setNewTeamArray((uArray) => [...uArray, teamMember])
    }
    if (action === 'edit') {
      const findx = _.findIndex(uArray, { uid: uid })
      //console.log(`findx=> ${findx}`)
      newArry.splice(findx, 1, data)
      setNewTeamArray((uArray) => [...uArray, teamMember])
    }
    if (action === 'remove') {
      const changeArray = _.remove(uArray, function (n) {
        return n.uid === uid
      })
      //console.log(`findx=> ${findx}`)
      // newArry.splice(findx, 1, data)
      setNewTeamArray(() => [...changeArray])
    }
  }

  const sendInvites = async () => {
    const accid = member.teamAccountId
    //console.log(`accid=> ${accid}`)
    try {
      await inviteTeamMembers({
        teamAccountId: accid,
        teamMemberArray: newTeamArray
      }).then(() => {
        handleNextAction()
      })
    } catch (error) {}
  }

  const updateInvites = async () => {
    const accid = member.teamAccountId
    //console.log(`accid=> ${accid}`)
    try {
      await updateTeamMembers({
        teamAccountId: accid,
        teamMemberArray: invitedMembersArray
      })
    } catch (error) {}
  }

  // const UpdateInvitedMembersForm = () => {
  //   return (
  //     <Box>
  //       {invitedMembersArray.map(
  //         (invitedMember, index) =>
  //           member.uid !== invitedMember.uid &&
  //           invitedMember.status === 'pending' && (
  //             <EditTeamMemberForm
  //               key={invitedMember.firstName + index}
  //               teamIndex={index}
  //               memberData={invitedMember}
  //               onAction={handleOnUpdate}
  //             />
  //           )
  //       )}
  //       <Button onClick={updateInvites} disabled={!hasInvitedMembersChange}>
  //         Update Invites
  //       </Button>
  //     </Box>
  //   )
  // }

  if (!isReady) {
    return <div>Form not Ready</div>
  }
  return (
    <VStack align="flex-start" spacing="4">
      <Box textStyle="head5">Email Addresses</Box>
      {ownerData && <OwnerTeamMemberForm memberData={ownerData} />}

      {!onboarding &&
        currentTeamArray.length > 0 &&
        currentTeamArray.map((item) => (
          <EditTeamMemberForm
            key={item.email}
            teamIndex={item.email}
            memberData={item}
          />
        ))}
      {newTeamArray.length > 0 &&
        newTeamArray.map((item, index) => (
          <EditTeamMemberForm
            key={item.email}
            teamIndex={item.email}
            memberData={item}
            onAction={handleOnAction}
          />
        ))}
      <AddTeamMemberForm onAction={handleOnAction} />
      <Flex
        width="100%"
        direction="row"
        justifyContent="center"
        alignItems="center"
        py="16"
      >
        <VStack spacing="4">
          <ChakraButton
            onClick={sendInvites}
            disabled={newTeamArray.length < 1}
          >
            Send Invites
          </ChakraButton>
          <Spacer />
          {hasSkipBtn && (
            <ChakraButton variant="form-link" onClick={handleNextAction}>
              SKIP FOR NOW
            </ChakraButton>
          )}
        </VStack>
      </Flex>
    </VStack>
  )
}

export default InviteYourTeam

// const handleOnUpdate = ({ action, data, teamIndex }) => {
//   const uArray = [...invitedMembersArray]

//   if (action === 'edit') {
//     const findx = teamIndex
//
//     uArray.splice(findx, 1)
//     setInvitedMembersArray(() => [...uArray, data])
//   }
//   if (action === 'remove') {
//     // const changeArray = _.remove(uArray, function (n) {
//     //   return n.uid === uid
//     // })
//     uArray.splice(teamIndex, 1)
//     //console.log(`findx=> ${findx}`)
//     // newArry.splice(findx, 1, data)
//     setInvitedMembersArray(() => [...uArray])
//   }
//   setHasInvitedMembersChange(true)
// }

/* {invitedMembersArray.length > 0 && <UpdateInvitedMembersForm />} */

/*teamArray.length > 0 &&
        teamArray.map((item) => (
          <EditTeamMemberForm
            key={item.uid}
            teamIndex={item.uid}
            memberData={item}
            onAction={handleOnAction}
          />
        ))*/

/* {team?.teamMembers.map((item) => (
        <EditTeamMemberForm
          key={item.uid}
          teamIndex={item.uid}
          memberData={item}
          onAction={handleOnAction}
        />
      ))} */
