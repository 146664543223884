import {
  Box,
  List,
  ListItem,
  Flex,
  Link,
  Spacer,
  Stack,
  Text,
  Button,
  Heading,
  Divider,
  VStack,
  Center,
  Grid,
  HStack,
  GridItem,
  AspectRatio,
  Tag
} from '@chakra-ui/react'

import Image from 'next/image'

import { MDXRemote } from 'next-mdx-remote'
import _ from 'lodash'

const Body2 = (props) => {
  return <Box textStyle="body2" {...props} />
}

const copycomp = {
  p: (props) => <Box textStyle="body2" w="100%" textAlign="center" {...props} />
}
const headcomp = {
  p: (props) => <Box textStyle="head5" w="100%" textAlign="center" {...props} />
}
const subcomp = {
  p: (props) => <Box textStyle="head6" w="100%" textAlign="center" {...props} />
}
const cardcolhead = {
  ol: (props) => <Box m="0" p="0" {...props} />,
  li: (props) => <ListItem {...props} />
}
const InfoCardColumn = ({
  headline,
  subheadline,
  copy,
  note,
  colSpan = 1,
  cardMedia
}) => {
  return (
    <GridItem
      rowSpan={1}
      colSpan={colSpan}
      display="flex"
      flexDirection="column"
      justifyContent={{ base: 'center', md: 'flex-start' }}
      alignItems="center"
      px="5"
    >
      {cardMedia && (
        <AspectRatio ratio="1" position="relative" width="100%">
          <Image
            src={cardMedia.url}
            alt={cardMedia.title}
            title={cardMedia.title}
            layout="fill"
            objectFit="cover"
          />
        </AspectRatio>
      )}

      <Box height="5" />
      <VStack spacing="2" px="5">
        {headline && <MDXRemote {...headline.mdx} components={headcomp} />}
        {subheadline && <MDXRemote {...subheadline.mdx} components={subcomp} />}
        {copy && <MDXRemote w="100%" {...copy.mdx} components={copycomp} />}
      </VStack>
    </GridItem>
  )
}
export default InfoCardColumn
