import React from 'react'
import { Box, SimpleGrid, VStack } from '@chakra-ui/react'

const FormFieldGroup = ({ label, children, ...props }) => {
  return (
    <VStack spacing="2" w="100%">
      <Box textStyle="subtitle2" alignItems="flex-start" w="100%">
        {label}
      </Box>
      <SimpleGrid w="100%" {...props}>
        {children}
      </SimpleGrid>
    </VStack>
  )
}
export default FormFieldGroup
