import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  VStack
} from '@chakra-ui/react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useGetModalData } from '@/hooks'

export default NiceModal.create(
  ({ modalName, hasExit = true, variant = 'default' }) => {
    const modal = useModal()
    const modalData = useGetModalData(modalName)

    return (
      <Modal
        variant={variant}
        isOpen={() => modal.show()}
        onClose={() => modal.remove()}
      >
        <ModalOverlay />
        <ModalContent>
          {hasExit && <ModalCloseButton onClick={() => modal.hide()} />}
          <ModalBody>
            <VStack>
              {modalData?.title && (
                <Box textStyle="head2">{modalData.title} </Box>
              )}
              {modalData?.subTitle && (
                <Box textStyle="bt2">{modalData.subTitle}</Box>
              )}
              {modalData?.copy && <Box textStyle="body1">{modalData.copy}</Box>}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
)
