const spacer = {
  none: '0', //10
  xxs: '10px',
  xs: '20px', //20
  sm: '30px', //32
  md: '40px', //40
  lg: '60px', //60
  xl: '80px', //80
  xxl: '100px' //100
}

const yPad = {
  none: '0', //10
  xs: '5', //20
  sm: '8', //32
  md: '10', //40
  lg: '15', //60
  xl: '20', //80
  xxl: '25' //100
}
const xPad = {
  none: '0',
  xs: '5', //20

  sm: '8', //32px
  md: '10', //40px
  lg: '15', //60px
  xl: '20' //80px
}

export function getPaddingX(size) {
  //console.log('paddingYsize', size)
  const px = spacer[size]
  //console.log('pX', px)
  return px
}
export function getPaddingY(size) {
  //console.log('paddingYsize', size)
  const pY = spacer[size]
  //console.log('pY', pY)
  return pY
}
