import React, { useRef, useEffect } from 'react'
import _ from 'lodash'

import NiceModal from '@ebay/nice-modal-react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { signOutSimple } from '@/lib/firebase'
import { HiMenuAlt2 } from 'react-icons/hi'
import { useFireAuth } from '@/lib/fireAuth'
import { NavLink, AccountTag, NavMenu } from '@/components'
import { ArrowDown } from '@/icons'

import {
  Icon,
  Box,
  Flex,
  VisuallyHidden,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  DrawerContent,
  useDisclosure,
  VStack,
  StackDivider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react'

import APPVARS from '@/constants/appvars'
import MODALS from '@/constants/modals'
import ROUTES from '@/constants/routes'

import { NavMenuDivider, NavMenuNote } from '@/elements'

const NavBar = ({ forModal = false }) => {
  const { status } = useFireAuth()
  const router = useRouter()
  const btnRef = useRef()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const modals = useSelector((state) => state.modals)

  useEffect(() => {
    const handleRouteStart = () => {
      //console.log('msize', msize)
      if (isOpen) {
        onClose()
      }
    }
    const handleRouteFinish = () => {
      //console.log('msize', msize)

      _.forOwn(modals, function (value) {
        //console.log('value', value)
        const mid = _.get(value, 'id')
        if (mid) {
          try {
            NiceModal.remove(mid)
          } catch (error) {
            console.log('error', error)
          }

          ////console.log(`key=${key} value=${value}`)
        }
      })
    }

    router.events.on('routeChangeStart', handleRouteStart)
    router.events.on('routeChangeComplete', handleRouteFinish)

    return () => {
      router.events.off('routeChangeStart', handleRouteStart)
      router.events.off('routeChangeComplete', handleRouteFinish)
    }
  })

  // useEffect(() => {
  //   const { pathname } = router

  //   // setRoutePath(pathname)
  // }, [router])

  const onSignIn = () => {
    const routerPath = router.asPath
    NiceModal.show(`${MODALS.SIGN_IN}`, {
      variant: 'normal',
      size: 'md',
      slug: routerPath
    })
      .then(() => {
        if (router.asPath === '/' || router.asPath === '/home') {
          NiceModal.remove(`${MODALS.SIGN_IN}`)
        } else {
          router.push(`${ROUTES.HOME}`)
        }
      })

      .catch(() => {
        //console.log('error', error)
      })
  }

  const onSignOut = async () => {
    try {
      signOutSimple().then(() => {
        router.push(`${ROUTES.HOME}`)
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  if (forModal) {
    return null
  }

  return (
    <>
      <Box display={{ base: 'none', md: 'flex' }}>
        <NavMenu menuItems={APPVARS.MENU_LIST_PRIVATE}>
          {status === 'loggedOut' && (
            <>
              <Box>
                <NavLink
                  to="log-in"
                  onClick={onSignIn}
                  variant="navbtn"
                  aria-label="LogIn"
                >
                  Log In
                </NavLink>
              </Box>
              <NavMenuDivider isVertical={false} />
              <Box>
                <NavLink
                  to={ROUTES.ONBOARDING_NEW_MEMBER_SIGN_UP}
                  variant="navbtn"
                  aria-label="Sign Up"
                >
                  Sign Up
                </NavLink>
              </Box>
            </>
          )}
          {status === 'loggedIn' && (
            <>
              <Box>
                <NavLink variant="navbtn" px="0" to={ROUTES.ACCOUNT}>
                  ACCOUNT
                </NavLink>
              </Box>
              <Box px="2">
                <NavLink
                  variant="navbtn"
                  px="0"
                  to={ROUTES.ACCOUNT}
                  aria-label="account"
                >
                  <AccountTag />
                </NavLink>
              </Box>
              <Box>
                <Menu role="menu" aria-label="Menu">
                  <MenuButton
                    role="menubutton"
                    aria-label="Menu Button"
                    variant="ghost"
                    as={IconButton}
                    size="icon-nav"
                    icon={<Box as={ArrowDown} aria-hidden="true" />}
                  >
                    <VisuallyHidden>Open menu</VisuallyHidden>
                    <Box as={ArrowDown} aria-hidden="true" />
                  </MenuButton>

                  <MenuList>
                    <MenuItem onClick={onSignOut} aria-label="Sign Out">
                      <Box textStyle="btn-text1">Sign Out</Box>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </>
          )}
        </NavMenu>
      </Box>

      <Flex
        flexGrow="1"
        align="center"
        flexShrink="0"
        justifySelf="flex-end"
        justifyContent="flex-end"
        display={{ base: 'flex', md: 'none' }}
      >
        <IconButton
          variant="ghost"
          size="icon-nav"
          icon={
            <Icon
              color="black"
              width="24px"
              height="24px"
              as={HiMenuAlt2}
              aria-hidden="true"
            />
          }
          onClick={onOpen}
        >
          <VisuallyHidden>Open menu</VisuallyHidden>
          <Icon as={HiMenuAlt2} aria-hidden="true" />
        </IconButton>
      </Flex>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        isCentered={true}
        size="full"
      >
        <DrawerOverlay />

        <DrawerContent p={5}>
          <DrawerCloseButton />
          <DrawerHeader></DrawerHeader>
          <NavMenuNote status="info">
            Elevate is a desktop-only web app.
          </NavMenuNote>

          <NavMenu isVertical={true} menuItems={APPVARS.MENU_LIST_MOBILE}>
            <DrawerBody p="0" w="full">
              {status === 'loggedIn' ? (
                <Box w="100%" alignItems="center" spacing="10px" align="center">
                  <NavLink
                    aria-label="Sign Out"
                    to="log-out"
                    onClick={() => onSignOut()}
                  >
                    <Box textStyle="nav-btn2">Sign Out</Box>
                  </NavLink>
                </Box>
              ) : (
                <VStack
                  w="100%"
                  alignItems="center"
                  spacing="10px"
                  align="center"
                  divider={<StackDivider borderColor="gray.200" />}
                >
                  <Box>
                    <NavLink
                      aria-label="Log In"
                      to="log-in"
                      onClick={() => onSignIn()}
                    >
                      <Box textStyle="nav-btn2">Log in</Box>
                    </NavLink>
                  </Box>

                  <Box>
                    <NavLink
                      aria-label="Sign Up"
                      to={ROUTES.ONBOARDING_NEW_MEMBER_SIGN_UP}
                    >
                      <Box textStyle="nav-btn2">Sign Up</Box>
                    </NavLink>
                  </Box>
                </VStack>
              )}
            </DrawerBody>
          </NavMenu>
        </DrawerContent>
      </Drawer>
    </>
  )
}
export default NavBar
