const APPVARS = {
  BLOCK_MAX_WIDTH_BASE: 'calc(100vw - 60px)',
  BLOCK_MAX_WIDTH_FULL: '964px',
  MAX_BLOCK_WIDTH: '964px',
  NAV_BAR_HEIGHT: '76px',
  HRZ: {
    XS: '4px', //pink
    SM: '8px', //red
    MD: '12px', //green
    LG: '24px', //purple
    XL: '48px', //orange
    '2XL': '72px', //lt blue
    '3XL': '96px' //yellow
  },
  VRT: {
    XS: '4px',
    SM: '8px',
    MD: '12px', //green
    LG: '24px', //dk purple
    XL: '30px', //neon green
    '2XL': '80px' //ltblue
  },
  FORM_FIELD_VALUES: {
    LABEL_HEIGHT: '26px'
  },
  MENU_LIST_PRIVATE: [
    {
      id: 'home',
      label: 'home',
      slug: 'home',
      isPrivate: false
    },

    {
      id: 'discover',
      label: 'Discover',
      slug: 'discover',
      isPrivate: false
    },
    {
      id: 'benefits',
      label: 'Benefits',
      slug: 'benefits',
      isPrivate: false
    }
  ],
  MENU_LIST_MOBILE: [
    {
      id: 'home',
      label: 'home',
      slug: 'home',
      isPrivate: false
    },

    {
      id: 'discover',
      label: 'Discover',
      slug: 'discover',
      isPrivate: false
    },

    {
      id: 'benefits',
      label: 'Benefits',
      slug: 'benefits',
      isPrivate: true
    }
  ]
}
export default APPVARS
