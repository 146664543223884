import { Grid, Box, VStack, SimpleGrid } from '@chakra-ui/react'
import { RichText } from '@graphcms/rich-text-react-renderer'
import { getPaddingY } from '@/theme/helpers'
import { MDXRemote } from 'next-mdx-remote'

import APPVARS from '@/constants/appvars'
import * as Columns from '@/columns'
const mdText = {
  h3: (props) => (
    <Box textStyle="head3" w="100%" textAlign="center" {...props} />
  ),
  p: (props) => (
    <Box mt="5" textStyle="body2" w="100%" textAlign="center" {...props} />
  )
}

export default function GridBlock({
  children,
  columnComponent,
  columns,
  gridTextAlign,
  gridRichText,
  gridMdText,
  gridGap,
  layout = 'STACK',
  cols = 1
}) {
  const maxBlockWidth = APPVARS.MAX_BLOCK_WIDTH
  // const simpleCols = useBreakpointValue({ base: 1, sm: 2, md: 4 })
  if (!columns || !columns.length) return null

  const yPad10 = getPaddingY('xxs')
  const yPad40 = getPaddingY('md')
  const yPad60 = getPaddingY('lg')
  //console.log('gspacing', gspacing)
  //const gspacing = getGridSpacing(gridSpacing)
  const stackLayout = layout === 'STACK'
  const simpleLayout = layout === 'SIMPLE'
  const fluidLayout = layout === 'FLUID'
  const gridLayout = layout === 'GRID'
  //console.log('gspacing', gspacing)
  // const containerWidth = blockWidth ? `container.${blockWidth}` : `container.lg`
  //console.log('simpleCols', simpleCols)
  return (
    <Box
      className="GRIDBLOCK"
      overflow="hidden"
      width="100%"
      mx="auto"
      align="center"
      maxWidth={{ base: 'calc(100vw - 60px)', lg: `${maxBlockWidth}` }}
    >
      {gridRichText && (
        <Box pt={yPad60} textAlign={gridTextAlign}>
          <RichText
            content={gridRichText.json}
            renderers={{
              h3: ({ children }) => <Box textStyle="head3">{children}</Box>,
              bold: ({ children }) => <strong>{children}</strong>
            }}
          />
        </Box>
      )}
      {gridMdText && (
        <Box mb={yPad10}>
          <MDXRemote {...gridMdText.mdx} components={mdText} />
        </Box>
      )}
      <Box className="COLUMN COMPONENT">
        {gridLayout && (
          <Grid
            gridTemplateColumns={{
              base: '268px',
              md: '268px 268px',
              lg: 'repeat(3,268px)'
            }}
            justifyContent="center"
            alignContent="center"
            gridTemplateRows="300px"
            gridAutoFlow="row dense"
            className="GRID"
            rowGap={APPVARS.HRZ['2XL']}
            columnGap={APPVARS.VRT['2XL']}
            py={APPVARS.HRZ.XL}
          >
            {children
              ? children()
              : columns.map((column) => {
                  const Component =
                    Columns[columnComponent] || Columns[column.__typename]

                  if (!Component) return null

                  return <Component key={column.id} {...column} />
                })}
          </Grid>
        )}
        {simpleLayout && (
          <SimpleGrid
            justifyContent={{ base: 'center', md: 'stretch' }}
            className="GRID_SIMPLE"
            py={yPad40}
            columns={{ base: 1, md: 2, lg: `${cols}` }}
            // spacingX={gridGap}
            // spacingY={gridGap}
            spacing="48px"
          >
            {children
              ? children()
              : columns.map((column) => {
                  const Component =
                    Columns[columnComponent] || Columns[column.__typename]

                  if (!Component) return null

                  return <Component key={column.id} {...column} />
                })}
          </SimpleGrid>
        )}

        {fluidLayout && (
          <SimpleGrid
            minChildWidth="190px"
            spacing={gridGap}
            className="GRID_FLUID"
          >
            {children
              ? children()
              : columns.map((column) => {
                  const Component =
                    Columns[columnComponent] || Columns[column.__typename]

                  if (!Component) return null

                  return <Component key={column.id} {...column} />
                })}
          </SimpleGrid>
        )}

        {stackLayout && (
          <VStack
            className="StackLayout"
            spacing="0"
            width="100%"
            maxWidth={maxBlockWidth}
          >
            {children
              ? children()
              : columns.map((column) => {
                  const Component =
                    Columns[columnComponent] || Columns[column.__typename]

                  if (!Component) return null

                  return <Component key={column.id} {...column} />
                })}
          </VStack>
        )}
      </Box>
    </Box>
  )
}
