import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react'
import NiceModal, {
  useModal,
  show,
  create,
  remove,
  register
} from '@ebay/nice-modal-react'


export default NiceModal.create(({ name, variant, hasExit, onAction, onCancel}) => {
  const modal = useModal()

  return (
    <Modal
      variant={variant}
      isOpen={() => modal.show()}
      onClose={() => modal.remove()}
    >
      <ModalOverlay />
      <ModalContent>
            {hasExit ? <ModalCloseButton onClick={() => modal.hide()} /> : ''}
        <ModalBody>{name}</ModalBody>
      </ModalContent>
    </Modal>
  )
})

