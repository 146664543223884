import * as React from 'react'

function SvgLogoHonBW(props) {
  return (
    <svg
      width="59"
      height="18"
      viewBox="0 0 59 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3137_9687)">
        <path
          d="M28.142 17.9182C23.2125 17.9182 19.183 13.9091 19.183 8.95909C19.183 4.00909 23.192 0 28.142 0C33.092 0 37.1011 4.00909 37.1011 8.95909C37.1011 13.9091 33.0716 17.9182 28.142 17.9182ZM28.142 3.96818C25.3193 3.96818 23.0284 6.23864 23.0284 9.06136C23.0284 11.8841 25.2989 14.1341 28.142 14.1341C30.9648 14.1341 33.2557 11.8841 33.2557 9.06136C33.2352 6.23864 30.9648 3.96818 28.142 3.96818ZM51.6034 17.6114V6.83182C51.6034 5.44091 50.458 4.29545 49.0466 4.29545H42.808V17.6114H38.7375V0.409091H49.4148C52.7693 0.409091 55.5307 3.17045 55.5307 6.525V17.6114H51.6034ZM13.5784 17.5909V11.9659H4.9875V17.5909H0.9375V0.409091H4.9875V7.99773H13.5784V0.409091H17.5261V17.5909H13.5784ZM57.4125 17.4068C56.8193 17.4068 56.3284 16.9159 56.3284 16.3227C56.3284 15.7295 56.8193 15.2386 57.4125 15.2386C58.0057 15.2386 58.4966 15.7295 58.4966 16.3227C58.4966 16.9364 58.0057 17.4068 57.4125 17.4068ZM57.4125 15.5045C56.9625 15.5045 56.6148 15.8727 56.6148 16.3432C56.6148 16.8136 56.9625 17.1818 57.4125 17.1818C57.8625 17.1818 58.2102 16.8136 58.2102 16.3432C58.2102 15.8727 57.8625 15.5045 57.4125 15.5045ZM57.617 16.4455L57.9034 16.8955H57.5966L57.3307 16.4659H57.2693V16.8955H56.983V15.75H57.3511C57.617 15.75 57.8625 15.8114 57.8625 16.0773C57.8829 16.3023 57.7398 16.425 57.617 16.4455ZM57.4125 15.975H57.2693V16.2205H57.4125C57.4739 16.2205 57.5966 16.2205 57.5966 16.0977C57.5966 15.9955 57.4943 15.975 57.4125 15.975Z"
          fill="#3B3F46"
        />
      </g>
      <defs>
        <clipPath id="clip0_3137_9687">
          <rect
            width="57.6818"
            height="18"
            fill="white"
            transform="translate(0.9375)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgLogoHonBW
