import { useState, useCallback } from 'react'

import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import MODALS from '@/constants/modals'
import NiceModal from '@ebay/nice-modal-react'

const CheckWindowScroll = ({ scrollHeight }) => {
  const [loopCheck, setLoopCheck] = useState(false)
  const [modalLoad, setModalLoad] = useState(false)
  const [showArticleBlock, setShowArticleBlock] = useState(false)
  //const modalSignUp = useModal(`${MODALS.SIGN_UP_TO_CONTINUE}`)

  const hasWindow = typeof window !== 'undefined'

  const showSignUpToContinue = useCallback(() => {
    if (!modalLoad) {
      setModalLoad(true)
      NiceModal.show(`${MODALS.SCROLL_BLOCKER_MODAL}`, {
        variant: 'blocker',
        hasExit: false
      })
    }
  }, [modalLoad])

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //const isScrollUp  = currPos.y > prevPos.y
      //const winHeight = hasWindow ? window.innerHeight : null
      //console.log(`curPos`, currPos)
      //console.log(`scrollHeight`, scrollHeight)
      //const breakPoint = scrollHeight * -0.2
      // const breakPoint = window.innerHeight * -1.5
      const breakPoint = -300
      //console.log(`breakPoint`, breakPoint)
      const pastPoint = currPos.y < breakPoint
      if (pastPoint && !loopCheck) {
        setShowArticleBlock(true)
        setLoopCheck(true)

        showSignUpToContinue()
      }
    },
    [showArticleBlock]
  )
  return null
}

export default CheckWindowScroll

// useScrollPosition(({ prevPos, currPos }) => {
//  //console.log(currPos.x)
//  //console.log(currPos.y)
// })
