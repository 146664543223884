import { getErrorByCode } from '@/utils/_errors'

const getAlertHtmlContent = ({ type, code, msg }) => {
  if (type === 'AUTH') {
    const message = getErrorByCode(code)
    return { __html: `${message}` }
  }
  return { __html: `${msg}` }
}
export { getAlertHtmlContent }
