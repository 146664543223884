import {
  Box,
  Flex,
  Link,
  Stack,
  Text,
  Heading,
  Spacer,
  VStack,
  Button,
  Divider,
  StackDivider
} from '@chakra-ui/react'

import Image from 'next/image'

export default function FlipResourceBlock({
  resourceTitle,
  resourceSubtitle,
  resourceSlug,
  resourceContent,
  resourceCtaLink,
  resourceCtaLabel,
  resourceImage,
  flip
}) {
  return (
    <Stack
      bg="white"
      direction={{ base: 'column', md: `${flip ? 'row' : 'row-reverse'}` }}
      spacing={3}
      justifyContent="space-between"
    >
      <Box
        flexGrow="0"
        flexShrink="1"
        flexbasis="0"
        justifyContent="center"
        align="center"
      >
        <Image
          src={resourceImage.url}
          alt={resourceImage.title}
          title={resourceImage.title}
          width={resourceImage.width}
          height={resourceImage.height}
          objectFit="cover"
          objectPosition="50% 50%"
          resize="both"
          transform="scale(0.7)"
        />
      </Box>
      <Box />
      <VStack flex="3 1 0" align="stretch">
        <Box textStyle="head5">{resourceTitle}</Box>
        <Box textStyle="body1" color="gray.400">
          {resourceSubtitle}
        </Box>

        <Box textStyle="bt2">{resourceContent}</Box>
        <Flex justifyContent={{ base: 'center', md: 'flex-start' }}>
          <Link href={resourceCtaLink} target="_blank" m={0} p={0}>
            <Button
              mt={{ base: 2, md: 5 }}
              w={{ base: '100%', sm: 32 }}
              variant="outline"
              target="_blank"
            >
              {resourceCtaLabel ? resourceCtaLabel : 'Read More'}
            </Button>
          </Link>
        </Flex>
      </VStack>
    </Stack>
  )
  //   <Flex flexDir="column" overflow="hidden">
  //     <Flex
  //       display="flex"
  //       flex="1 1 0"
  //       bg="white"
  //       flexDirection="row"
  //       justifyContent="space-between"
  //     >
  //       <Box flexShrink="0">
  //         {resourceImage && (
  //           <Image
  //             className="blog-post-card-image"
  //             src={resourceImage.url}
  //             alt={resourceImage.title}
  //             title={resourceImage.title}
  //             width={resourceImage.width}
  //             height={resourceImage.height}
  //             objectFit="cover"
  //           />
  //         )}
  //       </Box>

  //       <Box px={2} flex="1 1 0">
  //         <Title variant="">{title}</Title>
  //         <Heading
  //           as="h4"
  //           fontSize="md"
  //           fontWeight="medium"
  //           color="indigo.600"
  //           textAlign="left"
  //         >
  //           {title}
  //         </Heading>
  //         <Subtitle textAlign="left">{subtitle}</Subtitle>
  //         <Text
  //           fontSize="sm"
  //           fontWeight="medium"
  //           color="indigo.600"
  //           textAlign="left"
  //         >
  //           {resourceContent}
  //         </Text>
  //       </Box>
  //     </Flex>
  //   </Flex>
  // )
}
