const checkbox = {
  baseStyle: {
    label: { py: '0' },
    icon: { mt: '0' },
    control: { mt: '1' }
  },
  sizes: {
    sm: {
      control: { h: 3, w: 3 },
      label: { fontSize: 'sm' },
      icon: { fontSize: '0.45rem' }
    },
    md: {
      control: { w: 4, h: 4 },
      label: { fontSize: 'md' },
      icon: { fontSize: '0.625rem' }
    },
    md2: {
      control: { w: '18px', h: '18px' },
      label: { fontSize: 'md2' },
      icon: { fontSize: '1.125rem' }
    },
    lg: {
      control: { w: 5, h: 5 },
      label: { fontSize: 'lg' },
      icon: { fontSize: '0.625rem' }
    }
  },
  defaultProps: {
    size: 'md',
    colorScheme: 'blue'
  }
}

export default checkbox
